import {
    Box,
    Button,
    createStyles,
    makeStyles,
    styled,
    TablePaginationProps,
    TextField,
    Theme,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState, useContext } from "react";
import { useIntl } from "react-intl";
import { UtilFunctions } from "../utils/UtilFunctions";
import { RwdContext } from "../App";

export type PaginationState = {
    curPage: number;
    rowsPerPage: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "22px",
            userSelect: "none",
        },
        wrapper_m: {
            width: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "8px",
            userSelect: "none",
        },
    })
);

// type Cust = Omit<TablePaginationProps, "onChangePage"> & {
//     onChangePage(draft: (_: WritableDraft<PaginationState>) => void | PaginationState): void;
// };

type Cust = Omit<TablePaginationProps, "onChangePage"> & {
    pageChangeHandler(action: Action, value?: number | undefined): void;
};

/**
 * Action for navigating pages
 */
export enum Action {
    PREV_PAGE,
    NEXT_PAGE,
    JUMP,
}

function CustomizeTablePagination(props: Cust) {
    const intl = useIntl();
    const classes = useStyles();
    const { count, rowsPerPage, page, pageChangeHandler } = props;
    const [curPageInput, updateCurPageInput] = useState("");
    const ref = React.useRef<HTMLDivElement | null>(null);
    const device = useContext(RwdContext);

    useEffect(() => {
        updateCurPageInput(page + "");
        UtilFunctions.delayInMs(1).then(() => ref.current?.scrollIntoView());
    }, [page]);

    const totalPage = useMemo(() => {
        if (count > rowsPerPage) {
            return Math.ceil(count / rowsPerPage);
        }
        return 1;
    }, [count, rowsPerPage]);

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        let countDecimals = totalPage.toString().split("").length * 2;
        if (value.length <= countDecimals) {
            updateCurPageInput(value);

            let valueInNum = parseInt(value);

            if (valueInNum && valueInNum > 0 && valueInNum <= totalPage) {
                pageChangeHandler(Action.JUMP, valueInNum);
            }
        }
    };
    const renderBody = () => {
        return (
            <Box
                display="flex"
                alignItems="center"
                marginLeft="12px"
                marginRight="12px"
                fontSize={device == "PC" ? "22px" : "14px"}
            >
                <Box display="inline-block" width={device == "PC" ? `${totalPage + 5}ch` : `auto`}>
                    <CurrentPageInput
                        value={curPageInput}
                        onBlur={() => updateCurPageInput(page.toString())}
                        InputProps={{ disableUnderline: true, fullWidth: true }}
                        inputProps={{
                            style: {
                                textAlign: "center",
                            },
                        }}
                        onChange={inputHandler}
                    />
                </Box>
                {`/ ` + intl.formatMessage({ id: "editor.allPosts.4.019" }, { page: totalPage })}
            </Box>
        );
    };

    return (
        <Box
            // @ts-ignore
            ref={ref}
            className={device == "PC" ? classes.wrapper : classes.wrapper_m}
        >
            <NavButton
                disabled={page === 1}
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => pageChangeHandler(Action.PREV_PAGE)}
            >
                {intl.formatMessage({ id: "editor.allPosts.4.018" })}
            </NavButton>
            {renderBody()}
            <NavButton
                disabled={page === totalPage}
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => pageChangeHandler(Action.NEXT_PAGE)}
            >
                {intl.formatMessage({ id: "editor.allPosts.4.02" })}
            </NavButton>
        </Box>
    );
}
const NavButton = styled(Button)({
    background: "#22ab39",
    border: 0,
    borderRadius: 3,
    color: "#ffffff",
    paddingLeft: 17,
    paddingRight: 17,
    fontSize: 22,
    "&:disabled": {
        backgroundColor: "#d8d8d8",
        color: "#222222",
    },
});
const NavButtonMobile = styled(Button)({
    background: "#22ab39",
    border: 0,
    borderRadius: 3,
    color: "#ffffff",
    paddingLeft: 17,
    paddingRight: 17,
    fontSize: 16,
    "&:disabled": {
        backgroundColor: "#d8d8d8",
        color: "#222222",
    },
});
const CurrentPageInput = styled(TextField)({
    backgroundColor: "#ffffff",
    color: "#222222",
    borderRadius: 3,
    height: 43,
    fontSize: 22,
    display: "inline-flex",
    justifyContent: "center",
    outline: 0,
    marginRight: 12,
});
export default CustomizeTablePagination;
