import { makeStyles, Theme, createStyles, IconButton } from "@material-ui/core";
import React, { useContext } from "react";
import { Animated } from "react-animated-css";
import { ReactComponent as OverlayEditIc } from "../assets/editor/bn_edit.svg";
import { ReactComponent as OverlayRemoveIc } from "../assets/editor/bn_remove_circle.svg";
import { ReactComponent as OverlayAdminViewIc } from "../assets/admin/img_view.svg";
import { useIntl } from "react-intl";
import { ArticleStatus } from "../interfaces/enums";
import { RwdContext } from "../App";

export type ArticleInGridData = {
    title: string;
    category: JSX.Element[];
    post_date: string;
    update_date: string;
    status: ArticleStatus;
    statusText: string;
    rejectText: string;
    img: any;
};

interface props {
    data: ArticleInGridData;
    statusElement: JSX.Element | string;
    deniedElement?: JSX.Element;
    deleteHandler: (_) => void;
    clickThroughHandler: (_) => void;
    isAdmin: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid_block: {
            "&:not(:nth-child(-n + 3))": {
                marginTop: "3%",
            },
            padding: 10,
            backgroundColor: "#fff",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
            flexBasis: "30%",
            height: "399px",
            overflow: "hidden",
            borderRadius: "6px",
            "& span": {
                display: "block",
                fontSize: "18px",
                "&:not(:last-child)": {
                    marginBottom: 6,
                },
            },
            "& .bold": {
                fontWeight: "bold",
            },
            "& .status": {
                marginRight: "13px",
            },
        },
        grid_block_m: {
            marginTop: "2%",
            width: "auto",
            height: "auto",
            padding: 10,
            backgroundColor: "#ffffff",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
            flexBasis: "30%",
            overflow: "auto",
            borderRadius: "6px",
            "& span": {
                display: "block",
                fontSize: "18px",
                "&:not(:last-child)": {
                    marginBottom: 6,
                },
            },
            "& .bold": {
                fontWeight: "bold",
            },
            "& .status": {
                marginRight: "13px",
            },
        },
        cover_img: {
            position: "relative",
            height: "226px",
            marginBottom: "4px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",

            "& .overlay": {
                position: "absolute",
                width: "100%",
                height: "100%",
                margin: "-10px",
                padding: "10px 10px 4px 10px",
                backgroundColor: "rgba(34, 34, 34, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },

            "& .ic": {
                margin: 16,
            },
        },
        title: {
            display: "-webkit-box",
            WebkitLineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitBoxOrient: "vertical",
            margin: 0,
            marginBottom: 6,
            minHeight: "43.200px",
        },
        title_m: {
            fontSize: "18px",
            margin: "4px 0 9px",
            fontWeight: "bold",
            lineHeight: 1.33,
            textAlign: "left",
            color: "#222222",
        },
    })
);

function ArticleInGrid(props: props) {
    const classes = useStyles();
    const device = useContext(RwdContext);
    const { data, statusElement, deniedElement, deleteHandler, clickThroughHandler, isAdmin } = props;
    const [visible, setVisible] = React.useState(false);
    const { formatMessage } = useIntl();

    const set_to_on = () => setVisible(true);
    const set_to_off = () => setVisible(false);
    const mobile_set = () => setVisible(!visible);

    const IcOverlay = React.useMemo(
        () => (
            <div>
                <IconButton aria-label="overlay edit" className="ic" onClick={clickThroughHandler}>
                    {isAdmin ? <OverlayAdminViewIc /> : <OverlayEditIc />}
                </IconButton>

                {isAdmin ? null : (
                    <IconButton aria-label="overlay remove" onClick={deleteHandler}>
                        <OverlayRemoveIc />
                    </IconButton>
                )}
            </div>
        ),
        [isAdmin]
    );

    return (
        <div
            className={device == "PC" ? classes.grid_block : classes.grid_block_m}
            style={data.status === ArticleStatus.PUBLISH_DENIED ? { backgroundColor: "#ffdfdf" } : undefined}
        >
            <div
                className={classes.cover_img}
                style={{
                    backgroundImage: `url("${data.img}")`,
                }}
                onMouseEnter={device == "PC" ? set_to_on : () => {}}
                onMouseLeave={device == "PC" ? set_to_off : () => {}}
                onClick={device == "PC" ? () => {} : mobile_set}
            >
                <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    isVisible={visible}
                    animationInDuration={300}
                    animationOutDuration={300}
                    animateOnMount={false}
                >
                    <div className="overlay">
                        <Animated
                            animationIn="fadeInUp"
                            animationOut="fadeOutDown"
                            isVisible={visible}
                            animationInDuration={300}
                            animationOutDuration={300}
                        >
                            {IcOverlay}
                        </Animated>
                    </div>
                </Animated>
            </div>

            {/* <span>{props.data.title}</span> */}
            <p className={`${device == "PC" ? classes.title : classes.title_m} bold`}>{props.data.title}</p>
            <span>{data.category}</span>
            <span>
                {formatMessage({ id: "editor.allPosts.4.014" })}：{data.post_date}
            </span>
            <span>
                {formatMessage({ id: "editor.allPosts.4.015" })}：{data.update_date}
            </span>
            <span>
                <span
                    className="status"
                    style={{
                        display: "inline",
                    }}
                >
                    {formatMessage({ id: "editor.allPosts.4.016" })}：
                </span>
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                    {typeof statusElement !== "string" ? statusElement : <span className="bold">{statusElement}</span>}{" "}
                    {deniedElement}
                </span>
            </span>
        </div>
    );
}

export default ArticleInGrid;
