import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Location } from "history";

function usePrompt(message, when) {
    const history = useHistory();
    const unblock = useRef<Function | null>(null);

    useEffect(() => {
        if (when) {
            unblock.current = history.block(message);
        } else {
            unblock.current = null;
        }
        return () => {
            if (unblock.current) {
                unblock.current();
            }
        };
    }, [when, history, message]);
}

export function useCallbackPrompt(when) {
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const cancelNavigation = useCallback(() => {
        setShowPrompt(false);
    }, []);

    const handleBlockedNavigation = useCallback(
        (nextLocation) => {
            if (!confirmedNavigation) {
                setShowPrompt(true);
                setLastLocation(nextLocation);
                return false;
            }
            return true;
        },
        [confirmedNavigation]
    );

    const confirmNavigation = useCallback(() => {
        setShowPrompt(false);
        setConfirmedNavigation(true);
    }, []);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            history.push(lastLocation.pathname, lastLocation.state);
        }
    }, [confirmedNavigation, lastLocation]);

    usePrompt(handleBlockedNavigation, when);

    return [showPrompt, confirmNavigation, cancelNavigation];
}
