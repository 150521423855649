import React, { useState } from "react";
import {
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    makeStyles,
    styled,
    ClickAwayListener,
} from "@material-ui/core";
import { useIntl } from "react-intl";

import { ReactComponent as DragIcon } from "../assets/store/ic_drag_handle.svg";
import { ReactComponent as EditIcon } from "../assets/store/ic_edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/store/ic_delete.svg";
import ImgAdvancePromotionBox from "../assets/dummyImage/Advance promotion box.jpg";
import ImgBrandLogo from "../assets/dummyImage/Product or Brand Logo.jpg";
import ImgDeliveryInfo from "../assets/dummyImage/Delivery Information.jpg";
import ImgHotPicks from "../assets/dummyImage/Hot picks.jpg";
import ImgRotatingBanner from "../assets/dummyImage/Rotating Banner.jpg";
import ImgShopDescription from "../assets/dummyImage/Shop Description.jpg";
import ImgStorePageOptionalBanner from "../assets/dummyImage/Store Page Optional Banner.jpg";
import ImgStorePickList from "../assets/dummyImage/Store Pick List.jpg";
import ImgStoreRemarks from "../assets/dummyImage/Store Remarks.jpg";
import ImgTnc from "../assets/dummyImage/T & C.jpg";
import ImgBanner1 from "../assets/dummyImage/img_banner1.svg";
import ImgBanner2 from "../assets/dummyImage/img_banner2.svg";
import ImgBanner3 from "../assets/dummyImage/img_banner3.svg";
import ImgBanner4 from "../assets/dummyImage/img_banner4.svg";

import { ModuleDialog, ModuleErrorDialog } from "./dialogs/ModuleDialog";
import { Module, ModuleList } from "../pages/EditAppPage";
import { HtmlType, StoreBannerComponentType } from "../interfaces/enums";

// Define props
type DragOrderListItemProps = {
    isFrom: boolean;
    isTo: boolean;
    isDragging: boolean;
    onMouseDown: () => void;
    onMouseOver: (e, n: number) => void;
    onEditModule?: () => void;
    onDeleteModule?: () => void;
} & Module;
type DragOrderListProps = {
    items: ModuleList;
    onChangeOrder: (o: ModuleList) => void;
    onEditModule?: (i: number) => void;
    onDeleteModule?: (i: number) => void;
};

// Divider for indicating module position after dragging
const HighlightDivider = styled(Divider)({ background: "#5fbcff", height: 3 });

// List component for each module item
const DragListItem = (props: DragOrderListItemProps) => {
    const intl = useIntl();
    const { name, enable, type, onEditModule, onDeleteModule, isFrom, isTo, isDragging, onMouseDown, onMouseOver } =
        props;
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const onCloseDialog = () => setOpenDeleteDialog(!openDeleteDialog);

    // const PictureMatch =
    const IconStyle = { cursor: "pointer", marginLeft: "15px" };
    const deleteStyle = { cursor: "pointer", marginLeft: "15px" };
    const useStyles = makeStyles({
        button: {
            cursor: "default",
            background: isFrom ? "rgba(58, 153, 58, 0.2)" : "#FFFFFF",
            "&.disabled": { background: "#E8E8E8" },
            "&:hover": { background: isDragging && !isFrom ? "#FFFFFF" : "rgba(58, 153, 58, 0.2)" },
        },
        root: { minWidth: 40, cursor: "grab" },
        primary: { color: "#3a993a" },
        middle: { width: "calc(100% - 32px)" },
        LeftIconSetAndName: { display: "inline-flex", alignItems: "center" },
        dummyAndIconSet: { display: "inline-flex", justifyContent: "space-between" },
    });
    const classes = useStyles();

    const dummyImg = (type: string) => {
        let srcPath;
        switch (type) {
            case "STORE_REMARKS":
                srcPath = ImgStoreRemarks;
                break;
            case "ROTATING_BANNER":
                srcPath = ImgRotatingBanner;
                break;
            case "ADVANCED_PROMOTION_BOX":
                srcPath = ImgAdvancePromotionBox;
                break;
            case "STORE_PICK_LISTS":
                srcPath = ImgStorePickList;
                break;
            case "STORE_OPTIONAL_BANNER":
                srcPath = ImgStorePageOptionalBanner;
                break;
            case "HOT_PICKS":
                srcPath = ImgHotPicks;
                break;
            case "STORE_INFO":
                srcPath = ImgShopDescription;
                break;
            case "PRODUCT_OR_BRAND_LOGO":
                srcPath = ImgBrandLogo;
                break;
            case "DELIVERY_INFO":
                srcPath = ImgDeliveryInfo;
                break;
            case "TERMS_AND_CONDITION":
                srcPath = ImgTnc;
                break;
            case "IMAGE_GALLERY_LAYOUT_1":
                //image src
                srcPath = ImgBanner1;
                break;
            case "IMAGE_GALLERY_LAYOUT_2":
                //image src
                srcPath = ImgBanner2;
                break;
            case "IMAGE_GALLERY_LAYOUT_3":
                //image src
                srcPath = ImgBanner3;
                break;
            case "IMAGE_GALLERY_LAYOUT_4":
                //image src
                srcPath = ImgBanner4;
                break;
        }
        return <img src={srcPath} style={{ width: 250 }} />;
    };

    const editIcon = (type) => {
        let iconComponent;
        switch (type) {
            case StoreBannerComponentType.IMAGE_GALLERY_LAYOUT_1:
            case StoreBannerComponentType.IMAGE_GALLERY_LAYOUT_2:
            case StoreBannerComponentType.IMAGE_GALLERY_LAYOUT_3:
            case StoreBannerComponentType.IMAGE_GALLERY_LAYOUT_4:
            case StoreBannerComponentType.IMAGE_GALLERY_LAYOUT_5:
            case StoreBannerComponentType.IMAGE_GALLERY_LAYOUT_6:
            case StoreBannerComponentType.IMAGE_GALLERY_LAYOUT_7:
            case StoreBannerComponentType.IMAGE_GALLERY_LAYOUT_8:
            case HtmlType.CUSTOM_HTML:
                iconComponent = (
                    <>
                        <EditIcon style={IconStyle} onClick={() => onEditModule && onEditModule()} />
                        <DeleteIcon style={deleteStyle} onClick={() => setOpenDeleteDialog(!openDeleteDialog)} />
                    </>
                );
                break;
            default:
                break;
        }
        return iconComponent;
    };

    return (
        <>
            <ListItem
                button
                disableRipple
                className={`${classes.button} draglist-item ${enable ? "" : "disabled"}`}
                onMouseOver={(e) =>
                    onMouseOver &&
                    (e.nativeEvent.offsetY <= e.currentTarget.offsetHeight / 2 ? onMouseOver(e, 0) : onMouseOver(e, 1))
                }
                style={{ justifyContent: "space-between" }}
            >
                <div className={classes.LeftIconSetAndName}>
                    <ListItemIcon
                        className={classes.root}
                        onMouseDown={() => onMouseDown && onMouseDown()}
                        children={<DragIcon />}
                    />
                    <ListItemText className={classes.primary} primary={name} />
                    {editIcon(type)}
                </div>
                <div>{dummyImg(type)}</div>
            </ListItem>
            {isTo ? <HighlightDivider /> : <Divider variant="middle" className={classes.middle} />}

            <ModuleErrorDialog
                open={openDeleteDialog}
                title={intl.formatMessage({ id: "text_605c550654115400ca1c9bb8" })}
                confirmText={intl.formatMessage({ id: "text_60658943393faa00be277dd0" })}
                cancelText={intl.formatMessage({ id: "text_60658943393faa00be277dcf" })}
                onCancel={onCloseDialog}
                onConfirm={onDeleteModule && onDeleteModule}
                onClose={onCloseDialog}
                confirmClose={onCloseDialog}
            >
                {intl.formatMessage({ id: "text_605c550654115400ca1c9bc2" })}
            </ModuleErrorDialog>
        </>
    );
};

export const DragOrderList = (props: DragOrderListProps) => {
    const useStyles = makeStyles({ root: { width: "100%", padding: 0 } });
    const classes = useStyles();

    const { items, onChangeOrder, onEditModule, onDeleteModule } = props;
    const [from, setFrom] = useState<number>(-1);
    const [to, setTo] = useState<number>(-1);

    const newItems = () => {
        if (from !== -1 && to !== -1) {
            let newItems = [...items];
            newItems.splice(from, 1);
            newItems.splice(from < to ? to - 1 : to, 0, items[from]);
            setFrom(-1);
            setTo(-1);
            onChangeOrder(newItems);
        } else {
            setFrom(-1);
            setTo(-1);
        }
    };

    const handleOnMouseOver = (e, n: number) => {
        if (from !== -1) {
            setTo(n);
            // scroll when the list is too long
            let headerHeight = document.getElementById("header")?.getBoundingClientRect()?.height;
            let listItemHeight = document.getElementsByClassName("draglist-item")[0]?.getBoundingClientRect()?.height;
            if (headerHeight && e.clientY < headerHeight + listItemHeight)
                window.scrollBy({ top: -(listItemHeight + 6), behavior: "smooth" });
            else if (e.clientY > window.innerHeight - listItemHeight)
                window.scrollBy({ top: listItemHeight + 6, behavior: "smooth" });
        }
    };

    return (
        <ClickAwayListener mouseEvent="onMouseUp" onClickAway={newItems}>
            <List component="div" className={classes.root} onMouseUp={newItems}>
                {from !== -1 && to === 0 && <HighlightDivider />}
                {items.map((o, i) => (
                    <DragListItem
                        key={i}
                        {...o}
                        isDragging={from !== -1 && to !== -1}
                        isFrom={i === from}
                        isTo={i === to - 1}
                        onMouseDown={() => setFrom(i)}
                        onMouseOver={(e, n) => handleOnMouseOver(e, i + n)}
                        onEditModule={() => onEditModule && onEditModule(i)}
                        onDeleteModule={() => onDeleteModule && onDeleteModule(i)}
                    />
                ))}
            </List>
        </ClickAwayListener>
    );
};
