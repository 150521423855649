import React from "react";

interface DragAndDropProps {
    handleDrop?: Function;
    children?: React.ReactNode;
    multiFile?: boolean;
    fullHeight?: boolean;
}

function DragAndDrop(props: DragAndDropProps) {
    const dropRef = React.useRef<HTMLDivElement | null>(null);
    const dragCounter = React.useRef<number>(0);
    const [dragging, setDragging] = React.useState<boolean>(false);

    React.useEffect(() => {
        const div = dropRef.current;
        if (div === null) return;

        div.addEventListener("dragenter", handleDragIn);
        div.addEventListener("dragleave", handleDragOut);
        div.addEventListener("dragover", handleDrag);
        div.addEventListener("drop", handleDrop);

        return () => {
            // Remove event listener during umount.
            div?.removeEventListener("dragenter", handleDragIn);
            div?.removeEventListener("dragleave", handleDragOut);
            div?.removeEventListener("dragover", handleDrag);
            div?.removeEventListener("drop", handleDrop);
        };
    }, [props.handleDrop]);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        ++dragCounter.current;
        const { dataTransfer }: { dataTransfer: DataTransfer } = e;
        if (dataTransfer?.items?.length > 0) {
            setDragging(true);
        }
    };
    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        --dragCounter.current;
        if (dragCounter.current > 0) return;
        setDragging(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        const { dataTransfer }: { dataTransfer: DataTransfer } = e;
        if (dataTransfer?.files?.length > 0) {
            if (props.multiFile) props.handleDrop?.(dataTransfer.files);
            else props.handleDrop?.(dataTransfer.files[0]);
            dataTransfer.clearData();
            dragCounter.current = 0;
            console.log(dataTransfer.files);
        }
    };

    return (
        <div style={{ position: "relative", height: props.fullHeight ? "100%" : "auto" }} ref={dropRef}>
            {dragging && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        border: "dashed grey 4px",
                        backgroundColor: "rgba(255,255,255,.8)",
                        top: 0,
                        bottom: 4,
                        left: 0,
                        right: 0,
                        zIndex: 9999,
                    }}
                >
                    <div
                        style={{
                            color: "grey",
                            fontSize: 36,
                        }}
                    >
                        <div>Drop Here</div>
                    </div>
                </div>
            )}
            {props.children}
        </div>
    );
}

export default DragAndDrop;
