import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Grid, Select, MenuItem, TextField, styled } from "@material-ui/core";

import { UtilFunctions } from "../utils/UtilFunctions";
import { TemplateNumber, BannerDetail, BannerModuleData, getBannerModuleType } from "../pages/EditAppPage";
import { ApiServiceContext, ChooseStoreStatusContext } from "../App";

import { ModuleDialog, ModuleErrorDialog } from "./dialogs/ModuleDialog";
import { DragAndDropBannerUpload } from "./DragAndDropBannerUpload";
import { ErrorBanner } from "./boxContainers/ErrorMessageContainers";
import { ReactComponent as ClockIc } from "../assets/editor/ic/clock.svg";

import { ReactComponent as Banner1 } from "../assets/store/1banner.svg";
import { ReactComponent as Banner2 } from "../assets/store/2banners.svg";
import { ReactComponent as Banner3 } from "../assets/store/3banners.svg";
import { ReactComponent as Banner4 } from "../assets/store/4banners.svg";
import { ReactComponent as Banner5 } from "../assets/store/image_template5.svg";
import { ReactComponent as Banner6 } from "../assets/store/image_template6.svg";
import { ReactComponent as Banner7 } from "../assets/store/image_template7.svg";
import { ReactComponent as Banner8 } from "../assets/store/image_template8.svg";
import { ReactComponent as Banner1Grey } from "../assets/store/1banner_grey.svg";
import { ReactComponent as Banner2Grey } from "../assets/store/2banners_grey.svg";
import { ReactComponent as Banner3Grey } from "../assets/store/3banners_grey.svg";
import { ReactComponent as Banner4Grey } from "../assets/store/4banners_grey.svg";
import { ReactComponent as Banner5Grey } from "../assets/store/template5_grey.svg";
import { ReactComponent as Banner6Grey } from "../assets/store/template6_grey.svg";
import { ReactComponent as Banner7Grey } from "../assets/store/template7_grey.svg";
import { ReactComponent as Banner8Grey } from "../assets/store/template8_grey.svg";
import { ReactComponent as CloseIcon } from "../assets/store/ic_closeBlack.svg";
import { ReactComponent as BigCloseIcon } from "../assets/store/ic_close bigger.svg";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";

// define types
type TemplateChoice = {
    title: string;
    label: string;
    imageSize: {
        width: number;
        height: number;
    }[];
    size: number;
    bannerImage: React.ReactNode;
    bannerImageGrey: React.ReactNode;
    libraryWidth: number;
    previewWidth: number;
    previewHeight?: number;
    imageSet: string;
    imageFormat?: string;
    templateSize: number;
};
interface libraryInterface {
    url: string;
    bannerType: string;
}
// define props
type NumberOfBannerSelectProps = {
    numberOfBanner: TemplateNumber;
    setNumberOfBanner: (n: TemplateNumber) => void;
};
type LabeledTextFieldProps = {
    label: string;
    value: string;
    maxChar?: number;
    optional?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    onChange: (val: string) => void;
};
type TemplateChoiceProps = {
    isSelected: boolean;
    onClick: () => void;
} & TemplateChoice;
type ChooseTemplateProps = {
    onChange: (n: TemplateNumber) => void;
    onClose: () => void;
};
type SelectBannerProps = {
    children?: React.ReactNode;
    template: TemplateChoice;
    index: number;
    bannerType: string;
    onClick: () => void;
};
type PreviewBannerProps = {
    children?: React.ReactNode;
    index: number;
    numOfBanner: number;
    template: TemplateChoice;
    isFocused: boolean;
    onClick: () => void;
    onClose: () => void;
    bannerType: string;
    size?: number;
};
type SelectBannerModuleProps = {
    numberOfBanner: TemplateNumber;
    focusedBanner: number;
    library: libraryInterface[];
    bannerData: BannerDetail[];
    setBannerData: (a: BannerDetail[]) => void;
};

type PreviewBannerModuleProps = {
    numberOfBanner: TemplateNumber;
    focusedBanner: number;
    template: TemplateChoice;
    bannerData: BannerDetail[];
    setFocusedBanner: (i: number) => void;
    removeZhImage: (i: number) => void;
    removeEnImage: (i: number) => void;
};
type BannerFormProps = {
    focusedBanner: number;
    moduleName: string;
    duplicateName: boolean;
    url: string;
    bannerNameZh: string;
    bannerNameEn: string;
    setModuleName: (s: string) => void;
    setUrl: (s: string) => void;
    setBannerNameZh: (s: string) => void;
    setBannerNameEn: (s: string) => void;
};
type BannerDetailEditorProps = {
    data: BannerModuleData;
    hasDuplicateName: (s: string) => boolean;
    onChange: (o: BannerModuleData) => void;
    onSubmit: () => void;
    onClose: () => void;
};
type BannerEditorProps = {
    open: boolean;
    data: BannerModuleData;
    onDataChange: (o: BannerModuleData) => void;
    hasDuplicateName: (s: string) => boolean;
    onSubmit: (o: BannerModuleData) => void;
    onClose: () => void;
};

// constant 1/2/3/4 options
const LAYOUT_CHOICES: TemplateChoice[] = [
    {
        title: "text_605c550654115400ca1c9ac5",
        label: "text_6048985b3857e1012082651c",
        imageSize: [
            {
                width: 1188,
                height: 0,
            },
        ],
        size: 5,
        imageSet: "TYPE_1",
        bannerImage: <Banner1 />,
        bannerImageGrey: <Banner1Grey />,
        libraryWidth: 297,
        previewWidth: 356,
        templateSize: 1,
    },
    {
        title: "text_605c550654115400ca1c9ac6",
        label: "text_6048985b3857e1012082651d",
        imageSize: [
            {
                width: 594,
                height: 484,
            },
        ],
        size: 5,
        imageSet: "TYPE_2",
        bannerImage: <Banner2 />,
        bannerImageGrey: <Banner2Grey />,
        libraryWidth: 149,
        previewWidth: 178,
        templateSize: 2,
    },
    {
        title: "text_605c550654115400ca1c9ac3",
        label: "text_6048985b3857e10120826527",
        imageSize: [
            {
                width: 396,
                height: 484,
            },
        ],
        size: 5,
        imageSet: "TYPE_3",
        bannerImage: <Banner3 />,
        bannerImageGrey: <Banner3Grey />,
        libraryWidth: 99,
        previewWidth: 119,
        templateSize: 3,
    },
    {
        title: "text_605c550654115400ca1c9acb",
        label: "text_6048985b3857e1012082651f",
        imageSize: [
            {
                width: 297,
                height: 484,
            },
        ],
        size: 5,
        imageSet: "TYPE_4",
        bannerImage: <Banner4 />,
        bannerImageGrey: <Banner4Grey />,
        libraryWidth: 74,
        previewWidth: 89,
        templateSize: 4,
    },
    {
        title: "text_605c550654115400ca1c9ac7",
        label: "text_6048985b3857e1012082651g",
        imageSize: [
            {
                width: 355,
                height: 355,
            },
            {
                width: 833,
                height: 355,
            },
        ],
        size: 5,
        imageSet: "TYPE_5",
        bannerImage: <Banner5 />,
        bannerImageGrey: <Banner5Grey />,
        libraryWidth: 106,
        previewWidth: 106,
        previewHeight: 106,
        templateSize: 2,
    },
    {
        title: "text_605c550654115400ca1c9ac8",
        label: "text_6048985b3857e1012082651h",
        imageSize: [
            {
                width: 833,
                height: 355,
            },
            {
                width: 355,
                height: 355,
            },
        ],
        size: 5,
        imageSet: "TYPE_6",
        bannerImage: <Banner6 />,
        bannerImageGrey: <Banner6Grey />,
        libraryWidth: 249,
        previewWidth: 249,
        previewHeight: 106,
        templateSize: 2,
    },
    {
        title: "text_605c550654115400ca1c9ac9",
        label: "text_6048985b3857e1012082651i",
        imageSize: [
            {
                width: 485,
                height: 496,
            },
            {
                width: 703,
                height: 248,
            },
        ],
        size: 5,
        imageSet: "TYPE_7",
        bannerImage: <Banner7 />,
        bannerImageGrey: <Banner7Grey />,
        libraryWidth: 119,
        previewWidth: 144,
        previewHeight: 146,
        imageFormat: "12",
        templateSize: 3,
    },
    {
        title: "text_605c550654115400ca1c9ac10",
        label: "text_6048985b3857e1012082651j",
        imageSize: [
            {
                width: 703,
                height: 248,
            },
            {
                width: 485,
                height: 496,
            },
        ],
        size: 5,
        imageSet: "TYPE_8",
        bannerImage: <Banner8 />,
        bannerImageGrey: <Banner8Grey />,
        libraryWidth: 173,
        previewWidth: 211,
        previewHeight: 73,
        imageFormat: "21",
        templateSize: 3,
    },
];
const PREVIEW_HEIGHT = 145;

// styled components
const StyledDialogContentBox = styled(Box)({
    width: 1008,
    margin: "16px 16px 0px",
    padding: 20,
    background: "#ffffff",
});
const TemplateGrid = styled(Grid)({
    position: "relative",
    width: 496,
    height: 256,
    margin: 4,
    padding: 16,
    backgroundColor: "rgba(58, 153, 58, 0.2)",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.06)",
});
const TemplateGridBorder = styled(Box)({
    position: "absolute",
    top: 0,
    left: 0,
    width: "calc(100% - 12px)",
    height: "calc(100% - 12px)",
    border: "solid 6px #3a993a",
});
const TemplateGridTitle = styled(Box)({
    fontSize: 18,
    lineHeight: 1.33,
    color: "#3a993a",
    fontWeight: "bold",
});
const TemplateGridContent = styled(Box)({
    fontSize: 16,
    lineHeight: 1.5,
    color: "#3a993a",
});
const TemplateImageBox = styled(Box)({
    position: "absolute",
    top: 128,
    left: 204,
});
const StyledTextField = styled(TextField)({
    width: "100%",
});
const StyledTextLabel = styled(Box)({
    display: "inline-block",
    marginTop: 16,
    fontSize: 16,
    lineHeight: 1.5,
    color: "#222222",
});
const StyledOptionalLabel = styled(Box)({
    display: "inline-block",
    marginLeft: 4,
    fontSize: 14,
    lineHeight: 1.14,
    color: "rgba(34, 34, 34, 0.6)",
});
const StyledSelect = styled(Select)({
    width: 256,
    height: 42,
    padding: 8,
    borderRadius: 4,
    border: "solid 1px rgba(34, 34, 34, 0.2)",
});
const CircleNumberContainer = styled(Grid)({
    width: 24,
    height: 24,
    borderRadius: "50%",
    border: "solid 1px #3a993a",
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 10,
    lineHeight: 1.17,
    fontWeight: "bold",
    color: "#3a993a",
});
const GridBorder = styled(Box)({
    position: "absolute",
    width: "calc(100% - 8px)",
    height: "calc(100% - 8px)",
    border: "solid 4px #3a993a",
    backgroundColor: "rgba(34, 34, 34, 0.3)",
});
const BannerFormOverlay = styled(Box)({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0.8,
    backgroundColor: "#ffffff",
});

// helper funciton
const initBannerArray = (image?: string) => [
    {
        image: "",
        url: "",
        bannerNameZh: "",
        bannerNameEn: "",
        imageZh: image ? image : "",
        imageEn: "",
    },
];

// Components for Choosing 1/2/3/4/5/6/7/8 Template
const TemplateChoice = (props: TemplateChoiceProps) => {
    const { formatMessage } = useIntl();
    const { title, imageSize, imageSet, bannerImage, isSelected, onClick } = props;
    return (
        <TemplateGrid item onClick={() => onClick && onClick()}>
            {isSelected && <TemplateGridBorder />}
            <Box>
                <TemplateGridTitle>{formatMessage({ id: title }, { lineBreak: <br /> })}</TemplateGridTitle>
            </Box>
            <Box style={{ marginTop: 24 }}>
                <TemplateGridContent>
                    {imageSet === "TYPE_1" || imageSet === "TYPE_2" || imageSet === "TYPE_3" || imageSet === "TYPE_4"
                        ? formatMessage({ id: "text_605c550654115400ca1c9aaa_a" })
                        : (imageSet === "TYPE_7" || imageSet === "TYPE_8") && imageSize[0].width > imageSize[0].height
                        ? formatMessage({ id: "text_605c550654115400ca1c9aaa_a3" })
                        : formatMessage({ id: "text_605c550654115400ca1c9aaa_a1" })}
                </TemplateGridContent>
                <TemplateGridContent>
                    {formatMessage({ id: "text_605c550654115400ca1c9aaa_b" })}
                    {imageSize[0].width}
                    {formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                    {" / " + formatMessage({ id: "text_605c550654115400ca1c9aaa_c" })}
                    {!imageSize[0].height
                        ? formatMessage({ id: "text_605c550654115400ca1c9aaa_f" })
                        : imageSize[0].height + formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                </TemplateGridContent>
                <>
                    <TemplateGridContent>
                        {(imageSet === "TYPE_5" || imageSet === "TYPE_6") &&
                            formatMessage({ id: "text_605c550654115400ca1c9aaa_a2" })}
                        {(imageSet === "TYPE_7" || imageSet === "TYPE_8") &&
                            (imageSize[0].width > imageSize[0].height
                                ? formatMessage({ id: "text_605c550654115400ca1c9aaa_a5" })
                                : formatMessage({ id: "text_605c550654115400ca1c9aaa_a4" }))}
                    </TemplateGridContent>
                    {(imageSet === "TYPE_5" ||
                        imageSet === "TYPE_6" ||
                        imageSet === "TYPE_7" ||
                        imageSet === "TYPE_8") && (
                        <TemplateGridContent>
                            {formatMessage({ id: "text_605c550654115400ca1c9aaa_b" })}
                            {imageSize[1].width}
                            {formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                            {" / " + formatMessage({ id: "text_605c550654115400ca1c9aaa_c" })}
                            {!imageSize[1].height
                                ? formatMessage({ id: "text_605c550654115400ca1c9aaa_f" })
                                : imageSize[1].height + formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                        </TemplateGridContent>
                    )}
                </>
            </Box>
            <Box style={{ marginTop: 4 }}>
                <TemplateGridContent>{formatMessage({ id: "text_605c550654115400ca1c9abe" })}</TemplateGridContent>
            </Box>
            <TemplateImageBox>{bannerImage}</TemplateImageBox>
        </TemplateGrid>
    );
};

// Choosing 1/2/3/4 Template
const ChooseTemplate = (props: ChooseTemplateProps) => {
    const { formatMessage } = useIntl();
    const { onChange, onClose } = props;
    const [numberOfBanner, setNumberOfBanner] = useState<TemplateNumber>(0);
    return (
        <ModuleDialog
            open={true}
            onClose={onClose}
            title={formatMessage({ id: "text_605c550654115400ca1c9b5d" })}
            submitText={formatMessage({ id: "text_605c550654115400ca1c9ab4" })}
            disableSubmit={numberOfBanner === 0}
            onSubmit={() => onChange && onChange(numberOfBanner)}
        >
            <StyledDialogContentBox>
                <Grid container>
                    {LAYOUT_CHOICES.map((o, i) => (
                        <TemplateChoice
                            key={i}
                            {...o}
                            isSelected={numberOfBanner === i + 1}
                            onClick={() => setNumberOfBanner((i + 1) as TemplateNumber)}
                        />
                    ))}
                </Grid>
            </StyledDialogContentBox>
        </ModuleDialog>
    );
};

// Components for Uploading Banner
const NumberOfBannerSelect = (props: NumberOfBannerSelectProps) => {
    const { formatMessage } = useIntl();
    const { numberOfBanner, setNumberOfBanner } = props;
    return (
        <StyledSelect
            variant="outlined"
            value={numberOfBanner}
            onChange={(e) =>
                numberOfBanner !== (e.target.value as TemplateNumber) &&
                setNumberOfBanner &&
                setNumberOfBanner(e.target.value as TemplateNumber)
            }
        >
            {LAYOUT_CHOICES.map((o, i) => (
                <MenuItem key={i} value={i + 1}>
                    {formatMessage({ id: o.label })}
                </MenuItem>
            ))}
        </StyledSelect>
    );
};
const LabeledTextField = (props: LabeledTextFieldProps) => {
    const { formatMessage } = useIntl();
    return (
        <>
            <StyledTextLabel>{props.label}</StyledTextLabel>
            {props.maxChar && (
                <StyledOptionalLabel>
                    {formatMessage({ id: "text_605c550654115400ca1c9b36_max" }, { max: props.maxChar })}
                </StyledOptionalLabel>
            )}
            {props.optional && (
                <StyledOptionalLabel>{formatMessage({ id: "text_608bc72a5cf3b0754f0fa9fd" })}</StyledOptionalLabel>
            )}
            <StyledTextField
                variant="outlined"
                InputProps={{
                    style: {
                        height: 48,
                        fontSize: 14,
                        lineHeight: 1.14,
                        color: !props.disabled ? "#222222" : "rgba(34, 34, 34, 0.6)",
                        backgroundColor: !props.disabled ? "initial" : "#dde2e5",
                    },
                }}
                value={props.value}
                disabled={props.disabled}
                error={props.error}
                helperText={props.helperText}
                onChange={(e) => props.onChange && props.onChange(e.target.value)}
            />
        </>
    );
};

const SelectBanner = (props: SelectBannerProps) => {
    const { children, template, index, bannerType, onClick } = props;
    var style = {
        position: "relative",
        width: template.libraryWidth,
        height: "max-content",
        margin: "0px 3px 8px",
    } as React.CSSProperties;
    return (
        <Grid
            item
            style={style}
            onClick={() => {
                onClick && onClick();
            }}
        >
            {index > 0 && (
                <GridBorder style={{ backgroundColor: "rgba(34, 34, 34, 0.3)" }}>
                    <CircleNumberContainer container>{index.toString() + bannerType}</CircleNumberContainer>
                    {/* <CloseIcon
                        style={{ position: "absolute", top: -10, right: -10, zIndex: 3 }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            return onClose && onClose();
                        }}
                    /> */}
                </GridBorder>
            )}
            {children}
        </Grid>
    );
};
const PreviewBanner = (props: PreviewBannerProps) => {
    const { children, template, isFocused, onClick, onClose, index, numOfBanner, bannerType, size } = props;

    const tranfromImage = (index: number, numOfBanner: number) => {
        if (numOfBanner === 7) {
            if (index !== 0) {
                if (index % 2 === 0) {
                    return "translate(143px, -76px)";
                } else {
                    return "translate(-1px, -72px)";
                }
            }
        }
        if (numOfBanner === 8) {
            if (index !== 0) {
                if (index % 2 === 0 || (index % 2 === 1 && size === 3)) {
                    return "translate(0px, -76px)";
                } else if (index % 2 === 1) {
                    return "translate(0px, -4px)";
                }
            }
        }
        return "";
    };

    var style = {
        transform: tranfromImage(index, numOfBanner),
        position: "relative",
        width: template.previewWidth,
        height: template.previewHeight ? template.previewHeight : PREVIEW_HEIGHT,
        display: "inline-block",
    } as React.CSSProperties;
    return (
        <Grid item style={style} onClick={() => onClick && onClick()}>
            {isFocused && (bannerType === "zh" || bannerType === "en") && (
                <GridBorder style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}>
                    <BigCloseIcon
                        style={{ position: "absolute", right: 0, zIndex: 3 }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            return onClose && onClose();
                        }}
                    />
                </GridBorder>
            )}
            {children}
        </Grid>
    );
};

const SelectBannerModule = (props: SelectBannerModuleProps) => {
    const { library, bannerData, setBannerData, focusedBanner, numberOfBanner } = props;

    const checkBannerType = (o: string) => {
        if (bannerData.some((i) => i.imageZh === o)) {
            return "Zh";
        } else if (bannerData.some((i) => i.imageEn === o)) {
            return "En";
        }
        return "";
    };

    const returnTemplateForImage = (imageType: string) => {
        let returnTemplate;
        LAYOUT_CHOICES.forEach((element) => {
            if (element.imageSet === imageType) {
                returnTemplate = element;
            }
        });
        return returnTemplate;
    };

    const checkUserSelectImageStatus = (index: number, bannerType: string) => {
        if (numberOfBanner === 5) {
            if ((index === 0 && bannerType === "TYPE_5") || (index === 1 && bannerType === "TYPE_6")) {
                return true;
            } else {
                return false;
            }
        } else if (numberOfBanner === 6) {
            if ((index === 0 && bannerType === "TYPE_6") || (index === 1 && bannerType === "TYPE_5")) {
                return true;
            } else {
                return false;
            }
        } else if (numberOfBanner === 7) {
            if (((index === 1 || index === 2) && bannerType === "TYPE_8") || (index === 0 && bannerType === "TYPE_7")) {
                return true;
            } else {
                return false;
            }
        } else if (numberOfBanner === 8) {
            if (((index === 0 || index === 1) && bannerType === "TYPE_8") || (index === 2 && bannerType === "TYPE_7")) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    };

    return (
        <Grid container>
            {library.map((o, i) => (
                <SelectBanner
                    key={o.url}
                    template={returnTemplateForImage(o.bannerType)}
                    index={
                        bannerData.findIndex((i) => i.imageZh === o.url) + 1 ||
                        bannerData.findIndex((i) => i.imageEn === o.url) + 1
                    }
                    bannerType={checkBannerType(o.url)}
                    onClick={() => {
                        // if the banner is already selected
                        if (
                            bannerData.some((i) => i.imageZh === o.url) ||
                            bannerData.some((i) => i.imageEn === o.url)
                        ) {
                            return;
                        }

                        // if selected banner is not full
                        else {
                            if (bannerData.length < returnTemplateForImage(o.bannerType).templateSize) {
                                if (checkUserSelectImageStatus(bannerData.length, o.bannerType)) {
                                    setBannerData(bannerData.concat(initBannerArray(o.url)));
                                }
                            } else if (
                                bannerData.some((i) => i.imageZh === "") &&
                                checkUserSelectImageStatus(focusedBanner, o.bannerType)
                            ) {
                                bannerData[focusedBanner].imageZh = o.url;
                                setBannerData(bannerData);
                            } else if (
                                bannerData.some((i) => i.imageEn === "") &&
                                checkUserSelectImageStatus(focusedBanner, o.bannerType)
                            ) {
                                bannerData[focusedBanner].imageEn = o.url;
                                setBannerData(bannerData);
                            }
                        }
                    }}
                >
                    <img src={o.url} alt="" style={{ maxWidth: returnTemplateForImage(o.bannerType).libraryWidth }} />
                </SelectBanner>
            ))}
        </Grid>
    );
};
const PreviewBannerModule = (props: PreviewBannerModuleProps) => {
    const { formatMessage } = useIntl();
    const { numberOfBanner, focusedBanner, template, bannerData, setFocusedBanner, removeZhImage, removeEnImage } =
        props;
    const { imageSize, imageSet, imageFormat, bannerImageGrey } = template;

    const whichTemplatePass = (bannerNum: number, index: number) => {
        let layoutIndex = bannerNum - 1;
        switch (bannerNum) {
            case 5:
                return LAYOUT_CHOICES[index === 0 ? layoutIndex : layoutIndex + 1];
            case 6:
                return LAYOUT_CHOICES[index === 0 ? layoutIndex : layoutIndex - 1];
            case 7:
                return LAYOUT_CHOICES[index === 0 ? layoutIndex : layoutIndex + 1];
            case 8:
                return LAYOUT_CHOICES[index === 2 ? layoutIndex - 1 : layoutIndex];
            default:
                return LAYOUT_CHOICES[layoutIndex];
        }
    };

    const enBannerTransform = () => {
        return "translate(1px, 62px)";
    };

    const getTheHeightOfBannerArea = () => {
        if (numberOfBanner < 5) {
            return PREVIEW_HEIGHT;
        } else if (numberOfBanner > 4 && numberOfBanner < 7) {
            return "106px";
        } else if (numberOfBanner > 6 && numberOfBanner < 9) {
            return "146px";
        }
    };

    const checkZhImageFieldEmpty = (img: any) => {
        if (img.imageZh) {
            return img.imageZh;
        } else {
            return "";
        }
    };

    const checkEnImageFieldEmpty = (img: any) => {
        if (img.imageEn) {
            return img.imageEn;
        } else {
            return "";
        }
    };

    return (
        <>
            {<div style={{ fontSize: "22px", fontWeight: 700, color: "black" }}>ZH</div>}
            {bannerImageGrey}
            {<div style={{ fontSize: "22px", fontWeight: 700, color: "black" }}>EN</div>}
            {bannerImageGrey}
            <Grid container style={{ position: "absolute", top: 0, left: 20, width: 357 }}>
                <div
                    style={{
                        transform: "translate(1px, 29px)",
                        position: "relative",
                        height: getTheHeightOfBannerArea(),
                    }}
                >
                    {bannerData.map((o, i) => (
                        <PreviewBanner
                            key={i}
                            template={whichTemplatePass(numberOfBanner, i)}
                            isFocused={focusedBanner === i}
                            onClick={() => setFocusedBanner && setFocusedBanner(i)}
                            onClose={() => removeZhImage && removeZhImage(i)}
                            numOfBanner={numberOfBanner}
                            index={i}
                            bannerType="zh"
                            size={bannerData.length}
                        >
                            <img
                                src={checkZhImageFieldEmpty(o)}
                                style={{
                                    maxWidth: whichTemplatePass(numberOfBanner, i).previewWidth,
                                    maxHeight: whichTemplatePass(numberOfBanner, i).previewHeight
                                        ? whichTemplatePass(numberOfBanner, i).previewHeight
                                        : PREVIEW_HEIGHT,
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                            />
                        </PreviewBanner>
                    ))}
                </div>
                <div
                    style={{ transform: enBannerTransform(), position: "relative", height: getTheHeightOfBannerArea() }}
                >
                    {bannerData.map((o, i) => (
                        <PreviewBanner
                            key={i}
                            template={whichTemplatePass(numberOfBanner, i)}
                            isFocused={o.imageEn !== "" && focusedBanner === i}
                            onClick={() => setFocusedBanner && setFocusedBanner(i)}
                            onClose={() => removeEnImage && removeEnImage(i)}
                            numOfBanner={numberOfBanner}
                            index={i}
                            bannerType="en"
                            size={bannerData.length}
                        >
                            <img
                                src={checkEnImageFieldEmpty(o)}
                                style={{
                                    maxWidth: whichTemplatePass(numberOfBanner, i).previewWidth,
                                    maxHeight: whichTemplatePass(numberOfBanner, i).previewHeight
                                        ? whichTemplatePass(numberOfBanner, i).previewHeight
                                        : PREVIEW_HEIGHT,
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                            />
                        </PreviewBanner>
                    ))}
                </div>
            </Grid>
            <StyledTextLabel>
                {imageSet === "TYPE_1" || imageSet === "TYPE_2" || imageSet === "TYPE_3" || imageSet === "TYPE_4"
                    ? formatMessage({ id: "text_605c550654115400ca1c9aaa_a" })
                    : formatMessage({ id: "text_605c550654115400ca1c9aaa_a6" })}
                {imageSize[0].width}
                {formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                {formatMessage({ id: "text_605c550654115400ca1c9aaa_g" })}
                {!imageSize[0].height
                    ? formatMessage({ id: "text_605c550654115400ca1c9aaa_f" })
                    : imageSize[0].height + formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                {(imageSet === "TYPE_5" || imageSet === "TYPE_6" || imageSet === "TYPE_7" || imageSet === "TYPE_8") && (
                    <>
                        {<br />}
                        {formatMessage({ id: "text_605c550654115400ca1c9aaa_a7" })}
                        {(imageFormat === "12" && imageSize[1].width) || (imageFormat === "21" && imageSize[0].width)}
                        {!imageFormat && imageSize[1].width}
                        {formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                        {formatMessage({ id: "text_605c550654115400ca1c9aaa_g" })}
                        {imageFormat === "12" &&
                            imageSize[1].height &&
                            imageSize[1].height + formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                        {(imageFormat === "21" || !imageFormat) &&
                            imageSize[0].height &&
                            imageSize[0].height + formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                    </>
                )}
                {imageFormat ? (
                    <>
                        {<br />}
                        {formatMessage({ id: "text_605c550654115400ca1c9aaa_a8" })}
                        {imageSize[1].width}
                        {formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                        {formatMessage({ id: "text_605c550654115400ca1c9aaa_g" })}
                        {imageSize[1].height &&
                            imageSize[1].height + formatMessage({ id: "text_605c550654115400ca1c9aaa_d" })}
                    </>
                ) : (
                    <></>
                )}
            </StyledTextLabel>
        </>
    );
};

const BannerForm = (props: BannerFormProps) => {
    const { formatMessage } = useIntl();
    const {
        focusedBanner,
        moduleName,
        duplicateName,
        url,
        bannerNameZh,
        bannerNameEn,
        setModuleName,
        setUrl,
        setBannerNameZh,
        setBannerNameEn,
    } = props;
    return (
        <>
            <LabeledTextField
                label={formatMessage({ id: "text_605c550654115400ca1c9b36" })}
                maxChar={50}
                value={moduleName}
                onChange={setModuleName}
                disabled={focusedBanner !== 0}
                error={duplicateName}
                helperText={duplicateName ? formatMessage({ id: "text_605c550654115400ca1c9a6b" }) : undefined}
            />
            <LabeledTextField
                label={formatMessage({ id: "text_605c550654115400ca1c9b37" })}
                maxChar={512}
                value={url}
                onChange={setUrl}
                optional
            />
            <LabeledTextField
                label={formatMessage({ id: "text_605c550654115400ca1c9b38" })}
                maxChar={50}
                value={bannerNameZh}
                onChange={setBannerNameZh}
            />
            <LabeledTextField
                label={formatMessage({ id: "text_605c550654115400ca1c9b40" })}
                maxChar={50}
                value={bannerNameEn}
                onChange={setBannerNameEn}
            />
        </>
    );
};

// Upload Banner
const BannerDetailEditor = (props: BannerDetailEditorProps) => {
    const { formatMessage } = useIntl();
    const apiService = useContext(ApiServiceContext);
    const [chooseStore, setChooseStoreStatus] = useContext(ChooseStoreStatusContext);
    const refTemp = React.useRef(false);
    const { data, onChange, hasDuplicateName, onSubmit, onClose } = props;
    const { moduleName, numberOfBanner, bannerData, focusedBanner } = data;
    const template = LAYOUT_CHOICES[numberOfBanner - 1];
    const { imageSize, size } = template;
    const { url, bannerNameZh, bannerNameEn } = bannerData.length
        ? bannerData[focusedBanner]
        : { url: "", bannerNameZh: "", bannerNameEn: "" };
    const [library, setLibrary] = useState<libraryInterface[]>([]);
    const [dialogError, setDialogError] = useState<React.ReactNode[]>([]);
    const [endUpload, setEndUpload] = React.useState(false);
    const [exitUpload, setExitUpload] = React.useState(false);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");
    const updateLibrary = () =>
        chooseStore.shopCode &&
        apiService
            .getBannerList(chooseStore.shopCode)
            .then((res) => {
                if (UtilFunctions.validateApiResponseSuccess(res)) {
                    let resBannerData = res.data.data ?? {};
                    let currentType = getBannerModuleType(numberOfBanner);
                    let bannerType = resBannerData?.types ?? {};
                    let typesComponentUrl = bannerType[currentType] ?? [];
                    let saveTypesComponent = typesComponentUrl.map((o) => ({
                        url: o.url,
                        bannerType: currentType,
                    }));
                    if (numberOfBanner === 5 || numberOfBanner === 7) {
                        let tempCurrentType = getBannerModuleType((numberOfBanner + 1) as TemplateNumber);
                        let tempTypesComponentUrl = bannerType[tempCurrentType] ?? [];
                        saveTypesComponent = saveTypesComponent.concat(
                            tempTypesComponentUrl.map((o) => ({
                                url: o.url,
                                bannerType: tempCurrentType,
                            }))
                        );
                    } else if (numberOfBanner === 6 || numberOfBanner === 8) {
                        let tempCurrentType = getBannerModuleType((numberOfBanner - 1) as TemplateNumber);
                        let tempTypesComponentUrl = bannerType[tempCurrentType] ?? [];
                        saveTypesComponent = saveTypesComponent.concat(
                            tempTypesComponentUrl.map((o) => ({
                                url: o.url,
                                bannerType: tempCurrentType,
                            }))
                        );
                    }
                    setLibrary(saveTypesComponent);
                } else throw new Error("Error Unknown");
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });

    useEffect(() => {
        updateLibrary();
    }, [numberOfBanner]);

    useEffect(() => {
        refTemp.current = exitUpload;
        if (refTemp.current) {
            onClose();
        }
    }, [exitUpload]);

    // setter function for each field
    const setNumberOfBanner = (numberOfBanner: TemplateNumber) => {
        onChange({ ...data, numberOfBanner, focusedBanner: 0, bannerData: [] });
    };
    const setModuleName = (moduleName: string) => {
        onChange({ ...data, moduleName: moduleName.substring(0, 50) });
    };
    const setFocusedBanner = (focusedBanner: number) => {
        onChange({ ...data, focusedBanner });
    };
    const setBannerData = (bannerData: BannerDetail[]) => {
        onChange({ ...data, bannerData: bannerData });
    };
    const setUrl = (url: string) => {
        if (focusedBanner < bannerData.length) {
            let newBannerData = [...bannerData];
            newBannerData[focusedBanner].url = url.substring(0, 512);
            setBannerData(newBannerData);
        }
    };
    const setBannerNameZh = (name: string) => {
        if (focusedBanner < bannerData.length) {
            let newBannerData = [...bannerData];
            newBannerData[focusedBanner].bannerNameZh = name.substring(0, 50);
            setBannerData(newBannerData);
        }
    };
    const setBannerNameEn = (name: string) => {
        if (focusedBanner < bannerData.length) {
            let newBannerData = [...bannerData];
            newBannerData[focusedBanner].bannerNameEn = name.substring(0, 50);
            setBannerData(newBannerData);
        }
    };

    // handle remove image
    const removeZhImage = (i: number) => {
        let newBannerData = [...bannerData];
        newBannerData[i].imageZh = "";
        onChange({
            ...data,
            bannerData: newBannerData,
        });
    };

    const removeEnImage = (i: number) => {
        let newBannerData = [...bannerData];
        newBannerData[i].imageEn = "";
        onChange({
            ...data,
            bannerData: newBannerData,
        });
    };

    // load image from url
    const loadImage = (f: File) =>
        new Promise<HTMLImageElement>((resolve) => {
            let img = new Image();
            img.onload = () => resolve(img);
            img.src = window.URL.createObjectURL(f);
        });
    // handle upload image
    const checkImageSizeDimension = (imageSize: { width: number; height: number }[], img: any) => {
        let returnCount = 0;
        imageSize.forEach((element) => {
            if (element.width !== img.width || (element.height && element.height !== img.height)) {
                returnCount += 1;
            }
        });
        return returnCount;
    };

    const checkImageType = (imageSize: { width: number; height: number }[], img: HTMLImageElement) => {
        let addNumer = numberOfBanner;
        imageSize.map((element, i) => {
            if (element.width === img.width && element.height === img.height) {
                if (addNumer === 5 || addNumer === 7) {
                    addNumer += i;
                } else if (addNumer === 6 || addNumer === 8) {
                    addNumer -= i;
                }
            }
        });
        return addNumer;
    };

    const uploadImages = async (files: File[]) => {
        var sizeErrArray: string[] = [],
            formatErrArray: string[] = [],
            dimensionErrArray: string[] = [];
        // Validate image
        for (let i = 0; i < files.length; i++) {
            let f = files[i];
            if (f && f.name && UtilFunctions.isValidImageExt(f.name)) {
                if (f.size > size * 1048576) {
                    sizeErrArray.push(f.name);
                } else {
                    await loadImage(f).then(async (img) => {
                        if (checkImageSizeDimension(imageSize, img) === imageSize.length) {
                            dimensionErrArray.push(f.name);
                        }
                        // if image is valid, upload image
                        else {
                            if (chooseStore.shopCode) {
                                setUploading(true);
                                let formData = new FormData();
                                formData.append("imageFiles", f);
                                formData.append("type", getBannerModuleType(checkImageType(imageSize, img)));
                                formData.append("store", chooseStore.shopCode);
                                if (!refTemp.current) {
                                    await apiService
                                        .postBannerList(formData)
                                        .then((res) => {
                                            handleFinishUpload();
                                            if (!UtilFunctions.validateApiResponseSuccess(res)) {
                                                switch (res.data.code) {
                                                    case "MAX_IMAGE_FILE_SIZE":
                                                        sizeErrArray.push(f.name);
                                                        break;
                                                    case "IMAGE_EXTENSION_UNSUPPORTED":
                                                        formatErrArray.push(f.name);
                                                        break;
                                                    case "IMAGE_DIMENSION_UNMATCH":
                                                        dimensionErrArray.push(f.name);
                                                        break;
                                                    default:
                                                        throw new Error("Error Unknown");
                                                }
                                            }
                                        })
                                        .catch((err) => {
                                            setErrMsg("general.1.002");
                                            setOpenValidateError(true);
                                        });
                                } else {
                                    files = [];
                                }
                            }
                        }
                    });
                }
            } else {
                formatErrArray.push(f.name);
            }
        }
        setStartUpload(false);
        setEndUpload(true);
        setUploading(false);

        return { formatErrArray, sizeErrArray, dimensionErrArray };
    };

    const handleImageDrag = (files: File[]) => {
        setTotalImg(files.length);
        if (files.length + library.length > 100) {
            setDialogError([formatMessage({ id: "text_605c550654115400ca1c9a21" })]);
            return;
        }

        uploadImages(files).then((res) => {
            let dialogError: React.ReactNode[] = [];
            if (res.formatErrArray.length) {
                let msg: React.ReactNode = (
                    <div>
                        {formatMessage({ id: "text_60b0be2fa2efe900ee04e8c0" })}
                        {res.formatErrArray.map((o) => (
                            <div>
                                {o}
                                <br />
                            </div>
                        ))}
                    </div>
                );
                dialogError.push([msg]);
            }
            if (res.sizeErrArray.length) {
                let msg: React.ReactNode = (
                    <div>
                        {formatMessage({ id: "text_60b0be2fa2efe900ee04e8ad" })}
                        {res.sizeErrArray.map((o) => (
                            <div>
                                {o}
                                <br />
                            </div>
                        ))}
                    </div>
                );
                dialogError.push([msg]);
            }
            if (res.dimensionErrArray.length) {
                let msg: React.ReactNode = (
                    <div>
                        {formatMessage({ id: "text_605c550654115400ca1c9a40" })}
                        {res.dimensionErrArray.map((o) => (
                            <div>
                                {o}
                                <br />
                            </div>
                        ))}
                    </div>
                );
                dialogError.push([msg]);
            }
            setDialogError(dialogError);
            updateLibrary();
        });
    };

    // validate inputs
    const duplicateName = hasDuplicateName(data.moduleName.trim());
    const allBannerDataFilled = !data.bannerData.some(
        (o) => o.imageZh === "" || o.bannerNameZh.trim() === "" || o.bannerNameEn.trim() === ""
    );

    const returnChecking = (num: number) => {
        switch (num) {
            case 1:
            case 2:
            case 3:
            case 4:
                return data.bannerData.length === num;
            case 5:
            case 6:
                return data.bannerData.length === 2;
            case 7:
            case 8:
                return data.bannerData.length === 3;
        }
    };

    const canSubmit =
        data.moduleName.trim() !== "" && !duplicateName && returnChecking(numberOfBanner) && allBannerDataFilled;

    const [startUpload, setStartUpload] = React.useState(false);
    const [uploadedImg, setUploadedImg] = React.useState<number>(0);
    const [totalImg, setTotalImg] = React.useState<number>(0);
    const [uploadingAndExit, setUploadingAndExit] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    var tempCount = 0;
    const intl = useIntl();
    const handleFinishUpload = () => {
        setStartUpload(true);
        tempCount++;
        setUploadedImg(tempCount);
        if (tempCount === totalImg) setStartUpload(false);
    };
    const handleOnClose = () => {
        if (uploading) {
            setUploadingAndExit(true);
            setDialogError([formatMessage({ id: "5.031" })]);
        } else {
            onClose();
        }
    };
    const handleUploadAndExitDialog = () => {
        if (uploadingAndExit && uploading) {
            setExitUpload(true);
            setUploading(false);
            setStartUpload(false);
            setUploadingAndExit(false);
        }
    };
    const onErrorDialogClose = () => {
        handleUploadAndExitDialog();
        setDialogError(dialogError.slice(1));
    };

    const onErrorDialogCancel = () => {
        setDialogError(dialogError.slice(1));
    };

    return (
        <>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <ModuleDialog
                title={formatMessage({ id: "text_605c550654115400ca1c9b5d" })}
                submitText={formatMessage({ id: "text_605c550654115400ca1c9b4c" })}
                onSubmit={onSubmit}
                disableSubmit={!canSubmit}
                open={true}
                onClose={handleOnClose}
            >
                <StyledDialogContentBox>
                    {duplicateName && (
                        <ErrorBanner>{formatMessage({ id: "text_605c550654115400ca1c9a65" })}</ErrorBanner>
                    )}

                    {/* Select number of banner */}
                    <NumberOfBannerSelect numberOfBanner={numberOfBanner} setNumberOfBanner={setNumberOfBanner} />

                    {/* Edit Banner Module */}
                    <Grid container style={{ marginTop: 16 }}>
                        {/* Left Panel */}
                        <Grid
                            item
                            style={{
                                width: "calc(100% - 376px)",
                                borderRight: "solid 1px rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <Grid container style={{ width: "100%", height: "100%" }} direction="column">
                                {startUpload && (
                                    <div style={{ color: "#3A993A", display: "flex", verticalAlign: "middle" }}>
                                        <div style={{ marginRight: "5px" }}>
                                            <ClockIc />
                                        </div>
                                        {intl.formatMessage(
                                            { id: "5.030" },
                                            { totalImg: totalImg, uploadedImg: uploadedImg }
                                        )}
                                    </div>
                                )}
                                <Grid item>
                                    <SelectBannerModule
                                        numberOfBanner={numberOfBanner}
                                        focusedBanner={focusedBanner}
                                        library={library}
                                        bannerData={bannerData}
                                        setBannerData={setBannerData}
                                    />
                                </Grid>
                                <Grid item xs style={{ paddingRight: 20 }}>
                                    <DragAndDropBannerUpload
                                        disabled={library.length === 100 || uploading}
                                        maxFileSize={size}
                                        onImageDrag={handleImageDrag}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Right Panel */}
                        <Grid
                            item
                            style={{
                                width: 376,
                                paddingLeft: 20,
                                borderLeft: "solid 1px rgba(0, 0, 0, 0.12)",
                                position: "relative",
                            }}
                        >
                            <PreviewBannerModule
                                numberOfBanner={numberOfBanner}
                                focusedBanner={focusedBanner}
                                template={template}
                                bannerData={bannerData}
                                setFocusedBanner={setFocusedBanner}
                                removeZhImage={removeZhImage}
                                removeEnImage={removeEnImage}
                            />
                            <Box style={{ position: "relative" }}>
                                <BannerForm
                                    focusedBanner={focusedBanner}
                                    moduleName={moduleName}
                                    duplicateName={duplicateName}
                                    url={url}
                                    bannerNameZh={bannerNameZh}
                                    bannerNameEn={bannerNameEn}
                                    setModuleName={setModuleName}
                                    setUrl={setUrl}
                                    setBannerNameZh={setBannerNameZh}
                                    setBannerNameEn={setBannerNameEn}
                                />

                                {bannerData.length === 0 && (
                                    <BannerFormOverlay
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </StyledDialogContentBox>
                <ModuleErrorDialog
                    title={formatMessage({ id: "text_605c550654115400ca1c9a1d" })}
                    confirmText={formatMessage({ id: "text_605c550654115400ca1c9a1a" })}
                    cancelText={formatMessage({ id: "text_605c550654115400ca1c9a1b" })}
                    onConfirm={onErrorDialogClose}
                    onCancel={onErrorDialogCancel}
                    open={dialogError.length > 0}
                    onClose={onErrorDialogClose}
                >
                    {dialogError.length > 0 && dialogError[0]}
                </ModuleErrorDialog>
            </ModuleDialog>
        </>
    );
};

// export module
export const BannerEditor = (props: BannerEditorProps) => {
    const { data, onDataChange, hasDuplicateName, onSubmit, open, onClose } = props;
    if (!open) return null;
    // display component according to number of banner
    switch (data.numberOfBanner) {
        // Not yet choose number of banner
        case 0:
            return (
                <ChooseTemplate
                    onClose={onClose}
                    onChange={(n) =>
                        onDataChange({
                            ...data,
                            numberOfBanner: n,
                            focusedBanner: 0,
                            bannerData: [],
                        })
                    }
                />
            );
        // Chosen number of banner
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
            return (
                <BannerDetailEditor
                    onClose={onClose}
                    onChange={onDataChange}
                    data={data}
                    onSubmit={() => onSubmit && onSubmit(data)}
                    hasDuplicateName={hasDuplicateName}
                />
            );
    }
};
