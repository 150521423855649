import { ThemeProvider } from "@material-ui/core";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppTheme } from "./AppTheme";
import en from "./i18n/en";
import zh from "./i18n/zh";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const getLang = () => {
    // TO-DO get lang param from url
    return "zh" === "zh" ? "zh" : "en";
};
const IntlApp = () => {
    const _lang: "zh" | "en" = getLang();
    const _langMessage = {
        zh: zh,
        en: en,
    };
    const [language, setLanguage] = useState(_lang);

    return (
        <IntlProvider locale={language} messages={_langMessage[language]}>
            <App language={language} setLanguage={(lang) => setLanguage(lang)} />
        </IntlProvider>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={AppTheme}>
                <IntlApp />
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
