import {
    Box,
    createStyles,
    Theme,
    withStyles,
    WithStyles,
    IconButton,
    Button,
    Popper,
    Paper,
    Grid,
} from "@material-ui/core";
import React, { useContext, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SidebarHiddenContext, UserInfoContext, RwdContext, StoreInfoContext, ChooseStoreStatusContext } from "../App";
import AccountIc from "../assets/admin/img_account.svg";
import { ReactComponent as MenuIc } from "../assets/mobile/menu.svg";
import { ReactComponent as CloseIc } from "../assets/mobile/close.svg";
import HKTVmallLogo from "../assets/HKTVmall.svg";
import { OAuth2Login } from "../utils/OAuth2Login";
import { ROLE_TYPE } from "../interfaces/enums";

const styles = (theme: Theme) =>
    createStyles({
        header: {
            background: "#ffffff",
            boxShadow: "0 4px 8px 0 rgba(34, 34, 34, 0.08)",
            width: "100%",
            position: "fixed",
            top: "0",
            right: "0",
            left: "auto",
            zIndex: theme.zIndex.drawer + 1,
        },
        logo: {
            height: 88,
            borderRight: "1px solid #eeeeee",
            cursor: "pointer",
            transition: "background-color 0.6s",
            "&:hover": {
                backgroundColor: "rgba(34, 171, 57, 0.08)",
            },
        },
        text: {
            lineHeight: "36px",
            fontSize: 24,
            textAlign: "right",
            "& .logout, .changeStoreButton": {
                fontSize: 16,
                "& .buttonText": {
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "200px",
                },
            },
        },
        logom: {
            cursor: "pointer",
            textAlign: "left",
            justifyContent: "flex-start",
            transition: "background-color 0.6s",
            "&:hover": {
                backgroundColor: "rgba(34, 171, 57, 0.08)",
            },
            marginLeft: 0,
            marginTop: 0,
        },
        hktvImage: {
            height: 84,
            flexGrow: 4,
            marginLeft: 0,
            paddingLeft: 0,
            float: "left",
        },
        headerm: {
            background: "#ffffff",
            boxShadow: "0 4px 8px 0 rgba(34, 34, 34, 0.08)",
            justifyContent: "space-between",
            width: "100%",
            position: "fixed",
            height: "84px",
            top: "0",
            left: "0",
            zIndex: theme.zIndex.drawer + 1,
        },
        box_pc: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "30px",
        },
        box_m: {
            display: "flex",
            justifyContent: "center",
            paddingRight: "16px",
        },
        popperStyle: {
            zIndex: 9999,
        },
    });

interface HeaderProps extends WithStyles<typeof styles> {
    children?: React.ReactNode;
    onClose?: (e) => void;
    hidden?: boolean;
    hiddenStatus?: (e: boolean) => void;
}

const Header = withStyles(styles)((props: HeaderProps) => {
    const { classes } = props;
    const intl = useIntl();
    const userInfo = useContext(UserInfoContext);
    const storesInfo = useContext(StoreInfoContext);
    let stores = storesInfo?.storesDetails;
    const [chooseStoreStatus, setChooseStoreStatus] = useContext(ChooseStoreStatusContext);
    const [isSidebarHidden, setIsSidebarHidden] = useContext(SidebarHiddenContext);
    const device = useContext(RwdContext);
    let toAddress = "";
    switch (userInfo.roles[0]) {
        case "SHOP_IN_SHOP":
            toAddress = "/edit-app-page";
            break;
        case "ADMIN":
            toAddress = "/dashboard";
            break;
        default:
            toAddress = "/my-account";
    }

    return (
        <React.Fragment>
            <Box id="header" display="flex" className={device == "PC" ? classes.header : classes.headerm}>
                <Link to={toAddress} className={device == "PC" ? classes.logo : classes.logom}>
                    <img className={device == "PC" ? "" : classes.hktvImage} src={HKTVmallLogo} alt="HKTVmall logo" />
                </Link>
                <Box className={device == "PC" ? classes.box_pc : classes.box_m}>
                    <Box display="flex" className={classes.text} style={device == "PC" ? {} : { display: "none" }}>
                        <img src={AccountIc} style={{ marginRight: "9px" }} alt="Account icon" />

                        {intl.formatMessage({ id: "editor.myAccount.1.001" }, { name: userInfo.userName })}
                    </Box>
                    <Box className={classes.text} style={device == "PC" ? { cursor: "pointer" } : { display: "none" }}>
                        <Grid className="logout" onClick={() => OAuth2Login.logout()}>
                            {intl.formatMessage({ id: "editor.myAccount.1.002" })}
                        </Grid>
                    </Box>
                    <IconButton
                        aria-label="dropdown"
                        style={device == "PC" ? { display: "none" } : { float: "right" }}
                        onClick={
                            device == "PC"
                                ? () => {}
                                : () => {
                                      setIsSidebarHidden && setIsSidebarHidden(!isSidebarHidden);
                                  }
                        }
                    >
                        {isSidebarHidden ? <MenuIc /> : <CloseIc />}
                    </IconButton>
                </Box>
            </Box>
        </React.Fragment>
    );
});

export default Header;
