import {
    Box,
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogTitle,
    makeStyles,
    PropTypes,
    IconButton,
    DialogContent,
} from "@material-ui/core";
import React, { Props, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { classicNameResolver } from "typescript";
import { StoreBannerComponentType, imageType } from "../../interfaces/enums";
import { ApiServiceContext, UnknowErrorDialogContext } from "../../App";
import { UtilFunctions } from "../../utils/UtilFunctions";
import { ReactComponent as CloseIcon } from "../../assets/store/ic_closeWhite.svg";
import { ErrorDialog } from "../../components/dialogs/ErrorDialog";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../../assets/animations/btn_loading_animation.json";

const useDialogStyle = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: "transparent",
        },
        title: {
            fontSize: "36px",
            backgroundColor: "#ff4c4c",
            color: "#ffffff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "bold",
        },
        buttonText: {
            fontSize: "24px",
            fontWeight: "bold",
        },
        content: {
            padding: "16px 16px",
            width: "600px",
            height: "100px",
        },
        paper: {
            // width: 296,
            // height: 164
        },
        cancelBtn: {
            "&:hover": {
                background: "white",
                border: "1px solid rgba(234, 49, 80, 0.5)",
            },
            textTransform: "none",
            fontSize: "16px",
            padding: "10px",
        },
        closeBtn: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        removeBtn: {
            "&:hover": {
                boxShadow: "0px 0px 0px 0px",
                backgroundColor: "#ff5555",
                color: "white",
            },
            boxShadow: "0px 0px 0px 0px",
            backgroundColor: "#ff5555",
            color: "white",
            textTransform: "none",
            fontSize: "16px",
            padding: "10px",
        },
    })
);

export interface RemoveAlertDialogProps {
    isOver5mb: boolean;
    isOver50images: boolean;
    open: boolean;
    onClose: () => void;
    imageID: string;
    whichImageList?: string;
    bannerid: string;
    handleChange: () => void;
    fileName?: Array<string>;
    storeId: string;
    isImageFormatError?: boolean;
    isImageSizeDimensionError?: boolean;
}

export const RemoveAlertDialog = (props: RemoveAlertDialogProps) => {
    const {
        isOver5mb,
        open,
        onClose,
        imageID,
        bannerid,
        whichImageList,
        handleChange,
        isOver50images,
        fileName,
        storeId,
        isImageFormatError,
        isImageSizeDimensionError,
    } = props;
    const classes = useDialogStyle();
    const intl = useIntl();
    const apiService = useContext(ApiServiceContext);
    const over5mb = isOver5mb;
    const over50images = isOver50images;
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const handleClose = () => {
        onClose();
    };

    const handleRemoveBanner = () => {
        let component_type_test: imageType = imageType.TYPE_1;
        if (bannerid === "1") component_type_test = imageType.TYPE_1;
        else if (bannerid === "2") component_type_test = imageType.TYPE_2;
        else if (bannerid === "3") component_type_test = imageType.TYPE_3;
        else if (bannerid === "4") component_type_test = imageType.TYPE_4;
        else if (bannerid === "5-6" && whichImageList === "1") component_type_test = imageType.TYPE_5;
        else if (bannerid === "5-6" && whichImageList === "2") component_type_test = imageType.TYPE_6;
        else if (bannerid === "7-8" && whichImageList === "1") component_type_test = imageType.TYPE_7;
        else if (bannerid === "7-8" && whichImageList === "2") component_type_test = imageType.TYPE_8;

        let data = new FormData();

        data.append("type", component_type_test);
        data.append("store", storeId);
        data.append("imageId", imageID);
        setDisabled(true);
        if (!disabled) {
            apiService
                .deleteBannerList(data)
                .then((res) => {
                    onClose();
                    if (UtilFunctions.validateApiResponse(res.data)) {
                        props.handleChange();
                    } else throw new Error("Error Unknown");
                })
                .catch(() => {
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                })
                .finally(() => {
                    setDisabled(false);
                });
        }
    };

    const switchTitle = () => {
        if (over5mb || isImageFormatError || isImageSizeDimensionError) return intl.formatMessage({ id: "5.024" });
        else if (isOver50images) return intl.formatMessage({ id: "5.020" });
        else return intl.formatMessage({ id: "5.020" });
    };

    const switchCaption = () => {
        if (over5mb) {
            return intl.formatMessage({ id: "5.026" });
        } else if (isImageSizeDimensionError) {
            return intl.formatMessage({ id: "5.014" });
        } else if (isImageFormatError) {
            return intl.formatMessage({ id: "5.028" });
        } else if (isOver50images) return intl.formatMessage({ id: "5.027" });
        else return intl.formatMessage({ id: "5.021" });
    };

    return (
        <>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <div style={{ backgroundColor: "transparent" }}>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ backgroundColor: "transparent" }}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className={classes.title}
                        style={{ padding: "16px 16px" }}
                        disableTypography
                    >
                        <Box display="contents" alignItems="centre" justifyContent="space-between">
                            <div style={{ fontSize: "20px", fontWeight: "bold" }}> {switchTitle()}</div>
                            <IconButton onClick={handleClose} className={classes.closeBtn}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>

                    <DialogContent style={{ height: "70px", padding: "16px 16px" }}>
                        {switchCaption()}
                        <br />
                        {fileName}
                    </DialogContent>
                    <Box
                        display="flex"
                        flexDirection="row-reverse"
                        paddingTop="20px"
                        paddingBottom="10px"
                        paddingRight="10px"
                    >
                        {over5mb || isImageFormatError || isImageSizeDimensionError ? (
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                className={classes.removeBtn}
                                style={{
                                    boxShadow: "0px 0px 0px 0px",
                                    backgroundColor: "#ff5555",
                                    color: "white",
                                    textTransform: "none",
                                    fontSize: "16px",
                                    padding: "10px",
                                }}
                            >
                                {intl.formatMessage({ id: "5.025" })}
                            </Button>
                        ) : over50images ? (
                            <Button
                                id={"over50"}
                                onClick={handleClose}
                                variant="contained"
                                className={classes.removeBtn}
                            >
                                {intl.formatMessage({ id: "5.025" })}
                            </Button>
                        ) : (
                            <Button
                                onClick={handleRemoveBanner}
                                variant="contained"
                                className={classes.removeBtn}
                                disabled={disabled}
                            >
                                {disabled ? (
                                    <>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: BtnLoadingAnimation,
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice",
                                                },
                                            }}
                                            width={40}
                                            height={29}
                                            style={{ position: "absolute" }}
                                        />
                                    </>
                                ) : (
                                    <>{intl.formatMessage({ id: "5.019" })}</>
                                )}
                            </Button>
                        )}

                        <div style={{ paddingRight: "5px" }}>
                            <Button
                                onClick={handleClose}
                                variant="outlined"
                                color="secondary"
                                className={classes.cancelBtn}
                            >
                                {intl.formatMessage({ id: "5.022" })}
                            </Button>
                        </div>
                    </Box>
                </Dialog>
            </div>
        </>
    );
};

export default RemoveAlertDialog;
