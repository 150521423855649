import { Button, createStyles, Grid, InputBase, Theme, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/htmlmixed/htmlmixed";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import SunEditor, { SunEditorReactProps } from "suneditor-react";
import { ReactComponent as NewCoverImageBtn } from "../../assets/editor/img_coverPhoto.svg";
import { UtilFunctions } from "../../utils/UtilFunctions";
import { UploadImageButton } from "../buttons/UploadImageButton";
import { ContentContainerBox } from "./ContentContainer";
import { RwdContext } from "../../App";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../../assets/animations/btn_loading_animation.json";

const editorLangObj = {
    toolbar: {
        default: "Default",

        save: "Save",

        font: "Font",

        formats: "Formats",

        fontSize: "Size",

        bold: "Bold",

        underline: "Underline",

        italic: "Italic",

        strike: "Strike",

        subscript: "Subscript",

        superscript: "Superscript",

        removeFormat: "Remove Format",

        fontColor: "Font Color",

        hiliteColor: "Hilite Color",

        indent: "Indent",

        outdent: "Outdent",

        align: "Align",

        alignLeft: "Align left",

        alignRight: "Align right",

        alignCenter: "Align center",

        alignJustify: "Align justify",

        list: "list",

        orderList: "Ordered list",

        unorderList: "Unordered list",

        horizontalRule: "horizontal line",

        hr_solid: "solid",

        hr_dotted: "dotted",

        hr_dashed: "dashed",

        table: "Table",

        link: "Link",

        image: "Image",

        video: "Video",

        fullScreen: "Full screen",

        showBlocks: "Show blocks",

        codeView: "Code view",

        undo: "Undo",

        redo: "Redo",

        preview: "Preview",

        print: "print",

        tag_p: "Paragraph",

        tag_div: "Normal (DIV)",

        tag_h: "Header",

        tag_blockquote: "Quote",

        tag_pre: "Code",

        template: "Template",
    },

    dialogBox: {
        linkBox: {
            title: "Insert Link",

            url: "URL to link",

            text: "Text to display",

            newWindowCheck: "Open in new window",
        },

        imageBox: {
            title: "Insert image",

            file: "Select from files",

            url: "Image URL",

            altText: "Alternative text",
        },

        videoBox: {
            title: "Insert Video",

            url: "YouTube Url",
        },

        caption: "Insert description",

        close: "Close",

        submitButton: "Submit",

        revertButton: "Revert",

        proportion: "constrain proportions",

        width: "Width",

        height: "Height",

        basic: "Basic",

        left: "Left",

        right: "Right",

        center: "Center",

        ratio: "Ratio",
    },

    controller: {
        edit: "Edit",

        remove: "Remove",

        insertRowAbove: "Insert row above",

        insertRowBelow: "Insert row below",

        deleteRow: "Delete row",

        insertColumnBefore: "Insert column before",

        insertColumnAfter: "Insert column after",

        deleteColumn: "Delete column",

        resize100: "Resize 100%",

        resize75: "Resize 75%",

        resize50: "Resize 50%",

        resize25: "Resize 25%",

        mirrorHorizontal: "Mirror, Horizontal",

        mirrorVertical: "Mirror, Vertical",

        rotateLeft: "Rotate left",

        rotateRight: "Rotate right",

        maxSize: "Max size",

        minSize: "Min size",

        tableHeader: "Table header",

        mergeCells: "Merge cells",

        splitCells: "Split Cells",

        HorizontalSplit: "Horizontal split",

        VerticalSplit: "Vertical split",
    },
};
const styles = (theme: Theme) =>
    createStyles({
        containerBox: {
            display: "flex",
            flexWrap: "wrap",
            "& .fieldTitle": {
                marginRight: 30,
                lineHeight: "40px",
            },
            "& #articleTitleInput": {
                height: "40px",
                padding: 0,
            },
            "& .sun-editor": {
                flexBasis: "100%",
                marginTop: "18px",
            },
            "& .fieldTitleMobile": {
                fontSize: "14px",
                margrinBottom: "4px",
                textAlign: "left",
                color: "#222222",
            },
        },
        inputBase: {
            borderRadius: 8,
            border: "solid 1px #999999",
            padding: "0 10px",
            flexGrow: 1,
        },
        inputBaseMobile: {
            width: "95%",
            height: "auto",
            margin: "4px 10px 8px",
            borderRadius: "6px",
            border: "solid 1px #d8d8d8",
            backgroundColor: "#ffffff",
        },
    });

export interface ArticleContentEditContainerProps extends WithStyles<typeof styles> {
    title: string;
    content: string;
    titleOnChange: (title: string) => void;
    contentOnChange: (content: string) => void;
    handleRichTextEditorImageUpload: (targetImgElement, index, state, imageInfo, remainingFilesCount) => void;
    uploadImageUrl: string;
    imageUploadToken: string;
    onImageUploadError: (errorMessage: any, result: any) => void;
    onImageUploadBefore: SunEditorReactProps["onImageUploadBefore"];
}

export const ArticleContentEditContainer = withStyles(styles)((props: ArticleContentEditContainerProps) => {
    const intl = useIntl();
    const { classes } = props;
    const device = useContext(RwdContext);
    return (
        <ContentContainerBox
            key={props.imageUploadToken}
            className={classes.containerBox}
            style={device == "PC" ? {} : { width: "auto", height: "auto", marginLeft: "10px", marginRight: "10px" }}
        >
            <span className={device == "PC" ? "fieldTitle" : "fieldTitleMobile"}>
                {intl.formatMessage({ id: "newArticle.1.005" })}
            </span>
            <InputBase
                id="articleTitleInput"
                className={device == "PC" ? classes.inputBase : classes.inputBaseMobile}
                value={props.title ? props.title : ""}
                onChange={(e) => {
                    props.titleOnChange(e.currentTarget.value);
                }}
                error={props.title.length > 50}
            />
            {props.title.length > 50 && (
                <div
                    style={
                        device == "PC"
                            ? { fontSize: 18, color: "#ff0000", flexBasis: "100%", marginLeft: "89px" }
                            : { fontSize: 18, color: "#ff0000", flexBasis: "100%" }
                    }
                >
                    {intl.formatMessage({ id: "newArticle.NA.001" })}
                </div>
            )}

            <SunEditor
                lang="en"
                name="articleContentInput"
                setContents={props.content}
                onChange={(content) => {
                    props.contentOnChange(content);
                }}
                height={"430px"}
                width={device == "PC" ? "" : "90%"}
                onImageUploadError={props.onImageUploadError}
                onImageUploadBefore={props.onImageUploadBefore}
                // onImageUpload={props.handleRichTextEditorImageUpload}
                setOptions={{
                    codeMirror: CodeMirror,
                    buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize", "formatBlock"],
                        ["blockquote"],
                        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                        ["fontColor", "hiliteColor" /*, "textStyle" */],
                        ["removeFormat"],
                        "/", // Line break
                        // ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "lineHeight"],
                        ["table", "link", "image", "video" /* 'audio' ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin. // You must add the "imageGalleryUrl".
                        /** ['imageGallery'] */ ["showBlocks", "codeView"],
                    ],
                    lang: editorLangObj,
                    stickyToolbar: -1,
                    imageUrlInput: false,
                    imageUploadHeader: { authorization: props.imageUploadToken },
                    imageUploadUrl: props.uploadImageUrl ? props.uploadImageUrl : undefined,
                    videoSizeOnlyPercentage: true,
                    videoRotation: false,
                    youtubeQuery: "fs=0",
                }}
            />
        </ContentContainerBox>
    );
});

export interface ArticleCoverImageContainerProps extends WithStyles<typeof styles> {
    customId: string;
    imageUrl: string;
    imageOnChange: (e) => void;
    imageOnDelete: (e) => void;
    imageInvalid?: () => void;
}

export const ArticleCoverImageContainer = withStyles(styles)((props: ArticleCoverImageContainerProps) => {
    const intl = useIntl();
    const { classes } = props;
    const device = useContext(RwdContext);
    return (
        <ContentContainerBox
            style={
                device == "PC"
                    ? {}
                    : {
                          width: "auto",
                          margin: "8px 10px",
                          backgroundColor: "#ffffff",
                          borderRadius: "6px",
                          height: "auto",
                          paddingLeft: "8px",
                          paddingRight: "12px",
                      }
            }
        >
            <div className="containerTitle" style={device == "PC" ? {} : { fontSize: "14px" }}>
                {intl.formatMessage({ id: "newArticle.1.006" })}
            </div>
            <div className="containerRemark" style={device == "PC" ? {} : { fontSize: "14px" }}>
                {intl.formatMessage({ id: "newArticle.1.007" })}
            </div>
            <input
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
                id={props.customId}
                type="file"
                onChange={(e) => {
                    if (!UtilFunctions.isValidImageExt(e.target.value)) {
                        if (props.imageInvalid) {
                            props.imageInvalid();
                            return;
                        }
                    }

                    props.imageOnChange(e.currentTarget?.files ? e.currentTarget?.files[0] : undefined);
                }}
            />
            <label htmlFor={props.customId}>
                <UploadImageButton id="uploadCoverImage" imgSrc={props.imageUrl} onClose={props.imageOnDelete}>
                    <NewCoverImageBtn style={device == "PC" ? {} : { width: "auto", height: "auto" }} />
                </UploadImageButton>
            </label>
        </ContentContainerBox>
    );
});

export interface ArticleCategoryListContainerProps extends WithStyles<typeof styles> {
    categoryList;
    selectedCategory: string[];
    categorySelectHandler: (categoryId: string) => void;
}

export const ArticleCategoryListContainer = withStyles(styles)((props: ArticleCategoryListContainerProps) => {
    const intl = useIntl();
    const { classes } = props;
    const device = useContext(RwdContext);
    return (
        <ContentContainerBox
            style={
                device == "PC"
                    ? {}
                    : {
                          width: "auto",
                          margin: "8px 10px",
                          backgroundColor: "#ffffff",
                          borderRadius: "6px",
                          height: "auto",
                          overflow: "hidden",
                      }
            }
        >
            <div
                className="containerTitle"
                style={
                    device == "PC"
                        ? {}
                        : {
                              fontSize: "14px",
                              fontWeight: "bold",
                              margin: "12px 0 8px 8px",
                              color: "#222222",
                              paddingLeft: "8px",
                              paddingRight: "12px",
                          }
                }
            >
                {intl.formatMessage({ id: "newArticle.1.008" })}
            </div>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
                style={{ margin: "-8px 0", paddingLeft: "8px", paddingRight: "12px" }}
            >
                {props.categoryList?.map((category) => (
                    <Grid key={category._id} item>
                        <Button
                            variant={props.selectedCategory?.includes(category._id) ? "contained" : "outlined"}
                            color="primary"
                            disableElevation
                            onClick={() => props.categorySelectHandler(category._id)}
                            style={
                                device == "PC"
                                    ? { padding: "5px 15px", border: "1px solid rgba(34, 171, 57, 0.5)" }
                                    : { padding: "10px 12px", fontSize: "14px", gap: "10px", borderRadius: "3px" }
                            }
                        >
                            {intl.locale === "zh" ? category?.zh?.name : category?.en?.name}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </ContentContainerBox>
    );
});

export interface ArticlePublishButtonsContainerProps extends WithStyles<typeof styles> {
    disableButtons: boolean;
    isSubmitting: boolean;
    previewOnClickHandler: () => void;
    publishOnClickHandler: () => void;
    saveAsDraftOnClickHandler: () => void;
    sceduledPublishHandler: () => void;
}

export const ArticlePublishButtonsContainer = withStyles(styles)((props: ArticlePublishButtonsContainerProps) => {
    const intl = useIntl();
    const { classes } = props;
    const device = useContext(RwdContext);
    return (
        <ContentContainerBox
            style={
                device == "PC"
                    ? {}
                    : {
                          width: "auto",
                          margin: "8px 10px",
                          backgroundColor: "#ffffff",
                          borderRadius: "6px",
                          height: "auto",
                          overflow: "hidden",
                      }
            }
        >
            <div
                className="containerTitle"
                style={
                    device == "PC"
                        ? {}
                        : {
                              fontWeight: "bold",
                              color: "#222222",
                              margin: "12px 0 8px 8px",
                              fontSize: "14px",
                          }
                }
            >
                {intl.formatMessage({ id: "newArticle.1.009" })}
            </div>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
                style={{ margin: "-8px 0" }}
            >
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={props.disableButtons || props.isSubmitting}
                        disableElevation
                        onClick={() => props.previewOnClickHandler()}
                        style={device == "PC" ? {} : { fontSize: "14px" }}
                    >
                        {!props.isSubmitting ? (
                            intl.formatMessage({ id: "newArticle.1.010" })
                        ) : (
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: BtnLoadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width={40}
                                height={29}
                            />
                        )}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={props.disableButtons || props.isSubmitting}
                        disableElevation
                        onClick={() => props.publishOnClickHandler()}
                        style={device == "PC" ? {} : { fontSize: "14px" }}
                    >
                        {!props.isSubmitting ? (
                            intl.formatMessage({ id: "newArticle.1.011" })
                        ) : (
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: BtnLoadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width={40}
                                height={29}
                            />
                        )}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={props.disableButtons || props.isSubmitting}
                        disableElevation
                        onClick={() => props.saveAsDraftOnClickHandler()}
                        style={device == "PC" ? {} : { fontSize: "14px" }}
                    >
                        {!props.isSubmitting ? (
                            intl.formatMessage({ id: "newArticle.1.012" })
                        ) : (
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: BtnLoadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width={40}
                                height={29}
                            />
                        )}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={props.disableButtons || props.isSubmitting}
                        disableElevation
                        onClick={() => props.sceduledPublishHandler()}
                        style={device == "PC" ? {} : { fontSize: "14px" }}
                    >
                        {!props.isSubmitting ? (
                            intl.formatMessage({ id: "newArticle.1.013" })
                        ) : (
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: BtnLoadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width={40}
                                height={29}
                            />
                        )}
                    </Button>
                </Grid>
            </Grid>
        </ContentContainerBox>
    );
});
