import { Dialog, DialogContent } from "@material-ui/core";
import { createStyles, styled, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { ReactComponent as SuccessIcon } from "../../assets/store/ic_success.svg";

const styles = (theme: Theme) =>
    createStyles({
        // title: {
        //     backgroundColor: theme.palette.error.main,
        //     color: theme.palette.error.contrastText,
        //     fontSize: "20px",
        //     fontWeight: 600,
        //     padding: "14px 24px",
        //     minHeight: "60px",
        //     lineHeight: "60px",
        // },
    });

const StatusPopupContent = styled(DialogContent)({
    padding: 0,
    "&:first-child": {
        paddingTop: 0,
    },
    "& .content": {
        margin: "40px 80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "24px",
        color: "#222222",
        lineHeight: "36px",
        fontWeight: "bold",
    },
    "& .mobileContent": {
        margin: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "18px",
    },
});

interface StatusPopupProps extends WithStyles<typeof styles> {
    openStatus: boolean;
    toggleDialog: (status: boolean) => void;
    content: string | JSX.Element;
    rwdStatus?: string;
}

const StatusPopup = withStyles(styles)((props: StatusPopupProps) => {
    const { openStatus, toggleDialog, classes, content, rwdStatus } = props;
    return (
        <Dialog
            maxWidth={"sm"}
            scroll={"paper"}
            fullWidth={true}
            aria-labelledby="customized-dialog-title"
            open={openStatus}
            onClose={(e) => toggleDialog(false)}
        >
            <StatusPopupContent>
                <div className={rwdStatus == "PC" ? "content" : "mobileContent"}>{content}</div>
            </StatusPopupContent>
        </Dialog>
    );
});

export default StatusPopup;

type SubmitSuccessStatusPopupProps = {
    openStatus: boolean;
    toggleDialog: (open: boolean) => void;
    content: React.ReactNode;
};
export const SubmitSuccessStatusPopup = (props: SubmitSuccessStatusPopupProps) => (
    <StatusPopup
        openStatus={props.openStatus}
        toggleDialog={props.toggleDialog}
        content={
            <div style={{ width: "100%", textAlign: "center" }}>
                <SuccessIcon />
                <div style={{ color: "#00a759" }}>{props.content}</div>
            </div>
        }
    />
);
