import React from "react";
import { Box, styled } from "@material-ui/core";
import { ReactComponent as WarningIcon } from "../../assets/store/ic_Warning.svg";

const ErrorBannerContainer = styled(Box)({
    marginBottom: 16,
    padding: "12px 17px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    fontSize: 14,
    lineHeight: 1.14,
    color: "#ffffff",
    backgroundColor: "#ff4c4c",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.12)",
});

export const ErrorBanner = (props: { children: React.ReactNode }) => (
    <ErrorBannerContainer>
        <WarningIcon style={{ marginRight: 16, minWidth: 15, minHeight: 16 }} />
        {props.children}
    </ErrorBannerContainer>
);
