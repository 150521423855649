import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { UserInfoContext, StoreInfoContext, IsEmptyUserDetail, ChooseStoreStatusContext } from "../App";
import { RouteItem } from "../interfaces";
import { ROLE_TYPE, RoutePath, RoutePathRule } from "../interfaces/enums";
import _ from "lodash";

export const RouteItems: RouteItem[] = [
    {
        to: RoutePath.MY_ACCOUNT,
        activateRoles: [ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.PUBLIC, ROLE_TYPE.HOST],
    },
    {
        to: RoutePath.DASHBOARD,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.SUPERFOLLOW_ADMIN],
        rules: [RoutePathRule.MY_ACCOUNT_PROFILE_REQUIRED],
    },
    {
        to: RoutePath.EDIT_APP_PAGE,
        activateRoles: [ROLE_TYPE.SHOP_IN_SHOP],
        rules: [RoutePathRule.STORE_SELECT_REQUIRED],
    },
    {
        to: RoutePath.ARTICLES,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.PUBLIC, ROLE_TYPE.SUPERFOLLOW_ADMIN],
        rules: [RoutePathRule.MY_ACCOUNT_PROFILE_REQUIRED],
    },
    {
        to: RoutePath.NEW_ARTICLE,
        activateRoles: [ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.PUBLIC],
        rules: [RoutePathRule.MY_ACCOUNT_PROFILE_REQUIRED],
    },
    {
        to: RoutePath.EDIT_ARTICLE,
        activateRoles: [ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.PUBLIC],
        rules: [RoutePathRule.MY_ACCOUNT_PROFILE_REQUIRED],
    },
    {
        to: RoutePath.APPROVE_ARTICLE,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.SUPERFOLLOW_ADMIN],
    },
    {
        to: RoutePath.CATEGORY,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.SUPERFOLLOW_ADMIN],
    },
    {
        to: RoutePath.LIVE_BOOKING,
        activateRoles: [ROLE_TYPE.HOST, ROLE_TYPE.ADMIN, ROLE_TYPE.LIVE_ADMIN],
        rules: [RoutePathRule.MY_ACCOUNT_PROFILE_REQUIRED],
    },
    {
        to: RoutePath.LIVE_BOOKING_HISTORY,
        activateRoles: [ROLE_TYPE.HOST],
        rules: [RoutePathRule.MY_ACCOUNT_PROFILE_REQUIRED],
    },
    {
        to: RoutePath.TIME_SLOTS_FOR_BOOKING,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.LIVE_ADMIN],
    },
    {
        to: RoutePath.EDIT_TIME_SLOTS,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.LIVE_ADMIN],
    },
    {
        to: RoutePath.STORE_LIST_PAGE,
        activateRoles: [ROLE_TYPE.SHOP_IN_SHOP],
    },
    {
        to: RoutePath.ALL_TIME_SLOTS,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.LIVE_ADMIN],
    },
    {
        to: RoutePath.MANAGE_BANNER,
        activateRoles: [ROLE_TYPE.SHOP_IN_SHOP],
        rules: [RoutePathRule.STORE_SELECT_REQUIRED],
    },
    {
        to: RoutePath.NO_STORE,
        activateRoles: [ROLE_TYPE.SHOP_IN_SHOP],
    },
];

function PrivateRoute(props: RouteProps) {
    const userInfo = React.useContext(UserInfoContext);
    const storeInfo = React.useContext(StoreInfoContext);

    const [isEmptyUserDetail] = React.useContext(IsEmptyUserDetail);
    const [chooseStore, setChooseStoreStatus] = React.useContext(ChooseStoreStatusContext);

    const index = React.useMemo(
        () => _.findIndex(RouteItems, (item) => item.to === (props.path as string)),
        [userInfo, props.path]
    );

    // Check if the user have right role to access target route
    if (!RouteItems[index]?.activateRoles?.some((o) => userInfo.roles?.includes(o))) {
        // Default Landing when failed to route
        if (storeInfo.storesDetails.length > 0 && userInfo.roles?.includes(ROLE_TYPE.SHOP_IN_SHOP))
            return <Redirect to={RoutePath.STORE_LIST_PAGE} />;
        if (userInfo.roles?.includes(ROLE_TYPE.ADMIN)) return <Redirect to={RoutePath.DASHBOARD} />;
        if (userInfo.roles?.includes(ROLE_TYPE.SUPERFOLLOW_ADMIN)) return <Redirect to={RoutePath.DASHBOARD} />;
        if (userInfo.roles?.includes(ROLE_TYPE.LIVE_ADMIN)) return <Redirect to={RoutePath.LIVE_BOOKING_MENU} />;

        return <Redirect to={RoutePath.MY_ACCOUNT} />;
    }

    if (
        RouteItems[index]?.rules?.includes(RoutePathRule.MY_ACCOUNT_PROFILE_REQUIRED) &&
        isEmptyUserDetail &&
        [ROLE_TYPE.HOST, ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.PUBLIC].some((el) => userInfo.roles?.includes(el))
    ) {
        return <Redirect to={RoutePath.MY_ACCOUNT} />;
    }

    if (
        RouteItems[index]?.rules?.includes(RoutePathRule.STORE_SELECT_REQUIRED) &&
        !chooseStore.status &&
        userInfo.roles?.includes(ROLE_TYPE.SHOP_IN_SHOP)
    ) {
        return <Redirect to={RoutePath.STORE_LIST_PAGE} />;
    }

    // Everything passed, go to target route
    return <Route {...props} />;
}

export default PrivateRoute;
