import { IconButton } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { RwdContext } from "../../App";
import { ReactComponent as CloseIcon } from "../../assets/store/ic_close.svg";

export interface UploadImageButtonProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    imgSrc: string;
    onClose?: (e) => void;
}

const styles = () =>
    createStyles({
        uploadImageButtonWrapper: {
            position: "relative",
            display: "inline-block",
        },
        closeButton: {
            position: "absolute",
            right: 2,
            top: 2,
        },
        iconRoot: {
            // reset padding
            padding: 0,
        },
        iconLabel: {
            position: "relative",
            overflow: "hidden",
        },
    });

export const UploadImageButton = withStyles(styles)((props: UploadImageButtonProps) => {
    const { children, classes, imgSrc, onClose, ...other } = props;
    const device = useContext(RwdContext);
    return (
        <div
            className={classes.uploadImageButtonWrapper}
            style={device == "PC" ? {} : { width: "100%", maxWidth: 180 }}
        >
            <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                classes={{
                    root: classes.iconRoot,
                    label: classes.iconLabel,
                }}
                {...other}
                style={device == "PC" ? { width: "100%" } : { width: "100%" }}
            >
                {props.children}
                {props.imgSrc ? (
                    <img
                        src={props.imgSrc}
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "6px",
                        }}
                    ></img>
                ) : null}
            </IconButton>
            {onClose && props.imgSrc ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </div>
    );
});
