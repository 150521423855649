import React from "react";
import { Box, FormLabel, Grid, styled } from "@material-ui/core";
import { ReactComponent as UploadIcon } from "../assets/store/ic_uploadImage.svg";
import DragAndDrop from "./DragAndDrop";

interface DragAndDropBannerUploadProps {
    onImageDrag: Function;
    disabled?: boolean;
    maxFileSize?: number;
}

const UploadBannerLabel = styled(FormLabel)({
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.54)",
    textDecoration: "underline",
    cursor: "pointer",
});
const UploadBannerTextBox = styled(Box)({
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.54)",
});
const UploadBannerGrid = styled(Grid)({
    height: "100%",
    minHeight: 209,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fafafa",
    border: "dashed 0.8px rgba(0, 0, 0, 0.12)",
});
const UploadBannerOverlay = styled(Box)({
    position: "absolute",
    width: "calc(100% + 2px)",
    height: "calc(100% + 2px)",
    top: 0,
    left: 0,
    opacity: 0.8,
    backgroundColor: "#c4c4c4",
});

export const DragAndDropBannerUpload = (props: DragAndDropBannerUploadProps) => {
    const ID = "upload-banner";
    const { onImageDrag, disabled, maxFileSize } = props;

    return (
        <Box style={{ position: "relative", width: "100%", height: "100%" }}>
            <DragAndDrop fullHeight multiFile handleDrop={onImageDrag}>
                <input
                    id={ID}
                    type="file"
                    multiple
                    accept=".png, .jpg, .jpeg"
                    style={{ display: "none" }}
                    onChange={(e) =>
                        onImageDrag && onImageDrag(e.currentTarget?.files?.length && e.currentTarget.files)
                    }
                />
                <UploadBannerGrid>
                    <UploadIcon />
                    <UploadBannerTextBox>
                        {`Drag & Drop files here or click `}
                        <UploadBannerLabel htmlFor={ID}>{`here`}</UploadBannerLabel>
                        {` to browse files`}
                    </UploadBannerTextBox>
                    <UploadBannerTextBox>{`File format : .jpg / .png`}</UploadBannerTextBox>
                    {maxFileSize && <UploadBannerTextBox>{`Max. File Sie: ${maxFileSize}mb`}</UploadBannerTextBox>}
                </UploadBannerGrid>
            </DragAndDrop>
            {disabled && (
                <UploadBannerOverlay
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                />
            )}
        </Box>
    );
};
