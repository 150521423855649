import { Button, createStyles, Grid, Link, makeStyles, styled, Theme, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { ReactComponent as AddIc } from "../assets/admin/ic/add.svg";
import { NewCategoryDialog } from "../components/dialogs/NewCategoryDialog";
import { ApiServiceContext, UnknowErrorDialogContext, RwdContext } from "../App";
import { UtilFunctions } from "../utils/UtilFunctions";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import TextInputWithLabel from "../components/textfield/TextInputWithLabel";
import SelectInputWithLabel from "../components/textfield/SelectInputWithLabel";
import { ReactComponent as RemoveIc } from "../assets/editor/ic/remove.svg";
import StatusPopup from "../components/dialogs/StatusPopup";

type RemoveTimeSlotDialogStatus = {
    _timeSlotId: string | undefined;
    openStatus: boolean;
};

const dialogInitState: RemoveTimeSlotDialogStatus = {
    _timeSlotId: undefined,
    openStatus: false,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        EditTimeSlots_Wrapper: {
            width: "100%",
            height: "100%",
            "& .title": {
                fontSize: "22px",
                fontWeight: "bold",
            },
        },
        TableRow_Wrapper: {
            marginTop: 10,
            position: "relative",
        },
        tableRow_header: {
            fontWeight: "bold",
        },
        tableRow_items: {
            background: "#fff",
            textAlign: "center",
            padding: 11,
            fontSize: 20,
            margin: 1,
            "@media (max-width : 1280px)": {
                fontSize: "13px",
            },
        },
        tableRow_Corner: {
            borderRadius: 6,
        },
        timeSlotNotFound: {
            color: "#ff5555",
            fontWeight: "bold",
            fontSize: 20,
            marginTop: 25,
        },
        remove_button: {
            cursor: "pointer",
            // "@media (max-width : 400px )": {
            //     "& span": {
            //         display: "none",
            //     },
            // },
        },

        StartEndTime_Wrapper: {
            marginTop: 32,
        },
    })
);

const SubmitButton = styled(Button)({
    background: "#22ab39",
    border: 0,
    borderRadius: 3,
    color: "#ffffff",
    paddingLeft: 17,
    paddingRight: 17,
    fontSize: 16,
    "&:disabled": {
        backgroundColor: "#d8d8d8",
        color: "#222222",
    },
});

function NewTimeSlotDialog(props) {
    const intl = useIntl();

    return (
        <NewCategoryDialog
            title={intl.formatMessage({ id: "admin.editTimeSlots.1.042" })}
            content={props.newTimeSlotForm}
            confirmBtnText={intl.formatMessage({ id: "admin.editTimeSlots.1.053" })}
            confirmHandler={props.confirmAddTimeSlot_Dialog}
            cancelBtnText={intl.formatMessage({ id: "admin.editTimeSlots.1.052" })}
            cancelHandler={props.setPopUpAddTimeSlot_Dialog}
            openStatus={props.popUpAddTimeSLot_Dialog}
            toggleDialog={props.setPopUpAddTimeSlot_Dialog}
            newCategoryValidated={props.popUpAddTimeSLot_Dialog}
            notFullWidth={true}
            disabled={props.isSubmittingNewTimeSlot}
        />
    );
}

function EditTimeSlotsPage(props) {
    const classes = useStyles();
    const intl = useIntl();
    const apiService = useContext(ApiServiceContext);
    const device = useContext(RwdContext);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [popUpAddTimeSLot_Dialog, setPopUpAddTimeSlot_Dialog] = useState(false);
    const [isFetched, setIsFetched] = useState<Boolean>(false);

    const [dropdown_year, setDropdown_year] = useState("placeholder");
    const [dropdown_month, setDropdown_month] = useState("placeholder");
    const [fromHour, setFromHour] = useState("");
    const [fromMinutes, setFromMinutes] = useState("");
    const [toHour, setToHour] = useState("");
    const [toMinutes, setToMinutes] = useState("");

    const [openStatus, setOpenStatus] = useState<boolean>(false);
    const [statusDisplayText, setStatusDisplayText] = useState("");
    const [month, setMonth] = useState<Array<{ name: string; value: string }>>([]);
    const [openValidateError, setOpenValidateError] = useState(false);
    const [errMsg, setErrMsg] = useState("general.1.002");

    const [isSubmittingNewTimeSlot, setIsSubmittingNewTimeSlot] = useState(false);
    const year = UtilFunctions.getYearDropDownOptions();

    const getAllTimeSlot_handler = () => {
        let obj = UtilFunctions.getAllTimeSlot_params();
        apiService
            .getAllTimeSlots(obj)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setAllTimeSlots(res.data?.data);
                } else {
                    console.log("Fail to fetch getAllTimeSlots");
                    throw new Error("Error Unknown");
                }
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setIsFetched(true);
            });
    };

    const addTimeSlot_parser = (
        _dropdown_year: string,
        _dropdown_month: string,
        _fromHour: string,
        _fromMinutes: string,
        _toHour: string,
        _toMinutes: string
    ) => {
        let _fromHourNumber = Number(_fromHour);
        let _toHourNumber = Number(_toHour);
        _fromHourNumber = _fromHourNumber === 24 ? 0 : _fromHourNumber;
        _toHourNumber = _toHourNumber === 24 ? 0 : _toHourNumber;

        const startDateTime = _dropdown_year + _dropdown_month + "01" + " " + _fromHourNumber + ":" + _fromMinutes;
        let isNextDay = _toHourNumber < _fromHourNumber;
        const endDateTime =
            _dropdown_year + _dropdown_month + (isNextDay ? "02" : "01") + " " + _toHourNumber + ":" + _toMinutes;

        const startTimestamp = UtilFunctions.getUnixTimestamp(startDateTime, "YYYY-MM-DD HH:mm");
        const endTimestamp = UtilFunctions.getUnixTimestamp(endDateTime, "YYYY-MM-DD HH:mm");

        addTimeSlot_handler(startTimestamp, endTimestamp);
    };

    const addTimeSlot_handler = (_startTimestamp, _endTimestamp) => {
        const obj = {
            startTimestamp: _startTimestamp,
            endTimestamp: _endTimestamp,
        };
        setIsSubmittingNewTimeSlot(true);
        apiService
            .postAddTimeSlot(obj)
            .then((res) => {
                //console.log(res);
                if (res.data?.status == "success") {
                    getAllTimeSlot_handler();
                    setPopUpAddTimeSlot_Dialog(false);
                    setStatusDisplayText(intl.formatMessage({ id: "admin.editTImeSlots.1.062" }));
                    setOpenStatus(true);
                } else {
                    console.log("Fail to add time slot - startTime:" + _startTimestamp + " endTime:" + _endTimestamp);
                    setStatusDisplayText(intl.formatMessage({ id: "admin.editTImeSlots.1.054" }));
                    setOpenStatus(true);
                }
            })
            .catch((err) => {
                console.warn(err);
                console.log("Fail to add time slot - startTime:" + _startTimestamp + " endTime:" + _endTimestamp);
                if (err.code === "ECONNABORTED") {
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                } else {
                    setStatusDisplayText(intl.formatMessage({ id: "admin.editTImeSlots.1.054" }));
                    setOpenStatus(true);
                }
                setStatusDisplayText(intl.formatMessage({ id: "admin.editTImeSlots.1.054" }));
                setOpenStatus(true);
            })
            .finally(() => setIsSubmittingNewTimeSlot(false));
    };

    const newTimeSlotForm = () => (
        <div>
            {Year_DropdownList}
            {Month_DropdownList}
            {Timeslots_StartEndTime()}
        </div>
    );

    const Year_DropdownList = React.useMemo(
        () => (
            <Grid container justify="space-between">
                <Grid item xs={12}>
                    <SelectInputWithLabel
                        label={intl.formatMessage({ id: "admin.editTimeSlots.1.048a" })}
                        placeholder={intl.formatMessage({ id: "admin.editTimeSlots.1.049.a" })}
                        options={year}
                        value={dropdown_year}
                        typeTwoUI={true}
                        onChange={(val) => {
                            setDropdown_year(val);
                            setDropdown_month("placeholder");
                            setMonth(UtilFunctions.getMonthDropDownOptions(val));
                        }}
                    />
                </Grid>
            </Grid>
        ),
        [dropdown_year]
    );

    const Month_DropdownList = React.useMemo(
        () => (
            <Grid container justify="space-between">
                <Grid item xs={12}>
                    <SelectInputWithLabel
                        label={intl.formatMessage({ id: "admin.editTimeSlots.1.007" })}
                        placeholder={intl.formatMessage({ id: "admin.editTimeSlots.1.049" })}
                        disabled={!Boolean(month.length)}
                        options={month}
                        value={dropdown_month}
                        typeTwoUI={true}
                        onChange={(val) => {
                            setDropdown_month(val);
                        }}
                    />
                </Grid>
            </Grid>
        ),
        [dropdown_month, month]
    );

    const FromHour = React.useMemo(
        () => (
            <TextInputWithLabel
                label={intl.formatMessage({ id: "admin.editTimeSlots.1.050" })}
                value={fromHour}
                typeTwoUI={true}
                onChange={(val) => {
                    setFromHour(val);
                }}
            />
        ),
        [fromHour]
    );

    const FromMinutes = React.useMemo(
        () => (
            <TextInputWithLabel
                label={":"}
                value={fromMinutes}
                typeTwoUI={true}
                onChange={(val) => {
                    setFromMinutes(val);
                }}
            />
        ),
        [fromMinutes]
    );

    const ToHour = React.useMemo(
        () => (
            <TextInputWithLabel
                label={intl.formatMessage({ id: "admin.editTimeSlots.1.051" })}
                value={toHour}
                typeTwoUI={true}
                onChange={(val) => {
                    setToHour(val);
                }}
            />
        ),
        [toHour]
    );

    const ToMinutes = React.useMemo(
        () => (
            <TextInputWithLabel
                label={":"}
                value={toMinutes}
                typeTwoUI={true}
                onChange={(val) => {
                    setToMinutes(val);
                }}
            />
        ),
        [toMinutes]
    );

    const Timeslots_StartEndTime = () => (
        <Grid container className={classes.StartEndTime_Wrapper}>
            <Grid item>{FromHour}</Grid>
            <Grid item>{FromMinutes}</Grid>
            <Grid item>{ToHour}</Grid>
            <Grid item>{ToMinutes}</Grid>
        </Grid>
    );

    useEffect(() => {
        getAllTimeSlot_handler();
    }, []);

    return (
        <>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => setOpenValidateError(false)}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <div
                className={
                    device == "PC" ? classes.EditTimeSlots_Wrapper + " pageWrapper" : classes.EditTimeSlots_Wrapper
                }
            >
                {device == "PC" ? (
                    <Grid container justify="space-between" alignItems="center">
                        <span className="title">{intl.formatMessage({ id: "admin.editTimeSlots.1.002" })}</span>
                        <SubmitButton onClick={() => setPopUpAddTimeSlot_Dialog(!popUpAddTimeSLot_Dialog)}>
                            <AddIc />
                            {intl.formatMessage({ id: "admin.editTimeSlots.1.042" })}
                        </SubmitButton>
                    </Grid>
                ) : (
                    <Grid container xs={11} style={{ display: "flex", margin: "auto" }}>
                        <Grid container justify="space-between" alignItems="center">
                            <span className="title">{intl.formatMessage({ id: "admin.editTimeSlots.1.002" })}</span>
                            <SubmitButton onClick={() => setPopUpAddTimeSlot_Dialog(!popUpAddTimeSLot_Dialog)}>
                                <AddIc />
                                {intl.formatMessage({ id: "admin.editTimeSlots.1.042" })}
                            </SubmitButton>
                        </Grid>
                    </Grid>
                )}
                <div className="EditTimeSlots_PageContent pageContent">
                    <TableRow
                        allTimeSlots={allTimeSlots}
                        noTimeSlotsReturn={allTimeSlots.length ? true : false}
                        getAllTimeSlot_handler={() => getAllTimeSlot_handler()}
                        isFetched={isFetched}
                    />
                </div>

                <NewTimeSlotDialog
                    isSubmittingNewTimeSlot={isSubmittingNewTimeSlot}
                    popUpAddTimeSLot_Dialog={popUpAddTimeSLot_Dialog}
                    setPopUpAddTimeSlot_Dialog={() => setPopUpAddTimeSlot_Dialog(!popUpAddTimeSLot_Dialog)}
                    confirmAddTimeSlot_Dialog={() =>
                        addTimeSlot_parser(dropdown_year, dropdown_month, fromHour, fromMinutes, toHour, toMinutes)
                    }
                    newTimeSlotForm={newTimeSlotForm()}
                />

                <StatusPopup openStatus={openStatus} content={statusDisplayText} toggleDialog={setOpenStatus} />
            </div>
        </>
    );
}
function TableRow(props) {
    const classes = useStyles();
    const intl = useIntl();
    let length = props.allTimeSlots.length;
    const apiService = useContext(ApiServiceContext);
    const device = useContext(RwdContext);
    const [removeTimeSlotDialog, setRemoveTimeSlotDialog] = useState<RemoveTimeSlotDialogStatus>(dialogInitState);
    const [isSubmitting, setIsSubmitting] = useState("");

    const [openStatus, setOpenStatus] = useState<boolean>(false);
    const [statusDisplayText, setStatusDisplayText] = useState("");
    const [openValidateError, setOpenValidateError] = useState(false);
    const [errMsg, setErrMsg] = useState("general.1.002");
    const removeTimeSlot_handler = (_id) => {
        const obj = {
            timeSlotID: _id,
        };
        if (isSubmitting == "") {
            setIsSubmitting(_id);
            apiService
                .postRemoveTimeSlot(obj)
                .then((res) => {
                    //console.log(res);

                    if (res.data?.status !== "success") {
                        setStatusDisplayText(intl.formatMessage({ id: "admin.editTImeSlots.1.001.4" }));
                        setOpenStatus(true);
                    } else {
                        setStatusDisplayText(intl.formatMessage({ id: "admin.editTImeSlots.1.063" }));
                        setOpenStatus(true);
                        props.getAllTimeSlot_handler();
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    console.log("Fail to remove - " + obj.timeSlotID);
                    setStatusDisplayText(intl.formatMessage({ id: "admin.editTImeSlots.1.001.4" }));
                    setOpenStatus(true);
                })
                .finally(() => {
                    setIsSubmitting("");
                });

            setRemoveTimeSlotDialog(dialogInitState);
        }
    };

    const triggerRemoveTimeSlot_handler = (id: string) => {
        setRemoveTimeSlotDialog({ _timeSlotId: id, openStatus: true });
    };

    return props.isFetched ? (
        length ? (
            <div className={classes.TableRow_Wrapper}>
                <Grid container justify="center">
                    <Grid
                        item
                        className={classes.tableRow_items + " " + classes.tableRow_header}
                        xs={4}
                        style={{ borderRadius: "6px 0 0 0" }}
                    >
                        {intl.formatMessage({ id: "admin.editTimeSlots.1.007" })}
                    </Grid>
                    <Grid item className={classes.tableRow_items + " " + classes.tableRow_header} xs={4}>
                        {intl.formatMessage({ id: "admin.editTimeSlots.1.044" })}
                    </Grid>
                    <Grid
                        item
                        className={classes.tableRow_items + " " + classes.tableRow_header}
                        xs={3}
                        style={{ borderRadius: "0 6px 0 0" }}
                    >
                        {intl.formatMessage({ id: "admin.editTimeSlots.1.045" })}
                    </Grid>
                </Grid>
                {props.allTimeSlots.map((details: any, index) => {
                    return (
                        <div key={details._id + index}>
                            <Grid container justify="center">
                                <Grid
                                    item
                                    className={classes.tableRow_items}
                                    xs={4}
                                    style={{ borderRadius: length - 1 == index ? "0 0 0 6px" : "" }}
                                >
                                    {details.year + "年" + details.month + "月"}
                                </Grid>
                                <Grid item className={classes.tableRow_items} xs={4}>
                                    {UtilFunctions.getFormattedTime(details.startTime) +
                                        "-" +
                                        UtilFunctions.getFormattedTime(details.endTime)}
                                </Grid>
                                <Grid
                                    item
                                    className={classes.tableRow_items}
                                    xs={3}
                                    style={{ borderRadius: length - 1 == index ? "0 0 6px 0" : "" }}
                                >
                                    <div
                                        className={classes.remove_button}
                                        onClick={() => triggerRemoveTimeSlot_handler(details._id)}
                                    >
                                        <RemoveIc style={{ cursor: "pointer" }} />
                                        <span style={{ position: "relative", top: -7 }}>
                                            {intl.formatMessage({ id: "admin.editTimeSlots.1.046c" })}
                                        </span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    );
                })}

                <ErrorDialog
                    title={intl.formatMessage({ id: "admin.editTImeSlots.1.033" })}
                    content={intl.formatMessage({ id: "admin.editTimeSlots.1.055a" })}
                    confirmBtnText={intl.formatMessage({ id: "admin.editTimeSlots.1.053" })}
                    confirmHandler={() => removeTimeSlot_handler(removeTimeSlotDialog._timeSlotId)}
                    cancelBtnText={intl.formatMessage({ id: "admin.editTimeSlots.1.052" })}
                    cancelHandler={() => setRemoveTimeSlotDialog(dialogInitState)}
                    openStatus={removeTimeSlotDialog.openStatus}
                    toggleDialog={() => setRemoveTimeSlotDialog(dialogInitState)}
                />
                <ErrorDialog
                    openStatus={openValidateError}
                    toggleDialog={() => setOpenValidateError(false)}
                    confirmHandler={() => setOpenValidateError(false)}
                    title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                    content={
                        <div style={{ height: "82px" }}>
                            {intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}
                        </div>
                    }
                    confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
                />

                <StatusPopup openStatus={openStatus} content={statusDisplayText} toggleDialog={setOpenStatus} />
            </div>
        ) : (
            <>
                <div className={classes.timeSlotNotFound}>
                    {intl.formatMessage({ id: "admin.editTimeSlots.1.041" })}
                </div>
                <StatusPopup openStatus={openStatus} content={statusDisplayText} toggleDialog={setOpenStatus} />
            </>
        )
    ) : (
        <></>
    );
}

const month = [
    {
        name: "一月",
        value: "01",
    },
    {
        name: "二月",
        value: "02",
    },
    {
        name: "三月",
        value: "03",
    },
    {
        name: "四月",
        value: "04",
    },
    {
        name: "五月",
        value: "05",
    },
    {
        name: "六月",
        value: "06",
    },
    {
        name: "七月",
        value: "07",
    },
    {
        name: "八月",
        value: "08",
    },
    {
        name: "九月",
        value: "09",
    },
    {
        name: "十月",
        value: "10",
    },
    {
        name: "十一月",
        value: "11",
    },
    {
        name: "十二月",
        value: "12",
    },
];

export default EditTimeSlotsPage;
