import { Button, createMuiTheme, Grid, ThemeProvider, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import Compressor from "compressorjs";
import produce from "immer";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { SunEditorReactProps } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ApiServiceContext, UnknowErrorDialogContext, RwdContext } from "../App";
import { PreviewDialog, PreviewDialogProps } from "../components/dialogs/PreviewDialog";
import { Language } from "../const/languages";
import { useCallbackPrompt } from "../hooks/PromptHook";
import { Action, MODEL_CategoryDetails, REQUEST_ArticleNew } from "../interfaces";
import { articleListPopupStatus } from "../interfaces/enums";
import { apiAddress } from "../interfaces/index.d";
import { OAuth2Login } from "../utils/OAuth2Login";
import { UtilFunctions } from "../utils/UtilFunctions";
import {
    ArticleCategoryListContainer,
    ArticleContentEditContainer,
    ArticleCoverImageContainer,
    ArticlePublishButtonsContainer,
} from "./boxContainers/ArticlePageContainer";
import { NewArticleDescriptionContainer } from "./boxContainers/NewArticleDescriptionContainer";
import { ErrorDialog } from "./dialogs/ErrorDialog";
import { ReactComponent as ClockIc } from "../assets/editor/ic/clock.svg";
import { ReactComponent as CalendarIc } from "../assets/editor/ic/calendar.svg";
import { useImmer } from "use-immer";
import useQRCode from "../hooks/QRCodeHook";
import useRWD from "../hooks/MobileRWD";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../assets/animations/btn_loading_animation.json";

interface UpdateArticlepProps {
    articleId?: string;
    articleStatus?: string;
    isEditing: boolean;
    admin: boolean;
    categoryList?: MODEL_CategoryDetails[];
}

export type ScheduleProps = {
    isSubmitting: boolean;
    date: Date | null;
    time: Date | null;
};

function UpdateArticle(props: UpdateArticlepProps) {
    const intl = useIntl();
    const [unknowErrorDialog, setUnknowErrorDialog] = useContext(UnknowErrorDialogContext);
    const device = useContext(RwdContext);
    enum ActionType {
        UPDATE_TITLE = "updateTitle",
        UPDATE_CONTENT = "updateContent",
        UPDATE_COVER_IMAGE = "updateCoverImage",
        SET_CATEGORY = "setCategory",
        SET_SAVE_AS_DRAFT = "setSaveAsDraft",
        SET_SCHEDULED_PUBLISH = "setScheduledPublish",
    }
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");
    const reducer = (state: REQUEST_ArticleNew, action: Action) => {
        switch (action.type) {
            case ActionType.UPDATE_TITLE:
                return produce(state, (draft) => {
                    if (action.lang === Language.Chinese) {
                        draft.zh.title = action.value;
                    } else {
                        draft.en.title = action.value;
                    }
                });

            case ActionType.UPDATE_CONTENT:
                return produce(state, (draft) => {
                    if (action.lang === Language.Chinese) {
                        draft.zh.data = action.value;
                    } else {
                        draft.en.data = action.value;
                    }
                });

            case ActionType.UPDATE_COVER_IMAGE:
                return produce(state, (draft) => {
                    if (action.lang === Language.Chinese) {
                        draft.zh.coverImage = action.value;
                    } else {
                        draft.en.coverImage = action.value;
                    }
                });

            case ActionType.SET_CATEGORY:
                return produce(state, (draft) => {
                    draft.category = action.value;
                });

            case ActionType.SET_SCHEDULED_PUBLISH:
                return produce(state, (draft) => {
                    draft.scheduledPublish = action.value;
                });
            default:
                return state;
        }
    };

    const initStateArticleData: REQUEST_ArticleNew = {
        zh: {
            data: "",
            coverImage: "",
            type: "richTextContent", // Day 1 only have type "richTextContent"
            title: "",
        },
        en: {
            data: "",
            coverImage: "",
            type: "richTextContent", // Day 1 only have type "richTextContent"
            title: "",
        },
        category: [],
        scheduledPublish: undefined,
        publish: false, // Only set to true when clicking publish btn instead of save as draft
    };

    const [articleData, dispatch] = useReducer(reducer, initStateArticleData);

    const [articleId, setArticleId] = useState(props.articleId);

    const [validCategory, setValidCategory] = useState(false);

    const maxCategory = 1;

    const categorySelectHandler = (categoryId: string) => {
        setValidCategory(true);
        let tmp = [...articleData.category];
        let index = articleData.category.indexOf(categoryId);
        if (index != -1) {
            tmp.splice(index, 1);
        } else {
            tmp = tmp.concat([categoryId]);
        }
        if (tmp.length > maxCategory) {
            tmp.splice(0, tmp.length - maxCategory);
        }
        dispatch({ type: ActionType.SET_CATEGORY, value: tmp, lang: Language.Chinese });
    };

    const invalidCategoryHandler = () => {
        {
            props.categoryList?.map((category) =>
                articleData.category.includes(category._id) ? setValidCategory(true) : null
            );
        }
    };

    const [previewDialog, setPreviewDialog] = useState(false);
    const [qrcode, setQRCodeUrl] = useQRCode({ width: 95, margin: 0 });
    useEffect(() => {
        if (previewDialog) {
            previewHandler()?.then((res) => {
                if (res?.data?.zh) {
                    setQRCodeUrl(res.data.zh);
                }
            });
        }
    }, [previewDialog]);

    const previewHandler = () => {
        let submitData = { ...articleData, preview: true };
        return apiService
            .postNewArticle(submitData)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    return res.data;
                } else {
                    setIsSubmittingTime(false);
                    setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                    setFormChanged(true);
                    throw new Error("Error Unknown");
                }
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };

    const [removeCoverImageDialog, setRemoveCoverImageDialog] = useState(false);

    const [formChanged, setFormChanged] = useState(false);

    const [invalidContentDialog, setInvalidContentDialog] = useState(false);

    const [isSubmittingTime, setIsSubmittingTime] = useState(false);

    const [isPreviewSubmitting, setIsPreviewSubmitting] = useState(false);

    const [isScheduleSubmitting, setIsScheduleSubmitting] = useState(false);

    const [imageIds, setImageIds] = useState<string[]>([]);

    const history = useHistory();

    // unused, for scduledPublish
    const [sceduledPublishDialog, setSceduledPublishDialog] = useState(false);

    const handleDateChange = (date: Date | null, action: "time" | "date") => {
        updateScheduleProps((draft) => {
            draft[action] = date;
        });
    };

    const apiService = useContext(ApiServiceContext);

    const [accessToken, setAccessToken] = useState("");
    const [showCustomError, setShowCustomError] = useState(false);
    const [customError, updateCustomError] = useImmer<
        Pick<ErrorDialog, "title" | "content" | "confirmBtnText" | "cancelBtnText">
    >({
        title: "",
        content: "",
        confirmBtnText: "",
        cancelBtnText: "",
    });

    const uploadImageHandler = (file) => {
        if (file && UtilFunctions.isValidImageExt(file.name)) {
            new Compressor(file, {
                maxHeight: 1024,
                maxWidth: 1024,
                quality: 0.8,
                success(compressedImage) {
                    var file = new File([compressedImage], "compressed-image.jpg");
                    let imageData = new FormData();
                    imageData.append("imageFiles", file);
                    apiService
                        .postImages(imageData)
                        .then((res) => {
                            if (UtilFunctions.validateApiResponse(res.data)) {
                                let resizedUrl = UtilFunctions.getResizedImageUrl(
                                    750,
                                    450,
                                    res.data.data[0]?.url,
                                    true
                                );
                                if (resizedUrl)
                                    dispatch({
                                        type: ActionType.UPDATE_COVER_IMAGE,
                                        value: resizedUrl,
                                        lang: Language.Chinese,
                                    });
                                setImageIds([...imageIds].concat([res.data.data[0]?.id]));
                                setFormChanged(true);
                                // window.addEventListener("beforeunload", onBeforeUnloadHandler);
                            } else throw new Error("Error Unknown");
                        })
                        .catch((err) => {
                            console.warn(err);
                            setErrMsg("general.1.002");
                            setOpenValidateError(true);
                        });
                },
            });
        } else {
            setUnknowErrorDialog({
                status: !unknowErrorDialog.status,
                content: intl.formatMessage({ id: "newArticle.1.024" }),
            });
        }
    };

    const setInvalidImageFormatPopup = () => {
        setUnknowErrorDialog({
            status: !unknowErrorDialog.status,
            content: intl.formatMessage({ id: "newArticle.1.024" }),
        });
    };

    const deleteImageHandler = (e, imageUrl) => {
        e.stopPropagation();
        e.preventDefault();
        setRemoveCoverImageDialog(false);
        let data = {
            imageId: UtilFunctions.getImageIdFromUrl(imageUrl),
        };
        dispatch({ type: ActionType.UPDATE_COVER_IMAGE, value: null, lang: Language.Chinese });
        // apiService.postDeleteImages(data).then((res) => {
        //     if (UtilFunctions.validateApiResponse(res.data)) {
        //         dispatch({ type: ActionType.UPDATE_COVER_IMAGE, value: null, lang: Language.Chinese });
        //     } else {
        //         setUnknowErrorDialog({ status: !unknowErrorDialog.status });
        //     }
        // });
    };

    const handleRichTextEditorImageUpload = () => {
        let doc = new DOMParser().parseFromString(articleData.zh.data, "text/html");
        let imgTags = doc.querySelectorAll("img");
        let tmpData = articleData.zh.data;
        let newImageIds: string[] = [];
        if (imgTags && imgTags.length > 0) {
            let formData = new FormData();
            imgTags.forEach((img, index) => {
                if (img.src.startsWith("data:image")) {
                    // base64 images
                    formData.append("imageFiles", UtilFunctions.dataURLtoFile(img.src, index));
                } else {
                    let newImageId = img.src.split("/").pop()?.split(".")[0];
                    if (newImageId && !imageIds.includes(newImageId)) {
                        newImageIds.push(newImageId);
                    }
                }
            });

            // Only call api when have base64 image source in content, which means has imageFiles in formData
            if (formData.has("imageFiles"))
                apiService
                    .postRichTextEditorImages(formData)
                    .then((res) => {
                        if (UtilFunctions.validateApiResponse(res.data) && res.data) {
                            res.data.result.forEach((image, index) => {
                                tmpData.replace(imgTags[index].src, `https:${image.url}`);
                                newImageIds.push(image.name.split(".")[0]);
                            });
                        } else throw new Error("Error Unknown");
                    })
                    .catch((err) => {
                        console.warn(err);
                        setErrMsg("general.1.002");
                        setOpenValidateError(true);
                    });

            if (newImageIds.length > 0) setImageIds([...imageIds].concat(newImageIds));
        }
    };

    useEffect(() => {
        handleRichTextEditorImageUpload();
    }, [articleData.zh.data]);

    useEffect(() => {
        if (!accessToken) {
            getToken();
        }
    }, []);

    useEffect(() => {
        invalidCategoryHandler();
    }, []);

    const getToken = async () => {
        const { token } = await OAuth2Login.getAccessToken();
        setAccessToken(token?.value ?? "");
    };

    useEffect(() => {
        if (props.articleId && props.articleStatus) setArticleId(props.articleId);
    }, [props.articleId]);

    const shouldDisableButtons = React.useMemo(
        () =>
            !(articleData.zh.title && articleData.zh.coverImage && articleData.category.length) ||
            articleData.zh.title.length > 50,
        [articleData]
    );

    const [scheduleProps, updateScheduleProps] = useImmer<ScheduleProps>({
        isSubmitting: false,
        date: new Date(),
        time: new Date(),
    });

    const toggleToWYSIWYG = () => {
        let codeViewBtn: HTMLElement = document.getElementsByClassName(
            "se-btn se-code-view-enabled se-resizing-enabled _se_command_codeView se-tooltip active"
        )[0] as HTMLElement;
        if (codeViewBtn) codeViewBtn.click();
    };

    const saveAsDraftHandler = () => {
        setFormChanged(false);
        setIsSubmittingTime(true);
        toggleToWYSIWYG();
        if (!UtilFunctions.validateContent(articleData.zh.data)) {
            setInvalidContentDialog(true);
            return;
        }
        let imageStatusData = {
            imageIds: imageIds,
            status: "commit",
        };
        if (imageIds.length) {
            apiService
                .postUpdateImageStatus(imageStatusData)
                .then((res) => {
                    if (UtilFunctions.validateApiResponse(res.data)) {
                        if (articleId) {
                            apiService
                                .postUpdateArticle(articleData, articleId)
                                .then((res) => {
                                    if (UtilFunctions.validateApiResponse(res.data)) {
                                        // window.removeEventListener("beforeunload", onBeforeUnloadHandler);
                                        history.push("/articles", {
                                            status: articleListPopupStatus.SAVED_AS_DRAFT,
                                        });
                                    } else {
                                        setIsSubmittingTime(false);
                                        setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                                        setFormChanged(true);
                                        throw new Error("Error Unknown");
                                    }
                                })
                                .catch((err) => {
                                    console.warn(err);
                                    setErrMsg("general.1.002");
                                    setOpenValidateError(true);
                                });
                        } else {
                            apiService
                                .postNewArticle(articleData)
                                .then((res) => {
                                    if (UtilFunctions.validateApiResponse(res.data)) {
                                        history.push("/articles", {
                                            status: articleListPopupStatus.SAVED_AS_DRAFT,
                                        });
                                    } else {
                                        setIsSubmittingTime(false);
                                        setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                                        throw new Error("Error Unknown");
                                    }
                                })
                                .catch((err) => {
                                    console.warn(err);
                                    setErrMsg("general.1.002");
                                    setOpenValidateError(true);
                                });
                        }
                    } else {
                        setIsSubmittingTime(false);
                        setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                });
        } else {
            if (articleId) {
                apiService
                    .postUpdateArticle(articleData, articleId)
                    .then((res) => {
                        if (UtilFunctions.validateApiResponse(res.data)) {
                            // window.removeEventListener("beforeunload", onBeforeUnloadHandler);
                            history.push("/articles", {
                                status: articleListPopupStatus.SAVED_AS_DRAFT,
                            });
                        } else {
                            setIsSubmittingTime(false);
                            setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                            setFormChanged(true);
                            throw new Error("Error Unknown");
                        }
                    })
                    .catch((err) => {
                        console.warn(err);
                        setErrMsg("general.1.002");
                        setOpenValidateError(true);
                    });
            } else {
                apiService
                    .postNewArticle(articleData)
                    .then((res) => {
                        if (UtilFunctions.validateApiResponse(res.data)) {
                            history.push("/articles", {
                                status: articleListPopupStatus.SAVED_AS_DRAFT,
                            });
                        } else {
                            setIsSubmittingTime(false);
                            setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                            throw new Error("Error Unknown");
                        }
                    })
                    .catch((err) => {
                        console.warn(err);
                        setErrMsg("general.1.002");
                        setOpenValidateError(true);
                    });
            }
        }
    };

    const submitHandler = () => {
        toggleToWYSIWYG();
        setIsSubmittingTime(true);
        setIsPreviewSubmitting(true);
        setIsScheduleSubmitting(true);
        if (!UtilFunctions.validateContent(articleData.zh.data)) {
            setInvalidContentDialog(true);
            return;
        }
        let submitData = { ...articleData };
        if (sceduledPublishDialog) {
            try {
                let date = scheduleProps.date as Date; // could be null
                let _time = new Date(scheduleProps.time?.getTime() ?? 0);
                _time.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());

                if ((scheduleProps.time instanceof Date && isNaN(scheduleProps.time.getTime())) || _time < new Date()) {
                    throw new Error();
                }
                submitData.scheduledPublish = _time?.getTime();
                updateScheduleProps((draft) => {
                    draft.isSubmitting = true;
                });
            } catch {
                updateCustomError((draft) => {
                    draft.title = intl.formatMessage({ id: "editor.allPosts.4.042" });
                    draft.content = intl.formatMessage({ id: "newArticle.NA.002" });
                    draft.confirmBtnText = intl.formatMessage({ id: "newArticle.NA.003" });
                });

                setShowCustomError(true);
                return;
            }
        }
        submitData.publish = true;
        setFormChanged(false);

        let imageStatusData = {
            imageIds: imageIds,
            status: "commit",
        };

        if (imageIds.length) {
            apiService
                .postUpdateImageStatus(imageStatusData)
                .then((res) => {
                    if (UtilFunctions.validateApiResponse(res.data)) {
                        if (articleId) {
                            apiService
                                .postUpdateArticle(submitData, articleId)
                                .then((res) => {
                                    if (UtilFunctions.validateApiResponse(res.data)) {
                                        // window.removeEventListener("beforeunload", onBeforeUnloadHandler);
                                        history.push("/articles", {
                                            status: articleListPopupStatus.SAVED_UPDATES,
                                        });
                                    } else {
                                        if (sceduledPublishDialog) {
                                            updateScheduleProps((draft) => {
                                                draft.isSubmitting = false;
                                            });
                                        }
                                        setIsSubmittingTime(false);
                                        setIsPreviewSubmitting(false);
                                        setIsScheduleSubmitting(false);
                                        setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                                        setFormChanged(true);
                                        setFormChanged(true);
                                        setUnknowErrorDialog({
                                            status: !unknowErrorDialog.status,
                                            content: intl.formatMessage({ id: "general.1.002" }),
                                        });
                                    }
                                })
                                .catch((err) => {
                                    console.warn(err);
                                    setErrMsg("general.1.002");
                                    setOpenValidateError(true);
                                })
                                .finally(() => {
                                    if (sceduledPublishDialog) {
                                        updateScheduleProps((draft) => {
                                            draft.isSubmitting = false;
                                        });
                                    }
                                });
                        } else {
                            apiService
                                .postNewArticle(submitData)
                                .then((res) => {
                                    if (UtilFunctions.validateApiResponse(res.data)) {
                                        // window.removeEventListener("beforeunload", onBeforeUnloadHandler);
                                        history.push("/articles", {
                                            status: articleListPopupStatus.WAITING_FOR_APPROVAL,
                                        });
                                    } else {
                                        if (sceduledPublishDialog) {
                                            updateScheduleProps((draft) => {
                                                draft.isSubmitting = false;
                                            });
                                        }
                                        setIsSubmittingTime(false);
                                        setIsPreviewSubmitting(false);
                                        setIsScheduleSubmitting(false);
                                        setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                                        setFormChanged(true);
                                        setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                                        setFormChanged(true);
                                        throw new Error("Error Unknown");
                                    }
                                })
                                .catch((err) => {
                                    console.warn(err);
                                    setErrMsg("general.1.002");
                                    setOpenValidateError(true);
                                })
                                .finally(() => {
                                    if (sceduledPublishDialog) {
                                        updateScheduleProps((draft) => {
                                            draft.isSubmitting = false;
                                        });
                                    }
                                });
                        }
                    } else {
                        setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                        if (sceduledPublishDialog) {
                            updateScheduleProps((draft) => {
                                draft.isSubmitting = false;
                            });
                        }
                        setIsSubmittingTime(false);
                        setIsPreviewSubmitting(false);
                        setIsScheduleSubmitting(false);
                        throw new Error("Error Unknown");
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                });
        } else {
            if (articleId) {
                apiService
                    .postUpdateArticle(submitData, articleId)
                    .then((res) => {
                        if (UtilFunctions.validateApiResponse(res.data)) {
                            // window.removeEventListener("beforeunload", onBeforeUnloadHandler);
                            history.push("/articles", {
                                status: articleListPopupStatus.SAVED_UPDATES,
                            });
                        } else {
                            if (sceduledPublishDialog) {
                                updateScheduleProps((draft) => {
                                    draft.isSubmitting = false;
                                });
                            }
                            setIsSubmittingTime(false);
                            setIsPreviewSubmitting(false);
                            setIsScheduleSubmitting(false);
                            setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                            setFormChanged(true);
                            throw new Error("Error Unknown");
                        }
                    })
                    .catch((err) => {
                        console.warn(err);
                        setErrMsg("general.1.002");
                        setOpenValidateError(true);
                    })
                    .finally(() => {
                        if (sceduledPublishDialog) {
                            updateScheduleProps((draft) => {
                                draft.isSubmitting = false;
                            });
                        }
                    });
            } else {
                apiService
                    .postNewArticle(submitData)
                    .then((res) => {
                        if (UtilFunctions.validateApiResponse(res.data)) {
                            // window.removeEventListener("beforeunload", onBeforeUnloadHandler);
                            history.push("/articles", {
                                status: articleListPopupStatus.WAITING_FOR_APPROVAL,
                            });
                        } else {
                            if (sceduledPublishDialog) {
                                updateScheduleProps((draft) => {
                                    draft.isSubmitting = false;
                                });
                            }
                            setIsSubmittingTime(false);
                            setIsPreviewSubmitting(false);
                            setIsScheduleSubmitting(false);
                            setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                            setFormChanged(true);
                            throw new Error("Error Unknown");
                        }
                    })
                    .catch((err) => {
                        console.warn(err);
                        setErrMsg("general.1.002");
                        setOpenValidateError(true);
                    })
                    .finally(() => {
                        if (sceduledPublishDialog) {
                            updateScheduleProps((draft) => {
                                draft.isSubmitting = false;
                            });
                        }
                    });
            }
        }
    };

    const [categoryList, setCategoryList] = useState(props.categoryList ? props.categoryList : []);

    const fetchCategoryList = () => {
        apiService
            .getCategoryList({ showDisabled: false })
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setCategoryList([...res.data.data]);
                } else throw new Error("Error Unknown");
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };

    const fetchArticleData = (articleId: string) => {
        apiService
            .getSavedArticle(articleId)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    dispatch({
                        type: ActionType.UPDATE_TITLE,
                        value: res.data.data.articleList[0]?.zh?.title,
                        lang: Language.Chinese,
                    });
                    dispatch({
                        type: ActionType.UPDATE_CONTENT,
                        value: res.data.data.articleList[0]?.zh?.data,
                        lang: Language.Chinese,
                    });
                    dispatch({
                        type: ActionType.UPDATE_COVER_IMAGE,
                        value: res.data.data.articleList[0]?.zh?.coverImage,
                        lang: Language.Chinese,
                    });
                    dispatch({
                        type: ActionType.SET_CATEGORY,
                        value: res.data.data.articleList[0]?.category,
                        lang: Language.Chinese,
                    });
                    if (res.data.data.articleList[0]?.scheduledPublish) {
                        let _time: number = res.data.data.articleList[0].scheduledPublish;

                        let date = new Date(_time);
                        date.setHours(0, 0, 0, 0);

                        updateScheduleProps((draft) => {
                            draft.date = date;
                            draft.time = new Date(_time);
                        });
                    }
                } else throw new Error("Error Unknown");
            })
            .catch((err) => {
                console.warn(err);
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setFormChanged(false);
            });
    };

    const onBeforeUnloadHandler = (e) => {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
        return "";
    };

    const beforePreviewChecking = () => {
        if (!UtilFunctions.validateContent(articleData.zh.data)) {
            setInvalidContentDialog(true);
        }else{
            setPreviewDialog(true);
        }
    }

    const imageUploadErrorHandler = (errMsg, result) => {
        if (errMsg === "NA") {
            setUnknowErrorDialog({ status: !unknowErrorDialog.status });
            return;
        }
        setUnknowErrorDialog({ status: !unknowErrorDialog.status, content: errMsg });
    };

    const imageUploadBeforeHandler: SunEditorReactProps["onImageUploadBefore"] = (files, info, uploadHandler) => {
        // If true is returned, the internal upload process runs normally.
        // If false is returned, no image upload is performed.
        // If new fileList are returned,  replaced the previous fileList
        // If undefined is returned, it waits until "uploadHandler" is executed.

        // let max_in_bytes = 10 * 1024 * 1024; // 10m
        // if (files[0]?.size >= max_in_bytes) return false;
        const formData = new FormData();
        formData.append("file-0", files[0]);
        if (!UtilFunctions.isValidImageExt(files[0].name)) {
            setInvalidImageFormatPopup();
            return false;
        }
        apiService
            .postRichTextEditorImages(formData)
            .then((res) => {
                if (res?.data?.status === "fail") uploadHandler("NA");
                else uploadHandler(res.data);
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 413) {
                    uploadHandler("圖片大於10MB");
                    return;
                }
                if (err.response?.status === 500) {
                    uploadHandler(err.response?.data?.message);
                    return;
                }
                uploadHandler("NA");
            });
        return false;
    };

    useEffect(() => {
        if (!(categoryList && categoryList.length > 0)) fetchCategoryList();
        if (props.articleId) fetchArticleData(props.articleId);
    }, []);

    useEffect(() => {
        if (articleData !== initStateArticleData) {
            setFormChanged(true);
            invalidCategoryHandler();
            // window.addEventListener("beforeunload", onBeforeUnloadHandler);
        }
    }, [articleData]);

    return (
        <React.Fragment>
            <div className="articleWrapper">
                {!props.isEditing && (
                    <NewArticleDescriptionContainer
                        style={
                            device == "PC"
                                ? {}
                                : {
                                      width: "auto",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      marginBottom: "5px",
                                      borderRadius: "6px",
                                      height: "auto",
                                      fontSize: "14px",
                                      color: "#22ab39",
                                      fontWeight: "bold",
                                      backgroundColor: "#ffffff",
                                      padding: "12px",
                                  }
                        }
                    >
                        <div>{intl.formatMessage({ id: "newArticle.1.002" })}</div>
                        <div>{intl.formatMessage({ id: "newArticle.1.003" })}</div>
                        <div>{intl.formatMessage({ id: "newArticle.1.004" })}</div>
                    </NewArticleDescriptionContainer>
                )}
                {(!articleId || articleData.zh.data || (articleId && articleData.zh.data.length == 0)) && (
                    <ArticleContentEditContainer
                        title={articleData.zh.title}
                        content={articleData.zh.data ?? ""}
                        titleOnChange={(title) => {
                            dispatch({ type: ActionType.UPDATE_TITLE, value: title, lang: Language.Chinese });
                        }}
                        contentOnChange={(content) => {
                            dispatch({ type: ActionType.UPDATE_CONTENT, value: content, lang: Language.Chinese });
                        }}
                        handleRichTextEditorImageUpload={handleRichTextEditorImageUpload}
                        uploadImageUrl={`${
                            process.env.REACT_APP_API_DOMAIN_PREFIX
                        }${apiAddress.imageUploadVersion2_POST()}`}
                        imageUploadToken={`Bearer ${accessToken}`}
                        onImageUploadError={imageUploadErrorHandler}
                        onImageUploadBefore={imageUploadBeforeHandler}
                    />
                )}
                <ArticleCoverImageContainer
                    customId={"articleCoverPhotoInput"}
                    imageUrl={articleData.zh.coverImage}
                    imageOnChange={uploadImageHandler}
                    imageOnDelete={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        // Clears the real input value to enable re-upload for the same image
                        let inputDOM = document.getElementById("articleCoverPhotoInput") as HTMLInputElement;
                        inputDOM.value = "";
                        setRemoveCoverImageDialog(true);
                    }}
                    imageInvalid={setInvalidImageFormatPopup}
                />

                {categoryList && (
                    <ArticleCategoryListContainer
                        categoryList={categoryList}
                        selectedCategory={articleData.category}
                        categorySelectHandler={categorySelectHandler}
                    />
                )}

                {!props.isEditing ? (
                    <ArticlePublishButtonsContainer
                        disableButtons={shouldDisableButtons || !validCategory}
                        isSubmitting={isSubmittingTime}
                        previewOnClickHandler={() => {
                            toggleToWYSIWYG();
                            beforePreviewChecking();
                            setIsSubmittingTime(true);
                        }}
                        publishOnClickHandler={() => submitHandler()}
                        saveAsDraftOnClickHandler={() => saveAsDraftHandler()}
                        sceduledPublishHandler={() => {
                            setSceduledPublishDialog(true);
                            setIsSubmittingTime(true);
                        }}
                    />
                ) : (
                    <Grid container spacing={2} style={device == "PC" ? { marginTop: 15 } : { margin: "-8px 0" }}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={shouldDisableButtons || !validCategory || isSubmittingTime}
                                disableElevation
                                onClick={() => submitHandler()}
                                style={device == "PC" ? {} : { fontSize: "14px" }}
                            >
                                {!isSubmittingTime ? (
                                    "發表"
                                ) : (
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: BtnLoadingAnimation,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice",
                                            },
                                        }}
                                        width={40}
                                        height={29}
                                    />
                                )}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={shouldDisableButtons || !validCategory || isSubmittingTime}
                                disableElevation
                                onClick={() => {
                                    setSceduledPublishDialog(true);
                                    setIsSubmittingTime(true);
                                }}
                                style={device == "PC" ? {} : { fontSize: "14px" }}
                            >
                                {!isSubmittingTime ? (
                                    intl.formatMessage({ id: "newArticle.1.013" })
                                ) : (
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: BtnLoadingAnimation,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice",
                                            },
                                        }}
                                        width={40}
                                        height={29}
                                    />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                )}
                <ErrorDialog
                    openStatus={openValidateError}
                    toggleDialog={() => setOpenValidateError(false)}
                    confirmHandler={() => {
                        setOpenValidateError(false);
                    }}
                    title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                    content={
                        <div style={{ height: "82px" }}>
                            {intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}
                        </div>
                    }
                    confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
                />
                <PreviewDialog
                    qrCode={qrcode}
                    title={articleData.zh.title}
                    content={articleData.zh.data}
                    confirmBtnText={
                        !isPreviewSubmitting ? (
                            intl.formatMessage({ id: "newArticle.1.016" })
                        ) : (
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: BtnLoadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width={40}
                                height={29}
                            />
                        )
                    }
                    confirmHandler={() => {
                        setPreviewDialog(false);
                        setIsSubmittingTime(false);
                        submitHandler();
                    }}
                    cancelBtnText={intl.formatMessage({ id: "newArticle.1.015" })}
                    cancelHandler={() => {
                        setPreviewDialog(false);
                        setIsSubmittingTime(false);
                    }}
                    openStatus={previewDialog}
                    toggleDialog={() => {
                        setPreviewDialog(false);
                        setIsSubmittingTime(false);
                    }}
                />

                {/* Remove cover image dialog */}
                <ErrorDialog
                    openStatus={openValidateError}
                    toggleDialog={() => setOpenValidateError(false)}
                    confirmHandler={() => {
                        setOpenValidateError(false);
                    }}
                    title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                    content={
                        <div style={{ height: "82px" }}>
                            {intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}
                        </div>
                    }
                    confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
                />
                <ErrorDialog
                    title={intl.formatMessage({ id: "4.046" })}
                    content={intl.formatMessage({ id: "4.047" })}
                    confirmBtnText={intl.formatMessage({ id: "4.049" })}
                    confirmHandler={(e) => deleteImageHandler(e, articleData.zh.coverImage)}
                    cancelBtnText={intl.formatMessage({ id: "4.048" })}
                    cancelHandler={() => setRemoveCoverImageDialog(false)}
                    openStatus={removeCoverImageDialog}
                    toggleDialog={() => setRemoveCoverImageDialog(!removeCoverImageDialog)}
                />

                {/* Content contain external link dialog */}
                <ErrorDialog
                    title={intl.formatMessage({ id: "editor.allPosts.4.042" })}
                    content={intl.formatMessage({ id: "invalidContent" })}
                    confirmBtnText={intl.formatMessage({ id: "4.011" })}
                    confirmHandler={() => {
                        setInvalidContentDialog(!invalidContentDialog);
                        setIsSubmittingTime(false);
                        setIsScheduleSubmitting(false);
                        setIsPreviewSubmitting(false);
                    }}
                    openStatus={invalidContentDialog}
                    toggleDialog={() => {
                        setInvalidContentDialog(!invalidContentDialog);
                        setIsSubmittingTime(false);
                        setIsScheduleSubmitting(false);
                        setIsPreviewSubmitting(false);
                    }}
                />

                {/* Custom Error */}
                <ErrorDialog
                    title={customError.title}
                    content={customError.content}
                    confirmBtnText={customError.confirmBtnText}
                    confirmHandler={() => {
                        setShowCustomError(false);
                        setIsScheduleSubmitting(false);
                        setIsPreviewSubmitting(false);
                    }}
                    openStatus={showCustomError}
                    toggleDialog={() => {
                        setShowCustomError(!showCustomError);
                        setIsScheduleSubmitting(false);
                    }}
                />

                <BlockingAlert isBlocking={formChanged} />
                <PreviewDialog
                    schedulePost={scheduleProps}
                    openStatus={sceduledPublishDialog}
                    toggleDialog={() => {
                        setSceduledPublishDialog(!sceduledPublishDialog);
                        setIsSubmittingTime(false);
                    }}
                    title={articleData.zh.title}
                    confirmBtnText={
                        !isScheduleSubmitting ? (
                            intl.formatMessage({ id: "newArticle.1.022" })
                        ) : (
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: BtnLoadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width={40}
                                height={29}
                            />
                        )
                    }
                    cancelBtnText={intl.formatMessage({ id: "general.1.004" })}
                    content={articleData.zh.data}
                    confirmHandler={() => {
                        submitHandler();
                    }}
                    cancelHandler={() => {
                        setSceduledPublishDialog(false);
                        setIsSubmittingTime(false);
                    }}
                    rwdStatus={device}
                    disabled={isScheduleSubmitting ? true : false}
                >
                    <>
                        <Typography variant="h5" style={device == "PC" ? {} : { fontSize: "14px" }}>
                            {intl.formatMessage({ id: "newArticle.1.018" })}
                        </Typography>
                        <ThemeProvider theme={device == "PC" ? pickerTheme : pickerThemeMobile}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    spacing={0}
                                    style={device == "PC" ? { marginTop: 16 } : { marginTop: "10px" }}
                                >
                                    <Grid item>
                                        <Typography
                                            variant="subtitle1"
                                            style={device == "PC" ? {} : { fontSize: "14px", width: "35px" }}
                                        >
                                            {intl.formatMessage({ id: "newArticle.1.019" })}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={device == "PC" ? {} : { width: "calc(100% - 35px)" }}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="none"
                                            id="date-picker-inline"
                                            value={scheduleProps.date}
                                            onChange={(date) => {
                                                date?.setHours(0, 0, 0, 0);
                                                handleDateChange(date, "date");
                                            }}
                                            KeyboardButtonProps={{
                                                disableRipple: true,
                                                disableFocusRipple: true,
                                                disableTouchRipple: true,
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={<CalendarIc />}
                                            InputAdornmentProps={{
                                                position: "start",
                                            }}
                                            disablePast
                                            allowKeyboardControl={false}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            helperText={null}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="subtitle1"
                                            style={device == "PC" ? {} : { fontSize: "14px", width: "35px" }}
                                        >
                                            {intl.formatMessage({ id: "newArticle.1.020" })}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={device == "PC" ? {} : { width: "calc(100% - 35px)" }}>
                                        <KeyboardTimePicker
                                            margin="none"
                                            variant="inline"
                                            id="time-picker"
                                            value={scheduleProps.time}
                                            onChange={(date) => {
                                                handleDateChange(date, "time");
                                            }}
                                            KeyboardButtonProps={{
                                                "aria-label": "change time",
                                            }}
                                            keyboardIcon={<ClockIc />}
                                            InputAdornmentProps={{
                                                position: "start",
                                            }}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            helperText={null}
                                            ampm={false}
                                        />
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </>
                </PreviewDialog>
            </div>
        </React.Fragment>
    );
}

const BlockingAlert = ({ isBlocking }) => {
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isBlocking);
    const { formatMessage } = useIntl();

    return (
        <ErrorDialog
            title={formatMessage({ id: "editor.allPosts.4.042" })}
            content={formatMessage({ id: "4.043" })}
            confirmBtnText={formatMessage({ id: "editor.allPosts.4.044" })}
            cancelBtnText={formatMessage({ id: "4.048" })}
            confirmHandler={confirmNavigation as () => void}
            cancelHandler={cancelNavigation as () => void}
            openStatus={showPrompt as boolean}
            toggleDialog={cancelNavigation as () => void}
        />
    );
};

const pickerTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#499c56",
            },
        },
        MuiInputLabel: {
            root: {
                color: "#222",
                fontSize: "18px",
                fontWeight: "bold",
                "&$focused": {
                    color: "inherit",
                },
            },
        },
        MuiInputBase: {
            root: {
                borderRadius: 6,
                border: "solid 1px #999",
                backgroundColor: "#fff",
            },
        },
    },
});
const pickerThemeMobile = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#499c56",
            },
        },
        MuiInputLabel: {
            root: {
                color: "#222222",
                fontSize: "12px",
                textAlign: "left",
                fontWeight: "bold",
                "&$focused": {
                    color: "inherit",
                },
            },
        },
        MuiInputBase: {
            root: {
                borderRadius: 6,
                margin: " 0 0 0 12px",
                border: "solid 1px #999",
                backgroundColor: "#ffffff",
            },
        },
    },
});

export default UpdateArticle;
