import React, { useContext } from "react";
import { useIntl } from "react-intl";
import UpdateArticle from "../components/UpdateArticle";
import { RwdContext } from "../App";

function ArticlePage(props) {
    const intl = useIntl();
    const device = useContext(RwdContext);
    return (
        <React.Fragment>
            <div
                className={device == "PC" ? "articlePageWrapper pageWrapper" : ""}
                style={device == "PC" ? {} : { width: "100%", backgroundColor: "#eeeeee" }}
            >
                <div
                    className={device == "PC" ? "title" : ""}
                    style={
                        device == "PC"
                            ? {}
                            : {
                                  width: "80%",
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  color: "#222222",
                                  marginLeft: "10px",
                                  marginTop: "8px",
                                  marginBottom: "5px",
                              }
                    }
                >
                    {intl.formatMessage({ id: "newArticle.1.001" })}
                </div>
                <div
                    className={device == "PC" ? "pageContent" : ""}
                    style={
                        device == "PC"
                            ? {}
                            : { backgroundColor: "#eeeeee", overflow: "hidden", width: "auto", height: "auto" }
                    }
                >
                    <UpdateArticle
                        articleId={props.location?.state?.articleId}
                        articleStatus={props.location?.state?.articleStatus}
                        isEditing={false}
                        admin={false}
                        categoryList={props.location?.state?.categories}
                    ></UpdateArticle>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ArticlePage;
