import React from "react";
import QRCode, { QRCodeRenderersOptions } from "qrcode";

function useQRCode(option: QRCodeRenderersOptions) {
    const [url, setUrl] = React.useState<string | undefined>(undefined);
    const [qrcode, genQrCode] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (url) {
            QRCode.toDataURL(url, option)
                .then((url) => {
                    genQrCode(url);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [url]);

    return [qrcode, setUrl] as const;
}

export default useQRCode;
