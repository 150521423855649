import { createStyles, makeStyles, Theme, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { IsEmptyUserDetail } from "../App";
import ManageBanner from "../components/ManageBanner";
import { Redirect } from "react-router-dom";
import { ApiServiceContext, ChooseStoreStatusContext } from "../App";
import { SelectStoreButton } from "../components/buttons/SelectStoreButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContent: {
            marginTop: 18,
        },

        title: {
            fontWeight: "bold",
            fontSize: "20px",
        },
    })
);

function ManageBannerPage() {
    const classes = useStyles();
    const intl = useIntl();
    const [isEmptyUserDetail] = useContext(IsEmptyUserDetail);
    const [chooseStore, setChooseStoreStatus] = useContext(ChooseStoreStatusContext);
    return (
        <React.Fragment>
            {!chooseStore.shopCode ? <Redirect to="/store-list-page" /> : null}
            <div className="mangageBannerPageWrapper pageWrapper">
                <Grid container direction="row" justify="space-between">
                    <div className="title">{intl.formatMessage({ id: "5.001" })}</div>
                    <Grid item direction="row" alignItems="center">
                        <span className="title">Select Store: </span>
                        <SelectStoreButton />
                    </Grid>
                </Grid>
                <div className={`pageContent ${classes.pageContent}`}>
                    <ManageBanner isSis={true}></ManageBanner>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ManageBannerPage;
