import { styled } from "@material-ui/core/styles";
import { ContentContainerBox } from "./ContentContainer";

export const NewArticleDescriptionContainer = styled(ContentContainerBox)({
    color: "#22ab39",
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1.5,
});
export default NewArticleDescriptionContainer;
