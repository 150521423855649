import Compressor from "compressorjs";
import moment from "moment";

export class UtilFunctions {
    static validateApiResponse = (responseData) => {
        if (responseData && responseData.status === "success" && responseData.timestamp) {
            return true;
        }
        return false;
    };

    static validateApiResponseSuccess = (res) => {
        if (res && res.data && res.data.success) return true;
        return false;
    };

    static getResizedImageUrl = (width: number, height: number, url: string, crop?: boolean) => {
        let updatedUrl = "";
        if (url) {
            updatedUrl =
                url.slice(0, url.lastIndexOf(".")) +
                `_${width}_${height}${crop ? "_crop" : ""}` +
                url.slice(url.lastIndexOf("."));
        }
        return updatedUrl;
    };

    static getImageIdFromUrl = (url: string) => {
        let imageId = "";
        if (url) {
            imageId = url
                .slice(0, url.lastIndexOf("."))
                .slice(url.lastIndexOf("/") + 1)
                .split("_")[0];
        }
        return imageId;
    };

    static getUnixTimestamp = (dateTime: string, formatting: string) => {
        let unix_timestamp = moment(dateTime, formatting).format("x");
        return unix_timestamp;
    };

    static getDateFromTimestamp = (unix_timestamp: number) => {
        let momentObj = moment(unix_timestamp);
        return momentObj.format("DD/MM/YYYY");
    };

    static getShortFormDay = (_date: string) => {
        let dateName = moment(_date, "YYYY-MM-DD").format("DD MMM");
        return dateName;
    };

    static getShortFormDate = (_date: string) => {
        let dateName = moment(_date, "YYYY-MM").format("MMM YYYY");
        return dateName;
    };

    static getDateNameFromDate = (_date: string) => {
        let dateName = moment(_date, "YYYY-MM-DD").format("ddd");
        return dateName;
    };

    static getYearDropDownOptions = () => {
        let thisYear = moment().year();
        let momentObj = [
            {
                name: `${thisYear}年`,
                value: `${thisYear}`,
            },
            {
                name: `${thisYear + 1}年`,
                value: `${thisYear + 1}`,
            },
            {
                name: `${thisYear + 2}年`,
                value: `${thisYear + 2}`,
            },
        ];
        return momentObj;
    };

    static getInitialDateRange = () => {
        // from 2 months ago to 2 years later DEC
        let fromToDateRange: Array<string> = [];
        let nowDate = moment().format("YYYY-MM");
        let toDate = moment().year() + "-12";

        let from = moment(nowDate).subtract(2, "months").format("YYYY-MM");
        let to = moment(toDate).add(2, "years").format("YYYY-MM");

        fromToDateRange[0] = from;
        fromToDateRange[1] = to;

        // console.log("from " + fromToDateRange[0]);
        // console.log("to " + fromToDateRange[1]);

        return fromToDateRange;
    };

    static getThisYear = () => {
        let thisYear = moment().year();
        return thisYear;
    };

    static getAllTimeSlot_params = () => {
        let startYear = moment().year();
        let startMonth = moment().month() + 1;
        let obj;

        if (startMonth == 1) {
            startMonth = 12;
            startYear = startYear - 1;
        } else {
            startMonth = startMonth - 1;
        }

        obj = {
            startLocalDate: startYear + "-" + `${startMonth < 10 ? `0${startMonth}` : startMonth}` + "-01",
            endLocalDate: `${startYear + 2}-12-31`,
            showDisabled: false,
        };

        return obj;
    };

    static getCalendar = (yearAndMonth: string) => {
        let startWeek: any = moment(yearAndMonth).startOf("month").week();
        let endWeek: any = moment(yearAndMonth).endOf("month").week();

        let calendarObj: any = [];

        if (yearAndMonth.includes("-12")) {
            endWeek = 4 + startWeek;
        }

        for (let week = startWeek; week < endWeek + 1; week++) {
            calendarObj.push({
                timeSlot: {},
                date: Array(7)
                    .fill(0)
                    .map((n, i) =>
                        moment(yearAndMonth)
                            .week(week)
                            .startOf("week")
                            .clone()
                            .add(n + i, "day")
                            .format("YYYY-MM-DD")
                    ),
            });
        }

        // console.log("calendarObj");

        // console.log(calendarObj);

        return calendarObj;
    };

    static getEndWeek = (yearAndMonth: string) => {
        const startWeek = moment(yearAndMonth).startOf("month").week();
        let endWeek = moment(yearAndMonth).endOf("month").week();

        if (yearAndMonth.includes("-12")) {
            endWeek = 5 + startWeek;
        }

        return endWeek - startWeek;
    };

    static getStartEndDay = (_yearMonth: string) => {
        const startDay = moment(_yearMonth).startOf("month").format("DD");
        const endDay = moment(_yearMonth).endOf("month").format("DD");
        let startEndDay: Array<any> = [];
        startEndDay[0] = startDay;
        startEndDay[1] = endDay;

        return startEndDay;
    };

    static getMonthDropDownOptions = (_dropdown_year: string) => {
        let thisYear = moment().year();
        let thisMonth = moment().month();

        let momentObj: Array<{ name: string; value: string }> = [];
        let i = 0;
        if (`${thisYear}` == _dropdown_year || _dropdown_year == "placeholder") i = thisMonth;
        for (i; i < 12; i++) {
            let j = i + 1;
            momentObj.push({
                name: j + "月",
                value: `${j < 10 ? `0${j}` : j}`,
            });
        }

        return momentObj;
    };

    static getFormattedTime = (time: string) => {
        let _time = moment(time, "HH:mm:ss").format("HH:mm");
        return _time;
    };

    static getFormattedDate = (date: string) => {
        let _date = moment(date, "YYYY-MM-DD").format("DD");
        return _date;
    };

    static trimYearMonth = (date: string) => {
        let _date = moment(date, "YYYY-MM-DD").format("YYYY-MM");
        return _date;
    };

    static getFormattedCreateTime = (unix_timestamp: string) => {
        let momentObj = moment(unix_timestamp);
        return momentObj.format("DD-MM-YYYY HH:mm");
    };

    static getDateTimeFromTimestamp = (unix_timestamp: number) => {
        let momentObj = moment(unix_timestamp);
        return momentObj.format("DD/MM/YYYY HH:mm");
    };

    static getUnixFromDate = (_dateAndTime: string) => {
        let unix = moment(_dateAndTime, "YYYY-MM-DD HH:mm:ss").unix();
        return unix;
    };

    static getUnixFromShortFormDate = (_yearMonth: string) => {
        let unix = moment(_yearMonth, "YYYY-MM").unix();
        return unix;
    };

    static isTheDateWithinThisMonth = (_thisMonth: string, _thisDay: string) => {
        const startDay = moment(_thisMonth).startOf("month").format("DD");
        const endDay = moment(_thisMonth).endOf("month").format("DD");

        let startDate = moment(_thisMonth + "-" + startDay, "YYYY-MM-DD").unix();
        let endDate = moment(_thisMonth + "-" + endDay, "YYYY-MM-DD").unix();

        let thisDate = moment(_thisDay, "YYYY-MM-DD").unix();

        if (thisDate >= startDate && thisDate <= endDate) {
            return true;
        } else {
            return false;
        }
    };

    static dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    };

    static validateContent = (textToCheck) => {
        let valid = true;
        let imageDomains = process.env.REACT_APP_IMAGE_SERVER_DOMAIN
            ? process.env.REACT_APP_IMAGE_SERVER_DOMAIN.split(",")
            : [];
        const scriptTagRegex = new RegExp("<script/?>");
        const whitelistedDomain = [
            "www.hktvmall.com",
            "www.youtube.com",
            "youtu.be",
            "promo.hktvmall.com",
            "cloud.marketing.hktvmall.com",
            "ecomtest01.hktv.com.hk",
            "www.hkmpcl.com.hk",
            ...imageDomains,
            process.env.REACT_APP_CDN_DOMAIN,
        ];

        //var expression = /(https?:\/\/)?[a-zA-Z_\-~]+(\.[a-zA-Z_\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;
        var expression =
            /\b((?:https?:\/\/|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/gm;
        var regex = new RegExp(expression);
        var match;
        var splitText: { text: string; type: string }[] = [];
        var startIndex = 0;
        while ((match = regex.exec(textToCheck)) != null) {
            splitText.push({ text: textToCheck.substr(startIndex, match.index - startIndex), type: "text" });

            var cleanedLink = textToCheck.substr(match.index, match[0].length);
            cleanedLink = cleanedLink.replace(/^https?:\/\//, "");

            splitText.push({ text: cleanedLink, type: "link" });

            startIndex = match.index + match[0].length;
        }
        if (startIndex < textToCheck.length) splitText.push({ text: textToCheck.substr(startIndex), type: "text" });

        splitText
            .filter((textObj) => {
                return textObj.type === "link";
            })
            .forEach((filteredTextObj) => {
                if (
                    !whitelistedDomain.some((domain) => {
                        if (domain) {
                            return filteredTextObj.text.includes(domain.replace(/^https?:\/\//, ""));
                        }
                        return false;
                    })
                ) {
                    valid = false;
                }
            });
        if (scriptTagRegex.exec(textToCheck) !== null) valid = false;

        return valid;
    };

    // Promise-based Compressor
    static compressImage = (file: File | Blob, options?: Compressor.Options | undefined) =>
        new Promise<Blob>((resolve, reject) => {
            new Compressor(file, {
                ...options,
                success(compressedImage) {
                    resolve(compressedImage);
                },
                error(err) {
                    reject(err);
                },
            });
        });

    static svgToBlob = (rawSVG, callback?) => {
        var c = document.createElement("canvas");
        var ctx = c.getContext("2d");

        var svg = new Blob([rawSVG], { type: "image/svg+xml;charset=utf-8" }),
            domURL = window.self.URL || window.self.webkitURL || window.self,
            url = domURL.createObjectURL(svg),
            img = new Image();

        img.onload = function () {
            if (ctx) {
                ctx.drawImage(img, 0, 0);
                domURL.revokeObjectURL(url);
                if (callback) callback(img);
            }
        };
        return url;
    };

    static isValidImageExt = (fileName: string): boolean => {
        fileName = fileName.toLowerCase();
        return fileName.endsWith(".png") || fileName.endsWith(".jpg") || fileName.endsWith(".jpeg");
    };

    static delayInMs = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    static copyToClipboard = (stringToCopy: string) => {
        var textField = document.createElement("textarea");
        textField.innerText = stringToCopy;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
    };
}
