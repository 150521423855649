import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
export const AppTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#22ab39",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#ff5555",
            contrastText: "#FFFFFF",
        },
        error: {
            main: "#ff5555",
            contrastText: "#FFFFFF",
        },
        text: {
            primary: "#222222",
        },
    },
    typography: {
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Noto Sans"',
            "微軟正黑體",
            "蘋果儷中黑",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        fontWeightRegular: "bold",
    },
    overrides: {
        MuiInputBase: {
            root: {
                "&$error": {
                    borderColor: "#ff0000",
                },
            },
        },
        MuiButton: {
            root: {
                minHeight: "45px",
                minWidth: "64px",
                padding: "8px 12px",
                fontSize: "20px",
                lineHeight: "20px",
                background: "#ffffff",
            },
        },
        MuiIconButton: {
            root: {
                padding: 0,
            },
        },
    },
});

export const useButtonStyle = makeStyles({
    // style rule
    clickableButton: {
        cursor: "pointer",
        borderRadius: "3px",
        backgroundColor: "#bde5c3",
    },
});
