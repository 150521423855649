import React, { useContext, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { UploadContainer } from "./UploadContainer";
import Box from "@material-ui/core/Box";
import { useIntl } from "react-intl";
import { ReactComponent as ExpandMoreBtn } from "../../assets/editor/icon_icExpandMore.svg";
import { ReactComponent as AddImageBtn } from "../../assets/editor/ic_addImage.svg";
import { ReactComponent as SuccessIcon } from "../../assets/editor/ic_success.svg";
import { ReactComponent as DeleteIcon } from "../../assets/editor/ic_deleteGreen.svg";
import { ReactComponent as CloseIcon } from "../../assets/store/ic_close.svg";
import { RemoveAlertDialog } from "../dialogs/RemoveAlertDialog";
import { ApiServiceContext } from "../../App";
import { ModuleErrorDialog } from "../dialogs/ModuleDialog";
import { StoreBannerComponentType } from "../../interfaces/enums";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            flexFlow: "row",
            paddingTop: "0px",
            position: "relative",
            boxShadow: "0 2px 4px 0 rgb(0 0 0 / 12%)",
            "& .bannerHeader": {
                margin: 0,
                padding: "12px 12px 5.5px 12px",
                flexBasis: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#FFFFFF",
                alignItems: "center",
                fontSize: "16px",
                color: "rgba(34, 34, 34, 0.6)",
                fontWeight: "bold",
                "& .containerTitle": {
                    fontSize: "36px",
                    fontWeight: "bold",
                },
                "& a": {
                    textDecoration: "none",
                },
                "& .uploadBtn": {
                    fontSize: "16px",
                    backgroundColor: "transparent",
                    textTransform: "none",
                    color: "green",

                    "& svg": {
                        display: "inline-block",
                        verticalAlign: "middle",
                        color: "green",
                    },
                },
            },
        },
        photo: {
            height: "121px",
            width: "297px",
        },
        uploadbtn: {
            background: "transparent",
            color: "green",
            boxShadow: "none",
            textTransform: "none",
        },
        success: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "80px",
            paddingBottom: "80px",
            width: "100%",
            fontSize: "16px",
            color: " rgba(34, 34, 34, 0.6)",
        },
        imageContainer: {
            position: "relative",
            width: "50%",
        },
        buttonoverimage: {
            left: "50%",
            top: "50%",
            marginLeft: "-50px",
            marginTop: "-50px",
            zIndex: 10,
        },
        bannerPreview: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "left",
            paddingTop: "25px",
            paddingBottom: "25px",
            paddingLeft: "10px",
            fontSize: "40px",
        },
        bannerDisplay: {
            display: "flex",
            flexWrap: "wrap",
        },
    })
);

const useDialogStyle = makeStyles((theme) =>
    createStyles({
        root: {},
        title: {
            fontSize: "32px",
            fontWeight: "bold",
        },
        buttonText: {
            fontSize: "24px",
            fontWeight: "bold",
        },
        paper: {
            backgroundColor: "#f5f5f5",
            width: "2000",
            flexDirection: "column",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dialogTitle: {
            backgroundColor: "white",
            fontSize: "16px",
            color: "rgba(34, 34, 34, 0.6)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 16px",
            fontWeight: "bold",
        },
        errorBox: {
            padding: "0px 30px",
            backgroundColor: "#f5f5f5",
        },
    })
);

const useAndCreateStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
            width: "50%",
            "&:hover": {
                "& .middle": { opacity: 1 },
                "& .img": {
                    filter: "brightness(50%)",
                },
            },

            "& .btn ": {
                position: "absolute",
                top: "50%",
                transform: "translate(150%, -50%)",
                msTransform: "translate(-50%, -50%)",
                fontSize: "14px",
                border: "none",
                borderRadius: "100px",
                backgroundColor: "white",
                color: "#3a993a",
                minHeight: "30px",
                minWidth: "20px",
                padding: "6px 6px",
            },
            "& .img": {
                width: "297px",
                height: "auto",
                display: "block",
                transition: ".5s ease",
                marginRight: "7px",
                marginBottom: "7px",
            },
            "& .middle": {
                transition: ".5s ease",
                opacity: "0",
                position: "absolute",
                backgroundColor: "black",
                transform: "translate(150%, -50%)",
                msTransform: "translate(-50%, -50%)",
                textAlign: "center",
                top: "50%",
                left: "50%",
            },
        },
    })
);

export interface BannerUploadContainerProps {
    bannerid: string;
    uploaded_img_list: any[];
    imgSize: any[];
    handleChange: () => void;
    storeId: string;
    typesList?: string[];
}

export default function BannerUploadContainer(props: BannerUploadContainerProps) {
    const { bannerid, uploaded_img_list, imgSize, handleChange, storeId } = props;
    const apiService = useContext(ApiServiceContext);
    const [open, setOpen] = React.useState(false);
    const [expand, setExpand] = React.useState(true);

    const [openDialogId, setopenDialogId] = React.useState("");
    const intl = useIntl();
    const handleClickOpen = (bannerid) => {
        setOpen(true);
        setopenDialogId(bannerid);
    };
    const handleClickExpand = () => {
        if (expand) setExpand(false);
        else setExpand(true);
    };

    const handleClose = () => {
        setopenDialogId("");
        setOpen(false);
    };

    const switchbuttonDisplay = (bannerid: string) => {
        switch (bannerid) {
            case "1":
                return <>{intl.formatMessage({ id: "5.002" })}</>;
            case "2":
                return <>{intl.formatMessage({ id: "5.004" })}</>;
            case "3":
                return <>{intl.formatMessage({ id: "5.005" })}</>;
            case "4":
                return <>{intl.formatMessage({ id: "5.006" })}</>;
            case "5-6":
                return <>{intl.formatMessage({ id: "5.032" }, { lineBreak: <br /> })}</>;
            case "7-8":
                return <>{intl.formatMessage({ id: "5.034" }, { lineBreak: <br /> })}</>;
        }
    };

    const classes = useStyles();

    return (
        <div>
            <Box boxShadow={2} className={classes.root}>
                <div className="bannerHeader">
                    {switchbuttonDisplay(bannerid)}
                    <div className="uploadBtn" id={bannerid}>
                        <Button className="uploadBtn" onClick={(e) => handleClickOpen(bannerid)}>
                            <AddImageBtn />
                            <div style={{ marginLeft: "3px" }}>{intl.formatMessage({ id: "5.003" })}</div>
                        </Button>
                        <IconButton onClick={handleClickExpand}>
                            <ExpandMoreBtn />
                        </IconButton>
                    </div>
                </div>
                <BannerUploadDialog
                    storeId={storeId}
                    imgSize={imgSize}
                    bannerid={openDialogId}
                    newDialog={false}
                    open={open}
                    onClose={handleClose}
                    imglist={uploaded_img_list}
                    handleChange={props.handleChange}
                />
            </Box>
            <BannerPreview
                handleChange={props.handleChange}
                expand={expand}
                bannerid={bannerid}
                imageList={uploaded_img_list}
                storeId={storeId}
                imageSize={imgSize}
            />
            <br />
        </div>
    );
}

export interface BannerPreviewProps {
    expand?: boolean;
    imageList: any[];
    imageSize: any[];
    bannerid: string;
    RemoveBtn?: boolean;
    removedialog?: boolean;
    handleChange: () => void;
    storeId: string;
}

function BannerPreview(props: BannerPreviewProps) {
    const classes = useStyles();
    const classname = useAndCreateStyles();
    const { expand, imageList, imageSize, bannerid, handleChange, storeId } = props;
    const img = document.createElement("img");
    const [display, setDisplay] = React.useState(false);
    const [btnindex, setBtnIndex] = React.useState(bannerid);
    const [whichImageList, setWhichImageList] = React.useState("");
    const intl = useIntl();
    const [removedialog, setRemoveDialog] = React.useState(false);

    const clickRemove = (id: string) => {
        setRemoveDialog(true);
        setBtnIndex(id);
    };

    const handleonMouseEnter = (e) => {
        if (e.target.id) setBtnIndex(e.target.id);
        setDisplay(true);
    };
    const handleonMouseLeave = (e) => {
        setDisplay(false);
    };

    const handleCloseDialog = () => {
        setBtnIndex(bannerid);
        setRemoveDialog(false);
    };
    const switchWidth = (bannerid, width: number) => {
        if (bannerid === "1") return 297;
        if (bannerid === "2") return 148;
        if (bannerid === "3") return 99;
        if (bannerid === "4") return 74;
        else return width / 4;
    };
    const switchLeft = (bannerid) => {
        if (bannerid === "1") return "50%";
        if (bannerid === "2") return "25%";
        if (bannerid === "3") return "17%";
        if (bannerid === "4") return "12%";
        else return "5%";
    };
    const switchHeight = (bannerid, height: number) => {
        if (bannerid === "1") return "auto";
        if (bannerid === "2" || bannerid === "3" || bannerid === "4") return "106px";
        else return height / 4;
    };

    const switchTransform = (bannerid) => {
        if (bannerid === "1") return "translate(180%, -50%)";
        if (bannerid === "2") return "translate(68%, -50%)";
        if (bannerid === "3") return "translate(30%, -50%)";
        if (bannerid === "4") return "translate(8%, -50%)";
        else return "translate(45%, -50%)";
    };

    return (
        <div>
            {expand ? (
                <Box boxShadow={2} className={classes.bannerPreview} bgcolor="white">
                    <div style={{ fontSize: "16px", fontWeight: 700, color: "grey" }}>
                        {bannerid === "5-6" && intl.formatMessage({ id: "5.036" })}
                        {bannerid === "7-8" && intl.formatMessage({ id: "5.038" })}
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap", paddingRight: "10px" }}>
                        {imageList[0]?.length ? (
                            imageList[0].map((i, val) => (
                                <Box key={val}>
                                    <div
                                        id={i.id}
                                        className={classname.root}
                                        onMouseEnter={(e) => handleonMouseEnter(e)}
                                        onMouseLeave={(e) => handleonMouseLeave(e)}
                                    >
                                        <img
                                            id={i.id}
                                            src={i.url}
                                            className="img"
                                            style={{
                                                height: switchHeight(bannerid, imageSize[0].height),
                                                width: switchWidth(bannerid, imageSize[0].width),
                                            }}
                                        ></img>
                                        <div className="middle">
                                            {
                                                <Button
                                                    className="btn"
                                                    style={{
                                                        left: switchLeft(bannerid),
                                                        transform: switchTransform(bannerid),
                                                    }}
                                                    id={i.id}
                                                    onClick={() => {
                                                        clickRemove(i.id);
                                                        setWhichImageList("1");
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </Box>
                            ))
                        ) : (
                            <Box className={classes.success}>{intl.formatMessage({ id: "5.029" })}</Box>
                        )}
                    </div>
                    {imageList.length > 1 && (
                        <>
                            <div style={{ fontSize: "16px", fontWeight: 700, color: "grey" }}>
                                {bannerid === "5-6" && intl.formatMessage({ id: "5.037" })}
                                {bannerid === "7-8" && intl.formatMessage({ id: "5.039" })}
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", paddingRight: "10px" }}>
                                {imageList[1]?.length ? (
                                    imageList[1].map((i, val) => (
                                        <Box key={val}>
                                            <div
                                                id={i.id}
                                                className={classname.root}
                                                onMouseEnter={(e) => handleonMouseEnter(e)}
                                                onMouseLeave={(e) => handleonMouseLeave(e)}
                                            >
                                                <img
                                                    id={i.id}
                                                    src={i.url}
                                                    className="img"
                                                    style={{
                                                        height: switchHeight(bannerid, imageSize[1].height),
                                                        width: switchWidth(bannerid, imageSize[1].width),
                                                    }}
                                                ></img>
                                                <div className="middle">
                                                    {
                                                        <Button
                                                            className="btn"
                                                            style={{
                                                                left: switchLeft(bannerid),
                                                                transform: switchTransform(bannerid),
                                                            }}
                                                            id={i.id}
                                                            onClick={() => {
                                                                clickRemove(i.id);
                                                                setWhichImageList("2");
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        </Box>
                                    ))
                                ) : (
                                    <Box className={classes.success}>{intl.formatMessage({ id: "5.029" })}</Box>
                                )}
                            </div>
                        </>
                    )}
                    <RemoveAlertDialog
                        isOver50images={false}
                        isOver5mb={false}
                        handleChange={props.handleChange}
                        imageID={btnindex}
                        whichImageList={whichImageList}
                        bannerid={bannerid}
                        open={removedialog}
                        onClose={handleCloseDialog}
                        storeId={storeId}
                        isImageFormatError={false}
                        isImageSizeDimensionError={false}
                    />
                </Box>
            ) : (
                <></>
            )}
        </div>
    );
}

function SuccessDialog() {
    const intl = useIntl();

    const classes = useStyles();
    return (
        <div>
            <Box className={classes.success}>
                <div>
                    <SuccessIcon />
                </div>
                <div style={{ color: "#00a759", fontSize: "32px", fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "5.015" })}
                </div>
            </Box>
        </div>
    );
}
export interface BannerUploadDialogProps {
    open: boolean;
    onClose: () => void;
    newDialog: boolean | false;
    imglist: string[];
    imgSize: any[];
    bannerid: string;
    handleChange: () => void;
    storeId: string;
}
function BannerUploadDialog(props: BannerUploadDialogProps) {
    const classes = useDialogStyle();
    const { onClose, open, bannerid, imglist, imgSize, handleChange, storeId } = props;
    const [successUpload, setsuccessUpload] = React.useState(false);
    const intl = useIntl();
    const [exceed5mb, setexceed5mb] = React.useState(false);
    const [exceed50images, setexceed50images] = React.useState(false);
    const [errorFileList, seterrorFileList] = React.useState([""]);
    const [dimensionerrorFileList, setDimensionerrorFileList] = React.useState([""]);
    const [formaterrorFileList, setFormaterrorFileList] = React.useState([""]);

    const [dimensionError, setDimensionError] = React.useState(false);
    const [imageFormatError, setImageFormatError] = React.useState(false);
    const [exitUpload, setExitUpload] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [uploadPaused, setUploadPaused] = React.useState(false);

    const handleSuccessUploadDialog = (childData) => {
        setsuccessUpload(childData);
    };
    const handleClose = () => {
        onClose();
        setsuccessUpload(false);
        setUploadPaused(false);
    };

    useEffect(() => {
        setIsUploading(false);
        setUploadPaused(false);
    }, []);

    useEffect(() => {
        DisableUpload();
    }, [isUploading]);
    const handleCloseUploadDialog = () => {
        if (isUploading) {
            setUploadPaused(true);
        } else {
            onClose();
        }
        setsuccessUpload(false);
        setExitUpload(false);
    };

    const handleCloseFor50img = () => {
        onClose();
        setexceed50images(false);
    };

    const handleCloseForDimension = () => {
        onClose();
        setDimensionError(false);
    };
    const handleCloseForFormat = () => {
        onClose();
        setImageFormatError(false);
    };

    const handleCloseFor5mb = () => {
        onClose();
        setexceed5mb(false);
    };

    const handleover5mb = (list) => {
        let errorFile = [""];
        for (let i = 0; i < list.length; i++) {
            if (i === 0 && list.length !== 1) errorFile = [list[i], <br />];
            else if (i === 0 && list.length === 1) errorFile = [list[i]];
            else if (i === list.length - 1) errorFile = [errorFile, list[i]];
            else errorFile = [errorFile, list[i], <br />];
        }
        seterrorFileList(errorFile);
        setexceed5mb(true);
    };

    const handleDimensionError = (list) => {
        let errorFile = [""];
        for (let i = 0; i < list.length; i++) {
            if (i === 0 && list.length !== 1) errorFile = [list[i], <br />];
            else if (i === 0 && list.length === 1) errorFile = [list[i]];
            else if (i === list.length - 1) errorFile = [errorFile, list[i]];
            else errorFile = [errorFile, list[i], <br />];
        }
        setDimensionerrorFileList(errorFile);
        setDimensionError(true);
    };

    const handleFormatError = (list) => {
        let errorFile = [""];
        for (let i = 0; i < list.length; i++) {
            if (i === 0 && list.length !== 1) errorFile = [list[i], <br />];
            else if (i === 0 && list.length === 1) errorFile = [list[i]];
            else if (i === list.length - 1) errorFile = [errorFile, list[i]];
            else errorFile = [errorFile, list[i], <br />];
        }
        setFormaterrorFileList(errorFile);
        setImageFormatError(true);
    };
    const handleover50limit = () => {
        setexceed50images(true);
    };
    function DisableUpload() {
        let initialValue = 0;
        let totalLength = imglist.reduce(
            (previousValue, currentValue) => previousValue + currentValue.length,
            initialValue
        );
        if (totalLength >= 100 || isUploading) return true;
        else return false;
    }

    const checkUpload = (status) => {
        if (status) {
            setIsUploading(true);
        } else {
            setIsUploading(false);
        }
    };

    const closeUploadPaused = () => {
        setUploadPaused(false);
        setIsUploading(false);
    };

    return (
        <div>
            <>
                <>
                    <Dialog onClose={handleClose} open={successUpload && open} fullWidth={true} maxWidth={"md"}>
                        <SuccessDialog></SuccessDialog>
                    </Dialog>
                </>
                <Dialog
                    onClose={handleCloseUploadDialog}
                    open={
                        !successUpload && open && !exceed5mb && !exceed50images && !dimensionError && !imageFormatError
                    }
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <>
                        <DialogTitle disableTypography className={classes.dialogTitle}>
                            <Box display="contents" alignItems="centre" justifyContent="space-between">
                                <h2 style={{ fontSize: "16px" }}>
                                    <span>{intl.formatMessage({ id: "5.003" })}</span>
                                </h2>
                                <IconButton onClick={handleCloseUploadDialog}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </DialogTitle>

                        <DialogContent className={classes.paper}>
                            <UploadContainer
                                isUploading={checkUpload}
                                exitUploading={exitUpload}
                                disable={DisableUpload()}
                                storeId={storeId}
                                library={imglist}
                                maxSize={5}
                                handleChange={props.handleChange}
                                imgSize={imgSize}
                                Bannerid={bannerid}
                                newDialogHandler={handleSuccessUploadDialog}
                                getUploadStatus={successUpload}
                                over5mb={handleover5mb}
                                dimensionError={handleDimensionError}
                                formatError={handleFormatError}
                                over50images={handleover50limit}
                            ></UploadContainer>
                        </DialogContent>
                    </>
                </Dialog>

                <ModuleErrorDialog
                    confirmText={intl.formatMessage({ id: "5.025" })}
                    onConfirm={() => setExitUpload(true)}
                    cancelText={intl.formatMessage({ id: "5.022" })}
                    confirmClose={() => {
                        setUploadPaused(false);
                        onClose();
                    }}
                    open={uploadPaused}
                    children={<div>{intl.formatMessage({ id: "5.031" })}</div>}
                    onClose={closeUploadPaused}
                    title={intl.formatMessage({ id: "5.020" })}
                />
                <RemoveAlertDialog
                    isOver50images={true}
                    isOver5mb={false}
                    handleChange={props.handleChange}
                    imageID={""}
                    bannerid={""}
                    open={exceed50images}
                    onClose={handleCloseFor50img}
                    storeId={storeId}
                    isImageFormatError={false}
                    isImageSizeDimensionError={false}
                />
            </>
            <>
                <RemoveAlertDialog
                    isOver50images={false}
                    isOver5mb={false}
                    fileName={formaterrorFileList}
                    handleChange={props.handleChange}
                    imageID={""}
                    bannerid={""}
                    open={imageFormatError}
                    onClose={handleCloseForFormat}
                    storeId={storeId}
                    isImageFormatError={true}
                    isImageSizeDimensionError={false}
                />

                <RemoveAlertDialog
                    isOver50images={false}
                    isOver5mb={true}
                    fileName={errorFileList}
                    handleChange={props.handleChange}
                    imageID={""}
                    bannerid={""}
                    open={exceed5mb && !imageFormatError}
                    onClose={handleCloseFor5mb}
                    storeId={storeId}
                    isImageFormatError={false}
                    isImageSizeDimensionError={false}
                />
                <RemoveAlertDialog
                    isOver50images={false}
                    isOver5mb={true}
                    fileName={errorFileList}
                    handleChange={props.handleChange}
                    imageID={""}
                    bannerid={""}
                    open={exceed5mb && !imageFormatError}
                    onClose={handleCloseFor5mb}
                    storeId={storeId}
                    isImageFormatError={false}
                    isImageSizeDimensionError={false}
                />

                <RemoveAlertDialog
                    isOver50images={false}
                    isOver5mb={false}
                    fileName={dimensionerrorFileList}
                    handleChange={props.handleChange}
                    imageID={""}
                    bannerid={""}
                    open={dimensionError && !exceed5mb && !imageFormatError}
                    onClose={handleCloseForDimension}
                    storeId={storeId}
                    isImageFormatError={false}
                    isImageSizeDimensionError={true}
                />
            </>
        </div>
    );
}
