import { FormControl, InputLabel, Select } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { StoreInfoContext, ChooseStoreStatusContext } from "../../App";
import React, { useContext } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectStoreButtonWrapper: {
            position: "relative",
            display: "inline-flex",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectStyle: {
            lineHeight: "1.5rem",
            height: "1.5rem",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "400px",
        },
    })
);

export function SelectStoreButton() {
    const classes = useStyles();
    const storesInfo = useContext(StoreInfoContext);
    let stores = storesInfo?.storesDetails;
    const [chooseStoreStatus, setChooseStoreStatus] = useContext(ChooseStoreStatusContext);

    let handleClickForItem = (code: string) => {
        if (typeof code !== "undefined") {
            setChooseStoreStatus({
                status: true,
                shopCode: code,
            });
        }
    };

    return (
        <div className={classes.selectStoreButtonWrapper}>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-code-native-simple">Store</InputLabel>
                <Select
                    native
                    onChange={(e) => handleClickForItem(e.target.value as string)}
                    label="Store"
                    classes={{
                        root: classes.selectStyle,
                    }}
                >
                    {stores.map(
                        (store) =>
                            typeof store?.code !== "undefined" && (
                                <option value={store?.code} selected={store?.code === chooseStoreStatus.shopCode}>
                                    {store?.code + " " + store?.zh?.name}
                                </option>
                            )
                    )}
                </Select>
            </FormControl>
        </div>
    );
}
