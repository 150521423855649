import {
    Button,
    createStyles,
    Grid,
    makeStyles,
    Popover,
    styled,
    Theme,
    Typography,
    OutlinedInput,
} from "@material-ui/core";
import Lottie from "react-lottie";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ApiServiceContext, UnknowErrorDialogContext, RwdContext } from "../App";
import { UtilFunctions } from "../utils/UtilFunctions";
import SelectInputWithLabel from "../components/textfield/SelectInputWithLabel";
import StatusPopup from "../components/dialogs/StatusPopup";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import { ConfirmSaveDataDialog } from "../components/dialogs/ConfirmSaveDataDialog";
import _, { forEach } from "lodash";
import { useCallbackPrompt } from "../hooks/PromptHook";
import { ReactComponent as ToIc } from "../assets/admin/ic/rightArrow.svg";
import { ReactComponent as PreIc } from "../assets/admin/ic/leftArrow.svg";
import { ReactComponent as CloseIcon } from "../assets/admin/ic/closeSmall.svg";
import { ReactComponent as BackIcon } from "../assets/editor/icback.svg";
import { ReactComponent as CopyIcon } from "../assets/admin/ic/copy.svg";
import { CSVLink } from "react-csv";
import { ReactComponent as AddArticle } from "../assets/editor/icAddCircleOutline.svg";
import { subWeeks } from "date-fns";
import BtnLoadingAnimation from "../assets/animations/btn_loading_animation.json";

type RejectTimeSlotStatus = {
    _id: string | undefined;
    openStatus: boolean;
};

const rejectDialogInitState: RejectTimeSlotStatus = {
    _id: undefined,
    openStatus: false,
};

type CancelTimeSlotStatus = {
    _id: string | undefined;
    openStatus: boolean;
};

const cancelDialogInitState: CancelTimeSlotStatus = {
    _id: undefined,
    openStatus: false,
};
type EditSku = {
    id: string | undefined;
    sku_id: string | undefined;
    editStatus: boolean;
};
type EditSkuStatus = {
    id: string;
    status: boolean;
};
const EditSkuStatusInit: EditSkuStatus = {
    id: "",
    status: false,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        AllTimeSlotsCalendarPage: {
            width: "100%",
            height: "auto",
            padding: "11px 10px",
            backgroundColor: "#eeeeee",
        },
        mobileTitle: {
            fontSize: "22px",
            fontWeight: "bold",
        },
        FiltersContainer: {
            width: "100%",
            height: "auto",
            backgroundColor: "#fff",
            borderRadius: 6,
            marginTop: 11,
        },
        FiltersItems: {
            marginLeft: 12,
            marginTop: 10,
            "@media (max-width:1280px)": {
                width: "95%",
                fontSize: "14px",
                marginLeft: 12,
                marginTop: 10,
            },
        },
        mobileFiltersItems: {
            width: "95%",
            fontSize: "14px",
            marginLeft: 12,
            marginTop: 10,
        },
        FiltersButton: {
            marginLeft: 12,
            marginTop: 28,
            marginRight: 12,
            "@media (max-width : 1280px)": {
                marginLeft: 6,
                marginTop: 16,
                marginRight: 6,
            },
        },
        ApprovalContainer: {
            width: "100%",
            height: "auto",
            backgroundColor: "#fff",
            borderRadius: 6,
            marginTop: 11,
            padding: "0px 20px 20px",
            "@media (max-width : 1280px)": {
                padding: "0px 10px 10px",
            },
        },
        ApprovalItems: {
            marginTop: 20,
            position: "relative",
            display: "inline-flex",
            "@media (max-width : 1280px)": {
                width: "100%",
                display: "inline-flex",
                alignItems: "center",
            },
        },
        skuItemsMap: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "@media (max-width : 1280px)": {
                justifyContent: "flex-start",
            },
        },
        ApprovalItemsTitle: {
            display: "inline-block",
            fontWeight: "bold",
            minWidth: 170,
            "@media (max-width : 1280px)": {
                width: 70,
                fontSize: 14,
            },
        },
        ApprovalItemContent: {
            display: "inline-block",
            borderRadius: "6px",
            flexGrow: 1.5,
            marginLeft: 50,
            fontSize: 20,
            "@media (max-width : 1280px)": {
                fontSize: 14,
                marginLeft: 18,
                width: "40px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
        },
        LinkContent: {
            wordBreak: "break-all",
            maxWidth: "100%",
            verticalAlign: "middle",
            display: "inline-block",
            minWidth: 180,
            marginLeft: 50,
            fontSize: 20,
            color: "#0076ff",
            fontWeight: "bold",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
                marginLeft: 20,
                minWidth: 90,
                flex: 1,
                wordBreak: "break-word",
            },
        },
        threeButtons: {
            display: "flex",
            wordBreak: "keep-all",
            overflow: "hidden",
        },
        EditSkuInput: {
            display: "inline-block",
            flexGrow: 2,
            marginLeft: 50,
            backgroundColor: "#FFFFFF",
            border: "1px solid #D8D8D8",
            borderRadius: "6px",
            "@media (max-width : 1280px)": {
                marginLeft: 18,
                "& input": {
                    flex: 1,
                    padding: 0,
                    height: "auto",
                    fontSize: 10,
                },
            },
        },
        EditButton: {
            cursor: "pointer",
            flexGrow: 1,
            marginRight: "8px",
            marginLeft: "12px",
            fontSize: "20px",
            textAlign: "center",
            textDecorationLine: "underline",
            color: "#22AB39",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
                marginLeft: "6px",
                marginRight: "4px",
            },
        },
        DeleteButton: {
            cursor: "pointer",
            flexGrow: 1,
            fontSize: "20px",
            margin: "0 8px 0",
            textAlign: "center",
            textDecorationLine: "underline",
            color: "#22AB39",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
            },
        },
        CopyButton: {
            cursor: "pointer",
            flexGrow: 1,
            margin: "0 35px 0 8px",
            fontSize: "20px",
            color: "#22AB39",
            textAlign: "center",
            textDecorationLine: "underline",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
                margin: "0 20px 0 0px",
            },
        },
        addSkuStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "@media (max-width : 1280px)": {
                justifyContent: "flex-start",
            },
        },
        addSkuButton: {
            marginTop: 20,
            padding: "12px",
            backgroundColor: "#22AB39",
            borderRadius: "4px",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
            },
        },
        twoButtons: {
            display: "flex",
            width: "199px",
            alignItems: "center",
            "@media (max-width : 1280px)": {
                width: "auto",
            },
        },
        AddSkuButton: {
            cursor: "pointer",
            flexGrow: 10,
            padding: "0 40",
            color: "#ffffff",
            marginLeft: 12,
            marginRight: 8,
            backgroundColor: "#22AB39",
            borderRadius: "4px",
            fontSize: "20px",
            textAlign: "center",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
                minHeight: 0,
                minWidth: 0,
                marginRight: 0,
                flexGrow: 0,
                width: "66px",
            },
        },
        AddCancelButton: {
            cursor: "pointer",
            fontSize: "20px",
            marginRight: 35,
            marginLeft: 8,
            flexGrow: 1,
            textAlign: "center",
            color: "#22AB39",
            fontWeight: 500,
            textDecorationLine: "underline",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
                wordBreak: "keep-all",
                marginRight: "20px",
                flexGrow: 0,
            },
        },
        remarkStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "@media (max-width : 1280px)": {
                justifyContent: "flex-start",
            },
        },
        remarkEditButtonsStyle: {
            display: "flex",
            width: "199px",
            "@media (max-width : 1280px)": {
                justifyContent: "flex-end",
                width: "auto",
            },
        },
        RemarkEditButton: {
            float: "left",
            cursor: "pointer",
            marginRight: "8px",
            marginLeft: "12px",
            fontSize: "20px",
            textAlign: "center",
            textDecorationLine: "underline",
            color: "#22AB39",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
            },
        },
        RemarkDeleteButton: {
            cursor: "pointer",
            fontSize: "20px",
            margin: "0 8px 0",
            textAlign: "center",
            textDecorationLine: "underline",
            color: "#22AB39",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
                margin: "0 20px 0 0 ",
            },
        },
        backIcon: {
            top: 3,
            "@media (max-width : 1280px)": {
                width: "24px",
                height: "24px",
                verticalAlign: "bottom",
            },
        },
        StatusCircle: {
            height: 16,
            width: 16,
            right: 0,
            borderRadius: "50%",
            display: "inline-block",
            marginRight: 5,
            "@media (max-width : 1280px)": {
                height: 12,
                width: 12,
                left: 0,
                borderRadius: "50%",
                display: "inline-block",
            },
        },
        StatusText: {
            fontSize: 18,
            "@media (max-width : 1280px)": {
                fontSize: 14,
            },
        },
        StatusWrapper: {
            display: "inline-block",
            position: "absolute",
            right: 0,
        },
        mobileStatusWrapper: {
            display: "inline-block",
            marginTop: "8px",
        },
        WeekBarItems: {
            display: "inline",
            width: "12.5%",
            textAlign: "center",
            position: "relative",
            userSelect: "none",
        },
        Item_TimeSlot: {
            minHeight: 205,
            width: "12.5%",
            borderRight: "1.5px solid #c4c4c4",
            borderLeft: "3px solid #c4c4c4",
            textAlign: "center",
        },
        TimeRange: {
            position: "relative",
            top: "50%",
            transform: "translateY(-50%)",
            "@media (max-width : 1280px)": {
                fontSize: "12px",
            },
        },
        Item_Day: {
            minHeight: 205,
            width: "12.5%",
            textAlign: "center",
            borderRight: "1.5px solid #c4c4c4",
            borderLeft: "1.5px solid #c4c4c4",
        },
        Item_LastDay: {
            minHeight: 205,
            width: "12.5%",
            textAlign: "center",
            borderRight: "3px solid #c4c4c4",
            borderLeft: "1.5px solid #c4c4c4",
            backgroundColor: "#e8e8e8",
        },
        WeekRowContainer: {
            width: "100%",
            height: "auto",
            background: "#fff",
        },
        WeekBarContainer: {
            width: "100%",
            height: "auto",
            backgroundColor: "#fff",
            borderRadius: "6px 6px 0px 0px",
            marginTop: 11,
        },
        WeekBarPreviousBtn: {
            position: "absolute",
            left: 0,
            "@media (min-width : 1281px)": {
                bottom: 0,
            },
            "@media (max-width : 1280px)": {
                marginLeft: "10%",
                marginTop: "20px",
            },
        },
        WeekBarNextBtn: {
            position: "absolute",
            right: 0,
            "@media (min-width : 1281px)": {
                bottom: 0,
            },
            "@media (max-width : 1280px)": {
                marginTop: "20px",
            },
        },
        WeekRowFirstItem: {
            borderTop: "3px solid  #c4c4c4",
            borderBottom: "1.5px solid #c4c4c4",
        },
        WeekRowOnlyOneItem: {
            borderTop: "3px solid  #c4c4c4",
            borderBottom: "3px solid #c4c4c4",
        },
        WeekRowItems: {
            borderTop: "1.5px solid  #c4c4c4",
            borderBottom: "1.5px solid #c4c4c4",
        },
        WeekRowLastItem: {
            borderTop: "1.5px solid  #c4c4c4",
            borderBottom: "3px solid #c4c4c4",
        },
        TimeSlotBtn: {
            cursor: "pointer",
            display: "inline",
            borderRadius: 24,
            maxWidth: 113,
            width: "100%",
            minHeight: 36,
            overflow: "hidden",
            textOverflow: "ellipsis",
            "-webkit-line-clamp": 2 /* number of lines to show */,
            "-webkit-box-orient": "vertical",
            background: "none",
            marginTop: 4,
        },
        Reject: {
            border: "2px solid #d0021b",
            color: "#d0021b",
        },
        Waiting: {
            border: "2px solid #0076ff",
            color: "#0076ff",
        },
        Approve: {
            border: "2px solid #22ab39",
            color: "#22ab39",
        },
        Cancel: {
            border: "2px solid #ff8d00",
            color: "#ff8d00",
        },
        More: {
            border: "2px solid #222222",
            color: "#222222",
        },
        Disable: {
            filter: "opacity(0.4)",
            backgroundColor: "rgba(191,191,191, 0.4)",
        },
        Popover: {
            marginLeft: 128,
            "@media (max-width : 1280px)": {
                marginLeft: 10,
            },
        },
        Popover_Wrapper: {
            width: 284,
            height: 449,
            position: "relative",
            padding: 10,
            "@media (max-width : 1280px)": {
                width: "200px",
                height: "320px",
            },
        },
        Popover_CloseBtn: {
            textAlign: "end",
            position: "absolute",
            right: 0,
        },
        MoreTimeSlotBtn_Wrapper: {
            textAlign: "center",
            marginBottom: 10,
        },
        CopyIcon: {
            top: 3,
            position: "relative",
        },
        CloseBtn_Wrapper: {
            position: "relative",
            textAlign: "center",
        },
        Day_Wrapper: {
            fontSize: 16,
            fontWeight: "bold",
        },
        Time_Wrapper: {
            fontSize: 17,
            fontWeight: "bold",
            textAlign: "center",
        },
        DayItems: {
            marginBottom: 8,
            fontSize: 18,
            "@media (max-width : 1280px)": {
                marginBottom: 4,
                fontSize: "12px",
            },
        },
        NumberDayItems: {
            fontSize: 18,
            "@media (max-width : 1280px)": {
                fontSize: "12px",
            },
        },
    })
);

const SubmitButton = styled(Button)({
    background: "#22ab39",
    border: 0,
    borderRadius: 3,
    color: "#ffffff",
    paddingLeft: 17,
    paddingRight: 17,
    fontSize: 16,
    width: 95,
    "&:disabled": {
        backgroundColor: "#d8d8d8",
        color: "#222222",
    },
});

const Approve_Button = styled(Button)({
    background: "#22ab39",
    border: 0,
    borderRadius: 6,
    color: "#ffffff",
    paddingLeft: 17,
    paddingRight: 17,
    fontSize: 16,
    width: 105,
    "&:disabled": {
        backgroundColor: "#d8d8d8",
        color: "#222222",
    },
    "@media (max-width : 1280px)": {
        width: 0,
        fontSize: 14,
        padding: 0,
        height: 0,
    },
});

const Reject_Button = styled(Button)({
    background: "#fff",
    borderRadius: 6,
    color: "#22ab39",
    paddingLeft: 17,
    paddingRight: 17,
    fontSize: 16,
    width: 105,
    border: "1px solid #22ab39",
    "&:disabled": {
        backgroundColor: "#d8d8d8",
        color: "#222222",
    },
    "@media (max-width : 1280px)": {
        width: 0,
        fontSize: 14,
        padding: 0,
        height: 0,
    },
});
const Confirm_ChangeData_Button = styled(Button)({
    background: "#22AB39",
    color: "#fff",
    borderRadius: "6px",
    padding: "12px 22px",
    width: 140,
    fontSize: 16,
    fontWeight: "bold",
    "&:disabled": {
        backgroundColor: "#d8d8d8",
        color: "#222222",
    },
    "@media (max-width : 1280px)": {
        width: "90px",
        fontSize: 14,
        padding: 0,
    },
});
const Cancel_Button = styled(Button)({
    background: "#ff5555",
    borderRadius: 6,
    color: "#fff",
    paddingLeft: 17,
    paddingRight: 17,
    fontSize: 16,
    width: 105,
    "&:disabled": {
        backgroundColor: "#d8d8d8",
        color: "#222222",
    },
    "@media (max-width : 1280px)": {
        width: 0,
        height: 0,
        fontSize: 14,
        padding: 0,
    },
});

function DropDownList(props) {
    const intl = useIntl();
    const classes = useStyles();
    const device = useContext(RwdContext);
    if (props.placeholder) {
        return (
            <Grid item className={classes.FiltersItems}>
                <div>{intl.formatMessage({ id: props.text })}</div>
                <SelectInputWithLabel
                    placeholder={props.placeholder}
                    options={props.options}
                    value={props.value}
                    typeThreeUI={true}
                    disabled={!Boolean(props.options.length)}
                    onChange={(val) => {
                        props.func(val);
                    }}
                />
            </Grid>
        );
    } else {
        return (
            <Grid item className={classes.FiltersItems}>
                <div>{intl.formatMessage({ id: props.text })}</div>
                <SelectInputWithLabel
                    options={props.options}
                    value={props.value}
                    typeThreeUI={true}
                    disabled={!Boolean(props.options.length)}
                    onChange={(val) => {
                        props.func(val);
                    }}
                />
            </Grid>
        );
    }
}

function ApproveButton(props) {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid item className={classes.FiltersButton}>
            <Approve_Button disabled={props.isSubmitting === "APPROVED"} onClick={props.func}>
                {props.isSubmitting === "APPROVED" ? (
                    <>
                        <span style={{ visibility: "hidden" }}>{props.confirmBtnText}</span>
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: BtnLoadingAnimation,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                },
                            }}
                            width={40}
                            height={29}
                            style={{ position: "absolute" }}
                        />
                    </>
                ) : (
                    <>{intl.formatMessage({ id: props.text })}</>
                )}
            </Approve_Button>
        </Grid>
    );
}

function RejectButton(props) {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid item className={classes.FiltersButton}>
            <Reject_Button disabled={props.isSubmitting === "REJECTED"} onClick={props.func}>
                {intl.formatMessage({ id: props.text })}
            </Reject_Button>
        </Grid>
    );
}

function CancelButton(props) {
    const classes = useStyles();
    const intl = useIntl();
    return (
        <Grid item className={classes.FiltersButton}>
            <Cancel_Button onClick={props.func}>
                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.028" })}
            </Cancel_Button>
        </Grid>
    );
}
function ConfirmChangeDataButton(props) {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid item className={classes.FiltersButton}>
            <Confirm_ChangeData_Button
                onClick={props.func}
                disabled={props.isSubmitting === "CHANGED" || props.changes}
            >
                {props.isSubmitting === "CHANGED" ? (
                    <>
                        <span style={{ visibility: "hidden" }}>{props.confirmBtnText}</span>
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: BtnLoadingAnimation,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                },
                            }}
                            width={40}
                            height={29}
                            style={{ position: "absolute" }}
                        />
                    </>
                ) : (
                    <>{intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.028.1" })}</>
                )}
            </Confirm_ChangeData_Button>
        </Grid>
    );
}

function AllTimeSlotsCalendarPage(props) {
    const intl = useIntl();
    const classes = useStyles();
    const [selected_publisher, setSelected_publisher] = useState<string>("ALL_PUBLISHER");
    const [selected_month, setSelected_month] = useState("placeholder");
    const [selected_timeSlot, setSelected_timeSlot] = useState<string>("ALL_TIMESLOT");
    const [selected_status, setSelected_status] = useState<string>("ALL_STATUS");
    const [selected_week, setSelected_week] = useState<number>(0);
    const [dropdown_timeSlot, setDropdown_timeSlot] = useState<Array<any>>([
        { name: "所有時段", value: "alltimeslots" },
    ]);
    const [dropdown_publisher, setDropdown_publisher] = useState<Array<any>>([]);
    const [dropdown_yearMonth, setDropdown_yearMonth] = useState<Array<any>>([]);
    const [dropdown_status, setDropdown_status] = useState<Array<{ name: string; value: string }>>([]);

    const apiService = useContext(ApiServiceContext);
    const device = useContext(RwdContext);
    const [allTimeSlots, setAllTimeSlots] = useState<any>([]);
    const [allBookings, setAllBookings] = useState<any>([]);
    const [allBookings_isFetched, setAllBookings_IsFetched] = useState<boolean>(false);
    const [allTimeSlot_isFetched, setAllTimeSlot_isFetched] = useState<boolean>(false);
    const [calendarRows, setCalendarRows] = useState<Array<any>>([]);
    const [calendarBase, setCalendarBase] = useState<Array<any>>([]);

    const [monthSaver, setMonthSaver] = useState<Array<any>>([]);

    const [isApprovalPage, setIsApprovalPage] = useState<boolean>(false);
    const [approvalPageData, setApprovalPageData] = useState<any>();

    const [isMorePopover, setIsMorePopover] = useState<boolean>(false);
    const [morePopoverData, setMorePopoverData] = useState<any>({});
    const [outputcsv, setoutputcsv] = useState<Array<any>>([]);
    const [openValidateError, setOpenValidateError] = useState(false);
    const [errMsg, setErrMsg] = useState("general.1.002");

    const [disabled, setDisabled] = useState<boolean>(false);
    interface draftData {
        id: string;
        liveDate: string;
        time: string;
        KOL: string;
        skulist: Array<string>;
    }
    const draftBookingData = async (list: any) => {
        let DraftData: draftData[] = [];

        Promise.all(
            list.map((val, index) => {
                if (list.length == 0) {
                    csvDataHandler([]);
                    return;
                }
                setDisabled(true);
                if (!disabled)
                    return apiService
                        .getSingleBooking(val._id)
                        .then((res) => {
                            if (res.data?.status == "success") {
                                let temp = val.timeSlotRefId;
                                let tempData = res.data?.data;

                                if (tempData) val.skulist = tempData.skuid;

                                let timeslot = allTimeSlots.find((timeslots) => timeslots._id == val.timeSlotRefId);

                                let startEndTime =
                                    timeslot?.startTime?.split(":")[0] +
                                    ":" +
                                    timeslot?.startTime?.split(":")[1] +
                                    "-" +
                                    timeslot?.endTime?.split(":")[0] +
                                    ":" +
                                    timeslot?.endTime?.split(":")[1];

                                if (startEndTime !== "undefined:undefined-undefined:undefined") {
                                    return {
                                        id: val._id,
                                        liveDate: val.localDate,
                                        time: startEndTime,
                                        KOL: val.userName,
                                        skulist: val.skulist,
                                    };
                                }
                            } else {
                                throw new Error("Error Unknown");
                            }
                        })
                        .catch(() => {
                            setErrMsg("general.1.002");
                            setOpenValidateError(true);
                        });
            })
        )
            .then((dataset) => {
                csvDataHandler(dataset);
                csvInstance.current.link.click();
                setDataReady(true);
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setDisabled(false);
            });
    };

    interface ExportData {
        date: string;
        time: string;
        kol: string;
        SkuID1?: string;
        SkuID2?: string;
        SkuID3?: string;
        SkuID4?: string;
        SkuID5?: string;
        SkuID6?: string;
        SkuID7?: string;
        SkuID8?: string;
        SkuID9?: string;
        SkuID10?: string;
        SkuID11?: string;
        SkuID12?: string;
        SkuID13?: string;
        SkuID14?: string;
        SkuID15?: string;
        SkuID16?: string;
        SkuID17?: string;
        SkuID18?: string;
        SkuID19?: string;
        SkuID20?: string;
        SkuID21?: string;
        SkuID22?: string;
        SkuID23?: string;
        SkuID24?: string;
        SkuID25?: string;
        SkuID26?: string;
        SkuID27?: string;
        SkuID28?: string;
        SkuID29?: string;
        SkuID30?: string;
    }

    const csvDataHandler = (data: Array<any>) => {
        let exportData: ExportData[] = [];
        let skuLength = 30;
        if (data.length > 0) {
            data.map((item, index) => {
                if (item === undefined) {
                } else {
                    let skulist = [];
                    if (item?.skulist?.length > 0) {
                        skulist = item.skulist;
                    }
                    var temp: ExportData = {
                        date: item.liveDate,
                        time: item.time,
                        kol: item.KOL,
                    };
                    for (let j = 1; j <= skulist.length && skulist.length > 0; j++) {
                        temp["SkuID" + j] = skulist[j - 1];
                    }
                    for (let k = skulist.length + 1; k <= 30; k++) {
                        temp["SkuID" + k] = "NA";
                    }

                    exportData.push(temp);
                }
            });
            exportData.sort((obj1, obj2) => {
                if (obj1.date < obj2.date) {
                    return -1;
                }
                if (obj1.date > obj2.date) {
                    return 1;
                }

                return obj1.time.localeCompare(obj2.time);
            });
            setoutputcsv(exportData);
        } else {
            setoutputcsv([]);
        }
    };
    const filterSelected = () => {
        let fullList = allBookings;
        if (selected_publisher !== "ALL_PUBLISHER") {
            publisherFilter();
        }
        if (selected_timeSlot !== "ALL_TIMESLOT") {
            let SelectedStartTime = selected_timeSlot.split("-")[0] + ":00";
            let SelectedEndTime = selected_timeSlot.split("-")[1] + ":00";
            let timeslots = allTimeSlots.find(
                (timeslot) => timeslot.startTime == SelectedStartTime && timeslot.endTime == SelectedEndTime
            );
            timeSlotFilter(timeslots._id);
        }

        if (selected_status !== "ALL_STATUS") {
            statusFilter();
        }

        function publisherFilter() {
            fullList = fullList.filter((_booking) => _booking.userName == selected_publisher);
        }
        function timeSlotFilter(timeslot_id) {
            fullList = fullList.filter((_booking) => _booking.timeSlotRefId == timeslot_id);
        }
        function statusFilter() {
            fullList = fullList.filter((_booking) => _booking.status == selected_status);
        }
        if (!changeMonth) draftBookingData(fullList);
    };

    const filterAll = () => {
        let _og: Array<any> = _.cloneDeep(monthSaver);
        let _new: Array<any> = [];
        let updated = false;

        function publisherFilter(_obj: any) {
            _new = [];
            _obj.forEach((item, i_index) => {
                let counter = 0;
                for (let y = 0; y < item.length; y++) {
                    item[y].bookings = item[y].bookings.filter((_booking) => _booking.userName == selected_publisher);
                    item[y].bookings.length && counter++;
                }
                counter && _new.push(item);
            });
            updated = true;
        }

        function statusFilter(_obj: any) {
            _new = [];
            _obj.forEach((item, i_index) => {
                for (let y = 0; y < item.length; y++) {
                    // item[y].bookings = item[y].bookings.filter(b_item=>b_item.status == selected_status)
                    for (let i = 0; i < item[y].bookings.length; i++) {
                        if (item[y].bookings[i].status == selected_status) {
                            _new.push(item);
                            return;
                        }
                    }
                }
            });

            _new.forEach((item, i_index) => {
                for (let y = 0; y < item.length; y++) {
                    item[y].bookings = item[y].bookings.filter((_booking) => _booking.status == selected_status);
                }
            });
            updated = true;
        }

        function timeSlotFilter(_obj: any) {
            _new = [];
            _obj.forEach((item, index) => {
                let counter = 0;
                for (let i = 0; i < item.length; i++) {
                    const startTime = UtilFunctions.getFormattedTime(item[i].timeSlot.startTime);
                    const endTime = UtilFunctions.getFormattedTime(item[i].timeSlot.endTime);
                    const startEndTime = startTime + "-" + endTime;

                    if (startEndTime == selected_timeSlot && item[i].bookings.length) {
                        counter++;
                    }
                }
                counter && _new.push(item);
            });
            updated = true;
        }

        function BaseTimeSlot(_obj: any) {
            _new = [];
            _obj.forEach((item, index) => {
                let counter = 0;
                for (let i = 0; i < item.length; i++) {
                    const startTime = UtilFunctions.getFormattedTime(item[i].timeSlot.startTime);
                    const endTime = UtilFunctions.getFormattedTime(item[i].timeSlot.endTime);
                    const startEndTime = startTime + "-" + endTime;

                    if (startEndTime == selected_timeSlot) {
                        counter++;
                        if (item[i].bookings.length) {
                            item[i].bookings = [];
                        }
                    } else if (
                        selected_publisher !== "ALL_PUBLISHER" &&
                        selected_timeSlot === "ALL_TIMESLOT" &&
                        selected_status === "ALL_STATUS"
                    ) {
                        counter++;
                        if (item[i].bookings.length) {
                            item[i].bookings = [];
                        }
                    } else if (
                        selected_status !== "ALL_STATUS" &&
                        selected_timeSlot === "ALL_TIMESLOT" &&
                        selected_publisher === "ALL_PUBLISHER"
                    ) {
                        counter++;
                        if (item[i].bookings.length) {
                            item[i].bookings = [];
                        }
                    } else if (
                        selected_publisher !== "ALL_PUBLISHER" &&
                        selected_status !== "ALL_STATUS" &&
                        selected_timeSlot === "ALL_TIMESLOT"
                    ) {
                        counter++;
                        if (item[i].bookings.length) {
                            item[i].bookings = [];
                        }
                    }
                }
                counter && _new.push(item);
            });
        }

        if (selected_publisher !== "ALL_PUBLISHER") {
            publisherFilter(updated ? _new : _og);
        }

        if (selected_timeSlot !== "ALL_TIMESLOT") {
            timeSlotFilter(updated ? _new : _og);
        }

        if (selected_status !== "ALL_STATUS") {
            statusFilter(updated ? _new : _og);
        }

        if (_new.length === 0) {
            BaseTimeSlot(_og);
        }

        setCalendarRows(updated ? _new : _og);
    };

    const init_timeSlotDropDownList = (_obj: Array<any>) => {
        let list: Array<any> = [
            {
                name: "所有時段",
                value: "ALL_TIMESLOT",
            },
        ];

        _obj.forEach((item) => {
            const startTime = UtilFunctions.getFormattedTime(item[0].timeSlot.startTime);
            const endTime = UtilFunctions.getFormattedTime(item[0].timeSlot.endTime);

            list.push({
                name: startTime + "-" + endTime,
                value: startTime + "-" + endTime,
            });
        });

        setDropdown_timeSlot(list);
    };

    const init_publisherDropdownList = (_obj: Array<any>) => {
        let list: Array<any> = [
            {
                name: "所有",
                value: "ALL_PUBLISHER",
            },
        ];

        const nameList = new Set();

        _obj.forEach((item) => {
            const name = item.userName;
            nameList.add(name);
        });
        nameList.forEach((name) => {
            list.push({
                name: name,
                value: name,
            });
        });
        setDropdown_publisher(list);
    };

    const init_statusDropdownlist = () => {
        let list: Array<any> = [
            {
                name: "所有狀態",
                value: "ALL_STATUS",
            },
            {
                name: "待審核",
                value: "WAITING_FOR_APPROVAL",
            },
            {
                name: "已審核",
                value: "APPROVED",
            },
            {
                name: "已取消",
                value: "CANCELLED",
            },
            {
                name: "拒絕",
                value: "REJECTED",
            },
        ];

        setDropdown_status(list);
    };

    const init_yearMonth = (_obj: Array<any>) => {
        let list: Array<any> = [];
        const yearMonthList = new Set();

        _obj.forEach((item) => {
            const yearAndMonth = item.year + "-" + `${item.month <= 9 ? "0" + item.month : item.month}`;
            yearMonthList.add(yearAndMonth);
        });

        yearMonthList.forEach((item) => {
            list.push({
                name: item,
                value: item,
            });
        });
        setDropdown_yearMonth(list);
    };

    const init_yearMonthList = () => {
        let dateRange = UtilFunctions.getInitialDateRange();
        getAllTimeSlot_handler(dateRange[0], dateRange[1]);
    };

    const init_dataParser = (week: number, yearMonth: string) => {
        const calendar = UtilFunctions.getCalendar(yearMonth);
        let obj: Array<any> = [];
        let cal: Array<any> = [];

        allTimeSlots.forEach((_allTimeSlots, _timeSlotIndex) => {
            obj[_timeSlotIndex] = [];
            calendar[week].date.forEach((_date, _dateIndex) => {
                obj[_timeSlotIndex].push({
                    date: _date,
                    timeSlot: _allTimeSlots,
                    bookings: [],
                });

                if (allBookings.length) {
                    allBookings.forEach((_bookings) => {
                        if (
                            _bookings.localDate == _date &&
                            _bookings.timeSlotRefId == allTimeSlots[_timeSlotIndex]._id
                        ) {
                            if (obj[_timeSlotIndex][_dateIndex].bookings) {
                                obj[_timeSlotIndex][_dateIndex].bookings.push(_bookings);
                            }
                        }
                    });
                }
            });
        });
        cal.push(calendar[week]);

        init_timeSlotDropDownList(obj);
        init_publisherDropdownList(allBookings);
        init_statusDropdownlist();
        setMonthSaver(obj);
        setCalendarRows(obj);
        setCalendarBase(cal);

        //console.log("formatted obj");
        // console.log(obj);
    };

    const [allowDownload, setAllowDownload] = useState(false);

    const changeMonth_handler = (val: any) => {
        setchangeMonth(true);
        setSelected_week(0);
        setSelected_month(val);
        setAllowDownload(false);

        getAllBookings_handler(val);
        getAllTimeSlot_handler(val);

        setSelected_publisher("ALL_PUBLISHER");
        setSelected_timeSlot("ALL_TIMESLOT");
        setSelected_status("ALL_STATUS");

        init_dataParser(0, selected_month);

        //console.log("Change month to: " + val);
    };

    const changeWeek_handler = (action: string) => {
        let week;
        let endWeek = UtilFunctions.getEndWeek(selected_month);

        if (action == "previous") {
            week = selected_week - 1 >= 0 ? selected_week - 1 : selected_week;
        } else if (action == "next") {
            week = selected_week + 1 > endWeek ? selected_week : selected_week + 1;
        }

        setSelected_publisher(selected_publisher);
        setSelected_timeSlot(selected_timeSlot);
        setSelected_status(selected_status);

        if (selected_week !== week) {
            setSelected_week(week);
            init_dataParser(week, selected_month);
        }
    };

    const selected_publisher_handler = (val: string) => {
        setSelected_publisher(val);
    };

    const selected_timeSlot_handler = (val: string) => {
        setSelected_timeSlot(val);
    };

    const selected_status_handler = (val: string) => {
        setSelected_status(val);
    };

    const isApprovalPage_handler = () => {
        setIsApprovalPage(!isApprovalPage);
    };

    const refreshApiData = () => {
        getAllBookings_handler(selected_month);
        getAllTimeSlot_handler(selected_month);
        selected_publisher_handler("ALL_PUBLISHER");
        selected_timeSlot_handler("ALL_TIMESLOT");
        selected_status_handler("ALL_STATUS");

        if (isMorePopover) {
            setIsMorePopover(!isMorePopover);
        }
    };

    const getAllTimeSlot_handler = (_month: string, _toMonth?: string) => {
        let obj, startDayOfMonth, endDayOfMonth;

        if (_month && _toMonth) {
            const startDay = UtilFunctions.getStartEndDay(_month);
            const endDay = UtilFunctions.getStartEndDay(_toMonth);

            startDayOfMonth = startDay[0];
            endDayOfMonth = endDay[1];

            obj = {
                startLocalDate: `${_month}-${startDayOfMonth}`,
                endLocalDate: `${_toMonth}-${endDayOfMonth}`,
                showDisabled: false,
            };
        } else {
            const startEndDay = UtilFunctions.getStartEndDay(_month);
            startDayOfMonth = startEndDay[0];
            endDayOfMonth = startEndDay[1];

            obj = {
                startLocalDate: `${_month}-${startDayOfMonth}`,
                endLocalDate: `${_month}-${endDayOfMonth}`,
                showDisabled: false,
            };
        }

        apiService
            .getAllTimeSlots(obj)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    if (_month && _toMonth) {
                        let year = res.data?.data[0]?.year;
                        let month = res.data?.data[0]?.month;
                        let yearMonth = year + "-" + `${month <= 9 ? "0" + month : month}`;
                        // console.log(yearMonth);
                        if (year && month) {
                            console.log("initial yearMonth: " + yearMonth);
                            getAllBookings_handler(yearMonth);
                            getAllTimeSlot_handler(yearMonth);
                            setSelected_month(yearMonth);
                            init_yearMonth(res.data?.data);
                        } else {
                            console.log("No time slot for now");
                        }
                    } else {
                        setAllTimeSlots(res.data?.data);
                    }
                } else {
                    console.log("Fail to fetch getAllTimeSlots");
                    throw new Error("Error Unknown");
                }
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                if (!_toMonth) {
                    setAllTimeSlot_isFetched(true);
                }
            });
    };

    const getAllBookings_handler = (_month: string) => {
        let obj, startDayOfMonth, endDayOfMonth;

        const startEndDay = UtilFunctions.getStartEndDay(_month);
        startDayOfMonth = startEndDay[0];
        endDayOfMonth = startEndDay[1];

        obj = {
            startLocalDate: `${_month}-${startDayOfMonth}`,
            endLocalDate: `${_month}-${endDayOfMonth}`,
        };

        apiService
            .getAllBookings(obj)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    sortingForAllBookings(res.data?.data);
                } else {
                    console.log("Fail to fetch getAllBookings");
                    throw new Error("Error Unknown");
                }
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setAllBookings_IsFetched(true);
            });
    };

    useEffect(() => {
        if (allBookings.length) {
            setAllowDownload(true);
        } else {
            setAllowDownload(false);
        }
    }, [allBookings]);

    const sortingForAllBookings = (_bookings: any) => {
        let WAITING_FOR_APPROVAL: any = [];
        let REJECTED: any = [];
        let APPROVED: any = [];
        let CANCELLED: any = [];

        _bookings.forEach((booking, b_index) => {
            if (booking.status == "WAITING_FOR_APPROVAL") {
                WAITING_FOR_APPROVAL.push(booking);
            } else if (booking.status == "REJECTED") {
                REJECTED.push(booking);
            } else if (booking.status == "APPROVED") {
                APPROVED.push(booking);
            } else if (booking.status == "CANCELLED") {
                CANCELLED.push(booking);
            }
        });

        WAITING_FOR_APPROVAL.sort((a, b) => b.createTime - a.createTime);
        REJECTED.sort((a, b) => b.createTime - a.createTime);
        APPROVED.sort((a, b) => b.createTime - a.createTime);
        CANCELLED.sort((a, b) => b.createTime - a.createTime);

        const _obj: any = [...WAITING_FOR_APPROVAL, ...APPROVED, ...REJECTED, ...CANCELLED];
        setAllBookings(_obj);
    };

    const onClick_timeSlot_handler = (bookings: any, timeSlot: any) => {
        const obj = {
            booking: bookings,
            timeSlot: timeSlot,
        };

        setApprovalPageData(obj);
        setIsApprovalPage(true);
    };

    const onClick_moreTimeSlot_handler = (obj?: any) => {
        if (obj) {
            setMorePopoverData(obj);
        }

        setIsMorePopover(!isMorePopover);
    };

    const onClose_moreTimeSlot_handler = () => {
        setIsMorePopover(false);
    };

    useEffect(() => {
        init_yearMonthList();
    }, []);

    useEffect(() => {
        filterAll();
    }, [selected_publisher, selected_timeSlot, selected_status, selected_week]);

    /*(() => {
        filterSelected();
    }, [selected_publisher, selected_timeSlot, selected_status, allBookings_isFetched]);*/

    const csvInstance = React.useRef<any | null>(null);

    const [changeMonth, setchangeMonth] = React.useState(false);
    useEffect(() => {
        if (allTimeSlot_isFetched && allBookings_isFetched && selected_month) {
            init_dataParser(selected_week, selected_month);

            setAllTimeSlot_isFetched(false);
            setAllBookings_IsFetched(false);
            setchangeMonth(false);
        }
    }, [allTimeSlot_isFetched, allBookings_isFetched]);

    interface Header {
        label: string;
        key: string;
    }
    function FillHeader() {
        let csvHeader: Array<Header> = [
            {
                label: "Live Date",
                key: "date",
            },
            {
                label: "Time",
                key: "time",
            },
            {
                label: "KOL",
                key: "kol",
            },
        ];
        for (let i = 1; i <= 30; i++) {
            let temp: Header = {
                label: "SKU ID " + i,
                key: "SkuID" + i,
            };
            csvHeader.push(temp);
        }

        return csvHeader;
    }
    const clickToExport = async () => {
        await filterSelected();
    };

    const [dataReady, setDataReady] = React.useState<boolean>(false);

    useEffect(() => {
        setoutputcsv([]);
    }, [selected_month, selected_publisher, selected_timeSlot, selected_status]);

    return (
        <>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <div
                className={
                    device == "PC"
                        ? classes.AllTimeSlotsCalendarPage + " pageWrapper"
                        : classes.AllTimeSlotsCalendarPage
                }
            >
                {calendarRows && !isApprovalPage && (
                    <>
                        <Grid container xs={12} justify="space-between" alignItems="center">
                            <span className={device == "PC" ? "title" : classes.mobileTitle}>
                                {intl.formatMessage({ id: "admin.editTimeSlots.1.003" })}
                            </span>
                        </Grid>
                        <div className={device == "PC" ? "AllTimeSlotsCalendarPage_PageContent pageContent" : ""}>
                            <Grid
                                container
                                justify="flex-start"
                                className={classes.FiltersContainer}
                                style={
                                    device == "PC" ? { justifyContent: "spaceBetween" } : { flexDirection: "column" }
                                }
                            >
                                <Grid
                                    item
                                    xs={8}
                                    style={
                                        device == "PC"
                                            ? { display: "flex" }
                                            : {
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  flexGrow: 1,
                                                  maxWidth: "100%",
                                              }
                                    }
                                >
                                    <DropDownList
                                        text={"admin.editTimeSlots.1.007"}
                                        options={dropdown_yearMonth}
                                        value={selected_month}
                                        // placeholder={intl.formatMessage({ id: "admin.editTimeSlots.1.049.a" })}
                                        func={(val) => changeMonth_handler(val)}
                                    />
                                    <DropDownList
                                        text={"admin.allTimeSlotsCalendar.1.005"}
                                        options={dropdown_publisher}
                                        value={selected_publisher}
                                        //placeholder={"admin.allTimeSlotsCalendar.1.001.9"}
                                        func={(val) => selected_publisher_handler(val)}
                                    />
                                    <DropDownList
                                        text={"admin.allTimeSlotsCalendar.1.001.8"}
                                        options={dropdown_timeSlot}
                                        value={selected_timeSlot}
                                        //placeholder={"admin.allTimeSlotsCalendar.1.009"}
                                        func={(val) => selected_timeSlot_handler(val)}
                                    />
                                    <DropDownList
                                        text={"admin.allTimeSlotsCalendar.1.01"}
                                        options={dropdown_status}
                                        value={selected_status}
                                        //placeholder={"admin.allTimeSlotsCalendar.1.011"}
                                        func={(val) => selected_status_handler(val)}
                                    />
                                    {/* <FilterButton
                                text={"admin.allTimeSlotsCalendar.1.016"}
                                func={() => isApprovalPage_handler()}
                            /> */}
                                </Grid>
                                <Grid
                                    item
                                    style={
                                        device == "PC"
                                            ? { alignSelf: "center", marginLeft: "auto", padding: "10px" }
                                            : { padding: "10px" }
                                    }
                                >
                                    <Button
                                        style={{
                                            backgroundColor: allowDownload
                                                ? disabled
                                                    ? "#c4c4c4"
                                                    : "#22ab39"
                                                : "#c4c4c4",
                                            color: "white",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                        }}
                                        disabled={(!allowDownload && !changeMonth) || disabled}
                                        onClick={clickToExport}
                                    >
                                        {!disabled ? (
                                            intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.017" })
                                        ) : (
                                            <Lottie
                                                options={{
                                                    loop: true,
                                                    autoplay: true,
                                                    animationData: BtnLoadingAnimation,
                                                    rendererSettings: {
                                                        preserveAspectRatio: "xMidYMid slice",
                                                    },
                                                }}
                                                width={40}
                                                height={29}
                                            />
                                        )}
                                    </Button>
                                    <CSVLink
                                        style={{ textDecoration: "none" }}
                                        id={"csvDownload"}
                                        filename={`HKTV Blog Data ${selected_month}.csv`}
                                        key={outputcsv.length}
                                        data={outputcsv}
                                        headers={FillHeader()}
                                        ref={csvInstance}
                                        target="_self"
                                    ></CSVLink>
                                </Grid>
                            </Grid>
                        </div>

                        <Grid container className={classes.WeekBarContainer}>
                            {calendarBase.map((rows, r_index) => {
                                return rows.date.map((day, d_index) => {
                                    if (r_index == 0) {
                                        if (d_index == 0) {
                                            return (
                                                <>
                                                    {device == "mobile" && (
                                                        <span
                                                            className={classes.WeekBarPreviousBtn}
                                                            onClick={() => changeWeek_handler("previous")}
                                                        >
                                                            <PreIc style={{ cursor: "pointer" }} />
                                                        </span>
                                                    )}
                                                    <Grid
                                                        item
                                                        className={classes.WeekBarItems}
                                                        style={{ marginLeft: "12.5%" }}
                                                    >
                                                        {device == "PC" && (
                                                            <span
                                                                className={classes.WeekBarPreviousBtn}
                                                                onClick={() => changeWeek_handler("previous")}
                                                            >
                                                                <PreIc style={{ cursor: "pointer" }} />
                                                            </span>
                                                        )}
                                                        <Typography className={classes.DayItems}>
                                                            {dayNameMapping[d_index]}
                                                        </Typography>
                                                        <Typography className={classes.NumberDayItems}>
                                                            {UtilFunctions.getFormattedDate(day)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            );
                                        } else if (d_index == 6) {
                                            return (
                                                <>
                                                    <Grid item className={classes.WeekBarItems}>
                                                        <Typography className={classes.DayItems}>
                                                            {dayNameMapping[d_index]}
                                                        </Typography>
                                                        <Typography className={classes.NumberDayItems}>
                                                            {UtilFunctions.getFormattedDate(day)}
                                                        </Typography>
                                                        {device == "PC" && (
                                                            <span
                                                                className={classes.WeekBarNextBtn}
                                                                onClick={() => changeWeek_handler("next")}
                                                            >
                                                                <ToIc style={{ cursor: "pointer" }} />
                                                            </span>
                                                        )}
                                                    </Grid>
                                                    {device == "mobile" && (
                                                        <span
                                                            className={classes.WeekBarNextBtn}
                                                            onClick={() => changeWeek_handler("next")}
                                                        >
                                                            <ToIc style={{ cursor: "pointer" }} />
                                                        </span>
                                                    )}
                                                </>
                                            );
                                        } else {
                                            return (
                                                <Grid item className={classes.WeekBarItems}>
                                                    <Typography className={classes.DayItems}>
                                                        {dayNameMapping[d_index]}
                                                    </Typography>
                                                    <Typography className={classes.NumberDayItems}>
                                                        {UtilFunctions.getFormattedDate(day)}
                                                    </Typography>
                                                </Grid>
                                            );
                                        }
                                    }
                                });
                            })}
                        </Grid>

                        <Grid container className={classes.WeekRowContainer}>
                            {calendarRows.map((rows, r_index) => {
                                let disableStyle = "";
                                if (rows[0]?.timeSlot?.disabled) {
                                    disableStyle = classes.Disable;
                                }

                                let borderStyle = classes.WeekRowItems;
                                if (r_index == 0 && calendarRows.length - 1 !== r_index) {
                                    borderStyle = classes.WeekRowFirstItem;
                                } else if (r_index == calendarRows.length - 1 && r_index !== 0) {
                                    borderStyle = classes.WeekRowLastItem;
                                } else if (r_index == 0 && calendarRows.length - 1 == r_index) {
                                    borderStyle = classes.WeekRowOnlyOneItem;
                                }

                                return (
                                    <Grid container className={`${borderStyle} ${disableStyle}`}>
                                        <Week_Items
                                            rows={rows}
                                            onClick_timeSlot_handler={onClick_timeSlot_handler}
                                            onClick_moreTimeSlot_handler={onClick_moreTimeSlot_handler}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Popover
                            className={classes.Popover}
                            open={isMorePopover}
                            onClose={onClose_moreTimeSlot_handler}
                            anchorOrigin={{
                                vertical: "center",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "center",
                                horizontal: "center",
                            }}
                        >
                            <div className={classes.Popover_Wrapper}>
                                <div className={classes.CloseBtn_Wrapper}>
                                    <span className={classes.Day_Wrapper}>
                                        {morePopoverData?.bookings &&
                                            UtilFunctions.getDateNameFromDate(morePopoverData?.bookings[0]?.localDate)}
                                    </span>
                                    <span
                                        className={classes.Popover_CloseBtn}
                                        onClick={() => onClick_moreTimeSlot_handler()}
                                    >
                                        <CloseIcon style={{ cursor: "pointer" }} />
                                    </span>
                                </div>

                                <div className={classes.Time_Wrapper}>
                                    {morePopoverData?.timeSlot &&
                                        UtilFunctions.getFormattedTime(morePopoverData?.timeSlot?.startTime) +
                                            " - " +
                                            UtilFunctions.getFormattedTime(morePopoverData?.timeSlot?.endTime)}
                                </div>
                                <Grid container justify="space-evenly" className={classes.FiltersContainer}>
                                    {morePopoverData?.bookings?.map((booking, b_index) => {
                                        return (
                                            <Popover_TimeSlot_Button
                                                booking={booking}
                                                timeSlot={morePopoverData.timeSlot}
                                                onClick_timeSlot_handler={onClick_timeSlot_handler}
                                            />
                                        );
                                    })}
                                </Grid>
                            </div>
                        </Popover>
                    </>
                )}

                {isApprovalPage && (
                    <ApprovalPage
                        isApprovalPage_handler={() => isApprovalPage_handler()}
                        approvalPageData={approvalPageData}
                        selected_month={selected_month}
                        selected_week={selected_week}
                        refreshApiData={() => refreshApiData()}
                    />
                )}
            </div>
        </>
    );
}

function Week_Items(props) {
    return props.rows.map((day, d_index) => {
        return (
            <Week_Item
                day={day}
                d_index={d_index}
                onClick_timeSlot_handler={props.onClick_timeSlot_handler}
                onClick_moreTimeSlot_handler={props.onClick_moreTimeSlot_handler}
            />
        );
    });
}

function Week_Item(props) {
    const classes = useStyles();
    const startTime = UtilFunctions.getFormattedTime(props.day.timeSlot.startTime);
    const endTime = UtilFunctions.getFormattedTime(props.day.timeSlot.endTime);

    //Old logic by Chris lo
    // if (props.d_index == 0) {
    //     return (
    //         <>
    //             <Calendar_TimeSlot timeSlot={startTime + " - " + endTime} />
    //             <Grid item className={classes.Item_Day} style={{ backgroundColor: "#e8e8e8" }}></Grid>
    //         </>
    //     );
    // }

    // if (props.day.bookings.length) {
    //     return (
    //         <Grid item className={props.d_index == 6 ? classes.Item_LastDay : classes.Item_Day}>
    //             <Calendar_TimeSlot_Button
    //                 day={props.day}
    //                 onClick_timeSlot_handler={props.onClick_timeSlot_handler}
    //                 onClick_moreTimeSlot_handler={props.onClick_moreTimeSlot_handler}
    //             />
    //         </Grid>
    //     );
    // } else {
    //     return <Grid item className={props.d_index == 6 ? classes.Item_LastDay : classes.Item_Day}></Grid>;
    // }

    //New revamp from above
    return (
        <>
            {props.d_index == 0 ? <Calendar_TimeSlot timeSlot={startTime + " - " + endTime} /> : null}
            {
                <Grid
                    item
                    className={props.d_index == 6 || props.d_index == 0 ? classes.Item_LastDay : classes.Item_Day}
                >
                    {props.day.bookings.length ? (
                        <Calendar_TimeSlot_Button
                            day={props.day}
                            onClick_timeSlot_handler={props.onClick_timeSlot_handler}
                            onClick_moreTimeSlot_handler={props.onClick_moreTimeSlot_handler}
                        />
                    ) : null}
                </Grid>
            }
        </>
    );
}

function Calendar_TimeSlot_Button(props) {
    const classes = useStyles();
    const intl = useIntl();
    let hasMoreButton = false;

    return props.day.bookings.map((booking, b_index) => {
        let borderColor = classes.Waiting;
        const status = booking.status;

        if (status == "REJECTED") {
            borderColor = classes.Reject;
        } else if (status == "WAITING_FOR_APPROVAL") {
            borderColor = classes.Waiting;
        } else if (status == "APPROVED") {
            borderColor = classes.Approve;
        } else if (status == "CANCELLED") {
            borderColor = classes.Cancel;
        }

        if (b_index < 4) {
            return (
                <button
                    className={`${classes.TimeSlotBtn} ${borderColor}`}
                    onClick={() => props.onClick_timeSlot_handler(booking, props.day.timeSlot)}
                >
                    <span>{booking.userName}</span>
                </button>
            );
        } else {
            borderColor = classes.More;
            const moreText1 = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.017a" });
            const moreText2 = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.017b" });
            const _length = props.day?.bookings?.length;

            const _bookings = props.day?.bookings?.slice(4, _length);
            const _timeSlot = props.day?.timeSlot;
            const obj = {
                bookings: _bookings,
                timeSlot: _timeSlot,
            };

            if (hasMoreButton) {
                return "";
            } else {
                hasMoreButton = true;
                return (
                    <button
                        className={`${classes.TimeSlotBtn} ${borderColor}`}
                        onClick={() => props.onClick_moreTimeSlot_handler(obj)}
                    >
                        <span>{moreText1 + " " + `${_length - 4}` + " " + moreText2}</span>
                    </button>
                );
            }
        }
    });
}

function Popover_TimeSlot_Button(props) {
    const classes = useStyles();
    const intl = useIntl();

    // return props.bookings.map((booking, b_index) => {
    let borderColor = classes.Waiting;
    const status = props.booking.status;

    if (status == "REJECTED") {
        borderColor = classes.Reject;
    } else if (status == "WAITING_FOR_APPROVAL") {
        borderColor = classes.Waiting;
    } else if (status == "APPROVED") {
        borderColor = classes.Approve;
    } else if (status == "CANCELLED") {
        borderColor = classes.Cancel;
    }

    return (
        <Grid item xs={6} className={classes.MoreTimeSlotBtn_Wrapper}>
            <button
                className={`${classes.TimeSlotBtn} ${borderColor}`}
                onClick={() => props.onClick_timeSlot_handler(props.booking, props.timeSlot)}
            >
                <span>{props.booking.userName}</span>
            </button>
        </Grid>
    );

    // });
}

function Calendar_TimeSlot(props) {
    const classes = useStyles();
    return (
        <Grid item className={classes.Item_TimeSlot}>
            <div className={classes.TimeRange}>{props.timeSlot}</div>
        </Grid>
    );
}

function ApprovalPage(props) {
    const intl = useIntl();
    const classes = useStyles();
    // const [editSkuStatus, setEditSkuStatus] = useState<EditSkuStatus>(EditSkuStatusInit);
    const [editSkuStatus, setEdiSkuStatus] = useState<boolean[]>([]);
    //console.log(props);
    const [remarkWord, setRemarkWord] = useState<string>("");
    const [openStatus, setOpenStatus] = useState<boolean>(false);
    const [deleteSku, setDeleteSku] = useState<boolean>(false);
    const [deleteIndex, setDeleteIndex] = useState(0);
    const [statusDisplayText, setStatusDisplayText] = useState<string>("");
    const [editRemarkWord, setEditRemarkWord] = useState<string>("");
    const [editRemark, setEditRemark] = useState(false);
    const [skuidList, setSkuidList] = useState<string[]>([]);
    const [addSkuItem, setAddSkuItem] = useState("");
    const [addSkuStatus, setAddSkuStatus] = useState(false);
    const [approvedChange, setApprovedChange] = useState(false);
    const [leavePage, setLeavePage] = useState(false);
    const [saveStatus, setSaveStatus] = useState<boolean>();
    const [changes, setChanges] = useState(true);
    const [popUpBufferTime_Dialog, setPopUpBufferTime_Dialog] = useState(false);
    const [openValidateError, setOpenValidateError] = useState(false);
    const [errMsg, setErrMsg] = useState("general.1.002");
    // const [selected_bufferTime, setSelected_bufferTime] = useState("placeholder");

    const [rejectTimeSlot, setRejectTimeSlot] = useState<RejectTimeSlotStatus>(rejectDialogInitState);
    const [selected_rejectReason, setRejectReason] = useState<string>("placeholder");

    const [cancelTimeSlot, setCancelTimeSlot] = useState<CancelTimeSlotStatus>(cancelDialogInitState);
    const [selected_cancelReason, setCancelReason] = useState<string>("placeholder");
    const publisher = props.approvalPageData?.booking?.userName;
    const bookingDate = props.approvalPageData?.booking?.localDate;
    const bookingTime =
        UtilFunctions.getFormattedTime(props.approvalPageData?.timeSlot?.startTime) +
        " - " +
        UtilFunctions.getFormattedTime(props.approvalPageData?.timeSlot?.endTime);
    const apiService = useContext(ApiServiceContext);
    const device = useContext(RwdContext);

    let createTime = props.approvalPageData?.booking?.createTime;

    const bookingID = props.approvalPageData?.booking?._id;

    const [singleBookingData, setSingleBookingData] = useState<any>();
    const [bookingStatus, setBookingStatus] = useState<string>(props.approvalPageData?.booking?.status);
    const [isSubmitting, setIsSubmitting] = useState<string>("");

    const getSingleBooking = (_id: any) => {
        apiService
            .getSingleBooking(_id)
            .then((res) => {
                if (res.data?.status == "success") {
                    setSingleBookingData(res.data?.data);
                    setRemarkWord(res.data?.data?.remarks);
                    if (res.data?.data?.remarks == "") {
                        setEditRemarkWord("Null");
                    }
                    setSkuidList(res.data?.data?.skuid);
                } else {
                    console.log("fetch single booking fail");
                    if (res.data?.data?.status) {
                        setBookingStatus(res.data?.data?.status);
                    }
                }
            })
            .catch((err) => {
                console.warn(err);
                console.log("fetch single booking fail");
                setStatusDisplayText("找不到記錄 請聯絡相關技術人員");
                setOpenStatus(true);
            });
    };
    useEffect(() => {
        getSingleBooking(bookingID);
    }, []);
    const backToCalendar = () => {
        props.isApprovalPage_handler();
        props.refreshApiData();
    };

    if (createTime) {
        createTime = UtilFunctions.getFormattedCreateTime(createTime);
    }

    let approve_obj = {
        bookingID: props.approvalPageData?.booking?._id,
        status: "APPROVED",
        // bufferTime: selected_bufferTime,
    };

    let rejected_obj = {
        bookingID: props.approvalPageData?.booking?._id,
        status: "REJECTED",
        rejectCode: selected_rejectReason,
        rejectText: selected_rejectReason,
    };

    let cancelled_obj = {
        bookingID: props.approvalPageData?.booking?._id,
        status: "CANCELLED",
        cancelCode: selected_rejectReason,
        cancelText: selected_rejectReason,
    };

    const changeBookingStatus_handler = (obj: any) => {
        if (obj.status) {
            setIsSubmitting(obj.status);
        }
        apiService
            .postBookingStatus(obj)
            .then((res) => {
                if (res.data?.status == "success" && res.data?.data?.status == "APPROVED") {
                    setStatusDisplayText(intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.04" }));
                    setOpenStatus(true);
                    setPopUpBufferTime_Dialog(false);
                    setBookingStatus(res.data?.data?.status);
                    submitNewData(bookingID);
                    setSingleBookingData(res?.data?.data);
                } else if (res.data?.status == "success" && res.data?.data?.status == "CANCELLED") {
                    setStatusDisplayText("已取消時段");
                    setOpenStatus(true);
                    setCancelTimeSlot(cancelDialogInitState);
                    setBookingStatus(res.data?.data?.status);
                    setSingleBookingData(res?.data?.data);
                } else if (res.data?.status == "success" && res.data?.data?.status == "REJECTED") {
                    setStatusDisplayText("已拒絕審批");
                    setOpenStatus(true);
                    setRejectTimeSlot(rejectDialogInitState);
                    setBookingStatus(res.data?.data?.status);
                    setSingleBookingData(res?.data?.data);
                } else if (
                    res.data?.status == "fail" &&
                    res.data?.code == "APPROVED_BOOKINGS_QUOTA_FOR_TIMESLOT_REACHED"
                ) {
                    setStatusDisplayText("不能更改審批 時段內已審批的預約多於10個");
                    setOpenStatus(true);
                } else if (res.data?.status == "fail" && res.data?.code == "TIME_FRAME_MAX_BOOKING_REACHED") {
                    setStatusDisplayText("不能更改審批 時段內已審批的預約多於10個");
                    setOpenStatus(true);
                } else {
                    throw new Error("Error Unknown");
                }

                //setSingleBookingData(res?.data?.data);
            })
            .catch((err) => {
                console.warn(err);
                console.log("Fail to change status");
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => setIsSubmitting(""));
    };
    const submitNewData = (id: any) => {
        const data = {
            skuid: skuidList,
            remarks: remarkWord,
        };
        setIsSubmitting("CHANGED");
        apiService
            .putSkuIdAdmin(data, bookingID)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setSaveStatus(true);
                } else {
                    throw new Error("Error Unknown");
                }
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setIsSubmitting("");
            });
    };
    const copyToClipboard = (order: number) => {
        const copyText: any = document.getElementById(`copyLink${order}`);
        const textArea: any = document.createElement("textarea");
        textArea.value = process.env.REACT_APP_HYBRIS_DOMAIN + "/p/" + copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();

        setStatusDisplayText(intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.001.18" }));
        setOpenStatus(true);
    };

    const rejectPopUp_handler = () => {
        setRejectTimeSlot({ _id: "", openStatus: true });
    };

    const cancelPopUp_handler = () => {
        setCancelTimeSlot({ _id: "", openStatus: true });
    };
    const RejectTimeSlot_DropdownList = React.useMemo(
        () => (
            <Grid container justify="space-between">
                <Grid item xs={device == "PC" ? 12 : 10}>
                    <SelectInputWithLabel
                        placeholder={intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.037" })}
                        options={rejectReasonsList}
                        value={selected_rejectReason}
                        typeTwoUI={true}
                        onChange={(val) => {
                            setRejectReason(val);
                        }}
                    />
                </Grid>
            </Grid>
        ),
        [selected_rejectReason]
    );

    const CancelTimeSlot_DropdownList = React.useMemo(
        () => (
            <Grid container justify="space-between">
                <Grid item xs={device == "PC" ? 12 : 10}>
                    <SelectInputWithLabel
                        placeholder={intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.037" })}
                        options={cancelReasonsList}
                        value={selected_cancelReason}
                        typeTwoUI={true}
                        onChange={(val) => {
                            setCancelReason(val);
                        }}
                    />
                </Grid>
            </Grid>
        ),
        [selected_cancelReason]
    );

    const approve_handler = () => {
        if (approve_obj?.bookingID && approve_obj?.status) {
            changeBookingStatus_handler(approve_obj);
        } else {
            console.log("approve_obj not completed");
        }
    };

    const reject_handler = () => {
        rejected_obj.rejectCode = selected_rejectReason;
        rejected_obj.rejectText = selected_rejectReason;

        if (rejected_obj.rejectCode == "placeholder" || rejected_obj.rejectText == "placeholder") {
            setStatusDisplayText("請選擇拒絕審批原因");
            setOpenStatus(true);
        } else {
            changeBookingStatus_handler(rejected_obj);
        }
    };

    const cancel_handler = () => {
        cancelled_obj.cancelCode = selected_cancelReason;
        cancelled_obj.cancelText = selected_cancelReason;

        if (cancelled_obj.cancelCode == "placeholder" || cancelled_obj.cancelText == "placeholder") {
            setStatusDisplayText("請選擇取消審批原因");
            setOpenStatus(true);
        } else {
            changeBookingStatus_handler(cancelled_obj);
        }
    };
    const delete_sku_handle = (id: number) => {
        let skuList = skuidList;
        skuidList.splice(id, 1);
        setSkuidList(skuList);
        setDeleteSku(!deleteSku);
        setSaveStatus(false);
    };
    const changeEditStatus = (i: number) => {
        let temp = editSkuStatus.map((status, index) => {
            if (index === i) {
                status = !status;
                return status;
            } else {
                return status;
            }
        });
        setEdiSkuStatus(temp);
        setSaveStatus(false);
    };
    const resetEditSkuStatus = () => {
        let temp = editSkuStatus.map((status) => {
            if (status == true) {
                status = false;
                return status;
            } else {
                return status;
            }
        });
        setEdiSkuStatus(temp);
    };
    const editSkuId = (sku: string, i: number) => {
        let temp = skuidList.map((item, index) => {
            if (index === i) {
                item = sku;
                return item;
            } else {
                return item;
            }
        });
        setSkuidList(temp);
        setSaveStatus(false);
    };
    const addSkuId = (id: string) => {
        if (id !== "" && id != null) {
            let tempSkuId = skuidList;
            let tempEditSkuStatus = editSkuStatus;
            tempSkuId.splice(skuidList.length + 1, 0, id);
            tempEditSkuStatus.splice(tempEditSkuStatus.length + 1, 0, false);
            setSkuidList(tempSkuId);
            setAddSkuItem("");
            setSaveStatus(false);
        }
    };
    const confirmChangeData = (id: any) => {
        submitNewData(bookingID);
    };
    useEffect(() => {
        if ((bookingStatus == "APPROVED" || bookingStatus == "WAITING_FOR_APPROVAL") && saveStatus == false) {
            setLeavePage(true);
            setChanges(false);
        }
        return () => {
            setLeavePage(false);
            setChanges(true);
        };
    }, [[skuidList], remarkWord]);
    return (
        <>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <div style={device == "PC" ? {} : { width: "100%", height: "100%" }}>
                <Grid container xs={12} justify="space-between" alignItems="center">
                    <span
                        onClick={() => backToCalendar()}
                        style={{ cursor: "pointer", position: "relative" }}
                        className="title"
                    >
                        <BackIcon className={classes.backIcon} />
                        <span
                            style={
                                device == "PC" ? {} : { fontSize: "18px", fontWeight: "bold", verticalAlign: "bottom" }
                            }
                        >
                            {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.018" })}
                        </span>
                    </span>
                </Grid>
                <div className="ApprovalPage_PageContent pageContent" style={{ width: "100%" }}>
                    <Grid container justify={device == "PC" ? "flex-end" : "flex-start"}>
                        {bookingStatus == "WAITING_FOR_APPROVAL" && (
                            <>
                                <ConfirmChangeDataButton
                                    isSubmitting={isSubmitting}
                                    func={() => {
                                        resetEditSkuStatus();
                                        setEditRemark(false);
                                        setApprovedChange(!approvedChange);
                                        setEditRemarkWord(remarkWord);
                                    }}
                                    changes={changes}
                                />
                                <RejectButton
                                    text={"admin.allTimeSlotsCalendar.1.019"}
                                    func={() => rejectPopUp_handler()}
                                    status={bookingStatus}
                                />

                                {/* <ApproveButton
                                text={"admin.allTimeSlotsCalendar.1.02"}
                                func={() => setPopUpBufferTime_Dialog(true)}
                            /> */}
                                <ApproveButton
                                    isSubmitting={isSubmitting}
                                    text={"admin.allTimeSlotsCalendar.1.02"}
                                    func={() => {
                                        approve_handler();
                                    }}
                                />
                            </>
                        )}
                        {bookingStatus == "APPROVED" && (
                            <ConfirmChangeDataButton
                                isSubmitting={isSubmitting}
                                func={() => {
                                    resetEditSkuStatus();
                                    setEditRemark(false);
                                    setApprovedChange(!approvedChange);
                                    setEditRemarkWord(remarkWord);
                                }}
                                changes={changes}
                            />
                        )}
                        {bookingStatus == "APPROVED" && <CancelButton func={() => cancelPopUp_handler()} />}
                    </Grid>

                    <Grid container justify="flex-start" direction="column" className={classes.ApprovalContainer}>
                        {device === "mobile" && <Status status={bookingStatus} />}
                        <Grid item className={classes.ApprovalItems}>
                            <Typography variant="h6" className={classes.ApprovalItemsTitle}>
                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.021" })}
                            </Typography>
                            <span className={classes.ApprovalItemContent}>{createTime ? createTime : "N/A"}</span>
                            {device === "PC" && <Status status={bookingStatus} />}
                        </Grid>
                        <Grid item className={classes.ApprovalItems}>
                            <Typography variant="h6" className={classes.ApprovalItemsTitle}>
                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.022" })}
                            </Typography>
                            <span className={classes.ApprovalItemContent}>{publisher}</span>
                        </Grid>
                        {
                            <div>
                                <Grid item className={classes.ApprovalItems}>
                                    <Typography variant="h6" className={classes.ApprovalItemsTitle}>
                                        {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.028a" })}
                                    </Typography>
                                    <div
                                        className={
                                            singleBookingData?.streamInfo?.m3u8
                                                ? classes.LinkContent
                                                : classes.ApprovalItemContent
                                        }
                                    >
                                        {singleBookingData?.streamInfo?.m3u8
                                            ? singleBookingData?.streamInfo?.m3u8
                                            : "N/A"}
                                    </div>
                                </Grid>
                            </div>
                        }
                        {
                            <Grid item className={classes.ApprovalItems}>
                                <Typography variant="h6" className={classes.ApprovalItemsTitle}>
                                    {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.001.12" })}
                                </Typography>
                                <span
                                    className={
                                        singleBookingData?.streamInfo?.transcoderServer
                                            ? classes.LinkContent
                                            : classes.ApprovalItemContent
                                    }
                                >
                                    {singleBookingData?.streamInfo?.transcoderServer
                                        ? singleBookingData?.streamInfo?.transcoderServer
                                        : "N/A"}
                                </span>
                            </Grid>
                        }
                        {
                            <Grid item className={classes.ApprovalItems}>
                                <Typography variant="h6" className={classes.ApprovalItemsTitle}>
                                    {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.001.13" })}
                                </Typography>
                                <span
                                    className={
                                        singleBookingData?.streamInfo?.streamkey
                                            ? classes.LinkContent
                                            : classes.ApprovalItemContent
                                    }
                                >
                                    {singleBookingData?.streamInfo?.streamkey
                                        ? singleBookingData?.streamInfo?.streamkey
                                        : "N/A"}
                                </span>
                            </Grid>
                        }
                    </Grid>

                    <Grid container justify="flex-start" direction="column" className={classes.ApprovalContainer}>
                        <Grid item className={classes.ApprovalItems}>
                            <Typography variant="h6" className={classes.ApprovalItemsTitle}>
                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.001.16" })}
                            </Typography>
                            <span className={classes.ApprovalItemContent}>{bookingDate}</span>
                        </Grid>
                        <Grid item className={classes.ApprovalItems}>
                            <Typography variant="h6" className={classes.ApprovalItemsTitle}>
                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.001.17" })}
                            </Typography>
                            <span className={classes.ApprovalItemContent}>{bookingTime}</span>
                        </Grid>

                        {singleBookingData?.skuid &&
                            singleBookingData?.skuid.length > 0 &&
                            bookingStatus &&
                            skuidList.map((details, index) => {
                                editSkuStatus.length < skuidList.length && editSkuStatus.splice(index, 0, false);
                                const id = "copyLink" + index;
                                return (
                                    <Grid item className={`${classes.ApprovalItems} ${classes.skuItemsMap}`}>
                                        <Typography variant="h6" className={classes.ApprovalItemsTitle}>
                                            {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.001.11a" })}
                                        </Typography>
                                        {editSkuStatus[index] ? (
                                            <OutlinedInput
                                                className={classes.EditSkuInput}
                                                value={details}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    editSkuId ? editSkuId(e.target.value, index) : () => {}
                                                }
                                                id={id}
                                                key={index}
                                            />
                                        ) : (
                                            <span className={classes.ApprovalItemContent} id={id} key={index}>
                                                {details}
                                            </span>
                                        )}
                                        <div className={classes.threeButtons}>
                                            {(bookingStatus === "APPROVED" ||
                                                bookingStatus === "WAITING_FOR_APPROVAL") && (
                                                <>
                                                    <span
                                                        className={classes.EditButton}
                                                        onClick={() => changeEditStatus(index)}
                                                        key={index}
                                                    >
                                                        {intl.formatMessage({ id: "kol.allTimeSlotsCalendar.1.025.1" })}
                                                    </span>
                                                    <span
                                                        className={classes.DeleteButton}
                                                        onClick={() => {
                                                            setDeleteSku(!deleteSku);
                                                            setDeleteIndex(index);
                                                        }}
                                                    >
                                                        {intl.formatMessage({ id: "kol.allTimeSlotsCalendar.1.025.2" })}
                                                    </span>
                                                </>
                                            )}
                                            <span className={classes.CopyButton} onClick={() => copyToClipboard(index)}>
                                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.001.10" })}
                                            </span>
                                        </div>
                                    </Grid>
                                );
                            })}

                        {singleBookingData?.skuid &&
                            (bookingStatus === "APPROVED" || bookingStatus === "WAITING_FOR_APPROVAL") && (
                                <Grid>
                                    {addSkuStatus && skuidList.length < 30 && (
                                        <div className={`${classes.ApprovalItems} ${classes.addSkuStyle}`}>
                                            <Typography variant="h6" className={classes.ApprovalItemsTitle}>
                                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.001.11a" })}
                                            </Typography>
                                            <OutlinedInput
                                                className={classes.EditSkuInput}
                                                value={addSkuItem}
                                                onChange={(e) => setAddSkuItem(e.target.value)}
                                            />
                                            <div className={classes.twoButtons}>
                                                <Button
                                                    className={classes.AddSkuButton}
                                                    onClick={() => {
                                                        addSkuId(addSkuItem);
                                                        setAddSkuStatus(false);
                                                    }}
                                                >
                                                    {intl.formatMessage({ id: "general.1.001" })}
                                                </Button>
                                                <span
                                                    className={classes.AddCancelButton}
                                                    onClick={() => setAddSkuStatus(false)}
                                                >
                                                    {intl.formatMessage({ id: "general.1.004" })}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <Button
                                        variant="contained"
                                        className={classes.addSkuButton}
                                        onClick={() => setAddSkuStatus(true)}
                                        startIcon={<AddArticle />}
                                        disabled={skuidList.length == 30}
                                        style={{ color: "#ffffff" }}
                                    >
                                        {intl.formatMessage({ id: "kol.allTimeSlotsCalendar.1.025.3" })}
                                    </Button>
                                </Grid>
                            )}
                        <Grid item className={`${classes.ApprovalItems} ${classes.remarkStyle}`}>
                            <Typography variant="h6" className={classes.ApprovalItemsTitle}>
                                {intl.formatMessage({ id: "editor.liveBooking.1.018" })}
                            </Typography>
                            {editRemark ? (
                                <OutlinedInput
                                    className={classes.EditSkuInput}
                                    onChange={(e) => (setRemarkWord ? setRemarkWord(e.target.value) : () => {})}
                                    value={remarkWord}
                                />
                            ) : (
                                <span className={classes.ApprovalItemContent}>{remarkWord ? remarkWord : "N/A"}</span>
                            )}
                            <div className={classes.remarkEditButtonsStyle}>
                                {(bookingStatus === "APPROVED" || bookingStatus === "WAITING_FOR_APPROVAL") && (
                                    <>
                                        <span
                                            className={classes.RemarkEditButton}
                                            onClick={() => {
                                                setEditRemark(!editRemark);
                                                setSaveStatus(false);
                                            }}
                                        >
                                            {editRemarkWord == "Null"
                                                ? intl.formatMessage({ id: "kol.allTimeSlotsCalendar.1.025.6" })
                                                : intl.formatMessage({ id: "kol.allTimeSlotsCalendar.1.025.1" })}
                                        </span>
                                        <span
                                            className={classes.RemarkDeleteButton}
                                            onClick={() => {
                                                setRemarkWord("");
                                                setEditRemarkWord("Null");
                                            }}
                                        >
                                            {intl.formatMessage({ id: "kol.allTimeSlotsCalendar.1.025.2" })}
                                        </span>
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <StatusPopup openStatus={openStatus} content={statusDisplayText} toggleDialog={setOpenStatus} />

                {/* <BufferTimeDialog
                popUpBufferTime_Dialog={popUpBufferTime_Dialog}
                setPopUpBufferTime_Dialog={() => setPopUpBufferTime_Dialog(!popUpBufferTime_Dialog)}
                confirmBufferTimeDialog={() => approve_handler()}
                bufferTimeForm={bufferTimeForm()}
            /> */}

                <ErrorDialog
                    title={intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.001.15" })}
                    content={RejectTimeSlot_DropdownList}
                    disabled={isSubmitting === "REJECTED"}
                    confirmBtnText={
                        isSubmitting === "REJECTED" ? (
                            <>
                                <span style={{ visibility: "hidden" }}>{props.confirmBtnText}</span>
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: BtnLoadingAnimation,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                    width={40}
                                    height={29}
                                    style={{ position: "absolute" }}
                                />
                            </>
                        ) : (
                            intl.formatMessage({ id: "admin.editTimeSlots.1.053" })
                        )
                    }
                    confirmHandler={() => {
                        reject_handler();
                        resetEditSkuStatus();
                    }}
                    cancelBtnText={intl.formatMessage({ id: "admin.editTimeSlots.1.052" })}
                    cancelHandler={() => setRejectTimeSlot(rejectDialogInitState)}
                    openStatus={rejectTimeSlot.openStatus}
                    toggleDialog={() => setRejectTimeSlot(rejectDialogInitState)}
                />

                <ErrorDialog
                    title={intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.029" })}
                    content={CancelTimeSlot_DropdownList}
                    disabled={isSubmitting === "CANCELLED"}
                    confirmBtnText={intl.formatMessage({ id: "admin.editTimeSlots.1.053" })}
                    confirmHandler={() => cancel_handler()}
                    cancelBtnText={intl.formatMessage({ id: "admin.editTimeSlots.1.052" })}
                    cancelHandler={() => setCancelTimeSlot(cancelDialogInitState)}
                    openStatus={cancelTimeSlot.openStatus}
                    toggleDialog={() => setCancelTimeSlot(cancelDialogInitState)}
                />
                <ErrorDialog
                    title={intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.016.others" })}
                    content={intl.formatMessage({ id: "kol.allTimeSlotsCalendar.1.025.4" })}
                    confirmBtnText={intl.formatMessage({ id: "editor.allPosts.4.037" })}
                    confirmHandler={() => delete_sku_handle(deleteIndex)}
                    cancelBtnText={intl.formatMessage({ id: "editor.allPosts.4.036" })}
                    cancelHandler={() => setDeleteSku(!deleteSku)}
                    openStatus={deleteSku}
                    toggleDialog={() => setDeleteSku(!deleteSku)}
                />
                <ConfirmSaveDataDialog
                    title={intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.016.others" })}
                    content={intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.028.2" })}
                    confirmBtnText={intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.016" })}
                    confirmHandler={() => {
                        confirmChangeData(bookingID);
                        setApprovedChange(!approvedChange);
                    }}
                    cancelBtnText={intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.041" })}
                    cancelHandler={() => setApprovedChange(!approvedChange)}
                    openStatus={approvedChange}
                    toggleDialog={() => setApprovedChange(!approvedChange)}
                />
                <LeavePageAlert leaveStatus={leavePage} />
            </div>
        </>
    );
}

const Status = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const device = useContext(RwdContext);
    let statusText;
    let statusCircleColor;

    if (props.status == "WAITING_FOR_APPROVAL") {
        statusText = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.012" });
        statusCircleColor = "#0076ff";
    } else if (props.status == "REJECTED") {
        statusText = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.015" });
        statusCircleColor = "#d0021b";
    } else if (props.status == "APPROVED") {
        statusText = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.013" });
        statusCircleColor = "#22ab39";
    } else if (props.status == "CANCELLED") {
        statusText = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.014" });
        statusCircleColor = "#ff8d00";
    }

    return (
        <div className={device === "PC" ? classes.StatusWrapper : classes.mobileStatusWrapper}>
            <span className={classes.StatusCircle} style={{ backgroundColor: statusCircleColor }} />
            <span className={classes.StatusText}>{statusText}</span>
        </div>
    );
};
const LeavePageAlert = ({ leaveStatus }) => {
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(leaveStatus);
    const { formatMessage } = useIntl();

    return (
        <ErrorDialog
            title={formatMessage({ id: "editor.allPosts.4.042" })}
            content={formatMessage({ id: "4.043" })}
            confirmBtnText={formatMessage({ id: "editor.allPosts.4.044" })}
            cancelBtnText={formatMessage({ id: "4.048" })}
            confirmHandler={confirmNavigation as () => void}
            cancelHandler={cancelNavigation as () => void}
            openStatus={showPrompt as boolean}
            toggleDialog={cancelNavigation as () => void}
        />
    );
};
const rejectReasonsList = [
    {
        name: "預約時間已滿，無法通過審核",
        value: "FULL_BOOKING",
    },
    {
        name: "其他",
        value: "OTHERS",
    },
];

const cancelReasonsList = [
    {
        name: "預約時間已滿，無法通過審核",
        value: "FULL_BOOKING",
    },
    {
        name: "其他",
        value: "OTHERS",
    },
];

const dayNameMapping = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

export default AllTimeSlotsCalendarPage;
