import React, { useContext } from "react";
import { Grid, createStyles, makeStyles, Theme, ButtonGroup, Button, Box, Typography } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import rightArrow from "../assets/admin/ic/rightArrow.svg";
import { useIntl } from "react-intl";
import { StoreInfoContext, ChooseStoreStatusContext } from "../App";
import HKTVmallLogo from "../assets/HKTVmall.svg";
import UserRoleNotFoundPage from "./../pages/UserRoleNotFoundPage";
import { UserInfoContext } from "../App";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageColor: {
            backgroundColor: "white",
        },

        imgSet: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            position: "relative",
            marginBottom: "20px",
        },
        logoSet: {
            marginBottom: "0",
            paddingBottom: "0",
        },
        shopSet: {
            color: "rgba(0,0,0,0.54)",
            fontSize: "1.6rem",
            lineHeight: 1.1,
            align: "left",
            float: "left",
            clear: "both",
            marginBottom: "5px",
            textTransform: "capitalize",
            wordBreak: "break-all",
        },
        codeSet: {
            fontSize: "1rem",
            align: "left",
            float: "left",
            clear: "both",
            textTransform: "capitalize",
            color: "rgba(0,0,0,0.24)",
        },
        chooseText: {
            color: "#3a993a",
            fontWeight: "bold",
            height: "24px",
            marginTop: "0",
            paddingTop: "0",
            position: "absolute",
            top: "68%",
        },
        btSet: {
            backgroundColor: "white",
            color: "gray",
            marginBottom: "20px",
            justifyContent: "space-between",
        },
        centerSet: {
            color: "rgba(34,34,34,0.6)",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
        },
    })
);
export function StoreListPage() {
    const classes = useStyles();
    const intl = useIntl();
    const storeInfo = useContext(StoreInfoContext);
    const userInfo = useContext(UserInfoContext);
    const [chooseStoreStatus, setChooseStoreStatus] = useContext(ChooseStoreStatusContext);
    const ArrowIcon = <img src={rightArrow} alt="right arrow"></img>;
    let stores = storeInfo?.storesDetails;

    const buttonList = stores.map((store, index) => (
        <Button
            style={{ backgroundColor: "#ffffff", boxShadow: "0 2px 4px 0 rgba(0,0,0,0.12)", borderRadius: "4px" }}
            key={index}
            fullWidth
            onClick={() =>
                setChooseStoreStatus({
                    status: !chooseStoreStatus.status,
                    shopCode: store.code,
                    shopName: store?.zh?.name,
                })
            }
            className={classes.btSet}
            variant="contained"
            endIcon={ArrowIcon}
            component={Link}
            to="/edit-app-page"
        >
            <div>
                <p className={classes.shopSet}>{store.zh?.name}</p>
                <p className={classes.codeSet}>Store Code: {store.code}</p>
            </div>
        </Button>
    ));
    const storeLengthfix = (size: number) => {
        let action;

        switch (size) {
            case 0:
                action = <UserRoleNotFoundPage text={intl.formatMessage({ id: "text_608a1f2b06af3400b429006a" })} />;
                return action;
            case 1:
                action = (
                    <div>
                        {setChooseStoreStatus({ status: !chooseStoreStatus.status, shopCode: stores[0].code })}
                        <Redirect to="edit-app-page" />
                    </div>
                );
                return action;
            default:
                action = (
                    <ButtonGroup orientation="vertical" color="primary" style={{ width: "40vh" }}>
                        {buttonList}
                    </ButtonGroup>
                );
                return action;
        }
    };
    return (
        <React.Fragment>
            {stores.length === 0 ? (
                userInfo.roles?.length === 1 ? (
                    <UserRoleNotFoundPage text={intl.formatMessage({ id: "text_608a1f2b06af3400b429006a" })} />
                ) : (
                    // Multi-role layout
                    <Redirect to="/no-store-found" />
                )
            ) : (
                <Grid
                    className={classes.pageColor}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: "100vh" }}
                >
                    <Grid item sm={12} xs={6}>
                        <Box className={classes.imgSet}>
                            <img className={classes.logoSet} src={HKTVmallLogo} alt="HKTVmall logo"></img>
                            <h2 className={classes.chooseText}>
                                {intl.formatMessage({ id: "text_608a1f2b06af3400b4290072" })}
                            </h2>
                        </Box>
                        <div className={classes.centerSet}>
                            <h4 style={{ fontSize: "20px", marginBottom: "5px" }}>
                                {stores.length ? intl.formatMessage({ id: "text_608a1f2b06af3400b429006e" }) : ""}
                            </h4>
                        </div>
                        {storeLengthfix(stores.length)}
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}

export default StoreListPage;
