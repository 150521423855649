import {
    Box,
    createStyles,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { UserInfoContext, SidebarHiddenContext, RwdContext } from "../App";
import { ReactComponent as LiveG } from "../assets/admin/menu_ic/LiveG.svg";
import { ReactComponent as RightArrow } from "../assets/admin/ic/rightArrow.svg";
import { ReactComponent as LiveB } from "../assets/admin/menu_ic/LiveBlack.svg";
import { ReactComponent as AllPostsB } from "../assets/admin/menu_ic/AllPostsBlack.svg";
import { ReactComponent as AllPostsG } from "../assets/admin/menu_ic/AllPostsG.svg";
import { ReactComponent as CategoryB } from "../assets/admin/menu_ic/categoryBlack.svg";
import { ReactComponent as CategoryG } from "../assets/admin/menu_ic/categoryG.svg";
import { ReactComponent as DashboardB } from "../assets/admin/menu_ic/DashboardBlack.svg";
import { ReactComponent as DashboardG } from "../assets/admin/menu_ic/DashboardG.svg";
import { ReactComponent as CreatePostB } from "../assets/editor/MENU_ic/createBlack.svg";
import { ReactComponent as CreatePostG } from "../assets/editor/MENU_ic/createG.svg";
import { ReactComponent as ProfileB } from "../assets/editor/MENU_ic/profileBlack.svg";
import { ReactComponent as ProfileG } from "../assets/editor/MENU_ic/profileG.svg";
import { RouteItem } from "../interfaces";
import { ROLE_TYPE, RoutePath } from "../interfaces/enums";
import { useIntl } from "react-intl";
import { OAuth2Login } from "../utils/OAuth2Login";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: 256,
            flexShrink: 0,
            paddingTop: 256,
        },
        active: {
            color: "#118623",
            backgroundColor: "#bde5c3",
            "&:hover": {
                backgroundColor: "#bde5c3",
            },
        },
        drawerPaper: {
            width: 256,
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.5)",
        },
        drawerContainer: {
            overflow: "auto",
            marginBottom: "auto",
        },
        placeholder: {
            height: 88,
        },
        clearPadding: {
            padding: 0,
        },
        sideBarIc: {
            "& svg": {
                width: 32,
                height: 32,
            },
        },
        divider: {
            width: "90%",
            height: 1,
            marginLeft: "5%",
            backgroundColor: "#3a993a",
        },
        mobileDrawer: {
            flexShrink: 0,
            width: "auto",
            height: "100vh",
        },
        mobileDrawerPaper: {
            width: "100%",
            height: "100vh",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.5)",
        },
        mobilePlaceholder: {
            minHeight: 84,
        },
        mobiletext: {
            position: "relative",
            width: "80%",
            border: "solid 1px #22ab39",
            borderRadius: "6px",
            bottom: 0,
            left: "10%",
            lineHeight: "36px",
            fontSize: 24,
            marginBottom: "20px",
            textAlign: "center",
            "& .logout": {
                fontSize: 16,
                color: "#22ab39",
            },
        },
        barStyle: {
            borderLeft: "4px solid #3a993a",
        },
    })
);

interface RouteItemWithIc extends RouteItem {
    stringId: string;
    ic: React.ReactElement;
    icActive: React.ReactElement;
    includes?: RoutePath[];
}

export const SidebarItems: RouteItemWithIc[] = [
    {
        stringId: "我的資料",
        to: RoutePath.MY_ACCOUNT,
        ic: <ProfileB />,
        icActive: <ProfileG />,
        activateRoles: [ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.PUBLIC, ROLE_TYPE.HOST],
    },
    {
        stringId: "圖表", //unclear copy-table
        to: RoutePath.DASHBOARD,
        ic: <DashboardB />,
        icActive: <DashboardG />,
        activateRoles: [ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.ADMIN, ROLE_TYPE.SUPERFOLLOW_ADMIN],
    },
    {
        stringId: "新增文章",
        to: RoutePath.NEW_ARTICLE,
        ic: <CreatePostB />,
        icActive: <CreatePostG />,
        activateRoles: [ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.PUBLIC],
    },
    {
        stringId: "所有文章",
        to: RoutePath.ARTICLES,
        ic: <AllPostsB />,
        icActive: <AllPostsG />,
        includes: [RoutePath.ARTICLES, RoutePath.APPROVE_ARTICLE, RoutePath.EDIT_ARTICLE],
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.PUBLIC, ROLE_TYPE.SUPERFOLLOW_ADMIN],
    },
    {
        stringId: "分類",
        to: RoutePath.CATEGORY,
        ic: <CategoryB />,
        icActive: <CategoryG />,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.SUPERFOLLOW_ADMIN],
    },
    {
        stringId: "預約時段",
        to: RoutePath.LIVE_BOOKING_MENU,
        ic: <LiveB />,
        icActive: <LiveG />,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.LIVE_ADMIN],
    },
    {
        stringId: "新增/修改時段",
        to: RoutePath.EDIT_TIME_SLOTS,
        ic: <React.Fragment />,
        icActive: <React.Fragment />,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.LIVE_ADMIN],
    },
    {
        stringId: "所有預約時段",
        to: RoutePath.ALL_TIME_SLOTS,
        ic: <React.Fragment />,
        icActive: <React.Fragment />,
        activateRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.LIVE_ADMIN],
    },
    {
        stringId: "直播",
        to: RoutePath.LIVE_BOOKING_EDITOR_MENU,
        ic: <LiveB />,
        icActive: <LiveG />,
        activateRoles: [ROLE_TYPE.HOST],
    },
    {
        stringId: "預約時段",
        to: RoutePath.LIVE_BOOKING,
        ic: <React.Fragment />,
        icActive: <React.Fragment />,
        activateRoles: [ROLE_TYPE.HOST, ROLE_TYPE.ADMIN, ROLE_TYPE.LIVE_ADMIN],
    },
    {
        stringId: "預約紀錄",
        to: RoutePath.LIVE_BOOKING_HISTORY,
        ic: <React.Fragment />,
        icActive: <React.Fragment />,
        activateRoles: [ROLE_TYPE.HOST],
    },
    {
        stringId: "Edit app storefront",
        to: RoutePath.EDIT_APP_PAGE,
        ic: <React.Fragment />,
        icActive: <React.Fragment />,
        activateRoles: [ROLE_TYPE.SHOP_IN_SHOP],
    },
    {
        stringId: "Manage Dynamic Banner",
        to: RoutePath.MANAGE_BANNER,
        ic: <React.Fragment />,
        icActive: <React.Fragment />,
        includes: [RoutePath.MANAGE_BANNER],
        activateRoles: [ROLE_TYPE.SHOP_IN_SHOP],
    },
];

interface ListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
    index: number;
    hidden?: boolean;
}

function ListItemLink(props: ListItemLinkProps) {
    const { icon, primary, to, index } = props;
    const classes = useStyles();
    const noActiveStyleList = ["新增/修改時段", "所有預約時段", "預約時段", "預約紀錄"];
    const device = useContext(RwdContext);
    const { roles } = useContext(UserInfoContext);

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<any, "to">>((itemProps, ref) => (
                <NavLink
                    isActive={(match, location) =>
                        !!(match || SidebarItems[index].includes?.includes(location.pathname as RoutePath))
                    }
                    activeClassName={`${classes.active} ${
                        roles.includes(ROLE_TYPE.SHOP_IN_SHOP) ? classes.barStyle : ""
                    }`}
                    to={to}
                    ref={ref}
                    {...itemProps}
                />
            )),
        [to]
    );

    const noActiveStyle_RenderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<any, "to">>((itemProps, ref) => (
                <NavLink
                    isActive={(match, location) =>
                        !!(match || SidebarItems[index].includes?.includes(location.pathname as RoutePath))
                    }
                    activeClassName={""}
                    to={to}
                    ref={ref}
                    {...itemProps}
                />
            )),
        [to]
    );
    return (
        <li>
            <ListItem
                button
                component={noActiveStyleList.includes(primary) ? noActiveStyle_RenderLink : renderLink}
                disableTouchRipple
            >
                {roles.includes(ROLE_TYPE.SHOP_IN_SHOP) ? null : icon ? (
                    <ListItemIcon
                        classes={{
                            root: classes.sideBarIc,
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                ) : null}
                <ListItemText
                    primary={
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                            {primary}
                        </Typography>
                    }
                />
                <RightArrow style={device == "PC" ? { display: "none" } : {}} />
            </ListItem>
        </li>
    );
}

interface SidebarProps {
    children?: React.ReactNode;
    onSelect?: (e) => void;
    hidden?: boolean;
    hiddenSidebar?: (e: boolean) => void;
}

const Sidebar = (props: SidebarProps) => {
    const classes = useStyles();
    const location = useLocation();
    const { roles } = useContext(UserInfoContext);
    const [activeCode, setActiveCode] = useState<string>("");
    const intl = useIntl();
    const device = useContext(RwdContext);
    const [isSidebarHidden, setIsSidebarHidden] = useContext(SidebarHiddenContext);
    const elements = [
        RoutePath.EDIT_TIME_SLOTS,
        RoutePath.ALL_TIME_SLOTS,
        RoutePath.LIVE_BOOKING,
        RoutePath.LIVE_BOOKING_HISTORY,
    ];

    const init_LiveBookingSubMenu = () => {
        elements.forEach((item) => {
            const element = document.getElementById(item);
            if (element) {
                if (element.style.display !== "block") {
                    element.style.display = "none";
                }
                element.style.backgroundColor = "#e9ffec";
            }
        });
    };

    const collapse_LiveBookingSubMenu = () => {
        const subMenu = document.getElementById("/live-booking-menu");
        elements.forEach((item) => {
            const element = document.getElementById(item);
            if (element && subMenu) {
                if (element.style.display == "none") {
                    element.style.display = "block";
                    subMenu.style.backgroundColor = "#e9ffec";
                } else {
                    element.style.display = "none";
                    subMenu.style.backgroundColor = "unset";
                }
            }
        });
    };

    init_LiveBookingSubMenu();

    useEffect(() => {
        // let match = location.pathname.match(/[a-z-]+$/)?.[0];
        // if (match) setActiveCode(match);
        // else setActiveCode("");
        setActiveCode(location.pathname);
    }, [location]);
    return (
        <React.Fragment>
            <Drawer
                className={device == "PC" ? classes.drawer : classes.mobileDrawer}
                variant="permanent"
                classes={
                    device == "PC"
                        ? {
                              paper: classes.drawerPaper,
                          }
                        : { paper: classes.mobileDrawerPaper }
                }
                style={isSidebarHidden && device == "mobile" ? { display: "none" } : {}}
            >
                <div className={device == "PC" ? classes.placeholder : classes.mobilePlaceholder} />
                <div className={classes.drawerContainer}>
                    <List
                        classes={{
                            padding: classes.clearPadding,
                        }}
                    >
                        {SidebarItems.map((item, index) => {
                            let icon =
                                item.includes?.includes(activeCode as RoutePath) || activeCode === item.to
                                    ? item.icActive
                                    : item.ic;

                            let liveBookingSubMenu =
                                (item.to == RoutePath.LIVE_BOOKING_MENU &&
                                    item.stringId == intl.formatMessage({ id: "admin.editTimeSlots.1.001" })) ||
                                (item.to == RoutePath.LIVE_BOOKING_EDITOR_MENU &&
                                    item.stringId == intl.formatMessage({ id: "editor.liveBooking.1.001" }));

                            if (item.activateRoles) {
                                return item.activateRoles.some((o) => roles?.includes(o)) ? (
                                    <div
                                        key={index}
                                        id={liveBookingSubMenu ? "/live-booking-menu" : item.to}
                                        onClick={
                                            device == "PC"
                                                ? liveBookingSubMenu
                                                    ? () => {
                                                          collapse_LiveBookingSubMenu();
                                                      }
                                                    : () => {}
                                                : liveBookingSubMenu
                                                ? () => {
                                                      collapse_LiveBookingSubMenu();
                                                  }
                                                : () => {
                                                      setIsSidebarHidden && setIsSidebarHidden(!isSidebarHidden);
                                                  }
                                        }
                                    >
                                        <ListItemLink
                                            key={index}
                                            index={index}
                                            primary={item.stringId}
                                            icon={icon}
                                            to={item.to}
                                        />

                                        {!liveBookingSubMenu &&
                                            elements.includes(item.to) &&
                                            item.to !== RoutePath.LIVE_BOOKING_HISTORY && (
                                                <div className={classes.divider} />
                                            )}
                                    </div>
                                ) : null;
                            }
                            return (
                                <ListItemLink
                                    key={index}
                                    index={index}
                                    primary={item.stringId}
                                    icon={icon}
                                    to={item.to}
                                />
                            );
                        })}
                    </List>
                </div>
                <div
                    className={classes.mobiletext}
                    style={device == "PC" ? { display: "none" } : { cursor: "pointer", padding: "0 5px" }}
                >
                    <Box onClick={() => OAuth2Login.logout()}>
                        <span className="logout">{intl.formatMessage({ id: "mobile1.1" })}</span>
                    </Box>
                </div>
            </Drawer>
        </React.Fragment>
    );
};

export default Sidebar;
