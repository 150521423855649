import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, styled } from "@material-ui/core";
import { ReactComponent as CloseIcon } from "../../assets/store/ic_close.svg";
import { ReactComponent as WhiteCloseIcon } from "../../assets/store/ic_closeWhite.svg";
import { DialogButton, ErrorDialogButton, InvertedErrorDialogButton } from "../buttons/StyledButtons";

type ModuleDialogTitleProps = {
    children?: React.ReactNode;
    onClose: () => void;
};
type ModuleDialogProps = {
    open: boolean;
    onClose: () => void;
    title?: string;
    children?: React.ReactNode;
    submitText?: string;
    disableSubmit?: boolean;
    onSubmit?: () => void;
};

const StyledCloseIcon = styled(CloseIcon)({ cursor: "pointer" });
const StyledDialogContent = styled(DialogContent)({
    background: "#f1f1f1",
    padding: 0,
});
const StyledDialogTitle = styled(DialogTitle)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    fontSize: 16,
    lineHeight: 1.14,
    color: "rgba(34, 34, 34, 0.6)",
    fontWeight: "bold",
});

const ModuleDialogTitle = (props: ModuleDialogTitleProps) => (
    <StyledDialogTitle disableTypography>
        <Box component="div" display="inline">
            {props.children}
        </Box>
        <StyledCloseIcon onClick={() => props.onClose && props.onClose()} />
    </StyledDialogTitle>
);

export const ModuleDialog = (props: ModuleDialogProps) => {
    const { open, onClose, title, children, submitText, disableSubmit, onSubmit } = props;
    return (
        <Dialog open={open} onClose={onClose} maxWidth={false} transitionDuration={0}>
            {title && <ModuleDialogTitle onClose={onClose}>{title}</ModuleDialogTitle>}
            <StyledDialogContent dividers>{children}</StyledDialogContent>
            {submitText && (
                <DialogActions>
                    <DialogButton
                        onClick={() => (onSubmit ? onSubmit() : onClose && onClose())}
                        disabled={disableSubmit}
                        style={{ marginRight: 8 }}
                    >
                        {submitText}
                    </DialogButton>
                </DialogActions>
            )}
        </Dialog>
    );
};

type ModuleErrorDialogProps = {
    open: boolean;
    onClose: () => void;
    title?: string;
    children?: React.ReactNode;
    confirmText?: string;
    onConfirm?: () => void;
    cancelText?: string;
    onCancel?: () => void;
    confirmClose?: () => void;
};
const StyledWhiteCloseIcon = styled(WhiteCloseIcon)({ cursor: "pointer" });
const StyledErrorDialogTitle = styled(DialogTitle)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    backgroundColor: "#ff4c4c",
    fontSize: 20,
    fontWeight: "bold",
    color: "#ffffff",
});
const StyledErrorDialogContent = styled(DialogContent)({
    height: 70,
    padding: 16,
    color: "#222222",
});
const StyledErrorDialogActions = styled(DialogActions)({
    padding: "20px 10px 10px",
});
const ModuleErrorDialogTitle = (props: ModuleDialogTitleProps) => (
    <StyledErrorDialogTitle disableTypography>
        <Box display="inline">{props.children}</Box>
        <StyledWhiteCloseIcon onClick={() => props.onClose && props.onClose()} />
    </StyledErrorDialogTitle>
);
export const ModuleErrorDialog = (props: ModuleErrorDialogProps) => {
    const { open, onClose, title, children, confirmText, onConfirm, cancelText, onCancel, confirmClose } = props;
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"md"} transitionDuration={0}>
            {title && <ModuleErrorDialogTitle onClose={onClose}>{title}</ModuleErrorDialogTitle>}
            <StyledErrorDialogContent>{children}</StyledErrorDialogContent>
            {(confirmText || cancelText) && (
                <StyledErrorDialogActions>
                    {cancelText && (
                        <InvertedErrorDialogButton onClick={() => (onCancel ? onCancel() : onClose && onClose())}>
                            {cancelText}
                        </InvertedErrorDialogButton>
                    )}
                    {confirmText && (
                        <ErrorDialogButton
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                                onConfirm ? onConfirm() : onClose && onClose();
                                confirmClose && confirmClose();
                            }}
                        >
                            {confirmText}
                        </ErrorDialogButton>
                    )}
                </StyledErrorDialogActions>
            )}
        </Dialog>
    );
};
