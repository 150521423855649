import {
    Grid,
    Box,
    createStyles,
    Link,
    makeStyles,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    withStyles,
} from "@material-ui/core";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useImmer } from "use-immer";
import { ApiServiceContext, RwdContext } from "../App";
import { ReactComponent as ReasonIc } from "../assets/editor/ic/reason_big.svg";
import { ReactComponent as RemoveIc } from "../assets/editor/ic/remove.svg";
import {
    MODEL_article,
    MODEL_CategoryDetails,
    MODEL_RejectReason,
    MODEL_StatusCode,
    REQUEST_ContentProviderList,
} from "../interfaces";
import { ArticleStatus, RejectReasonCode } from "../interfaces/enums";
import { UtilFunctions } from "../utils/UtilFunctions";
import ArticleInGrid, { ArticleInGridData } from "./ArticleInGrid";
import CustomizeTablePagination, { Action, PaginationState } from "./CustomizeTablePagination";
import { ErrorDialog as ConfirmDialog } from "./dialogs/ErrorDialog";
import { Animated } from "react-animated-css";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../assets/animations/btn_loading_animation.json";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        head: {
            fontWeight: "bold",
            fontSize: "20px",
            whiteSpace: "nowrap",
        },
        head_m: {
            fontWeight: "bold",
            fontSize: "18px",
            whiteSpace: "nowrap",
        },
        errorRow: {
            backgroundColor: "#ffdfdf",
        },
        tableCell: {
            fontSize: "18px",
        },
        grid_wraper: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            minWidth: "828px",
            margin: "auto",
        },
        grid_wraper_m: {
            width: "auto",
            height: "auto",
            overflow: "auto",
        },
    })
);

type DialogStatus = {
    _articleId?: string;
    _route_properties?: { to: any; state: any; title: string; rejectReasonDisplayText: string };
    showDialog: boolean;
};

// 待審批 → 不能發佈 → 等待發佈 → 已發佈
const Priority: Partial<Record<ArticleStatus, number>> = {
    WAITING_FOR_APPROVAL: 1,
    PUBLISH_DENIED: 2,
    WAITING_FOR_PUBLISH: 3,
    PUBLISHED: 4,
};

interface ArticleListProps {
    admin: boolean;
    isGridView: boolean;
    filterCriteria?: string;
    getDropdownArticleData: (obj) => void;
    getDropdownCategoryData: (obj) => void;
    getDropdownStatusData: (obj) => void;
    filterUploadMonth: any;
    filterUpdateMonth: any;
    filterCategory: any;
    filterStatus: any;
}

interface ArticleData {
    id: string;
    data: string;
}

function ArticleList(props: ArticleListProps) {
    const intl = useIntl();
    const classes = useStyles();
    const device = useContext(RwdContext);
    const [isDeleting, setIsDeleting] = useState(false);
    const apiService = useContext(ApiServiceContext);
    const history = useHistory();
    const {
        admin: isAdmin,
        isGridView,
        filterCriteria,
        getDropdownArticleData,
        getDropdownCategoryData,
        getDropdownStatusData,
    } = props;
    const { filterUploadMonth, filterUpdateMonth, filterCategory, filterStatus } = props;
    const [fullData, setFullData] = React.useState<any>(null);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");
    const fetchArticleList = (notFirstRun?: boolean) => {
        apiService
            .getArticleList()
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    if (res.data?.data?.articleList) {
                        if (!!!notFirstRun) {
                            res.data.data?.categoryList && setCategoryList(res.data.data.categoryList);
                            res.data.data?.rejectReasonList && setRejectReasonList(res.data.data?.rejectReasonList);
                            res.data.data?.statusCodeList && setStatusCodeList(res.data.data?.statusCodeList);
                        }
                        setFullData(res.data.data?.articleList);
                        getDropdownArticleData(res.data.data?.articleList);
                        getDropdownCategoryData(res.data.data?.categoryList);
                        getDropdownStatusData(res.data.data?.statusCodeList);
                        if (
                            !filterStatus &&
                            !filterCategory &&
                            !filterUpdateMonth &&
                            !filterUploadMonth &&
                            !filterCriteria
                        )
                            setArticleList(
                                isAdmin
                                    ? _.orderBy(
                                          res.data.data?.articleList,
                                          [(article: MODEL_article) => Priority[article.statusCode]],
                                          ["asc"]
                                      )
                                    : res.data.data?.articleList
                            );
                    }
                } else throw new Error("Error Unknown");
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };

    const getDate = (publishDate) => {
        if (publishDate) {
            const date = UtilFunctions.getDateFromTimestamp(publishDate).substring(3).replace("/", "-");
            var temp = date.substring(3, 7) + "-" + date.substring(0, 2);
            return temp;
        } else return "-";
    };

    const getCategoryName = (list, filterCriteria) => {
        let temp_filter = categoryList.filter((category) => list.includes(category._id));
        return temp_filter.filter(
            (item) =>
                item?.zh?.name?.toLowerCase().includes(filterCriteria) ||
                item?.en?.name?.toLowerCase().includes(filterCriteria)
        );
    };

    const filterArticleListByFilters = () => {
        let newList = fullData;

        const filterArticleList = () => {
            if (filterCriteria && filterCriteria?.length > 0) {
                newList = newList.filter((name) => {
                    let retVal = getCategoryName(name.category, filterCriteria.toLowerCase());

                    if (
                        name?.en?.title.toLowerCase().includes(filterCriteria.toLowerCase()) ||
                        name?.zh?.title.toLowerCase().includes(filterCriteria.toLowerCase()) ||
                        retVal?.length > 0
                    )
                        return name;
                });
            }
        };
        const filterArticleListByUploadMonth = () => {
            if (filterUploadMonth && filterUploadMonth?.length > 0) {
                if (filterUploadMonth == "ALL_MONTH") {
                } else {
                    newList = newList.filter((article) => {
                        let date = getDate(article.publishDate);
                        if (date === filterUploadMonth) return article;
                    });
                }
            }
        };

        const filterArticleListByUpdateMonth = () => {
            if (filterUpdateMonth && filterUpdateMonth?.length > 0) {
                if (filterUpdateMonth == "ALL_MONTH") {
                } else {
                    newList = newList.filter((article) => {
                        let date = getDate(article.updateDate);
                        if (date === filterUpdateMonth) return article;
                    });
                }
            }
        };

        const filterArticleListByStatus = () => {
            let statusTextToBeShown;
            if (filterStatus && filterStatus?.length > 0) {
                if (filterStatus == "ALL_STATUS") {
                } else {
                    newList = newList.filter((article) => {
                        statusTextToBeShown = statusCodeListMap.get(article.statusCode as ArticleStatus)?.[intl.locale]
                            ?.statusCodeText;
                        if (statusTextToBeShown === filterStatus) return article;
                    });
                }
            }
        };
        const filterArticleListByCategory = () => {
            if (filterCategory && filterCategory?.length > 0) {
                if (filterCategory == "ALL_CATEGORY") {
                } else {
                    let categoryItem: Array<MODEL_CategoryDetails>;
                    categoryItem = categoryList.filter((val) => val.zh?.name === filterCategory);

                    newList = newList.filter((article) => {
                        if (
                            categoryItem?.filter((item) => article.category.includes(item._id)) &&
                            categoryItem?.filter((item) => article.category.includes(item._id))?.length > 0
                        )
                            return article;
                        else return null;
                    });
                }
            }
        };

        if (filterCategory.length > 0) filterArticleListByCategory();
        if (filterUploadMonth.length > 0) filterArticleListByUploadMonth();
        if (filterStatus.length > 0) filterArticleListByStatus();
        if (filterUpdateMonth.length > 0) filterArticleListByUpdateMonth();

        if (filterCriteria) filterArticleList();

        setArticleList(_.orderBy(newList, [(article: MODEL_article) => Priority[article.statusCode]], ["asc"]));
    };

    const fetchContentProviderList = () => {
        apiService
            .getAllContentProvider()
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setContentProviderList(res.data.data);
                } else throw new Error("Error Unknown");
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };

    const [articleList, setArticleList] = useState<MODEL_article[]>([]);
    const [contentProviderList, setContentProviderList] = useState<REQUEST_ContentProviderList[]>([]);
    const [pagedArticleList, setPagedArticleList] = useState<MODEL_article[]>([]);
    const [categoryList, setCategoryList] = useState<MODEL_CategoryDetails[]>([]);
    const [rejectReasonList, setRejectReasonList] = useState<MODEL_RejectReason[]>([]);
    const [statusCodeList, setStatusCodeList] = useState<MODEL_StatusCode[]>([]);

    const statusCodeListMap = useMemo(
        () =>
            statusCodeList.reduce((map, item) => {
                map.set(item.statusCode, { zh: item.zh, en: item.en });
                return map;
            }, new Map<ArticleStatus, Omit<MODEL_StatusCode, "statusCode">>()),
        [statusCodeList]
    );

    const rejectReasonListMap = useMemo(
        () =>
            rejectReasonList.reduce((map, item) => {
                const { zh, en } = item;
                map.set(item.rejectCode as RejectReasonCode, { zh, en });
                return map;
            }, new Map<RejectReasonCode, Omit<MODEL_RejectReason, "rejectCode">>()),
        [rejectReasonList]
    );

    const [paginationState, updatePaginationState] = useImmer<PaginationState>({
        curPage: 1,
        rowsPerPage: 10,
    });

    const [dialogStatus, updateDialogStatus] = useImmer<DialogStatus>({
        showDialog: false,
    });

    useEffect(() => {
        fetchArticleList();

        if (isAdmin) fetchContentProviderList();
    }, []);

    useEffect(() => {
        if (fullData && fullData.length >= 0) {
            filterArticleListByFilters();
        }
    }, [fullData]);

    useEffect(() => {
        filterArticleListByFilters();
    }, [filterCategory, filterStatus, filterUpdateMonth, filterUploadMonth, filterCriteria]);

    useEffect(() => {
        updatePaginationState((draft) => {
            if (draft.curPage !== 1) {
                if (draft.rowsPerPage === 10) {
                    // switch from list to grid view
                    draft.curPage = Math.floor((draft.curPage * 10) / 9);
                } else {
                    // Switch from Grid to list

                    if (draft.curPage > Math.floor(articleList.length / 10)) {
                        draft.curPage -= 1;
                    }
                }
            }

            draft.rowsPerPage = isGridView ? 9 : 10;
        });
    }, [isGridView]);

    useEffect(() => {
        if (articleList.length <= paginationState.rowsPerPage && paginationState.curPage !== 1) {
            updatePaginationState((draft) => {
                draft.curPage = 1;
            });
            return;
        }
        if (articleList.length >= 0) {
            let startIndex = paginationState.rowsPerPage * (paginationState.curPage - 1);
            setPagedArticleList(articleList.slice(startIndex, startIndex + paginationState.rowsPerPage));
        }
    }, [articleList, paginationState.rowsPerPage]);

    useEffect(() => {
        if (articleList.length > 0) {
            let startIndex = paginationState.rowsPerPage * (paginationState.curPage - 1);
            setPagedArticleList(articleList.slice(startIndex, startIndex + paginationState.rowsPerPage));
        }
    }, [paginationState.curPage]);

    const confirmDeletePrompt = (articleId: string) => {
        updateDialogStatus(() => ({
            _articleId: articleId,
            showDialog: true,
        }));
    };

    const confirm_denied_prompt = ({ to, state, title, rejectReasonDisplayText }) => {
        updateDialogStatus((draft) => ({
            _route_properties: { to, state, title, rejectReasonDisplayText },
            showDialog: true,
        }));
    };

    const prompt_confirm_handler = () => {
        setIsDeleting(true);
        // deleteArticle
        if (dialogStatus._articleId) {
            apiService
                .deleteArticle({ articleId: dialogStatus._articleId })
                .then((res) => {
                    if (UtilFunctions.validateApiResponse(res.data)) {
                        fetchArticleList(true);
                        setIsDeleting(false);
                        updateDialogStatus((draft) => ({ _articleId: undefined, showDialog: false }));
                    } else throw new Error("Error Unknown");
                })
                .catch((err) => {
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                });
        }

        // confirm navigate
        if (dialogStatus._route_properties) {
            let { to, state } = dialogStatus._route_properties;
            history.push(to, state);
        }
    };

    const pageChangeHandler = (action: Action, value?: number) => {
        let nextState = paginationState.curPage;

        switch (action) {
            case Action.PREV_PAGE:
                nextState -= 1;
                break;
            case Action.NEXT_PAGE:
                nextState += 1;
                break;
            case Action.JUMP:
                value && (nextState = value);
        }
        updatePaginationState((draft) => {
            draft.curPage = nextState;
        });
    };

    const renderArticlesInList = useMemo(
        () =>
            !isGridView
                ? pagedArticleList.map((article) => {
                      let statusTextToBeShown = statusCodeListMap.get(article.statusCode as ArticleStatus)?.[
                          intl.locale
                      ]?.statusCodeText;
                      let rejectTextToBeShown = rejectReasonListMap.get(article.rejectCode)?.[intl.locale]
                          ?.rejectReasonDisplayText;

                      return (
                          <TableRow
                              key={article._id}
                              className={article.statusCode === ArticleStatus.PUBLISH_DENIED ? classes.errorRow : ""}
                          >
                              <TableCell align="center" classes={{ root: classes.tableCell }}>
                                  <Link
                                      component={RouterLink}
                                      to={{
                                          pathname: isAdmin ? "./approve-article" : "./edit-article",
                                          state: {
                                              articleId: article._id,
                                              articleStatus: article.statusCode,
                                              categories: categoryList,
                                              userId: contentProviderList.find(
                                                  (contentProvider) => contentProvider.userName === article.userName
                                              )?.userId,
                                          },
                                      }}
                                      onClick={(e: React.MouseEvent) => {
                                          if (isAdmin || article.statusCode !== ArticleStatus.PUBLISH_DENIED) return;
                                          e.preventDefault();
                                          confirm_denied_prompt({
                                              to: "./edit-article",
                                              state: {
                                                  articleId: article._id,
                                                  articleStatus: article.statusCode,
                                                  categories: categoryList,
                                              },
                                              title: article?.[intl.locale].title,
                                              rejectReasonDisplayText:
                                                  article.rejectReasonDisplayText ?? rejectTextToBeShown,
                                          });
                                      }}
                                      color="textPrimary"
                                  >
                                      {article?.[intl.locale]?.title}
                                  </Link>
                              </TableCell>
                              <TableCell align="center" classes={{ root: classes.tableCell }}>
                                  {categoryList
                                      .filter((category) => article.category?.includes(category._id))
                                      .map((matchedCate, index) => (
                                          <div key={index}>{matchedCate?.[intl.locale]?.name}</div>
                                      ))}
                              </TableCell>
                              <TableCell align="center" classes={{ root: classes.tableCell }}>
                                  {article.publishDate ? UtilFunctions.getDateFromTimestamp(article.publishDate) : "-"}
                              </TableCell>
                              <TableCell align="center" classes={{ root: classes.tableCell }}>
                                  {UtilFunctions.getDateFromTimestamp(article.updateDate)}
                              </TableCell>
                              {isAdmin && contentProviderList ? (
                                  <TableCell align="center" classes={{ root: classes.tableCell }}>
                                      {
                                          contentProviderList.find(
                                              (contentProvider) => contentProvider.userName === article.userName
                                          )?.zh?.name
                                      }{" "}
                                      ({article.userName})
                                  </TableCell>
                              ) : null}
                              <TableCell align="center">
                                  <Box
                                      display="inline-flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      position="relative"
                                  >
                                      {renderStatusText(StatusBox())(article.statusCode, statusTextToBeShown)}

                                      {article.statusCode === ArticleStatus.PUBLISH_DENIED && (
                                          <CustomizedTooltip
                                              title={article.rejectReasonDisplayText ?? rejectTextToBeShown}
                                          />
                                      )}
                                  </Box>
                              </TableCell>
                              {!isAdmin ? (
                                  <TableCell align="center">
                                      <RemoveIc
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => confirmDeletePrompt(article._id)}
                                      />
                                  </TableCell>
                              ) : null}
                          </TableRow>
                      );
                  })
                : null,
        [isGridView, pagedArticleList]
    );

    const renderArticlesInGrid = useMemo(
        () =>
            isGridView ? (
                <>
                    {pagedArticleList.map((article) => {
                        let statusTextToBeShown = statusCodeListMap.get(article.statusCode as ArticleStatus)?.[
                            intl.locale
                        ]?.statusCodeText;
                        let rejectTextToBeShown = rejectReasonListMap.get(article.rejectCode)?.[intl.locale]
                            ?.rejectReasonDisplayText;

                        let data: ArticleInGridData = {
                            title: article?.[intl.locale]?.title,
                            category: categoryList
                                .filter((category) => article.category?.includes(category._id))
                                .map((matchedCate, index) => <div key={index}>{matchedCate?.[intl.locale]?.name}</div>),
                            post_date: article.publishDate
                                ? UtilFunctions.getDateFromTimestamp(article.publishDate)
                                : "-",
                            update_date: UtilFunctions.getDateFromTimestamp(article.updateDate),
                            status: article.statusCode as ArticleStatus,
                            statusText: statusTextToBeShown,
                            rejectText: rejectTextToBeShown,
                            img: article.zh?.coverImage,
                        };

                        let clickThroughHandler = (e: React.MouseEvent) => {
                            if (isAdmin || article.statusCode !== ArticleStatus.PUBLISH_DENIED) {
                                history.push(isAdmin ? "./approve-article" : "./edit-article", {
                                    articleId: article._id,
                                    articleStatus: article.statusCode,
                                    categories: categoryList,
                                    userId: contentProviderList.find(
                                        (contentProvider) => contentProvider.userName === article.userName
                                    )?.userId,
                                });
                                return;
                            }

                            confirm_denied_prompt({
                                to: "./edit-article",
                                state: {
                                    articleId: article._id,
                                    articleStatus: article.statusCode,
                                    categories: categoryList,
                                },
                                title: article?.[intl.locale].title,
                                rejectReasonDisplayText: article.rejectReasonDisplayText ?? rejectTextToBeShown,
                            });
                        };

                        let deleteHandler = () => confirmDeletePrompt(article._id);

                        return (
                            <ArticleInGrid
                                key={article._id}
                                data={data}
                                statusElement={renderStatusText(StatusBox(1))(article.statusCode, statusTextToBeShown)}
                                deniedElement={
                                    article.statusCode === ArticleStatus.PUBLISH_DENIED ? (
                                        <CustomizedTooltip
                                            title={article.rejectReasonDisplayText ?? rejectTextToBeShown}
                                            grid={1}
                                        />
                                    ) : undefined
                                }
                                clickThroughHandler={clickThroughHandler}
                                deleteHandler={deleteHandler}
                                isAdmin={isAdmin}
                            />
                        );
                    })}

                    {pagedArticleList.length % 3 > 0
                        ? Array(3 - (pagedArticleList.length % 3))
                              .fill(0)
                              .map((_, ind) => (
                                  <div key={`placeholder${ind}`} style={{ flexBasis: "30%", padding: "10px" }}></div>
                              ))
                        : null}
                </>
            ) : null,
        [isGridView, pagedArticleList]
    );

    return (
        <React.Fragment>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <div className="articleListWrapper">
                {!isGridView ? (
                    <Animated
                        animationIn="fadeIn"
                        animationOut="fadeOut"
                        isVisible={true}
                        animationInDuration={300}
                        animationOutDuration={300}
                        key="list_view"
                    >
                        <TableContainer
                            component={Paper}
                            style={device == "PC" ? {} : { width: "auto", overflow: "auto" }}
                        >
                            <Table aria-label="table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            className={device == "PC" ? classes.head : classes.head_m}
                                        >
                                            {intl.formatMessage({ id: "4.012" })}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={device == "PC" ? classes.head : classes.head_m}
                                        >
                                            {intl.formatMessage({ id: "4.013" })}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={device == "PC" ? classes.head : classes.head_m}
                                        >
                                            {intl.formatMessage({ id: "4.014" })}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={device == "PC" ? classes.head : classes.head_m}
                                        >
                                            {intl.formatMessage({ id: "4.015" })}
                                        </TableCell>
                                        {isAdmin ? (
                                            <TableCell
                                                align="center"
                                                className={device == "PC" ? classes.head : classes.head_m}
                                            >
                                                {intl.formatMessage({ id: "admin.allPosts.2.003" })}
                                            </TableCell>
                                        ) : null}
                                        <TableCell
                                            align="center"
                                            className={device == "PC" ? classes.head : classes.head_m}
                                            style={{ minWidth: 140 }}
                                        >
                                            {intl.formatMessage({ id: "4.016" })}
                                        </TableCell>
                                        {!isAdmin ? (
                                            <TableCell
                                                align="center"
                                                className={device == "PC" ? classes.head : classes.head_m}
                                            ></TableCell>
                                        ) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderArticlesInList}</TableBody>
                            </Table>
                        </TableContainer>
                    </Animated>
                ) : (
                    <Animated
                        animationIn="fadeIn"
                        animationOut="fadeOut"
                        isVisible={true}
                        animationInDuration={300}
                        animationOutDuration={300}
                        key="grid_view"
                    >
                        <div className={device == "PC" ? classes.grid_wraper : classes.grid_wraper_m}>
                            {renderArticlesInGrid}
                        </div>
                    </Animated>
                )}
                <CustomizeTablePagination
                    count={articleList.length}
                    rowsPerPage={paginationState.rowsPerPage}
                    page={paginationState.curPage}
                    pageChangeHandler={pageChangeHandler}
                />
            </div>
            {!isAdmin && (
                // Prompt for removal confirmation and showing rejected reason
                <ConfirmDialog
                    rwdStatus={device}
                    disabled={isDeleting}
                    title={
                        (dialogStatus._route_properties && intl.formatMessage({ id: "editor.allPosts.4.029" })) ??
                        intl.formatMessage({ id: "editor.allPosts.4.034" })
                    }
                    content={
                        (dialogStatus._route_properties && (
                            <>
                                <Box marginBottom="22px">
                                    {intl.formatMessage(
                                        { id: "editor.allPosts.4.03" },
                                        { article_title: dialogStatus._route_properties.title }
                                    )}
                                </Box>

                                <Box>
                                    {intl.formatMessage(
                                        { id: "editor.allPosts.4.031" },
                                        { reject_reason: dialogStatus._route_properties.rejectReasonDisplayText }
                                    )}
                                </Box>
                            </>
                        )) ??
                        intl.formatMessage({ id: "editor.allPosts.4.035" })
                    }
                    confirmBtnText={
                        (dialogStatus._route_properties && intl.formatMessage({ id: "editor.allPosts.4.033" })) ??
                        (isDeleting ? (
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: BtnLoadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width={28}
                                height={20}
                            />
                        ) : (
                            intl.formatMessage({ id: "editor.allPosts.4.049" })
                        ))
                    }
                    cancelBtnText={intl.formatMessage({ id: "editor.allPosts.4.048" })}
                    openStatus={dialogStatus.showDialog}
                    confirmHandler={(e) => prompt_confirm_handler()}
                    cancelHandler={() => {
                        updateDialogStatus(() => ({
                            showDialog: false,
                        }));
                    }}
                    toggleDialog={() => {
                        updateDialogStatus(() => ({
                            showDialog: false,
                        }));
                    }}
                    onClose={() => {
                        updateDialogStatus(() => ({
                            showDialog: false,
                        }));
                    }}
                />
            )}
        </React.Fragment>
    );
}

export const StatusBox = (grid = 0) =>
    styled(Box)({
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "fit-content",
        padding: `${!grid ? "0 24px" : "0 12px"}`,
        height: `${!grid ? "44px" : "30px"}`,
        borderRadius: 22,
        color: "#ffffff",
        userSelect: "none",
        whiteSpace: "nowrap",
    });

const renderStatusText = (Compoent) => (type: string, text: string) => {
    switch (type) {
        case ArticleStatus.WAITING_FOR_APPROVAL:
            return <Compoent bgcolor="#0076ff">{text}</Compoent>;
        case ArticleStatus.PUBLISH_DENIED:
            return <Compoent bgcolor="#d0021b">{text}</Compoent>;
        default:
            return text;
    }
};

const QTooltip = withStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
        fontSize: 18,
        paddingLeft: 12,
        paddingRight: 10,
    },
}))(Tooltip);

export const CustomizedTooltip = (props) => {
    return (
        <QTooltip arrow placement="bottom-end" {...props}>
            {props.children ? (
                props.children
            ) : (
                <ReasonIc
                    style={
                        !props.grid
                            ? {
                                  position: "absolute",
                                  right: 0,
                                  transform: "translateX(30px)",
                              }
                            : {
                                  flexShrink: 0,
                                  marginLeft: 13,
                              }
                    }
                />
            )}
        </QTooltip>
    );
};

// const injectProps = (Component) => ({ isRemovalPrompt, titleReomval, ...rest }) => {
//     const intl = useIntl();
//     if (isRemovalPrompt) {
//         return <Component title={titleReomval} {...rest} />;
//     }

//     return <Component {...rest} />;
// };

// const ConfirmDialog = injectProps(ErrorDialog);

export default ArticleList;
