import { AxiosResponse } from "axios";
import {
    AccessContext,
    Configuration,
    ErrorInvalidToken,
    ErrorNoAuthCode,
    fromWWWAuthenticateHeaderStringToObject,
    OAuth2AuthCodePKCE,
    toErrorClass,
} from "../lib/OAuth2AuthCodePKCE";

const sso_url = process.env.REACT_APP_SSO_URL || "";
const front_url = process.env.REACT_APP_FRONT_URL || "";
const _clientId = process.env.REACT_APP_SSO_CLIENTID || "";

export const oauth2Config: Configuration = {
    authorizationUrl: `${sso_url}/oauth2/authorize`,
    tokenUrl: `${sso_url}/oauth2/token`,
    revokeUrl: `${sso_url}/oauth2/revoke`,
    logoutUrl: `${sso_url}/oidc/logout`,
    clientId: _clientId,
    scopes: ["openid"],
    redirectUrl: `${front_url}/oauth2/setAuthCode`,
    onAccessTokenExpiry(refreshAccessToken) {
        console.log("Expired! Access token needs to be renewed.");
        return refreshAccessToken();
    },
    onInvalidGrant(refreshAuthCodeOrRefreshToken) {
        console.log("Expired! Auth code or refresh token needs to be renewed.");
        return refreshAuthCodeOrRefreshToken();
    },
};

/**
 * HTTP headers that we need to access.
 */
export const HEADER_AUTHORIZATION = "Authorization";
export const HEADER_WWW_AUTHENTICATE = "WWW-Authenticate";

export class OAuth2Login {
    private static _oauthInstance: OAuth2AuthCodePKCE;

    private static _accessToken: AccessContext;

    /**
     * init the OAuth2Login with Config
     */
    public static init(config: Configuration) {
        this._oauthInstance = new OAuth2AuthCodePKCE(config);
        return this._oauthInstance;
    }

    /**
     * getInstance
     */
    public static getInstance() {
        return this._oauthInstance;
    }

    /**
     * isAuthorized
     * Return boolean if user logged in
     */
    public static isAuthorized(): boolean {
        return this._oauthInstance.isAuthorized();
    }

    /**
     * authorize
     * Call this to authorize / Go to WSO2 login page
     */
    public static authorize(): void {
        // May need to override this function due to app state / adding other param for redirection
        this._oauthInstance.fetchAuthorizationCode();
    }

    /**
     * authorizationCodeHandler
     * Handle Auth code returned from Auth Server
     */
    public static authorizationCodeHandler() {
        return this._oauthInstance.isReturningFromAuthServer().then((hasAuthCode) => {
            if (!hasAuthCode) {
                // Failed to obtain auth code, cannot request token
                throw new ErrorNoAuthCode();
            }
        });
    }

    /**
     * getAccessToken
     */
    public static getAccessToken(): Promise<AccessContext> {
        return this._oauthInstance
            .getAccessToken()
            .then((token: AccessContext) => {
                this._accessToken = token;
                return Promise.resolve(token);
            })
            .catch((err) => {
                console.log("Error occurred when obtaining token:", err);
                return Promise.reject(err);
            });
    }

    /**
     * handleResponseOAuthError
     */
    public static handleResponseOAuthError(res: AxiosResponse) {
        const error = toErrorClass(
            fromWWWAuthenticateHeaderStringToObject(res.headers[HEADER_WWW_AUTHENTICATE.toLowerCase()]).error
        );

        if (error instanceof ErrorInvalidToken) {
            this._oauthInstance.exchangeRefreshTokenForAccessToken();
        }

        return Promise.reject(error);
    }

    public static revokeToken(): Promise<void> {
        return this._oauthInstance.revokeAccessToken();
    }

    public static logout(): void {
        this._oauthInstance.revokeAccessToken().finally(() => {
            this._oauthInstance.logout();
        });
    }

    public static removeOAuthStorage(): void {
        this._oauthInstance.removeStorage();
    }
}
