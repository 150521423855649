import { Button, Dialog, IconButton, Typography } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, styled, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/editor/ic/Close_white.svg";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../../assets/animations/btn_loading_animation.json";

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose?: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        title: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontSize: "20px",
            fontWeight: 600,
            padding: "0px 24px",
            minHeight: "60px",
            lineHeight: "60px",
        },
        closeButton: {
            padding: 0,
            position: "absolute",
            right: theme.spacing(1),
            top: "11px",
        },
    });

export const NewCategoryDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.title} {...other}>
            <Typography variant="inherit">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const NewCategoryDialogContent = styled(MuiDialogContent)({
    fontSize: "20px",
    color: "#222222",
    "& .title": {
        fontSize: "28px",
        fontWeight: 900,
    },
});

export const NewCategoryDialogActions = styled(MuiDialogActions)({
    fontSize: "20px",
});

export interface NewCategoryDialogProps extends WithStyles<typeof styles> {
    openStatus: boolean;
    toggleDialog: () => void;
    confirmHandler: (e?) => void;
    cancelHandler?: (e?) => void;
    title: string;
    content: string | JSX.Element;
    confirmBtnText: string;
    cancelBtnText?: string;
    newCategoryValidated: boolean;
    notFullWidth?: boolean;
    disabled?: boolean;
}

export const NewCategoryDialog = withStyles(styles)((props: NewCategoryDialogProps) => {
    return (
        <Dialog
            maxWidth={"md"}
            scroll={"paper"}
            fullWidth={props.notFullWidth ? false : true}
            aria-labelledby="customized-dialog-title"
            open={props.openStatus}
        >
            <NewCategoryDialogTitle id="error-dialog-title" onClose={() => props.toggleDialog()}>
                {props.title}
            </NewCategoryDialogTitle>
            <NewCategoryDialogContent>
                <div className="content">{props.content}</div>
            </NewCategoryDialogContent>
            <NewCategoryDialogActions>
                {props.cancelBtnText && props.cancelHandler && (
                    <Button variant="outlined" onClick={() => props.cancelHandler?.()} color="primary">
                        {props.cancelBtnText}
                    </Button>
                )}
                <Button
                    variant="contained"
                    onClick={(e) => props.confirmHandler(e)}
                    color="primary"
                    disabled={!props.newCategoryValidated || props.disabled}
                >
                    {props.disabled ? (
                        <>
                            <span style={{ visibility: "hidden" }}>{props.confirmBtnText}</span>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: BtnLoadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width={40}
                                height={29}
                                style={{ position: "absolute" }}
                            />
                        </>
                    ) : (
                        <>{props.confirmBtnText}</>
                    )}
                </Button>
            </NewCategoryDialogActions>
        </Dialog>
    );
});
