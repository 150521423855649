import React, { useContext } from "react";
import { createStyles, makeStyles, Theme, Select, MenuItem } from "@material-ui/core";
import Hint from "../buttons/Hint";
import { RwdContext } from "../../App";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        field: {
            margin: "32px 16px",
            width: "calc(100% - 32px)",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
        },
        mobilefield: {
            margin: "10px 0",
            width: "100%",
            height: "auto",
        },
        typeTwoUI_field: {
            marginTop: 32,
            width: "calc(100% - 32px)",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
        },
        fieldTitle: {
            marginRight: 30,
            width: "92px",
            lineHeight: "40px",
            fontSize: "20px",
            fontWeight: "bold",
            "@media (max-width : 1280px)": {
                fontSize: 14,
                lineHeight: "10px",
            },
        },
        typeTwoUI_fieldTitle: {
            marginRight: 0,
            width: "72px",
            lineHeight: "40px",
            fontWeight: "bold",
            fontSize: 18,
            "@media (max-width: 1208px)": {
                fontSize: "14px",
                marginBottom: 3,
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
            },
        },
        select: {
            margin: "auto",
            height: "40px",
            width: "calc(100% - 72px)",
            "@media (max-width : 1280px)": {
                width: "100%",
                marginTop: "5px",
                marginBottom: "8px",
            },
        },
        typeTwoUI_select: {
            height: "40px",
            width: "calc(100% - 83px)",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
                height: "40px",
                width: "98%",
            },
        },
        typeThreeUI_field: {
            marginBottom: 18,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            "@media (max-width: 1208px)": {
                fontSize: "14px",
                marginBottom: 3,
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
            },
        },
        typeThreeUI_select: {
            height: "40px",
            width: 150,
            "@media (max-width: 1208px)": {
                fontSize: "14px",
                height: "40px",
                width: "98%",
            },
        },
        menuFont: {
            fontSize: "1rem",
            "@media (max-width: 1208px)": {
                fontSize: "14px",
            },
        },
    })
);

export default function SelectInputWithLabel(props: {
    label?: string;
    placeholder?: string;
    hint?: string;
    options: { name: string; value: any }[];
    value: string;
    readOnly?: boolean;
    typeTwoUI?: boolean;
    typeThreeUI?: boolean;
    defaultValue?: any;
    disabled?: boolean;
    onChange?: (val: any) => void;
}) {
    const classes = useStyles();
    const device = useContext(RwdContext);
    return (
        <div
            className={
                props.typeTwoUI && device === "PC"
                    ? classes.typeTwoUI_field
                    : props.typeThreeUI && device == "PC"
                    ? classes.typeThreeUI_field
                    : device === "mobile"
                    ? classes.mobilefield
                    : classes.field
            }
        >
            {props.label ? (
                <span className={props.typeTwoUI ? classes.typeTwoUI_fieldTitle : classes.fieldTitle}>
                    {props.label}
                </span>
            ) : (
                ""
            )}
            {typeof props.hint !== "undefined" && props.hint && <Hint text={props.hint} />}
            <Select
                disabled={props.disabled ? true : false}
                value={props.value}
                variant="outlined"
                defaultValue={props.defaultValue}
                className={
                    props.typeTwoUI
                        ? classes.typeTwoUI_select
                        : props.typeThreeUI
                        ? classes.typeThreeUI_select
                        : classes.select
                }
                placeholder={props.placeholder ? props.placeholder : ""}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    props.onChange ? props.onChange(e.target.value) : () => {}
                }
                inputProps={{ readOnly: props.readOnly }}
                style={{ background: props.typeThreeUI ? "#fff" : "" }}
            >
                {typeof props.placeholder !== "undefined" && props.placeholder && props.placeholder !== "" && (
                    <MenuItem disabled value="placeholder">
                        {props.placeholder}
                    </MenuItem>
                )}
                {typeof props.options !== "undefined" &&
                    props.options &&
                    props.options.map((o) => (
                        <MenuItem className={classes.menuFont} key={o.value} value={o.value}>
                            {o.name}
                        </MenuItem>
                    ))}
            </Select>
        </div>
    );
}
