import { ROLE_TYPE, Store_component_types, RoutePath, RoutePathRule } from "./enums";

//________________________________________________________________________________ MODELS
interface MODEL_article {
    _id: string;
    views: number;
    category: string[];
    publishDate: long;
    updateDate: long;
    statusCode: string;
    userName?: string;
    rejectCode?: RejectReasonCode;
    rejectReasonDisplayText?: string;
    zh?: MODEL_articleContent;
    en?: MODEL_articleContent;
    data?: MODEL_articleContent; //This is for single lang
    isPublicContent: boolean;
}

interface MODEL_articleContent {
    publisher?: string; // Admin only
    title: string;
    data: string;
    coverImage: string;
    type: string; // Day 1 only have type "richTextContent"
}

interface MODEL_CategoryDetails {
    _id: string;
    zh?: MODEL_CategoryDetails_Content;
    en?: MODEL_CategoryDetails_Content;
    data?: MODEL_CategoryDetails_Content; //This is for single lang case
    status: string;
}
interface MODEL_CategoryDetails_Content {
    name: string;
}

interface MODEL_StoreDetails {
    code: string;
    zh: {
        name: string;
    };
    en: {
        name: string;
    };
}

interface MODEL_UserDetails {
    profilePicture: string; // ID of the user image on image server
    banner: string; // ID of the user image on image server
    zh: MODEL_UserDetails_Content;
    en: MODEL_UserDetails_Content;
    // data? : MODEL_UserDetails_Content //This is for single lang case //Not supported yet
    userId: string;
    userName: string;
    roles: ROLE_TYPE[];
}

interface MODEL_Store {
    storesDetails: MODEL_StoreDetails[];
}

interface MODEL_UserDetails_Context extends Omit<MODEL_UserDetails, "profilePicture" | "banner" | "zh" | "en"> {
    timestamp: string;
}

interface MODEL_UserDetails_Content {
    name: string;
    intro: string;
}

interface MODEL_baseResponse {
    status: string;
    message: string;
    timestamp: Long;
    data: Object;
}

// ----- Start GET dashboard data -----
interface dashboardStatistic {
    statistic: {
        publisher: string;
        published: number;
        waitingForApproval: number;
    }[];
}
// ----- End GET dashboard data -----

// ----- Start reducer action -----
interface Action {
    type: ActionType;
    value?: any;
    lang?: Language;
}

// ----- End reducer action -----

//________________________________________________________________________________ NewUserDetails
interface REQUEST_NewUserDetails {
    zh: MODEL_UserDetails_Content;
    en: MODEL_UserDetails_Content;
    profilePicture: string;
    banner: string;
}
interface RESPONSE_NewUserDetails extends MODEL_baseResponse {
    data: MODEL_UserDetails;
}

//________________________________________________________________________________ EditUserDetails
interface REQUEST_EditUserDetails {
    zh: MODEL_UserDetails_Content;
    en: MODEL_UserDetails_Content;
    profilePicture: string;
    banner: string;
}
interface RESPONSE_EditUserDetails extends MODEL_baseResponse {}

//________________________________________________________________________________ GetUserDetails
interface REQUEST_GetUserDetails {}
interface RESPONSE_GetUserDetails extends MODEL_baseResponse {
    data: MODEL_UserDetails;
}

//________________________________________________________________________________ Article
/*
    @param lang ,not required
*/
interface REQUEST_Article {
    lang?: string;
}
interface RESPONSE_Article extends MODEL_baseResponse {
    data: {
        articleList: MODEL_article[];
        categoryList: MODEL_CategoryDetails[];
    };
}

//________________________________________________________________________________ ArticleList
/*
    @param lang ,not required
*/
interface REQUEST_ArticleList {
    lang?: string;
}

interface RESPONSE_ArticleList extends MODEL_baseResponse {
    data: {
        articleList: MODEL_article[]; // without the article content data
        categoryList: MODEL_CategoryDetails[];
    };
}

//________________________________________________________________________________ ArticleNew
interface REQUEST_ArticleNew {
    zh: MODEL_articleContent;
    en: MODEL_articleContent;
    category: string[];
    scheduledPublish?: number; // If exist then means its publish with the date time provided ( unix timestamp )
    publish: boolean;
}
interface RESPONSE_ArticleNew extends MODEL_baseResponse {
    data: MODEL_article;
}

//________________________________________________________________________________ ArticleEdit
interface REQUEST_ArticleEdit {
    zh: MODEL_articleContent;
    en: MODEL_articleContent;
    category: string[];
    scheduledPublish?: number; // If exist then means its publish with the date time provided ( unix timestamp )
    publish: boolean;
}
interface RESPONSE_ArticleEdit extends MODEL_baseResponse {}

//________________________________________________________________________________ ArticleDelete
interface REQUEST_ArticleDelete {
    articleId: string;
}
interface RESPONSE_ArticleDelete extends MODEL_baseResponse {}

//________________________________________________________________________________ ArticleStatusUpdate
interface REQUEST_ArticleStatusUpdate {
    articleId: string;
    status: string;
    rejectCode?: String; // for status PUBLISH_DENIED only
    rejectCustomMessage?: String; //for rejectcode OTHERS only
}
interface RESPONSE_ArticleStatusUpdate extends MODEL_baseResponse {}

//________________________________________________________________________________ RejectList
interface REQUEST_RejectList {
    lang?: String;
}

interface MODEL_RejectReason {
    rejectCode: String;
    zh?: {
        rejectReasonDisplayText: String;
    };
    en?: {
        rejectReasonDisplayText: String;
    };
    data?: {
        //For single lang only
        rejectReasonDisplayText: String;
    };
}

interface RESPONSE_RejectList extends MODEL_baseResponse {
    data: MODEL_RejectReason;
}

//________________________________________________________________________________ CategoryList
/*
    @param lang ,not required
*/
interface REQUEST_CategoryList {}
interface RESPONSE_CategoryList extends MODEL_baseResponse {
    data: MODEL_CategoryDetails[];
}

//________________________________________________________________________________ CategoryAdd
interface REQUEST_CategoryAdd {
    zh: {
        name: string;
    };
    en: {
        name: string;
    };
}
interface RESPONSE_CategoryAdd extends MODEL_baseResponse {
    data: MODEL_CategoryDetails;
}

//________________________________________________________________________________ CategoryStatusUpdate
interface REQUEST_CategoryStatusUpdate {
    categoryId: string;
    status: string;
}
interface RESPONSE_CategoryStatusUpdate extends MODEL_baseResponse {}

//________________________________________________________________________________ ContentProviderList
interface REQUEST_ContentProviderList {
    userId: string;
    userName: string;
    zh: {
        name: string;
    };
    en: {
        name: string;
    };
    roles: ROLE_TYPE[];
}

//_______________________________________________________________ apiAddress
export let apiAddress = {
    allBookings_Get: () => "api/timeslot/booking",
    bookingStatus_Post: () => "api/timeslot/booking/toStatus",
    allTimeSlots_GET: () => "/api/timeslot",
    addTimeSlot_POST: () => "/api/timeslot/add",
    removeTimeSlot_POST: () => "/api/timeslot/remove",
    categoryList_GET: () => "/api/category",
    categoryAdd_POST: () => "/api/category",
    categoryStatusChange_POST: () => "/api/category/status",
    categoryDelete_POST: () => "/api/category",
    articleList_GET: () => "/api/article",
    articleAdd_POST: () => `/api/article`,
    article_GET: (id: string) => `/api/article/${id}`,
    articleEdit_POST: (id: string) => `/api/article/${id}`,
    imageUpload_POST: () => `/api/image/upload`,
    imageUploadVersion2_POST: () => `api/image/uploadVersion2`,
    imageDelete_POST: () => `/api/image/delete`,
    imageStatusUpdate_POST: () => "/api/image/status",
    userDetails_GET: () => `/api/userDetails`,
    userDetailsEdit_POST: () => `/api/userDetails`,
    rejectList_GET: () => "/api/rejectReasonList",
    downloadCSV_POST: () => `/api/report/getData`,
    allContentProvider_GET: () => "/api/userDetails/allContentProvider",
    dashboardInfo_GET: () => "/api/article/dashboardInfo",
    availableTimeslot_GET: () => "api/timeslot/available",
    addBookingTimeslot_POST: () => "api/timeslot/booking/add",
    singleBooking_GET: (bookingID: string) => `api/timeslot/booking/${bookingID}`,
    storeDetails_GET: () => `/api/userDetails/stores`,
    sisAvaliableComponents_GET: () => "api/sis/components",
    sisComponents_GET: (storeID: string) => `api/sis/components/${storeID}`,
    sisComponentsPreview_POST: (storeID: string) => `api/sis/components/preview/${storeID}`,
    sisComponents_PUT: (storeID: string) => `api/sis/components/${storeID}`,
    banner_GET: () => "api/sis/images",
    banner_POST: () => `/api/sis/images`,
    banner_DELETE: () => "api/sis/images",
    SkuIdControll_POST: (bookingID: string) => `api/timeslot/booking/${bookingID}`,
};

interface RouteItem {
    to: RoutePath;
    activateRoles?: ROLE_TYPE[];
    rules?: RoutePathRule[];
}

interface MODEL_StatusCode {
    statusCode: ArticleStatus;
    zh: {
        statusCodeText: string;
    };
    en: {
        statusCodeText: string;
    };
}

interface UserSettings {
    grid_view: boolean;
}

interface REQUEST_BannerListUpdate {
    store: String;
    components: Map<Store_component_types, sis_components_images_details>;
}

interface sis_components_images_details {
    imageId: string;
    url: string;
    title?: string;
}
