import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, default as axios } from "axios";
import log from "loglevel";
import { AlertDialogProps } from "../components/dialogs/AlertDialog";
import {
    apiAddress,
    MODEL_UserDetails,
    REQUEST_ArticleDelete,
    REQUEST_ArticleEdit,
    REQUEST_ArticleNew,
} from "../interfaces/index.d";
import { HEADER_AUTHORIZATION, HEADER_WWW_AUTHENTICATE, OAuth2Login } from "./OAuth2Login";

declare module "axios" {
    interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class HttpClient {
    protected readonly instance: AxiosInstance;

    private _accessToken = "";
    private _setGlobalAlertBox = (props: AlertDialogProps) => {};
    private _onTokenFailed = () => {};

    public constructor(
        baseURL: string,
        accessToken: string,
        alertBox: (props: AlertDialogProps) => void,
        onTokenFailed: VoidFunction
    ) {
        this._accessToken = accessToken;
        this._setGlobalAlertBox = alertBox;
        this._onTokenFailed = onTokenFailed;

        this.instance = axios.create({
            baseURL,
        });
        this._initializeRequestInterceptor();
        this._initializeResponseInterceptor();
    }

    public setAccessToken = (accessToken: string) => {
        this._accessToken = accessToken;
    };

    public getAccessToken = () => {
        return this._accessToken;
    };

    private _initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(this._handleRequest, this._handleError);
    };

    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(this._handleResponse, this._handleError);
    };

    private _handleRequest = async (config: AxiosRequestConfig) => {
        const configNew: any = Object.assign({}, config);
        if (!configNew.headers) {
            configNew.headers = {};
        }

        // Try to get & append OAuth2 Access Token
        if (OAuth2Login.isAuthorized()) {
            const { token } = await OAuth2Login.getAccessToken();
            configNew.headers[HEADER_AUTHORIZATION] = `Bearer ${token!.value}`;
        }

        // if (config.method === "POST") {
        //     configNew.headers["Content-Type"] = "application/x-www-form-urlencoded";
        // }

        return configNew;
    };

    private _handleResponse = (res: AxiosResponse) => {
        return res;
    };
    protected _handleError = (error: AxiosError) => {
        console.error("AJAX Error", error.response);
        // If WWW-Authenticate Header found, try refresh token
        if (error.response !== undefined && HEADER_WWW_AUTHENTICATE.toLowerCase() in error.response?.headers) {
            return OAuth2Login.handleResponseOAuthError(error.response);
        }
        return Promise.reject(error);
    };
}

export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN_PREFIX || "/";

const logger = log.getLogger("API Service");

class Api extends HttpClient {
    private lang = "zh";

    /**
     * constructor
     */
    public constructor(accessToken: string, alertBox: (props: AlertDialogProps) => void, onTokenFailed: VoidFunction) {
        super(API_DOMAIN, accessToken, alertBox, onTokenFailed);
        logger.warn("API Service initialized");
    }
    private defaultTimeout = 7000;
    private imageTimeout = 12000;
    /**
     * getAllTimeSlots
     */
    public postBookingStatus = (data) =>
        this.instance.request({
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            url: apiAddress.bookingStatus_Post(),
            data: data,
            timeout: this.defaultTimeout,
        });

    /**
     * getSingleBooking
     */
    public getSingleBooking = (_id) =>
        this.instance.request({
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
            url: apiAddress.singleBooking_GET(_id),
            timeout: this.defaultTimeout,
        });

    /**
     * getAllBookings
     */
    public getAllBookings = (params) =>
        this.instance.request({
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
            url: apiAddress.allBookings_Get(),
            params: params,
            timeout: this.defaultTimeout,
            // data: data,
        });

    /**
     * getAllTimeSlots
     */
    public getAllTimeSlots = (params) =>
        this.instance.request({
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
            url: apiAddress.allTimeSlots_GET(),
            params: params,
            timeout: this.defaultTimeout,
            // data: data,
        });

    /**
     * postAddTimeSlot
     */
    public postAddTimeSlot = (data) =>
        this.instance.request({
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            url: apiAddress.addTimeSlot_POST(),
            data: data,
            timeout: this.defaultTimeout,
        });

    /**
     * postAddTimeSlot
     */
    public postRemoveTimeSlot = (data) =>
        this.instance.request({
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            url: apiAddress.removeTimeSlot_POST(),
            data: data,
            timeout: this.defaultTimeout,
        });

    /**
     * postImages
     */
    public postImages = (data) =>
        this.instance.request({
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            url: apiAddress.imageUpload_POST(),
            data: data,
            timeout: this.imageTimeout,
        });

    /**
     * postRichTextEditorImages
     */
    public postRichTextEditorImages = (data) =>
        this.instance.request({
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            url: apiAddress.imageUploadVersion2_POST(),
            data: data,
            timeout: this.imageTimeout,
        });

    /**
     * postDeleteImages
     */
    public postDeleteImages = (data) =>
        this.instance.request({
            method: "POST",
            url: apiAddress.imageDelete_POST(),
            data: data,
            timeout: this.imageTimeout,
        });

    /**
     * postUpdateImageStatus
     */
    public postUpdateImageStatus = (data) =>
        this.instance.request({
            method: "POST",
            url: apiAddress.imageStatusUpdate_POST(),
            data: data,
            timeout: this.imageTimeout,
        });

    /**
     * getCategoryList
     */
    public getCategoryList = (data?) =>
        this.instance.request({
            method: "GET",
            url: apiAddress.categoryList_GET(),
            params: data,
            timeout: this.defaultTimeout,
        });

    /**
     * postCategoryList
     */
    public postCategoryList = (data) =>
        this.instance.request({
            method: "POST",
            url: apiAddress.categoryAdd_POST(),
            data: data,
            timeout: this.defaultTimeout,
        });

    /**
     * postCategoryStatusUpdate
     */
    public postCategoryStatusUpdate = (data) =>
        this.instance.request({
            method: "PUT",
            url: apiAddress.categoryStatusChange_POST(),
            data: data,
            timeout: this.defaultTimeout,
        });
    /**
     * postCategoryList
     */
    public deleteCategoryList = (data) =>
        this.instance.request({
            method: "DELETE",
            url: apiAddress.categoryDelete_POST(),
            params: data,
            timeout: this.defaultTimeout,
        });

    /**
     * getArticleList
     */
    public getArticleList = () =>
        this.instance.request({
            method: "GET",
            url: apiAddress.articleList_GET(),
            timeout: this.defaultTimeout,
        });

    /**
     * postNewArticle
     */
    public postNewArticle = (data: REQUEST_ArticleNew) =>
        this.instance.request({
            method: "POST",
            url: apiAddress.articleAdd_POST(),
            data: data,
            timeout: this.defaultTimeout,
        });

    /**
     * getSavedArticle
     */
    public getSavedArticle = (id: string) =>
        this.instance.request({
            method: "GET",
            url: apiAddress.article_GET(id),
            timeout: this.defaultTimeout,
        });

    /**
     * postUpdateArticle
     */
    public postUpdateArticle = (data: REQUEST_ArticleEdit, id: string) =>
        this.instance.request({
            method: "POST",
            url: apiAddress.articleEdit_POST(id),
            data: data,
            timeout: this.defaultTimeout,
        });

    /**
     * postUpdateArticle
     */
    public postUpdateArticleStatus = (data) =>
        this.instance.request({
            method: "POST",
            url: `api/article/statusUpdate`,
            data,
            timeout: this.imageTimeout,
        });

    public deleteArticle = (data: REQUEST_ArticleDelete) =>
        this.instance.request({
            method: "POST",
            url: `/api/article/delete`,
            data,
            timeout: this.imageTimeout,
        });

    /**
     * getDownloadCsv
     */
    public getDownloadCsv = (data) =>
        this.instance.request({
            method: "POST",
            url: apiAddress.downloadCSV_POST(),
            data: data,
            timeout: this.imageTimeout,
        });

    /**
     * getAllContentProvider
     */
    public getAllContentProvider = () =>
        this.instance.request({
            method: "GET",
            url: apiAddress.allContentProvider_GET(),
            timeout: this.defaultTimeout,
        });

    /**
     * getDashboardInfo
     */
    public getDashboardInfo = (data) =>
        this.instance.request({
            method: "POST",
            url: apiAddress.dashboardInfo_GET(),
            data: data,
            timeout: this.imageTimeout,
        });

    /**
     * getRejectList
     */
    public getRejectList = () =>
        this.instance.request({
            method: "GET",
            url: apiAddress.rejectList_GET(),
            timeout: this.defaultTimeout,
        });

    /**
     * Account related APIs
     * @function getUserDetails
     * @function postUserDetails
     */
    public account = {
        getUserDetails: () =>
            this.instance.request({
                method: "GET",
                url: apiAddress.userDetails_GET(),
                timeout: this.defaultTimeout,
            }),
        postUserDetails: (data: MODEL_UserDetails) =>
            this.instance.request({
                method: "POST",
                url: apiAddress.userDetailsEdit_POST(),
                data: data,
                timeout: this.defaultTimeout,
            }),
    };

    /**
     * Get available timeslot for booking
     */
    public getAvailableTimeslot = () =>
        this.instance.request({
            method: "GET",
            url: apiAddress.availableTimeslot_GET(),
            timeout: this.defaultTimeout,
        });
    /**
     *
     */
    public postAddBooking = (data) =>
        this.instance.request({
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            url: apiAddress.addBookingTimeslot_POST(),
            data: data,
            timeout: this.defaultTimeout,
        });
    // get stores data
    public getStoreDetails = () =>
        this.instance.request({
            method: "GET",
            url: apiAddress.storeDetails_GET(),
            timeout: this.defaultTimeout,
        });
    /**
     * Shop-in-shop API
     */
    /**
     * Return components that are not disabled
     */
    public getSisAvailableComponents = () =>
        this.instance.request({
            method: "GET",
            url: apiAddress.sisAvaliableComponents_GET(),
            timeout: this.imageTimeout,
        });
    public getSisComponents = (storeID: string) =>
        this.instance.request({
            method: "GET",
            url: apiAddress.sisComponents_GET(storeID),
            timeout: this.imageTimeout,
        });
    public postSisComponentsPreview = (storeID: string, data) =>
        this.instance.request({
            method: "POST",
            url: apiAddress.sisComponentsPreview_POST(storeID),
            data,
            timeout: this.imageTimeout,
        });
    public putSisComponents = (storeID: string, data) =>
        this.instance.request({
            method: "PUT",
            url: apiAddress.sisComponents_PUT(storeID),
            data,
            timeout: this.imageTimeout,
        });
    public getBannerList = (storeID: string) =>
        this.instance.request({
            method: "GET",
            url: apiAddress.banner_GET(),
            params: {
                store: storeID,
            },
            timeout: this.imageTimeout,
        });

    public postBannerList = (data) =>
        this.instance.request({
            method: "POST",
            url: apiAddress.banner_POST(),
            data: data,
            timeout: this.imageTimeout,
        });

    public deleteBannerList = (data) =>
        this.instance.request({
            method: "DELETE",
            url: apiAddress.banner_DELETE(),
            data: data,
            timeout: this.imageTimeout,
        });

    public putSkuIdAdmin = (data, id: string) =>
        this.instance.request({
            method: "PUT",
            url: apiAddress.SkuIdControll_POST(id),
            data: data,
            timeout: this.defaultTimeout,
        });
}

export default Api;
