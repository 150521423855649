import React, { useContext, useEffect } from "react";
import { Grid, createStyles, makeStyles, Theme, Box, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { StoreInfoContext, UserInfoContext } from "../App";
import HKTVmallLogo from "../assets/HKTVmall.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imgSet: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            position: "relative",
            marginBottom: "20px",
        },
        logoSet: {
            marginBottom: "0",
            paddingBottom: "0",
        },
        chooseText: {
            color: "#3a993a",
            fontWeight: "bold",
            height: "24px",
            marginTop: "0",
            paddingTop: "0",
            position: "absolute",
            top: "68%",
        },
    })
);
interface NotFoundPageProps {
    type?: string;
    text?: string;
}

export function StoreNotFoundPage(props: NotFoundPageProps) {
    const classes = useStyles();
    const intl = useIntl();
    const userInfo = useContext(UserInfoContext);
    const storeInfo = useContext(StoreInfoContext);
    let stores = storeInfo?.storesDetails;
    return (
        <React.Fragment>
            {!(userInfo.roles?.length === 0) && !(stores.length === 0) ? (
                <></>
            ) : (
                <Grid item sm={12} xs={6}>
                    <Box className={classes.imgSet}>
                        <img className={classes.logoSet} src={HKTVmallLogo} alt="HKTVmall logo"></img>
                        <h2 className={classes.chooseText}>
                            {intl.formatMessage({ id: "text_608a1f2b06af3400b4290072" })}
                        </h2>
                    </Box>
                    <Typography align="center">
                        {props.text ? props.text : intl.formatMessage({ id: "text_608a1f2b06af3400b429006b" })}
                    </Typography>
                </Grid>
            )}
        </React.Fragment>
    );
}

export default StoreNotFoundPage;
