import React, { useContext } from "react";
import { useIntl } from "react-intl";
import ApproveArticle from "../components/ApproveArticle";
import { ReactComponent as BackIc } from "../assets/admin/ic/back.svg";
import { useHistory } from "react-router-dom";
import { RwdContext } from "../App";

function ApproveArticlePage(props) {
    const intl = useIntl();
    const history = useHistory();
    const device = useContext(RwdContext);

    return (
        <React.Fragment>
            <div
                className={device == "PC" ? "articlePageWrapper pageWrapper" : ""}
                style={device == "PC" ? {} : { width: "100%", height: "100%", padding: "10px" }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <BackIc transform="scale(-1, 1)" onClick={history.goBack} style={{ cursor: "pointer" }} />
                    <span
                        className={device == "PC" ? "title" : ""}
                        style={
                            device == "PC"
                                ? { marginLeft: 12 }
                                : { marginLeft: 10, fontSize: "18px", fontWeight: "bold" }
                        }
                    >
                        {intl.formatMessage({ id: "admin.approve.6" })}
                    </span>
                </div>
                <div className="pageContent">
                    <ApproveArticle articleId={props.location?.state?.articleId}></ApproveArticle>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ApproveArticlePage;
