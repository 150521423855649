import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, createStyles, Grid, makeStyles, styled, Theme } from "@material-ui/core";

import { ReactComponent as BackIcon } from "../assets/editor/icback.svg";

import { ContentContainerBox } from "../components/boxContainers/ContentContainer";
import TextInputWithLabel from "../components/textfield/TextInputWithLabel";
import TextBoxWithLabel from "../components/textfield/TextBoxWithLabel";
import { UtilFunctions } from "../utils/UtilFunctions";
import { ApiServiceContext, RwdContext } from "../App";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import StatusPopup from "../components/dialogs/StatusPopup";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../assets/animations/btn_loading_animation.json";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mobilePageWrapper: {
            width: "100%",
            height: "auto",
            padding: "7px 10px 5px",
            "& .title": {
                display: "flex",
                fontSize: "18px",
                fontWeight: "bold",
                "& span": {
                    paddingLeft: "12px",
                },
                "& span, & svg": {
                    margin: "auto 0",
                },
            },
            "& .flex": {
                display: "flex",
                justifyContent: "space-between",
            },
        },
        pageContent: {
            marginTop: 18,
            "& .flex": {
                display: "flex",
                justifyContent: "space-between",
            },
            "& .title": {
                display: "flex",
                "& span": {
                    paddingLeft: "12px",
                },
                "& span, & svg": {
                    margin: "auto 0",
                },
            },
        },
        field: {
            fontSize: "20px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            color: "#222222",
            display: "flex",
            alignItems: "center",
            marginLeft: 0,
            "@media (max-width : 1280px)": {
                clear: "both",
                fontSize: "14px",
                width: "100%",
                display: "inline-flex",
                alignItems: "center",
            },
        },
        fieldTitle: {
            minWidth: "133px",
            height: "30px",
            margin: "5px 0px",
            textAlign: "right",
            display: "inline-block",
            "@media (max-width : 1280px)": {
                width: "70px",
                height: "auto",
                textAlign: "right",
                fontSize: "14px",
            },
        },
        fieldValue: {
            marginLeft: 20,
        },
        fieldLinkValue: {
            marginLeft: 20,
            color: "#0076ff",
            width: "100%",
            wordBreak: "break-all",
            "@media (max-width : 1280px)": {
                flex: 1,
                fontSize: "14px",
                minWidth: "90px",
            },
        },
        status: {
            position: "absolute",
            top: "19px",
            right: "20px",
            fontFamily: "NotoSansTC",
            fontSize: "18px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "right",
            color: "#222222",
        },
        mobileStatus: {
            top: "19px",
            right: "20px",
            fontFamily: "NotoSansTC",
            fontSize: "14px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "right",
            color: "#222222",
            float: "left",
            marginLeft: 0,
        },
        circle: {
            display: "inline-block",
            marginRight: "8px",
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            "@media (max-width : 1280px)": {
                width: "12px",
                height: "12px",
            },
        },
    })
);
const BookingRecordContainer = styled(ContentContainerBox)({
    marginTop: 12,
    padding: "12px 35px",
    position: "relative",
    "@media (max-width : 1280px)": {
        display: "block",
        padding: "5px 10px",
    },
});
const CancelButton = styled(Button)({
    width: "108px",
    height: "48px",
    padding: "12px 22px",
    background: "#ff5555",
    border: 0,
    borderRadius: 6,
    color: "#ffffff",
    paddingLeft: 17,
    paddingRight: 17,
    fontSize: 16,
});

type CancelTimeSlotStatus = {
    _id: string | undefined;
    openStatus: boolean;
};

const cancelDialogInitState: CancelTimeSlotStatus = {
    _id: undefined,
    openStatus: false,
};

function BookingRecordPage(props) {
    const classes = useStyles();
    const intl = useIntl();
    const apiService = useContext(ApiServiceContext);
    const device = useContext(RwdContext);

    //console.log(props);

    let createTime = props.bookingDetailsPage_Data?.booking?.createTime;
    if (createTime) {
        createTime = UtilFunctions.getFormattedCreateTime(createTime);
    }
    const bookingID = props.bookingDetailsPage_Data?.booking?._id;
    const bookingDate = props.bookingDetailsPage_Data?.booking?.localDate;

    let startTimeRaw = props.bookingDetailsPage_Data?.timeSlot?.filter(
        (t) => t[0]._id == props.bookingDetailsPage_Data?.booking?.timeSlotRefId
    );
    let endTimeRaw = props.bookingDetailsPage_Data?.timeSlot?.filter(
        (t) => t[0]._id == props.bookingDetailsPage_Data?.booking?.timeSlotRefId
    );

    const startTime = UtilFunctions.getFormattedTime(startTimeRaw[0][0].startTime);
    const endTime = UtilFunctions.getFormattedTime(endTimeRaw[0][0].endTime);

    const bookingTime = UtilFunctions.getFormattedTime(startTime) + " - " + UtilFunctions.getFormattedTime(endTime);

    const [singleBookingData, setSingleBookingData] = useState<any>();
    const [bookingStatus, setBookingStatus] = useState<string>(props.bookingDetailsPage_Data?.booking?.status);
    const [rejectOrCancelReason, setRejectOrCancelReason] = useState<string>("");
    const [cancelTimeSlot, setCancelTimeSlot] = useState<CancelTimeSlotStatus>(cancelDialogInitState);

    const [openStatus, setOpenStatus] = useState<boolean>(false);
    const [statusDisplayText, setStatusDisplayText] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [openValidateError, setOpenValidateError] = useState(false);
    const [errMsg, setErrMsg] = useState("general.1.002");

    const handler_reasonReturn = (reason: string) => {
        if (reason == "OTHERS") {
            setRejectOrCancelReason(intl.formatMessage({ id: "editor.liveBooking.1.028b" }));
        } else if (reason == "FULL_BOOKING") {
            setRejectOrCancelReason(intl.formatMessage({ id: "editor.liveBooking.1.028a" }));
        }
    };

    const getSingleBooking = (_id: any) => {
        apiService
            .getSingleBooking(_id)
            .then((res) => {
                if (res.data?.status == "success") {
                    setSingleBookingData(res.data?.data);

                    if (res.data?.data?.rejectCode) {
                        handler_reasonReturn(res.data?.data?.rejectCode);
                    } else if (res.data?.data?.cancelCode) {
                        handler_reasonReturn(res.data?.data?.cancelCode);
                    }
                } else {
                    console.log("fetch single booking fail");
                    if (res.data?.data?.status) {
                        setBookingStatus(res.data?.data?.status);
                    }
                }
            })
            .catch((err) => {
                console.warn(err);
                console.log("fetch single booking fail");
                setErrMsg("general.1.002");
                setOpenValidateError(true);
                // setStatusDisplayText("找不到記錄 請聯絡相關技術人員");
                // setOpenStatus(true);
            });
    };

    const changeBookingStatus_handler = (obj: any) => {
        setIsSubmitting(true);
        apiService
            .postBookingStatus(obj)
            .then((res) => {
                if (res.data?.status == "success" && res.data?.data?.status == "CANCELLED") {
                    setStatusDisplayText("已取消時段");
                    setOpenStatus(true);
                    setCancelTimeSlot(cancelDialogInitState);
                    setBookingStatus(res.data?.data?.status);
                } else {
                    setStatusDisplayText("不能更改審批");
                    setOpenStatus(true);
                }

                setSingleBookingData(res?.data?.data);
            })
            .catch((err) => {
                console.warn(err);
                console.log("Fail to change status");
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const cancel_handler = () => {
        let cancelled_obj = {
            bookingID: bookingID,
            status: "CANCELLED",
            cancelCode: "OTHERS",
            cancelText: "OTHERS",
        };

        changeBookingStatus_handler(cancelled_obj);
    };

    useEffect(() => {
        getSingleBooking(bookingID);
    }, []);

    return (
        <>
            <div className={device == "PC" ? "BookingRecordPageWrapper pageWrapper" : classes.mobilePageWrapper}>
                <div className={device == "PC" ? `pageContent ${classes.pageContent}` : ""}>
                    <div className="BookingRecordTopBar flex">
                        <div className="title" onClick={() => props.onCloseHandler()} style={{ cursor: "pointer" }}>
                            <BackIcon />
                            <span>{intl.formatMessage({ id: "editor.liveBooking.1.010" })}</span>
                        </div>
                        {bookingStatus == "WAITING_FOR_APPROVAL" && (
                            <CancelButton
                                disabled={isSubmitting}
                                onClick={() => setCancelTimeSlot({ _id: "", openStatus: true })}
                            >
                                {isSubmitting ? (
                                    <>
                                        <span style={{ visibility: "hidden" }}>{props.confirmBtnText}</span>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: BtnLoadingAnimation,
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice",
                                                },
                                            }}
                                            width={40}
                                            height={29}
                                            style={{ position: "absolute" }}
                                        />
                                    </>
                                ) : (
                                    <>{intl.formatMessage({ id: "editor.liveBooking.1.019" })}</>
                                )}
                            </CancelButton>
                        )}
                    </div>
                    <BookingRecordContainer>
                        <Status status={bookingStatus} />
                        <div className={classes.field}>
                            <div className={classes.fieldTitle}>
                                {intl.formatMessage({ id: "editor.liveBooking.1.011" })}
                            </div>
                            <span className={classes.fieldValue}>{createTime}</span>
                        </div>
                        {rejectOrCancelReason !== "" && (
                            <div className={classes.field}>
                                <div className={classes.fieldTitle}>
                                    {intl.formatMessage({ id: "editor.liveBooking.1.012" })}
                                </div>
                                <span className={classes.fieldValue}>{rejectOrCancelReason}</span>
                            </div>
                        )}
                        {singleBookingData?.bufferTime && (
                            <div className={classes.field}>
                                <div className={classes.fieldTitle}>
                                    {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.001.14a" })}
                                </div>
                                <span className={classes.fieldValue}>
                                    {singleBookingData?.bufferTime ? singleBookingData?.bufferTime : "N/A"}
                                </span>
                            </div>
                        )}
                        {singleBookingData?.streamInfo?.transcoderServer && (
                            <div className={classes.field}>
                                <div className={classes.fieldTitle}>
                                    {intl.formatMessage({ id: "editor.liveBooking.1.013" })}
                                </div>
                                <span className={classes.fieldLinkValue}>
                                    {singleBookingData?.streamInfo?.transcoderServer
                                        ? singleBookingData?.streamInfo?.transcoderServer
                                        : "N/A"}
                                </span>
                            </div>
                        )}
                        {singleBookingData?.streamInfo?.streamkey && (
                            <div className={classes.field}>
                                <div className={classes.fieldTitle}>
                                    {intl.formatMessage({ id: "editor.liveBooking.1.014" })}
                                </div>
                                <span className={classes.fieldLinkValue}>
                                    {singleBookingData?.streamInfo?.streamkey
                                        ? singleBookingData?.streamInfo?.streamkey
                                        : "N/A"}
                                </span>
                            </div>
                        )}
                    </BookingRecordContainer>
                    <BookingRecordContainer>
                        <TextInputWithLabel
                            label={intl.formatMessage({ id: "editor.liveBooking.1.015" })}
                            value={bookingDate}
                            readOnly={true}
                        />
                        <TextInputWithLabel
                            label={intl.formatMessage({ id: "editor.liveBooking.1.016" })}
                            value={bookingTime}
                            readOnly={true}
                        />

                        {singleBookingData?.skuid &&
                            singleBookingData?.skuid.length > 0 &&
                            singleBookingData?.skuid.map((details, index) => {
                                return (
                                    <TextInputWithLabel
                                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                                        value={details}
                                        readOnly={true}
                                    />
                                );
                            })}

                        <TextBoxWithLabel
                            label={intl.formatMessage({ id: "editor.liveBooking.1.018" })}
                            value={singleBookingData?.remarks ? singleBookingData?.remarks : "N/A"}
                            readOnly={true}
                        />
                    </BookingRecordContainer>
                </div>
            </div>
            <ErrorDialog
                title={intl.formatMessage({ id: "4.038" })}
                content={intl.formatMessage({ id: "editor.liveBooking.1.031" })}
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.033" })}
                confirmHandler={() => {
                    cancel_handler();
                    setCancelTimeSlot({ _id: "", openStatus: false });
                }}
                cancelBtnText={intl.formatMessage({ id: "admin.editTimeSlots.1.052" })}
                cancelHandler={() => setCancelTimeSlot(cancelDialogInitState)}
                openStatus={cancelTimeSlot.openStatus}
                toggleDialog={() => setCancelTimeSlot(cancelDialogInitState)}
            />
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => setOpenValidateError(false)}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <StatusPopup openStatus={openStatus} content={statusDisplayText} toggleDialog={setOpenStatus} />
        </>
    );
}

const Status = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const device = useContext(RwdContext);
    let statusText;
    let statusCircleColor;

    if (props.status == "WAITING_FOR_APPROVAL") {
        statusText = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.012" });
        statusCircleColor = "#0076ff";
    } else if (props.status == "REJECTED") {
        statusText = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.015" });
        statusCircleColor = "#d0021b";
    } else if (props.status == "APPROVED") {
        statusText = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.013" });
        statusCircleColor = "#22ab39";
    } else if (props.status == "CANCELLED") {
        statusText = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.014" });
        statusCircleColor = "#ff8d00";
    }

    return (
        <div className={device == "PC" ? `status ${classes.status}` : classes.mobileStatus}>
            <div>
                <div className={classes.circle} style={{ background: statusCircleColor }} />
                <span>{statusText}</span>
            </div>
        </div>
    );
};

export default BookingRecordPage;
