import React, { useContext, useEffect, useState } from "react";
import { ApiServiceContext } from "../App";
import { REQUEST_ContentProviderList } from "../interfaces";
import { UtilFunctions } from "../utils/UtilFunctions";
import { AdminDashboardContainer, DashboardContainer } from "./boxContainers/DashboardContainer";
import { LoadingIcon } from "./loading/LoadingIcon";
import { ROLE_TYPE } from "../interfaces/enums";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import { useIntl } from "react-intl";
function Dashboard(props) {
    const apiService = useContext(ApiServiceContext);
    const intl = useIntl();
    const [contentProviderList, setContentProviderList] = useState<REQUEST_ContentProviderList[]>([]);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");

    const fetchContentProviderList = () => {
        apiService
            .getAllContentProvider()
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setContentProviderList(res.data.data);
                } else throw new Error("Error Unknown");
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };

    useEffect(() => {
        if (props.isAdmin) fetchContentProviderList();
    }, []);

    return (
        <React.Fragment>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <div className="dashboard" style={{ width: "auto", height: "auto", backgroundColor: "#eeeeee" }}>
                {props.isAdmin ? (
                    contentProviderList ? (
                        <AdminDashboardContainer
                            contentProviderList={contentProviderList.filter((contentProvider) =>
                                contentProvider.roles?.some((o) => [ROLE_TYPE.KOL, ROLE_TYPE.MEDIA].includes(o))
                            )}
                        />
                    ) : (
                        <LoadingIcon />
                    )
                ) : (
                    <DashboardContainer userId={props.userId} />
                )}
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
