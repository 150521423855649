import { Button, Dialog, IconButton, Typography } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, styled, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ReactComponent as CloseIcon } from "../../assets/editor/ic/Close_white.svg";
import { RwdContext } from "../../App";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../../assets/animations/btn_loading_animation.json";

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose?: () => void;
    rwdStatus?: string;
    notError?: boolean;
    disabled?: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
        title: {
            color: theme.palette.error.contrastText,
            fontSize: "20px",
            fontWeight: 600,
            padding: "14px 20px 16px 24px",
        },
        mobileTitle: {
            color: theme.palette.error.contrastText,
            padding: "12px 4px 12px 12px",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(2),
            top: theme.spacing(1.5),
        },
        mobileCloseButton: {
            position: "absolute",
            width: "25px",
            right: theme.spacing(1),
            top: theme.spacing(0.8),
        },
        mobileContent: {
            padding: "12px 4px 12px 12px",
            fontSize: "14px",
            overflow: "hidden",
            "@media (max-width : 1280px)": {
                padding: "10px",
            },
        },
        pcContent: {
            marginTop: "20px",
        },
        mobileButton: {
            padding: "0px 12px 16px 0",
        },
        mobileTitleContent: {
            fontSize: "14px",
            color: "#ffffff",
        },
        mobileCancelButton: {
            fontSize: "14px",
            padding: "8px 22px",
            fontWeight: "bold",
            minHeight: 0,
        },
        mobileConfirmButton: {
            fontSize: "14px",
            padding: "8px 22px",
            minHeight: "100%",
        },
    });

export const ErrorDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, rwdStatus, disabled, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={rwdStatus == "PC" ? classes.title : classes.mobileTitle}
            style={{
                backgroundColor: props.notError ? "#4caf50" : "#ff5555",
            }}
            {...other}
        >
            <Typography variant="inherit" className={rwdStatus == "PC" ? "" : classes.mobileTitleContent}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={rwdStatus == "PC" ? classes.closeButton : classes.mobileCloseButton}
                    onClick={onClose}
                    disabled={disabled}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const ErrorDialogContent = styled(MuiDialogContent)({
    minHeight: "100px",
    fontSize: "16px",
    color: "#222222",
    "& .title": {
        fontSize: "28px",
        fontWeight: 900,
    },
});

export const ErrorDialogActions = styled(MuiDialogActions)({
    fontSize: "16px",
});

export type ErrorDialog = {
    openStatus: boolean;
    toggleDialog: () => void;
    confirmHandler: (e?) => void;
    cancelHandler?: (e?) => void;
    title: string;
    content: string | JSX.Element;
    confirmBtnText: any;
    cancelBtnText?: string;
    onClose?: (e) => void;
    rwdStatus?: string;
    confirmClose?: (e?) => void;
    notError?: boolean;
    disabled?: boolean;
};
export const ErrorDialog = withStyles(styles)((props: ErrorDialog & WithStyles<typeof styles>) => {
    const rwdStatus = useContext(RwdContext);
    const { classes } = props;
    return (
        <Dialog
            maxWidth={"md"}
            scroll={"paper"}
            fullWidth={true}
            aria-labelledby="customized-dialog-title"
            open={props.openStatus}
            onClose={props.onClose}
            transitionDuration={0}
            style={{ borderRadius: "6px" }}
        >
            <ErrorDialogTitle
                id="error-dialog-title"
                notError={props.notError}
                onClose={() => props.toggleDialog()}
                rwdStatus={rwdStatus}
                disabled={props.disabled}
            >
                {props.title}
            </ErrorDialogTitle>
            <ErrorDialogContent className={rwdStatus == "PC" ? classes.pcContent : classes.mobileContent}>
                <div className="content">{props.content}</div>
            </ErrorDialogContent>
            <ErrorDialogActions className={rwdStatus == "PC" ? "" : classes.mobileButton}>
                {props.cancelBtnText && props.cancelHandler && (
                    <Button
                        variant="outlined"
                        onClick={() => props.cancelHandler?.()}
                        color={props.notError ? "primary" : "secondary"}
                        className={rwdStatus == "PC" ? "" : classes.mobileCancelButton}
                        disabled={props.disabled}
                    >
                        {props.cancelBtnText}
                    </Button>
                )}
                <Button
                    variant="contained"
                    onClick={(e) => {
                        props.confirmHandler(e);
                        props.confirmClose && props.confirmClose(e);
                    }}
                    color={props.notError ? "primary" : "secondary"}
                    className={rwdStatus == "PC" ? "" : classes.mobileConfirmButton}
                    disabled={props.disabled}
                >
                    {props.disabled ? (
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: BtnLoadingAnimation,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                },
                            }}
                            width={rwdStatus == "PC" ? 40 : 28}
                            height={rwdStatus == "PC" ? 29 : 20}
                        />
                    ) : (
                        props.confirmBtnText
                    )}
                </Button>
            </ErrorDialogActions>
        </Dialog>
    );
});
