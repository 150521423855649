import React from "react";
import { UtilFunctions } from "../../utils/UtilFunctions";
import { UploadImageButton } from "../buttons/UploadImageButton";
import DragAndDrop from "../DragAndDrop";
import GeneralContainer from "./GeneralContainer";

interface ImageUploadContainerProps {
    imageUrl: string;
    imageOnChange: (e) => void;
    imageOnDelete: (e) => void;
    id: string;
    containerTitle?: string;
    containerRemark?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    imageInvalid?: () => void;
}

export const ImageUploadContainer = (props: ImageUploadContainerProps) => {
    const { children, containerTitle, containerRemark, imageUrl, imageOnChange, imageOnDelete } = props;

    const renderHeaderText = () => {
        return (
            <>
                {containerTitle ? <div className="containerTitle">{containerTitle}</div> : null}
                {containerRemark ? <div className="containerRemark">{containerRemark}</div> : null}
            </>
        );
    };
    return (
        <GeneralContainer style={props.style}>
            {renderHeaderText()}
            <input
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
                id={props.id}
                type="file"
                onChange={(e) => {
                    if (!UtilFunctions.isValidImageExt(e.target.value)) {
                        if (props.imageInvalid) {
                            props.imageInvalid();
                            return;
                        }
                    }

                    props.imageOnChange(e.currentTarget?.files ? e : undefined);
                }}
            />
            <label htmlFor={props.id}>
                <UploadImageButton id="uploadCoverImage" imgSrc={imageUrl} onClose={imageOnDelete}>
                    <DragAndDrop
                        handleDrop={(file) => {
                            if (!UtilFunctions.isValidImageExt(file.name)) {
                                if (props.imageInvalid) props.imageInvalid();
                            }
                            const wrap_like_an_event = {
                                currentTarget: {
                                    files: [file],
                                },
                            };
                            props.imageOnChange(wrap_like_an_event);
                        }}
                    >
                        {props.children}
                    </DragAndDrop>
                </UploadImageButton>
            </label>
        </GeneralContainer>
    );
};
