import { Box, withStyles } from "@material-ui/core";

const GeneralContainer = withStyles({
    root: {
        background: "#ffffff",
        border: 0,
        borderRadius: 6,
        color: "#222222",
        padding: "12px 16px 12px 16px",
        "& .containerTitle": {
            fontSize: "22px",
            fontWeight: "bold",
            color: "#222222",
            paddingBottom: "13px",
            "@media (max-width : 1280px)": {
                fontSize: "1.2rem",
                // fontWeight : "normal",
            },
        },
        "& .containerRemark": {
            fontSize: "16px",
            color: "#222222",
            marginBottom: "8px",
        },
    },
})(Box);

export default GeneralContainer;
