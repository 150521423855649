import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";
import { Button, createStyles, makeStyles, styled, Theme } from "@material-ui/core";
import { UserInfoContext, ApiServiceContext, RwdContext } from "../App";
import { UtilFunctions } from "../utils/UtilFunctions";
import { KolLiveBookingRejectReason, RoutePath } from "../interfaces/enums";
import { ContentContainerBox } from "../components/boxContainers/ContentContainer";
import SelectInputWithLabel from "../components/textfield/SelectInputWithLabel";
import TextInputWithLabel from "../components/textfield/TextInputWithLabel";
import TextBoxWithLabel from "../components/textfield/TextBoxWithLabel";
import { ROLE_TYPE } from "../interfaces/enums";
import StatusPopup from "../components/dialogs/StatusPopup";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import { useCallbackPrompt } from "../hooks/PromptHook";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../assets/animations/btn_loading_animation.json";

type option = { name: string; value: string };

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContent: {
            marginTop: 18,
            "& .flex": {
                display: "flex",
                justifyContent: "space-between",
            },
        },
        mobilePage: {
            width: "100%",
            padding: "11px 10px",
            "& .title": {
                fontSize: "22px",
                fontWeight: "bold",
                textAlign: "left",
            },
        },
    })
);
const LiveBookingFormContainer = styled(ContentContainerBox)({
    marginTop: 32,
    padding: "2px 24px",
    "@media (max-width : 1280px)": {
        marginTop: 10,
        padding: "11px 10px 16px",
        overflow: "hidden",
    },
});
const SubmitButton = styled(Button)({
    width: "108px",
    height: "48px",
    background: "#22ab39",
    border: 0,
    borderRadius: 6,
    color: "#ffffff",
    fontSize: 16,
    "&:disabled": {
        color: "#ffffff",
        opacity: 0.2,
    },
    "@media (max-width : 1280px)": {
        width: "100%",
    },
});

const BlockingAlert = ({ isBlocking }) => {
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isBlocking);
    const { formatMessage } = useIntl();
    return (
        <ErrorDialog
            title={formatMessage({ id: "editor.liveBooking.1.026" })}
            content={
                <div style={{ height: "82px" }}>
                    {formatMessage({ id: "editor.liveBooking.1.027" }, { lineBreak: <br /> })}
                </div>
            }
            confirmBtnText={formatMessage({ id: "editor.liveBooking.1.029" })}
            cancelBtnText={formatMessage({ id: "editor.liveBooking.1.028" })}
            confirmHandler={confirmNavigation as () => void}
            cancelHandler={cancelNavigation as () => void}
            openStatus={showPrompt as boolean}
            toggleDialog={cancelNavigation as () => void}
        />
    );
};

function LiveBookingPage(props) {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const apiService = useContext(ApiServiceContext);
    const device = useContext(RwdContext);

    // Form values
    const [date, setDate] = useState("placeholder");
    const [selectedDate, setSelectedDate] = useState("placeholder");
    const [time, setTime] = useState("placeholder");
    const [link1, setLink1] = useState("");
    const [link2, setLink2] = useState("");
    const [link3, setLink3] = useState("");
    const [link4, setLink4] = useState("");
    const [link5, setLink5] = useState("");
    const [link6, setLink6] = useState("");
    const [link7, setLink7] = useState("");
    const [link8, setLink8] = useState("");
    const [link9, setLink9] = useState("");
    const [link10, setLink10] = useState("");
    const [link11, setLink11] = useState("");
    const [link12, setLink12] = useState("");
    const [link13, setLink13] = useState("");
    const [link14, setLink14] = useState("");
    const [link15, setLink15] = useState("");
    const [link16, setLink16] = useState("");
    const [link17, setLink17] = useState("");
    const [link18, setLink18] = useState("");
    const [link19, setLink19] = useState("");
    const [link20, setLink20] = useState("");
    const [link21, setLink21] = useState("");
    const [link22, setLink22] = useState("");
    const [link23, setLink23] = useState("");
    const [link24, setLink24] = useState("");
    const [link25, setLink25] = useState("");
    const [link26, setLink26] = useState("");
    const [link27, setLink27] = useState("");
    const [link28, setLink28] = useState("");
    const [link29, setLink29] = useState("");
    const [link30, setLink30] = useState("");
    const [remark, setRemark] = useState("");

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [failedRes, setFailedRes] = useState(false);
    // Select options
    const [availableDates, setAvailableDates] = useState<option[]>([]);
    const [availableTimeslots, setAvailableTimeslots] = useState<option[]>([]);

    // Pop-up open
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [openValidateError, setOpenValidateError] = useState(false);
    const [errMsg, setErrMsg] = useState("general.1.002");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fetchAvailableTimeslot = () => {
        apiService
            .getAvailableTimeslot()
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    let dates: option[] = [];
                    let timeslots: option[] = [];

                    // set availableDates
                    if (res.data.data && res.data.data.length) {
                        let response = res.data.data;
                        let temp_data = { year: "", month: "" };
                        for (let k = 0; k < response.length; k++) {
                            let current = res.data.data[k];

                            if (current.year === temp_data.year && current.month === temp_data.month) continue;
                            else {
                                let [start, end] = UtilFunctions.getStartEndDay(`${current.year}-${current.month}`);
                                if (current.month === parseInt(moment().format("M")))
                                    start = moment().format("DD").toString();
                                for (var i = start; i <= end; i++) {
                                    var date = moment(new Date(`${current.year}-${current.month}-${i}`));

                                    dates.push({
                                        name: date.format("YYYY年M月D日"),
                                        value: date.format("YYYY-MM-DD"),
                                    });
                                }
                            }

                            temp_data = current;
                        }
                    }
                    setAvailableDates(dates);

                    // set availableTimeslots
                    if (selectedDate !== "placeholder") {
                        res.data.data.forEach((o) => {
                            var hms = moment().format("YYYY-MM-DD").toString() + " " + o.startTime;
                            var date1 = new Date(hms);
                            var currentTime = new Date();
                            var diff = date1.getTime() - currentTime.getTime();
                            if (o.month === parseInt(selectedDate.split("-")[1])) {
                                if (selectedDate !== moment().format("YYYY-MM-DD").toString())
                                    timeslots.push({
                                        name: o.startTime.substring(0, 5) + " - " + o.endTime.substring(0, 5),
                                        value: o._id,
                                    });
                                else {
                                    if (diff / 60000 >= 90) {
                                        timeslots.push({
                                            name: o.startTime.substring(0, 5) + " - " + o.endTime.substring(0, 5),
                                            value: o._id,
                                        });
                                    }
                                }
                            }
                        });
                    }

                    setAvailableTimeslots(timeslots);
                } else throw new Error("Error Unknown");
            })
            .catch((err) => {
                setFailedRes(true);
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };

    const isFormChanged = () => {
        if (isSubmitted) return false;
        if (date !== "placeholder") return true;
        if (time !== "placeholder") return true;
        if (link1 !== "") return true;
        if (link2 !== "") return true;
        if (link3 !== "") return true;
        if (link4 !== "") return true;
        if (link5 !== "") return true;
        if (link6 !== "") return true;
        if (link7 !== "") return true;
        if (link8 !== "") return true;
        if (link9 !== "") return true;
        if (link10 !== "") return true;
        if (link11 !== "") return true;
        if (link12 !== "") return true;
        if (link13 !== "") return true;
        if (link14 !== "") return true;
        if (link15 !== "") return true;
        if (link16 !== "") return true;
        if (link17 !== "") return true;
        if (link18 !== "") return true;
        if (link19 !== "") return true;
        if (link20 !== "") return true;
        if (link21 !== "") return true;
        if (link22 !== "") return true;
        if (link23 !== "") return true;
        if (link24 !== "") return true;
        if (link25 !== "") return true;
        if (link26 !== "") return true;
        if (link27 !== "") return true;
        if (link28 !== "") return true;
        if (link29 !== "") return true;
        if (link30 !== "") return true;
        if (remark !== "") return true;
        return false;
    };
    const validateForm: () => boolean = () => {
        if (typeof date !== "string" || date === "" || date === "placeholder") {
            return false;
        }
        if (typeof time !== "string" || time === "" || time === "placeholder") {
            return false;
        }
        return true;
    };
    const onSubmit: () => void = () => {
        const data = {
            timeSlotID: time,
            localDate: date,
            skuid: [
                link1,
                link2,
                link3,
                link4,
                link5,
                link6,
                link7,
                link8,
                link9,
                link10,
                link11,
                link12,
                link13,
                link14,
                link15,
                link16,
                link17,
                link18,
                link19,
                link20,
                link21,
                link22,
                link23,
                link24,
                link25,
                link26,
                link27,
                link28,
                link29,
                link30,
            ]
                .map((o) => o.trim())
                .filter((o) => typeof o === "string" && o !== ""),
            remarks: remark.trim(),
        };
        setIsSubmitting(true);
        apiService
            .postAddBooking(data)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setIsSubmitted(true);
                    setOpenSuccessPopup(true);
                } else {
                    if (res && res.data && res.data.code) {
                        switch (res.data.code) {
                            case KolLiveBookingRejectReason.OVERLAP_TIMEFRAME:
                                setErrMsg("editor.liveBooking.1.037");
                                setOpenValidateError(true);
                                break;
                            case KolLiveBookingRejectReason.TIME_FRAME_MAX_BOOKING_REACHED:
                                setErrMsg("editor.liveBooking.1.024e");
                                setOpenValidateError(true);
                                break;
                            case KolLiveBookingRejectReason.TIMESLOT_QUOTA_FULL:
                                setErrMsg("editor.liveBooking.1.024a");
                                setOpenValidateError(true);
                                break;
                            case KolLiveBookingRejectReason.BOOKING_QUOTA_FULL:
                                setErrMsg("editor.liveBooking.1.024b");
                                setOpenValidateError(true);
                                break;
                            case KolLiveBookingRejectReason.TIMESLOT_BOOKED:
                                setErrMsg("editor.liveBooking.1.024c");
                                setOpenValidateError(true);
                                break;
                            case KolLiveBookingRejectReason.APPROVED_BOOKINGS_QUOTA_FOR_TIMESLOT_REACHED:
                                setErrMsg("editor.liveBooking.1.024d");
                                setOpenValidateError(true);
                                break;
                            default:
                                throw new Error("Unknown Error Code");
                        }
                    } else throw new Error("Error Code Not Found");
                }
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    useEffect(() => {
        fetchAvailableTimeslot();
    }, [selectedDate]);
    const { roles } = useContext(UserInfoContext);
    return (
        <div className={device == "PC" ? "liveBookingPageWrapper pageWrapper" : classes.mobilePage}>
            <div className={device == "PC" ? `pageContent ${classes.pageContent}` : ""}>
                <div className="liveBookingTopBar flex">
                    <div className="title">{intl.formatMessage({ id: "editor.liveBooking.1.002" })}</div>
                    {device == "PC" && (
                        <SubmitButton disabled={isSubmitting || !validateForm()} onClick={() => onSubmit()}>
                            {isSubmitting ? (
                                <>
                                    <span style={{ visibility: "hidden" }}>{props.confirmBtnText}</span>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: BtnLoadingAnimation,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice",
                                            },
                                        }}
                                        width={40}
                                        height={29}
                                        style={{ position: "absolute" }}
                                    />
                                </>
                            ) : (
                                <>{intl.formatMessage({ id: "editor.liveBooking.1.021" })}</>
                            )}
                        </SubmitButton>
                    )}
                </div>

                <LiveBookingFormContainer>
                    <SelectInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.015" })}
                        placeholder={intl.formatMessage({ id: "editor.liveBooking.1.034" })}
                        options={availableDates}
                        value={date}
                        onChange={(val) => {
                            setSelectedDate(val);
                            setDate(val);
                        }}
                    />
                    <SelectInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.016" })}
                        placeholder={intl.formatMessage({ id: "editor.liveBooking.1.035" })}
                        options={availableTimeslots}
                        value={time}
                        onChange={(val) => {
                            setTime(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link1}
                        onChange={(val) => {
                            setLink1(val);
                        }}
                        hint={intl.formatMessage({ id: "editor.liveBooking.1.022" }, { lineBreak: <br /> })}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link2}
                        onChange={(val) => {
                            setLink2(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link3}
                        onChange={(val) => {
                            setLink3(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link4}
                        onChange={(val) => {
                            setLink4(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link5}
                        onChange={(val) => {
                            setLink5(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link6}
                        onChange={(val) => {
                            setLink6(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link7}
                        onChange={(val) => {
                            setLink7(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link8}
                        onChange={(val) => {
                            setLink8(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link9}
                        onChange={(val) => {
                            setLink9(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link10}
                        onChange={(val) => {
                            setLink10(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link11}
                        onChange={(val) => {
                            setLink11(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link12}
                        onChange={(val) => {
                            setLink12(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link13}
                        onChange={(val) => {
                            setLink13(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link14}
                        onChange={(val) => {
                            setLink14(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link15}
                        onChange={(val) => {
                            setLink15(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link16}
                        onChange={(val) => {
                            setLink16(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link17}
                        onChange={(val) => {
                            setLink17(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link18}
                        onChange={(val) => {
                            setLink18(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link19}
                        onChange={(val) => {
                            setLink19(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link20}
                        onChange={(val) => {
                            setLink20(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link21}
                        onChange={(val) => {
                            setLink21(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link22}
                        onChange={(val) => {
                            setLink22(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link23}
                        onChange={(val) => {
                            setLink23(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link24}
                        onChange={(val) => {
                            setLink24(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link25}
                        onChange={(val) => {
                            setLink25(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link26}
                        onChange={(val) => {
                            setLink26(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link27}
                        onChange={(val) => {
                            setLink27(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link28}
                        onChange={(val) => {
                            setLink28(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link29}
                        onChange={(val) => {
                            setLink29(val);
                        }}
                    />
                    <TextInputWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.017" })}
                        value={link30}
                        onChange={(val) => {
                            setLink30(val);
                        }}
                    />
                    <TextBoxWithLabel
                        label={intl.formatMessage({ id: "editor.liveBooking.1.018" })}
                        value={remark}
                        onChange={(val) => {
                            setRemark(val);
                        }}
                    />
                </LiveBookingFormContainer>
                {device == "mobile" && (
                    <SubmitButton disabled={isSubmitting || !validateForm()} onClick={() => onSubmit()}>
                        {isSubmitting ? (
                            <>
                                <span style={{ visibility: "hidden" }}>{props.confirmBtnText}</span>
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: BtnLoadingAnimation,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                    width={40}
                                    height={29}
                                    style={{ position: "absolute" }}
                                />
                            </>
                        ) : (
                            <>{intl.formatMessage({ id: "editor.liveBooking.1.021" })}</>
                        )}
                    </SubmitButton>
                )}
            </div>

            {/* Upload success dialog` */}

            <StatusPopup
                openStatus={openSuccessPopup}
                content={
                    roles.includes(ROLE_TYPE.ADMIN) || roles.includes(ROLE_TYPE.LIVE_ADMIN)
                        ? intl.formatMessage({ id: "editor.liveBooking.1.038" })
                        : intl.formatMessage({ id: "editor.liveBooking.1.02" })
                }
                toggleDialog={() => history.push(RoutePath.LIVE_BOOKING_HISTORY)}
            />
            {/* Error dialog` */}
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => setOpenValidateError(false)}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={
                    failedRes
                        ? intl.formatMessage({ id: "editor.liveBooking.1.039" })
                        : intl.formatMessage({ id: "editor.liveBooking.1.025" })
                }
            />
            {/* Unsaved warning dialog */}
            <BlockingAlert isBlocking={isFormChanged()} />
        </div>
    );
}

export default LiveBookingPage;
