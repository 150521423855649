import {
    Button,
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    Theme,
    WithStyles,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import QRCode from "qrcode";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { ApiServiceContext, UnknowErrorDialogContext, RwdContext } from "../../App";
import { ReactComponent as DownloadDataBtn } from "../../assets/admin/ic/download.svg";
import { UtilFunctions } from "../../utils/UtilFunctions";
import { ContentContainerBox } from "./ContentContainer";
import { CSVLink } from "react-csv";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-lineheight-annotation";
import { ErrorDialog } from "../../components/dialogs/ErrorDialog";

const styles = (theme: Theme) =>
    createStyles({
        dashboardContainerBox: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            flexFlow: "row",
            paddingTop: "0px",
            paddingBottom: "0px",
            "& .selectContentProviderTitle": {
                flexBasis: "100%",
                paddingTop: "12px",
            },
            "& .selectContentProvider": {
                flexBasis: "100%",
            },
            "& .borderBottom": {
                borderBottom: "solid 1px #cccccc",
                paddingBottom: "12px",
            },
            "& .downloadData": {
                margin: 0,
                padding: "12px 12px 5.5px 12px",
                flexBasis: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                "& .containerTitle": {
                    fontSize: "36px",
                    fontWeight: "bold",
                },
                "& a": {
                    textDecoration: "none",
                },
                "& .downloadBtn": {
                    "& svg": {
                        display: "inline-block",
                        verticalAlign: "middle",
                    },
                },
            },
            "& .graphSection": {
                flexBasis: "100%",
                width: "100%",
                margin: 0,
                padding: "15.5px 16px",
                "& .sectionTitle": {
                    padding: 0,
                },
                "& .statistic": {
                    color: "#2222222",
                    "& .value": {
                        fontSize: "32px",
                        fontWeight: "bold",
                        paddingTop: "16px",
                    },
                    "& .desc": {
                        fontSize: "20px",
                        paddingTop: "6px",
                        display: "inline-block",
                    },
                    "& .increase": {
                        display: "inline-block",
                        paddingLeft: "14px",
                        fontSize: "24px",
                        color: "#22ab39",
                        "& .arrowUp": {
                            display: "inline-block",
                            width: 0,
                            height: 0,
                            borderLeft: "12px solid transparent",
                            borderRight: "12px solid transparent",
                            borderBottom: "15.5px solid #22ab39",
                        },
                    },
                    "& .decrease": {
                        display: "inline-block",
                        paddingLeft: "14px",
                        fontSize: "24px",
                        color: "#df2929",
                        "& .arrowDown": {
                            display: "inline-block",
                            width: 0,
                            height: 0,
                            borderLeft: "12px solid transparent",
                            borderRight: "12px solid transparent",
                            borderTop: "15.5px solid #df2929",
                        },
                    },
                },
                "& canvas": {
                    paddingTop: "14px",
                    maxWidth: "100%",
                    maxHeight: "270px",
                },
            },
            "& .dashboardInfo": {
                "& .sectionTitle": {
                    fontSize: "26px",
                    fontWeight: "bold",
                    color: "#222222",
                },
                "& .content": {
                    fontSize: "42px",
                    fontWeight: "bold",
                    color: "#222222",
                    padding: "100px 8px",
                },
            },
            "& .row": {
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                margin: 0,
                "&:not(:last-child)": {
                    borderBottom: "solid 1px #cccccc",
                },
                "& > *": {
                    width: "100%",
                    textAlign: "left",
                    paddingBottom: "12px",
                    "& .statistic": {
                        paddingLeft: "8px",
                        paddingTop: "60px",
                        paddingBottom: "109px",
                        color: "#2222222",
                        "& .value": {
                            fontSize: "32px",
                            fontWeight: "bold",
                        },
                        "& .desc": {
                            fontSize: "20px",
                            paddingTop: "6px",
                        },
                    },
                    "&:not(:last-child)": {
                        borderRight: "solid 1px #cccccc",
                    },
                },
            },
            "& .sectionTitle": {
                fontSize: "20px",
                fontWeight: "bold",
                padding: "12px 8px",
            },
        },
        dashboardContainerBoxmobile: {
            width: "auto",
            height: "auto",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            flexFlow: "row",
            paddingTop: "0px",
            paddingBottom: "0px",
            margin: "8px 8px 25.5px 8px",
            "& .selectContentProviderTitle": {
                flexBasis: "100%",
                paddingTop: "12px",
            },
            "& .selectContentProvider": {
                flexBasis: "100%",
            },
            "& .borderBottom": {
                borderBottom: "solid 1px #cccccc",
                paddingBottom: "12px",
            },
            "& .downloadData": {
                margin: 0,
                padding: "12px 12px 5.5px 12px",
                flexBasis: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                "& .containerTitle": {
                    fontSize: "36px",
                    fontWeight: "bold",
                },
                "& a": {
                    textDecoration: "none",
                },
                "& .downloadBtn": {
                    padding: 0,
                    "& svg": {
                        verticalAlign: "middle",
                    },
                },
            },
            "& .graphSection": {
                flexBasis: "100%",
                width: "100%",
                margin: "6px 0 11px 8px",
                "& .sectionTitle": {
                    padding: 0,
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "left",
                    color: "#222222",
                },
                "& .statistic": {
                    color: "#2222222",
                    "& .value": {
                        fontSize: "14px",
                        fontWeight: "bold",
                        paddingTop: "4px",
                    },
                    "& .desc": {
                        fontSize: "12px",
                        display: "inline-block",
                        paddingTop: "6px",
                        color: "#222222",
                        textAlign: "left",
                    },
                    "& .increase": {
                        display: "inline-block",
                        paddingLeft: "14px",
                        fontSize: "14px",
                        color: "#22ab39",
                        "& .arrowUp": {
                            display: "inline-block",
                            width: 0,
                            height: 0,
                            borderLeft: "12px solid transparent",
                            borderRight: "12px solid transparent",
                            borderBottom: "15.5px solid #22ab39",
                        },
                    },
                    "& .decrease": {
                        display: "inline-block",
                        paddingLeft: "14px",
                        fontSize: "24px",
                        color: "#df2929",
                        "& .arrowDown": {
                            display: "inline-block",
                            width: 0,
                            height: 0,
                            borderLeft: "12px solid transparent",
                            borderRight: "12px solid transparent",
                            borderTop: "15.5px solid #df2929",
                        },
                    },
                    "& .dashboardData": {
                        textAlign: "center",
                    },
                },
                "& canvas": {
                    paddingTop: "14px",
                    maxWidth: "100%",
                    maxHeight: "270px",
                },
            },
            "& .dashboardInfo": {
                padding: "11px 0px 11px 8px",
                "& .sectionTitle": {
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#222222",
                },
                "& .content": {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#222222",
                },
            },
            "& .row": {
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                flexFlow: "colunmn",
                justifyContent: "space-around",
                marginBottom: "11px",
                "&:not(:last-child)": {
                    borderBottom: "solid 1px #cccccc",
                },
                "& > *": {
                    width: "100%",
                    textAlign: "left",
                    paddingBottom: "12px",
                    "& .statistic": {
                        color: "#2222222",
                        "& .value": {
                            fontSize: "18px",
                            fontWeight: "bold",
                        },
                        "& .desc": {
                            fontSize: "14px",
                            paddingTop: "6px",
                        },
                    },
                    "&:not(:last-child)": {
                        borderBottom: "solid 1px #cccccc",
                    },
                },
            },
            "& .sectionTitle": {
                fontSize: "14px",
                color: "#222222",
                fontWeight: "bold",
                marginBottom: "11.5px",
            },
        },
    });

const csvHeaders = [
    {
        label: "Date",
        key: "date",
    },
    {
        label: "ID",
        key: "contentId",
    },
    {
        label: "Number of followers",
        key: "followers",
    },
    {
        label: "Total commission",
        key: "totalCommission",
    },
    {
        label: "Total Purchased Amounts from Followers",
        key: "totalOrderAmount",
    },
];

const csvDataParser = (apiData, intl) => {
    let csvData = apiData.reduce((csvData, userRecord) => {
        userRecord.data.map((dateRecord) => {
            csvData.push({
                contentId: dateRecord.userId,
                date: moment(dateRecord.timestamp).format("YYYY-MM-DD"),
                followers: dateRecord.followers,
                totalCommission: dateRecord.totalCommission,
                totalOrderAmount: dateRecord.totalOrderAmount,
            });
        });
        return csvData;
    }, []);

    let graph = csvData.reduce(
        (graph, userRecord) => {
            graph.labels.push(moment(userRecord.date, "YYYY-MM-DD"));
            graph.data.push(userRecord.followers);
            return graph;
        },
        {
            labels: [],
            data: [],
        }
    );

    let graphData = {
        labels: graph.labels,
        datasets: [
            {
                display: false,
                label: intl.formatMessage({ id: "editor.dashboard.2.0041" }),
                data: graph.data,
                fill: true,
                backgroundColor: "#bde5c3",
                borderColor: "#22ab39",
                lineTension: 0,
                pointRadius: 3,
                pointHoverRadius: 8,
                pointHoverBackgroundColor: "#000000",
                pointHoverBorderColor: "#000000",
            },
        ],
    };

    return {
        csvData: csvData,
        graphData: graphData,
    };
};

const numDigits = (num) => {
    return Math.max(Math.floor(Math.log10(Math.abs(num))), 0) + 1;
};
const getChartOption = (dataset, device) => {
    let maxData = Math.max(...dataset.data);
    let minData = Math.min(...dataset.data);
    let stepSize = Math.pow(10, numDigits(maxData) - 2);
    return {
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    ticks: {
                        maxTicksLimit: device == "PC" ? 5.1 : 3.1,
                        maxRotation: 0,
                        minRotation: 0,
                    },
                    type: "time",
                    time: {
                        tooltipFormat: "DD/MM/YYYY",
                        displayFormats: {
                            millisecond: "DD/MM/YYYY",
                            second: "DD/MM/YYYY",
                            minute: "DD/MM/YYYY",
                            hour: "DD/MM/YYYY",
                            day: "DD/MM/YYYY",
                            week: "DD/MM/YYYY",
                            month: "DD/MM/YYYY",
                            quarter: "DD/MM/YYYY",
                            year: "DD/MM/YYYY",
                        },
                    },
                    distribution: "linear",
                    gridLines: {
                        display: false,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        suggestedMax: (maxData / stepSize) * stepSize + stepSize,
                        min: Math.max(minData - stepSize, 0),
                        stepSize: stepSize,
                    },
                },
            ],
        },
        tooltips: {
            backgroundColor: "#ffffff",
            titleFontColor: "#22ab39",
            bodyFontColor: "#22ab39",
            borderColor: "#cccccc",
            borderWidth: 0.5,
            mode: "index",
            intersect: false,
        },
        hover: {
            mode: "index",
            intersect: false,
        },
        lineHeightAnnotation: {
            color: "#000000",
            always: false,
            hover: true,
            noDash: true,
            lineWeight: 1.5,
        },
    };
};

export interface DashboardContainerProps extends WithStyles<typeof styles> {
    userId: string;
}

export const DashboardContainer = withStyles(styles)((props: DashboardContainerProps) => {
    const intl = useIntl();
    const device = useContext(RwdContext);
    const { classes } = props;
    const url = `${process.env.REACT_APP_CDN_DOMAIN}/zh/media/content_partner/${props.userId}/`;
    const dateStart = moment().subtract(28, "days");
    const dateEnd = moment().subtract(1, "days"); // Exclude today
    const apiService = useContext(ApiServiceContext);
    const [csvData, setCsvData] = useState();
    const [graphData, setGraphData] = useState();
    const [unknowErrorDialog, setUnknowErrorDialog] = useContext(UnknowErrorDialogContext);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");
    const downloadQrCodeHandler = () => {
        const canvas = document.getElementById("canvas") as HTMLCanvasElement;
        if (canvas) {
            const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `${props.userId}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    const fetchCsvData = () => {
        let data = {
            from: dateStart.unix() * 1000,
            to: dateEnd.unix() * 1000,
        };
        apiService
            .getDownloadCsv(data)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    let parsedData = csvDataParser(res.data.data, intl);
                    setCsvData(parsedData?.csvData);
                    setGraphData(parsedData?.graphData);
                } else {
                    setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                    throw new Error("Error Unknown");
                }
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };

    useEffect(() => {
        if (document.getElementById("canvas"))
            QRCode.toCanvas(document.getElementById("canvas"), url, function (error) {
                if (error) console.error(error);
            });
    });

    useEffect(() => {
        fetchCsvData();
    }, []);

    return (
        <>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <ContentContainerBox
                className={device == "PC" ? classes.dashboardContainerBox : classes.dashboardContainerBoxmobile}
            >
                <div className="downloadData borderBottom">
                    <div className="desc">
                        <div style={{ fontSize: "12px" }}>
                            {intl.formatMessage(
                                { id: "editor.dashboard.2.002a" },
                                {
                                    startYear: dateStart.format("YYYY"),
                                    startMonth: dateStart.format("MM"),
                                    startDate: dateStart.format("DD"),
                                    endYear: dateEnd.format("YYYY"),
                                    endMonth: dateEnd.format("MM"),
                                    endDate: dateEnd.format("DD"),
                                }
                            )}
                        </div>
                        <div style={{ fontSize: "12px" }}>{intl.formatMessage({ id: "editor.dashboard.2.002b" })}</div>
                    </div>

                    {csvData?.length > 0 && (
                        <CSVLink
                            id={"csvDownload"}
                            filename={`HKTV Blog Data ${dateStart.format("YYYY-MM-DD")} - ${dateEnd.format(
                                "YYYY-MM-DD"
                            )}.csv`}
                            key={csvData.length}
                            data={csvData}
                            headers={csvHeaders}
                            target="_self"
                        >
                            <Button className="downloadBtn">
                                <DownloadDataBtn />
                                <div style={{ fontSize: "14px" }}>
                                    {intl.formatMessage({ id: "admin.dashboard.1.01" })}
                                </div>
                            </Button>
                        </CSVLink>
                    )}
                </div>
                <div className="qrCode">
                    <div className="sectionTitle">{intl.formatMessage({ id: "editor.dashboard.2.01" })}</div>
                    <canvas id="canvas"></canvas>
                    <Button
                        className="downloadQrCodeBtn"
                        style={{ textAlign: "left", display: "block", fontSize: "14px" }}
                        onClick={() => downloadQrCodeHandler()}
                    >
                        {intl.formatMessage({ id: "editor.dashboard.2.011" })}
                    </Button>
                </div>
                {graphData && csvData.length > 0 && (
                    <React.Fragment>
                        <div className={"graphSection borderBottom"}>
                            <div className="sectionTitle">{intl.formatMessage({ id: "editor.dashboard.2.004" })}</div>
                            <div className="statistic">
                                <div className="value">
                                    <span>{csvData?.[csvData.length - 1]?.followers}</span>
                                </div>
                                <div className="desc">{intl.formatMessage({ id: "editor.dashboard.2.005" })}</div>
                                {csvData?.[csvData.length - 1].followers - csvData[0].followers >= 0 ? (
                                    <div className="increase">
                                        <div className="arrowUp"></div>
                                        {Math.floor(
                                            ((csvData?.[csvData.length - 1].followers - csvData[0].followers) /
                                                csvData[0].followers) *
                                                100 *
                                                100
                                        ) / 100}
                                        %
                                    </div>
                                ) : (
                                    <div className="decrease">
                                        <div className="arrowDown"></div>
                                        {Math.floor(
                                            (Math.abs(csvData?.[csvData.length - 1].followers - csvData[0].followers) /
                                                csvData[0].followers) *
                                                100 *
                                                100
                                        ) / 100}
                                        %
                                    </div>
                                )}
                            </div>
                            <div className="dashboardData">
                                <Line
                                    height={270}
                                    options={getChartOption(graphData.datasets[0], device)}
                                    legend={{ display: false }}
                                    data={graphData}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="commission">
                                <div className="sectionTitle">
                                    {intl.formatMessage({ id: "editor.dashboard.2.0071" })}
                                </div>
                                <div className="statistic">
                                    <div className="value">
                                        $
                                        {csvData
                                            ?.reduce(
                                                (totalCommission, dataOfTheDay) =>
                                                    totalCommission + dataOfTheDay.totalCommission,
                                                0
                                            )
                                            .toFixed(2)}
                                    </div>
                                    <div className="desc">{intl.formatMessage({ id: "editor.dashboard.2.007" })}</div>
                                </div>
                                <div></div>
                            </div>
                            <div className="orderAmount">
                                <div className="sectionTitle">
                                    {intl.formatMessage({ id: "editor.dashboard.2.008" })}
                                </div>
                                <div className="statistic">
                                    <div className="value">
                                        $
                                        {csvData
                                            ?.reduce(
                                                (totalOrder, dataOfTheDay) =>
                                                    totalOrder + dataOfTheDay.totalOrderAmount,
                                                0
                                            )
                                            .toFixed(2)}
                                    </div>
                                    <div className="desc">
                                        {intl.formatMessage(
                                            { id: "editor.dashboard.2.009" },
                                            { date: dateEnd.format("DD/MM/YYYY") }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </ContentContainerBox>
        </>
    );
});

export interface AdminDashboardContainerProps extends WithStyles<typeof styles> {
    contentProviderList: any;
}

export const AdminDashboardContainer = withStyles(styles)((props: AdminDashboardContainerProps) => {
    const intl = useIntl();
    const { classes } = props;
    const dateStart = moment().subtract(28, "days");
    const dateEnd = moment().subtract(1, "days"); // Exclude today
    const apiService = useContext(ApiServiceContext);
    const device = useContext(RwdContext);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");
    const fetchSelectedContentProviderDashboardData = () => {
        let data = {
            userIds: props.contentProviderList.map((contentProvider) => contentProvider.userId),
        };
        if (selectedOptions.length) {
            data = {
                userIds: selectedOptions,
            };

            apiService
                .getDashboardInfo(data)
                .then((res) => {
                    if (UtilFunctions.validateApiResponse(res.data)) {
                        setNumberOfPublishedArticles(res.data.data.published);
                        setNumberOfWaitingForApprovalArticles(res.data.data.waiting_for_approval);
                    } else {
                        throw new Error("Error Unknown");
                    }
                })
                .catch((err) => {
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                });
        }
    };
    const [numberOfPublishedArticles, setNumberOfPublishedArticles] = useState(undefined);
    const [numberOfWaitingForApprovalArticles, setNumberOfWaitingForApprovalArticles] = useState(undefined);
    const maximumSelection = 1;
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const selectOptionHandler = (value) => {
        let tmpArr = [...selectedOptions];
        if (!tmpArr.includes(value)) tmpArr.push(value);
        if (tmpArr.length > maximumSelection) {
            tmpArr.shift();
        }
        setSelectedOptions(tmpArr);
    };

    const [csvData, setCsvData] = useState();
    const [graphData, setGraphData] = useState();
    const fetchCsvData = () => {
        if (selectedOptions.length) {
            let data = {
                from: dateStart.unix() * 1000,
                to: dateEnd.unix() * 1000,
                userIds: selectedOptions,
            };
            apiService
                .getDownloadCsv(data)
                .then((res) => {
                    if (UtilFunctions.validateApiResponse(res.data)) {
                        let parsedData = csvDataParser(res.data.data, intl);
                        setCsvData(parsedData?.csvData);
                        setGraphData(parsedData?.graphData);
                    } else {
                        throw new Error("Error Unknown");
                    }
                })
                .catch((err) => {
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                });
        }
    };

    useEffect(() => {
        if (selectedOptions.length) fetchSelectedContentProviderDashboardData();
    }, [selectedOptions, props.contentProviderList]);

    useEffect(() => {
        fetchCsvData();
    }, [selectedOptions]);

    useEffect(() => {
        setSelectedOptions(props.contentProviderList.length ? [props.contentProviderList[0].userId] : []);
    }, [props.contentProviderList]);

    return (
        <React.Fragment>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <ContentContainerBox
                className={device == "PC" ? classes.dashboardContainerBox : classes.dashboardContainerBoxmobile}
            >
                <div className="selectContentProviderTitle">{intl.formatMessage({ id: "admin.dashboard.1.007" })}</div>
                <div className="selectContentProvider">
                    <FormControl component="fieldset">
                        <FormGroup row>
                            {props.contentProviderList.map((contentProvider, index) => {
                                return (
                                    <FormControlLabel
                                        value={contentProvider.userId}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onChange={(e) => selectOptionHandler(e.currentTarget.value)}
                                            />
                                        }
                                        label={contentProvider.zh?.name}
                                        labelPlacement="end"
                                        checked={selectedOptions.includes(contentProvider.userId)}
                                        key={index}
                                    />
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                </div>
            </ContentContainerBox>
            {numberOfPublishedArticles !== undefined && numberOfWaitingForApprovalArticles !== undefined && (
                <ContentContainerBox
                    className={device == "PC" ? classes.dashboardContainerBox : classes.dashboardContainerBoxmobile}
                    style={{ justifyContent: "left" }}
                >
                    {selectedOptions.length > 0 && csvData?.length > 0 && (
                        <React.Fragment>
                            <div className="downloadData borderBottom">
                                <div>
                                    <div className="containerTitle">
                                        {intl.formatMessage({ id: "admin.dashboard.1.015" })}
                                    </div>
                                    <div>
                                        {intl.formatMessage(
                                            { id: "editor.dashboard.2.002a" },
                                            {
                                                startYear: dateStart.format("YYYY"),
                                                startMonth: dateStart.format("MM"),
                                                startDate: dateStart.format("DD"),
                                                endYear: dateEnd.format("YYYY"),
                                                endMonth: dateEnd.format("MM"),
                                                endDate: dateEnd.format("DD"),
                                            }
                                        )}
                                    </div>
                                    <div>{intl.formatMessage({ id: "editor.dashboard.2.002b" })}</div>
                                </div>
                                <CSVLink
                                    id={"csvDownload"}
                                    filename={`HKTV Blog Data ${dateStart.format("YYYY-MM-DD")} - ${dateEnd.format(
                                        "YYYY-MM-DD"
                                    )}.csv`}
                                    key={csvData.length}
                                    data={csvData}
                                    headers={csvHeaders}
                                    target="_self"
                                >
                                    <Button className="downloadBtn">
                                        <DownloadDataBtn />
                                        <div>{intl.formatMessage({ id: "admin.dashboard.1.01" })}</div>
                                    </Button>
                                </CSVLink>
                            </div>
                        </React.Fragment>
                    )}
                    {graphData && csvData.length > 0 && (
                        <React.Fragment>
                            <div className={"graphSection borderBottom"}>
                                <div className="sectionTitle">
                                    {intl.formatMessage({ id: "editor.dashboard.2.004" })}
                                </div>
                                <div className="statistic">
                                    <div className="value">
                                        <span>{csvData?.[csvData.length - 1]?.followers}</span>
                                    </div>
                                    <div className="desc">{intl.formatMessage({ id: "editor.dashboard.2.005" })}</div>
                                    {csvData?.[csvData.length - 1].followers - csvData[0].followers >= 0 ? (
                                        <div className="increase">
                                            <div className="arrowUp"></div>
                                            {Math.floor(
                                                ((csvData?.[csvData.length - 1].followers - csvData[0].followers) /
                                                    csvData[0].followers) *
                                                    100 *
                                                    100
                                            ) / 100}
                                            %
                                        </div>
                                    ) : (
                                        <div className="decrease">
                                            <div className="arrowDown"></div>
                                            {Math.floor(
                                                (Math.abs(
                                                    csvData?.[csvData.length - 1].followers - csvData[0].followers
                                                ) /
                                                    csvData[0].followers) *
                                                    100 *
                                                    100
                                            ) / 100}
                                            %
                                        </div>
                                    )}
                                </div>
                                <Line
                                    height={270}
                                    options={getChartOption(graphData.datasets[0], device ? device : "")}
                                    legend={{ display: false }}
                                    data={graphData}
                                />
                            </div>

                            <div className="row">
                                <div className="commission">
                                    <div className="sectionTitle">
                                        {intl.formatMessage({ id: "editor.dashboard.2.0071" })}
                                    </div>
                                    <div className="statistic">
                                        <div className="value">
                                            $
                                            {csvData
                                                ?.reduce(
                                                    (totalCommission, dataOfTheDay) =>
                                                        totalCommission + dataOfTheDay.totalCommission,
                                                    0
                                                )
                                                .toFixed(2)}
                                        </div>
                                        <div className="desc">
                                            {intl.formatMessage({ id: "editor.dashboard.2.007" })}
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                                <div className="orderAmount">
                                    <div className="sectionTitle">
                                        {intl.formatMessage({ id: "editor.dashboard.2.008" })}
                                    </div>
                                    <div className="statistic">
                                        <div className="value">
                                            $
                                            {csvData
                                                ?.reduce(
                                                    (totalOrder, dataOfTheDay) =>
                                                        totalOrder + dataOfTheDay.totalOrderAmount,
                                                    0
                                                )
                                                .toFixed(2)}
                                        </div>
                                        <div className="desc">
                                            {intl.formatMessage(
                                                { id: "editor.dashboard.2.009" },
                                                { date: dateEnd.format("DD/MM/YYYY") }
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </React.Fragment>
                    )}
                    <div className="row">
                        <div className="publishedArticles dashboardInfo">
                            <div className="sectionTitle">{intl.formatMessage({ id: "admin.dashboard.1.013" })}</div>
                            <div className="content">
                                {numberOfPublishedArticles}
                                {intl.formatMessage({ id: "admin.dashboard.1.022" })}
                            </div>
                        </div>
                        <div className="waitingForApprovalArticles dashboardInfo">
                            <div className="sectionTitle">{intl.formatMessage({ id: "admin.dashboard.1.014" })}</div>
                            <div className="content">
                                {numberOfWaitingForApprovalArticles}
                                {intl.formatMessage({ id: "admin.dashboard.1.022" })}
                            </div>
                        </div>
                        <div></div>
                    </div>
                </ContentContainerBox>
            )}
        </React.Fragment>
    );
});
