import React, { useContext, useEffect, useState } from "react";
import { ApiServiceContext, ChooseStoreStatusContext } from "../App";
import { UtilFunctions } from "../utils/UtilFunctions";
import BannerUploadContainer from "./boxContainers/BannerUploadContainer";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import { useIntl } from "react-intl";

export interface ManageBannerProps {
    isSis: boolean;
    banner1Gallery?: string[];
    banner2Gallery?: string[];
    banner3Gallery?: string[];
    banner4Gallery?: string[];
    banner1List?: string[];
    banner2List?: string[];
    banner3List?: string[];
    banner4List?: string[];
    banner5List?: string[];
    banner6List?: string[];
    banner7List?: string[];
    banner8List?: string[];
}

export default function ManageBanner(props: ManageBannerProps) {
    const intl = useIntl();
    const apiService = useContext(ApiServiceContext);
    const [banner1List, setb1List] = useState([]);
    const [banner2List, setb2List] = useState([]);
    const [banner3List, setb3List] = useState([]);
    const [banner4List, setb4List] = useState([]);
    const [banner5List, setb5List] = useState([]);
    const [banner6List, setb6List] = useState([]);
    const [banner7List, setb7List] = useState([]);
    const [banner8List, setb8List] = useState([]);
    const [storeId, setstoreId] = useState("");
    const [chooseStore, setChooseStoreStatus] = useContext(ChooseStoreStatusContext);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");
    const fetchBannerList = () => {
        if (chooseStore.shopCode)
            apiService
                .getBannerList(chooseStore.shopCode)
                .then((res) => {
                    if (UtilFunctions.validateApiResponse(res.data)) {
                        const componentData = res.data.data?.types;
                        if (componentData) {
                            if (componentData.TYPE_1) {
                                setb1List(componentData.TYPE_1);
                            }
                            if (componentData.TYPE_2) setb2List(componentData.TYPE_2);
                            if (componentData.TYPE_3) setb3List(componentData.TYPE_3);
                            if (componentData.TYPE_4) setb4List(componentData.TYPE_4);
                            if (componentData.TYPE_5) {
                                setb5List(componentData.TYPE_5);
                            }
                            if (componentData.TYPE_6) {
                                setb6List(componentData.TYPE_6);
                            }
                            if (componentData.TYPE_7) {
                                setb7List(componentData.TYPE_7);
                            }
                            if (componentData.TYPE_8) {
                                setb8List(componentData.TYPE_8);
                            }
                        }
                    } else throw new Error("Error Unknown");
                })
                .catch((err) => {
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                });
    };

    useEffect(() => {
        if (props.isSis) {
            chooseStore.shopCode && fetchBannerList();
            if (chooseStore.shopCode) setstoreId(chooseStore.shopCode);
        }
        return () => {
            setb1List([]);
            setb2List([]);
            setb3List([]);
            setb4List([]);
            setb5List([]);
            setb6List([]);
            setb7List([]);
            setb8List([]);
        };
    }, [chooseStore.shopCode]);

    const bannerObj = [
        {
            bannerid: "1",
            imgSize: [
                {
                    width: 1188,
                    height: 80000,
                },
            ],
            list: [banner1List],
        },
        {
            bannerid: "2",
            imgSize: [
                {
                    width: 594,
                    height: 484,
                },
            ],
            list: [banner2List],
        },
        {
            bannerid: "3",
            imgSize: [
                {
                    width: 396,
                    height: 484,
                },
            ],
            list: [banner3List],
        },
        {
            bannerid: "4",
            imgSize: [
                {
                    width: 297,
                    height: 484,
                },
            ],
            list: [banner4List],
        },
        {
            bannerid: "5-6",
            imgSize: [
                {
                    width: 355,
                    height: 355,
                },
                {
                    width: 833,
                    height: 355,
                },
            ],
            list: [banner5List, banner6List],
        },
        {
            bannerid: "7-8",
            imgSize: [
                {
                    width: 485,
                    height: 496,
                },
                {
                    width: 703,
                    height: 248,
                },
            ],
            list: [banner7List, banner8List],
        },
    ];

    return (
        <React.Fragment>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            {bannerObj.map((i, k) => (
                <div key={k}>
                    <BannerUploadContainer
                        storeId={storeId}
                        bannerid={i.bannerid}
                        uploaded_img_list={i.list}
                        handleChange={fetchBannerList}
                        imgSize={i.imgSize}
                    ></BannerUploadContainer>
                </div>
            ))}
        </React.Fragment>
    );
}
