const en = {
    locale: "English",
    "general.1.001": "Confirm",
    "general.1.002": "Unknown errors occurred",
    "general.1.003": "Return to home page",
    "general.1.004": "Cancel",
    "error.1.01": "Sorry",
    "error.1.02": "Network is unstable, please try again later",
    "error.1.03": "Retry",
    "error.1.05": "did not assign shop-in-shop role",
    "newArticle.1.001": "新增文章",
    "newArticle.1.002":
        "1. 文章狀態為『不能發佈』、『等待發佈』、『已發佈』，若對文章作出修改，更新後需等待審核成功方能發佈。",
    "newArticle.1.003": "2. 系統會於審核成功後自動發佈。",
    "newArticle.1.004":
        "3. 所設定的文章發表時間，會於審核成功後作安排。（若設定發表時間早於審核成功，系統會於審核成功後自動發佈）",
    "newArticle.1.005": "標題",
    "newArticle.1.006": "文章封面圖片",
    "newArticle.1.007": "*圖片尺寸: 750x450px",
    "newArticle.1.008": "分類",
    "newArticle.1.009": "發表",
    "newArticle.1.010": "預覽",
    "newArticle.1.011": "發表文章",
    "newArticle.1.012": "儲存為草稿",
    "newArticle.1.013": "設定時間發表",
    "newArticle.1.014": "預覽",
    "newArticle.1.015": "更改",
    "newArticle.1.016": "發佈",
    "newArticle.1.017": "設定時間發表",
    "newArticle.1.018": "選擇未來日期和時間作為發佈帖子的時間:",
    "newArticle.1.019": "日期:",
    "newArticle.1.020": "時間:",
    "newArticle.1.021": "預覽文章",
    "newArticle.1.022": "確定發佈時間",
    "newArticle.1.023": "上傳成功！正等待審批",
    "newArticle.1.024": "圖片格式不正確，必須是 JPG 或 PNG 格式",
    "editor.myAccount.1.001": "Hi, {name}",
    "editor.myAccount.1.002": "登出",
    "editor.myAccount.1.003": "我的資料",
    "editor.myAccount.1.004": "圖表",
    "editor.myAccount.1.005": "新增文章",
    "editor.myAccount.1.006": "所有文章",
    "editor.myAccount.1.007": "個人照片",
    "editor.myAccount.1.008": "中文名稱",
    "editor.myAccount.1.009": "英文名稱",
    "editor.myAccount.1.01": "中文簡介",
    "editor.myAccount.1.011": "英文簡介",
    "editor.myAccount.1.012": "封面圖片",
    "editor.myAccount.1.013": "*圖片尺寸: 1208x320px",
    "editor.myAccount.1.014": "更新",
    "editor.myAccount.1.015": "更改成功！",
    "editor.myAccount.1.016": "Select Store",

    "editor.dashboard.2.001": "圖表",
    "editor.dashboard.2.002a": "{startYear}年{startMonth}月{startDate}日 - {endYear}年{endMonth}月{endDate}日的報表。",
    "editor.dashboard.2.002b": "注意：不包括當日資料",
    "editor.dashboard.2.003": "			export data	匯出資料",
    "editor.dashboard.2.004": "Followers人數",
    "editor.dashboard.2.0041": "人數",
    "editor.dashboard.2.005": "接觸人數",
    "editor.dashboard.2.006": "總佣金",
    "editor.dashboard.2.0071": "本月可獲得利潤",
    "editor.dashboard.2.007": "可分佣金",
    "editor.dashboard.2.008": "Followers 總消費額",
    "editor.dashboard.2.009": "截止{date}",
    "editor.dashboard.2.01": "QR code",
    "editor.dashboard.2.011": "立即下載你專屬的QR Code",
    "editor.dashboard.2.012": "自選時間",
    "editor.dashboard.2.013": "請選擇日期",
    "editor.dashboard.2.014": "日期",
    "editor.dashboard.2.015": "取消",
    "editor.dashboard.2.016": "確定移除",
    "editor.allPosts.4.001": "所有文章",
    "editor.allPosts.4.002": "搜尋...",
    "editor.allPosts.4.003": "分類",
    "editor.allPosts.4.004": "所有分類",
    "editor.allPosts.4.005": "發表月份",
    "editor.allPosts.4.006": "所有月份",
    "editor.allPosts.4.007": "更新月份",
    "editor.allPosts.4.008": "所有月份",
    "editor.allPosts.4.009": "狀態",
    "editor.allPosts.4.01": "所有狀態",
    "editor.allPosts.4.011": "確定",
    "editor.allPosts.4.012": "標題",
    "editor.allPosts.4.013": "分類",
    "editor.allPosts.4.014": "發表日期",
    "editor.allPosts.4.015": "更新日期",
    "editor.allPosts.4.016": "狀態",
    "editor.allPosts.4.017": "瀏覽人次",
    "editor.allPosts.4.018": "上一頁",
    "editor.allPosts.4.019": "共{page}頁",
    "editor.allPosts.4.02": "下一頁",
    "editor.allPosts.4.021": "編輯文章",
    "editor.allPosts.4.022": "標題",
    "editor.allPosts.4.023": "文章封面圖片",
    "editor.allPosts.4.024": "*圖片尺寸: 750x450px",
    "editor.allPosts.4.025": "分類",
    "editor.allPosts.4.026": "更新",
    "editor.allPosts.4.027": "更改成功！正等待審批",
    "editor.allPosts.4.028": "已成功儲存草稿",
    "editor.allPosts.4.029": "不能通過審批",
    "editor.allPosts.4.03": "文章標題：",
    "editor.allPosts.4.031": "原因：",
    "editor.allPosts.4.032": "取消",
    "editor.allPosts.4.033": "更改文章",
    "editor.allPosts.4.034": "移除文章",
    "editor.allPosts.4.035": " 確定要移除此文章？一經移除，文章不能重新發佈。 ",
    "editor.allPosts.4.036": "取消",
    "editor.allPosts.4.037": "確定移除",
    "editor.allPosts.4.038": "注意",
    "editor.allPosts.4.039": " 確定返回所有文章？你所更改的資料不會儲存。 ",
    "editor.allPosts.4.04": "取消",
    "editor.allPosts.4.041": "確定返回",
    "editor.allPosts.4.042": "注意",
    "editor.allPosts.4.043": " 確定離開視窗？你所更改的資料不會儲存。 ",
    "editor.allPosts.4.044": "確定關閉",
    "editor.allPosts.4.045": "取消關閉",
    "editor.allPosts.4.046": "移除圖片",
    "editor.allPosts.4.047": "確定要移除文章封面圖片？",
    "editor.allPosts.4.048": "取消",
    "editor.allPosts.4.049": "確定移除",

    "editor.liveBooking.1.001": "直播",
    "editor.liveBooking.1.002": "預約時段",
    "editor.liveBooking.1.003": "預約紀錄",
    "editor.liveBooking.1.004": "月份",
    "editor.liveBooking.1.005": "確定",
    "editor.liveBooking.1.006": "待審核",
    "editor.liveBooking.1.007": "已審核",
    "editor.liveBooking.1.008": "已取消",
    "editor.liveBooking.1.009": "拒絕",
    "editor.liveBooking.1.010": "查看紀錄",
    "editor.liveBooking.1.011": "上傳時間：",
    "editor.liveBooking.1.012": "拒絕原因：",
    "editor.liveBooking.1.013": "RTMP：",
    "editor.liveBooking.1.014": "Streamkey：",
    "editor.liveBooking.1.015": "預約日期",
    "editor.liveBooking.1.016": "預約時間",
    "editor.liveBooking.1.017": "商品號碼 (SKU ID)",
    "editor.liveBooking.1.018": "備註",
    "editor.liveBooking.1.019": "取消預約",
    "editor.liveBooking.1.02": "上傳成功！正等待審批",
    "editor.liveBooking.1.021": "送出審核",
    "editor.liveBooking.1.022":
        "HKTVmall網站中揀選商品的頁面，複製連結上最後的一組數字。例如：H7688001_S_C8045-FFBW6{lineBreak}注意：只能於電腦版複製",
    "editor.liveBooking.1.023": "注意",
    "editor.liveBooking.1.024a": "你所選擇的時段已滿。{lineBreak}請選擇其他時段。",
    "editor.liveBooking.1.024b": "已達預約上限，無法再預約新時段。",
    "editor.liveBooking.1.024c": "不能重複預約同時段，請選擇其他時段。",
    "editor.liveBooking.1.024d": "此時段名額已滿，請選擇其他時段。",
    "editor.liveBooking.1.024e": "不能更改審批，時段內已審批的預約多於10個。",
    "editor.liveBooking.1.025": "更改時段",
    "editor.liveBooking.1.026": "注意",
    "editor.liveBooking.1.027": "確定離開此畫面？{lineBreak}你所填寫的資料不會儲存。",
    "editor.liveBooking.1.028": "取消",
    "editor.liveBooking.1.028a": "預約時間已滿，無法通過審核",
    "editor.liveBooking.1.028b": "其他",
    "editor.liveBooking.1.029": "確定返回",
    "editor.liveBooking.1.03": "注意",
    "editor.liveBooking.1.031": "確定取消預約？",
    "editor.liveBooking.1.032": "取消",
    "editor.liveBooking.1.033": "確定取消",
    "editor.liveBooking.1.034": "請選擇時段",
    "editor.liveBooking.1.035": "請選擇時間",
    "editor.liveBooking.1.037": "時間重覆，請選擇其他時段。",
    "editor.liveBooking.1.038": "上傳成功！",
    "editor.liveBooking.1.039": "確定",

    "1.001a": "字數超出限額",
    "1.001b": "必須填寫",
    "2.001": "圖表",
    "2.002": "xxxx年x月xx日 - xxxx年x月xx日的報表。注意：不包括當日資料",
    "2.003": "匯出資料",
    "2.004": "Followers人數",
    "2.005": "接觸人數",
    "2.006": "總佣金",
    "2.007": "可分佣金",
    "2.008": "Followers 總消費額",
    "2.009": '截止"date"',
    "2.01": "QR code",
    "2.011": "立即下載你專屬的QR Code",
    "2.012": "自選時間",
    "2.013": "請選擇日期",
    "2.014": "日期",
    "2.015": "取消",
    "2.016": "確定移除",
    "4.001": "所有文章",
    "4.002": "搜尋...",
    "4.003": "分類",
    "4.004": "所有分類",
    "4.005": "發表月份",
    "4.006": "所有月份",
    "4.007": "更新月份",
    "4.008": "所有月份",
    "4.009": "狀態",
    "4.01": "所有狀態",
    "4.011": "確定",
    "4.012": "標題",
    "4.013": "分類",
    "4.014": "發表日期",
    "4.015": "更新日期",
    "4.016": "狀態",
    "4.017": "瀏覽人次",
    "4.018": "上一頁",
    "4.019": "共X頁",
    "4.02": "下一頁",
    "4.021": "編輯文章",
    "4.022": "標題",
    "4.023": "文章封面圖片",
    "4.024": "*圖片尺寸: 750x450px",
    "4.025": "分類",
    "4.026": "更新",
    "4.027": "更改成功！正等待審批",
    "4.028": "已成功儲存草稿",
    "4.029": "不能通過審批",
    "4.03": "文章標題：",
    "4.031": "原因：",
    "4.032": "取消",
    "4.033": "更改文章",
    "4.034": "移除文章",
    "4.035": "確定要移除此文章？一經移除，文章不能重新發佈。",
    "4.036": "取消",
    "4.037": "確定移除",
    "4.038": "注意",
    "4.039": "確定返回所有文章？你所更改的資料不會儲存。",
    "4.04": "取消",
    "4.041": "確定返回",
    "4.042": "注意",
    "4.043": "確定離開視窗？你所更改的資料不會儲存。",
    "4.044": "確定關閉",
    "4.045": "取消關閉",
    "4.046": "移除圖片",
    "4.047": "確定要移除文章封面圖片？",
    "4.048": "取消",
    "4.049": "確定移除",
    "5.001": "Manage Dynamic banner",
    "5.002": "1 Banner Per Row (Width: 1188px, Height: no limit)",
    "5.003": "Upload Image",
    "5.004": "2 Banners Per Row (594 x 484px)",
    "5.005": "3 Banners Per Row (396 x 484px)",
    "5.006": "4 Banners Per Row (297 x 484px)",
    "5.007": "Please input below demension",
    "5.008": "1 Banner Per Row (Width: 1188px, Height: no limit)",
    "5.009": "Drag & Drop fils here or click ",
    "5.010": "here",
    "5.011": " to browse files",
    "5.012": "File format: .jpg/ .png",
    "5.013": "Max. File Size: 5mb",
    "5.014": "Wrong dimension / size",
    "5.015": "Submit Success",
    "5.016": "2 Banners Per Row (Width: 594px, Height: 484px)",
    "5.017": "3 Banner Per Row (Width: 396px, Height: 484px)",
    "5.018": "4 Banner Per Row (Width: 297px, Height: 484px)",
    "5.019": "Remove",
    "5.020": "Caution",
    "5.021": "Are you sure want to remove image?",
    "5.022": "Cancel",
    "5.023": "請查詢客服了解",
    "5.024": "Notice",
    "5.025": "Confirm",
    "5.026": "The file size exceeds the allowable limit.",
    "5.027": "You have reached the limit (max.50 files)",
    "5.028": "Uploaded file is not a valid image. Only JPG and PNG files are allowed.",
    "5.029": "No Image",
    "5.030": "Uploading ... {uploadedImg} / {totalImg} Uploaded",
    "5.031": "Upload is not completed, confirm to leave?",
    //new Template for upload image
    "5.032": "Template5-6{lineBreak}2 Banner Per Row(355 x 355px / 833 x 355px)",
    "5.033": "Template6{lineBreak}2 Banner Per Row(833 x 355px / 355 x 355px)",
    "5.034": "Template7-8{lineBreak}3 Banner Per Row(485 x 496px / 703 x 248px)",
    "5.035": "Template8{lineBreak}3 Banner Per Row(703 x 248px / 485 x 496px)",
    "5.036": "355 x 355px",
    "5.037": "833 x 355px",
    "5.038": "485 x 496px",
    "5.039": "703 x 248px",
    "5.040": "2 Banner Per Row(355 x 355px / 833 x 355px)",
    "5.041": "2 Banner Per Row(833 x 355px / 355 x 355px)",
    "5.042": "3 Banner Per Row(485 x 496px / 703 x 248px)",
    "5.043": "3 Banner Per Row(703 x 248px / 485 x 496px)",

    "admin.dashboard.1.001": "Hi, Admin",
    "admin.dashboard.1.002": "登出",
    "admin.dashboard.1.003": "總圖表",
    "admin.dashboard.1.004": "所有文章",
    "admin.dashboard.1.005": "分類",
    "admin.dashboard.1.006": "總圖表",
    "admin.dashboard.1.007": "選擇發表者: (最多選取1位)",
    "admin.dashboard.1.008": "發表者之總報表",
    "admin.dashboard.1.009": '"xxxx年x月xx日 - xxxx年x月xx日的報表。注意：不包括當日資料"',
    "admin.dashboard.1.01": "匯出資料",
    "admin.dashboard.1.011": "Followers人數",
    "admin.dashboard.1.012": "接觸人數",
    "admin.dashboard.1.013": "已發表之文章",
    "admin.dashboard.1.014": "代審批之文章",
    "admin.dashboard.1.015": "已選取發表者之圖表",
    "admin.dashboard.1.016": "Followers 總消費額",
    "admin.dashboard.1.017": "截止{date}",
    "admin.dashboard.1.018": "總佣金",
    "admin.dashboard.1.019": "截止{date}, 已選擇發表者之總和",
    "admin.dashboard.1.02": "已發表之文章",
    "admin.dashboard.1.021": "代審批之文章",
    "admin.dashboard.1.022": "篇",
    "admin.allPosts.2.001": "所有文章",
    "admin.allPosts.2.002": "搜尋...",
    "admin.allPosts.2.003": "發表者",
    "admin.allPosts.2.004": "所有發表者",
    "admin.allPosts.2.005": "分類",
    "admin.allPosts.2.006": "所有分類",
    "admin.allPosts.2.007": "上傳月份",
    "admin.allPosts.2.008": "所有月份",
    "admin.allPosts.2.009": "狀態",
    "admin.allPosts.2.01": "所有狀態",
    "admin.allPosts.2.011": "確定",
    "admin.allPosts.2.012": "標題",
    "admin.allPosts.2.013": "分類",
    "admin.allPosts.2.014": "上傳日期",
    "admin.allPosts.2.015": "發表者",
    "admin.allPosts.2.016": "狀態",
    "admin.allPosts.2.017": "上一頁",
    "admin.allPosts.2.018": "共X頁",
    "admin.allPosts.2.019": "下一頁",
    "admin.allPosts.2.02": "審批文章",
    "admin.allPosts.2.021": "不能通過",
    "admin.allPosts.2.022": "通過審批",
    "admin.allPosts.2.023": "文章分類：",
    "admin.allPosts.2.024": "發表日期：",
    "admin.allPosts.2.025": "標題",
    "admin.allPosts.2.026": "內文",
    "admin.allPosts.2.027": "文章封面圖片",
    "admin.allPosts.2.028": "不能通過審批原因",
    "admin.allPosts.2.029": "請選擇",
    "admin.allPosts.2.03": "請填寫原因",
    "admin.allPosts.2.031": "取消",
    "admin.allPosts.2.032": "確定",
    "admin.allPosts.2.033": "完成審批！",
    "admin.category.3.001": "分類",
    "admin.category.3.002": "新增分類",
    "admin.category.3.003": "現有分類(中文)",
    "admin.category.3.004": "現有分類(英文)",
    "admin.category.3.005": "編輯",
    "admin.category.3.006": "移除",
    "admin.category.3.007": "新增分類",
    "admin.category.3.008": "中文名稱",
    "admin.category.3.009": "英文名稱",
    "admin.category.3.01": "取消",
    "admin.category.3.011": "確定",
    "admin.category.3.012": "注意",
    "admin.category.3.013": "確定要移除？",
    "admin.category.3.014": "取消",
    "admin.category.3.015": "確定移除",
    "admin.approve.1": "文章分類：",
    "admin.approve.2": "發表日期：",
    "admin.approve.3": "標題",
    "admin.approve.4": "內文",
    "admin.approve.5": "封面圖片",
    "admin.approve.6": "審批文章",
    "admin.approve.7": "不能通過",
    "admin.approve.8": "通過審批",
    "admin.approve.9": "不能通過審批原因",
    "admin.approve.10": "請選擇",
    "admin.approve.11": "複製網址",
    "admin.approve.12": "前往連結網址",
    "admin.approve.13": "成功複製網址",

    "admin.editTimeSlots.1.001": "預約時段",
    "admin.editTimeSlots.1.002": "新增/修改時段",
    "admin.editTimeSlots.1.003": "所有預約時段",
    "admin.editTimeSlots.1.007": "月份",
    "admin.editTimeSlots.1.048a": "年份",
    "admin.editTimeSlots.1.041": "沒有時段提供，請新增",
    "admin.editTimeSlots.1.042": "新增時段",
    "admin.editTimeSlots.1.044": "直播時段",
    "admin.editTimeSlots.1.045": "編輯",
    "admin.editTimeSlots.1.046c": "移除",
    "admin.editTimeSlots.1.050": "開始時間",
    "admin.editTimeSlots.1.051": "至",
    "admin.editTimeSlots.1.052": "取消",
    "admin.editTimeSlots.1.053": "確定",
    "admin.editTimeSlots.1.055a": "確定要移除時段？",
    "admin.editTimeSlots.1.049.a": "請選擇年份",
    "admin.editTimeSlots.1.049": "請選擇月份",
    "admin.allTimeSlotsCalendar.1.005": "發表者",
    "admin.allTimeSlotsCalendar.1.006": "所有發表者",
    "admin.allTimeSlotsCalendar.1.009": "所有時段",
    "admin.allTimeSlotsCalendar.1.01": "狀態",
    "admin.allTimeSlotsCalendar.1.011": "所有狀態",
    "admin.allTimeSlotsCalendar.1.012": "待審核",
    "admin.allTimeSlotsCalendar.1.013": "已審核",
    "admin.allTimeSlotsCalendar.1.014": "已取消",
    "admin.allTimeSlotsCalendar.1.015": "拒絕",
    "admin.allTimeSlotsCalendar.1.016": "確定",

    "admin.allTimeSlotsCalendar.1.018": "審批預約時段",
    "admin.allTimeSlotsCalendar.1.019": "不能通過",
    "admin.allTimeSlotsCalendar.1.02": "通過審核",
    "admin.allTimeSlotsCalendar.1.021": "上傳時間：",
    "admin.allTimeSlotsCalendar.1.022": "發表者：",
    "admin.allTimeSlotsCalendar.1.023": "日期",
    "admin.allTimeSlotsCalendar.1.024": "時間",
    "admin.allTimeSlotsCalendar.1.025": "商品號碼 (SKU ID)",
    "admin.allTimeSlotsCalendar.1.026": "複製",
    "admin.allTimeSlotsCalendar.1.027": "備註",
    "admin.allTimeSlotsCalendar.1.028": "取消審核",
    "admin.allTimeSlotsCalendar.1.029": "取消審批原因",
    "admin.allTimeSlotsCalendar.1.03": "請選擇",
    "admin.allTimeSlotsCalendar.1.03.fullBooking": "預約時間已滿，無法通過審核",
    "admin.allTimeSlotsCalendar.1.031": "其他",
    "admin.allTimeSlotsCalendar.1.032": "取消",
    "admin.allTimeSlotsCalendar.1.033": "其他",
    "admin.allTimeSlotsCalendar.1.016.others": "注意",
    "admin.allTimeSlotsCalendar.1.034": "此時段已滿，無法通過審核",
    "admin.allTimeSlotsCalendar.1.035": "確定",
    "admin.allTimeSlotsCalendar.1.036": "不能審批原因",
    "admin.allTimeSlotsCalendar.1.037": "請選擇",
    "admin.allTimeSlotsCalendar.1.037.others": "其他",
    "admin.allTimeSlotsCalendar.1.038": "取消",
    "admin.allTimeSlotsCalendar.1.039": "確定",
    "admin.allTimeSlotsCalendar.1.04": "完成審批！",

    "admin.editTimeSlots.1.046a": "年",
    "admin.editTimeSlots.1.046b": "月",
    "admin.editTImeSlots.1.033": "注意",
    "admin.editTImeSlots.1.001.4": "請確認狀態為『已審核』、『待審核』的預約經已取消。",
    "admin.editTImeSlots.1.054": "時間重覆或時間輸入錯誤(時段必須要180分鐘以內及整點)",
    "admin.editTImeSlots.1.062": "成功新增時段",
    "admin.editTImeSlots.1.063": "成功移除時段",
    "admin.allTimeSlotsCalendar.1.001.8": "時段",
    "admin.allTimeSlotsCalendar.1.001.9": "所有",
    "admin.allTimeSlotsCalendar.1.001.10": "複製",
    "admin.allTimeSlotsCalendar.1.001.11": "已複製商品連結",
    "admin.allTimeSlotsCalendar.1.001.11a": "商品連結",
    "admin.allTimeSlotsCalendar.1.001.12": "RTMP：",
    "admin.allTimeSlotsCalendar.1.001.13": "Streamkey：",
    "admin.allTimeSlotsCalendar.1.058": "Live Show Buffer Time",
    "admin.allTimeSlotsCalendar.1.028a": "M3U8：",
    "admin.allTimeSlotsCalendar.1.001.14": "請選擇Buffer time",
    "admin.allTimeSlotsCalendar.1.001.14a": "Buffer Time",
    "admin.allTimeSlotsCalendar.1.017a": "還有",
    "admin.allTimeSlotsCalendar.1.017b": "個預約",
    "admin.allTimeSlotsCalendar.1.001.15": "拒絕原因",
    "admin.allTimeSlotsCalendar.1.001.16": "預約日期",
    "admin.allTimeSlotsCalendar.1.001.17": "預約時間",
    "admin.allTimeSlotsCalendar.1.036b": "此月份沒有預約紀錄",

    "admin.approve.14": "預定發表日期：",

    invalidContent: "有HKTVmall / Youtube以外的網址",

    // Shop-in-shop
    // editAppPage_add1banner_error msg
    text_605c550654115400ca1c9a65: "Caution",
    text_605c550654115400ca1c9a6b: "Module name already exists",
    // editAppPage
    text_6048985b3857e101208263ff: "Edit App Storefront",
    text_6048985b3857e1012082640b: "You may add more modules to your store page",
    text_6048985b3857e101208263fb: "Dynamic Banner",
    text_6048985b3857e101208263fa: "HTML",
    text_6065202eb4bc0000c573f2ee: "Preview",
    text_605c550654115400ca1c9a5f: "Submit",
    // editAppPage_edit 1234 banner
    text_605c550654115400ca1c9b5d: "Dynamic Banner",
    text_605c550654115400ca1c9b36: "Module Name",
    text_605c550654115400ca1c9b37: "URL",
    text_608bc72a5cf3b0754f0fa9fd: "(Optional)",
    text_605c550654115400ca1c9b38: "Banner Name (ZH)",
    text_605c550654115400ca1c9b40: "Banner Name (EN)",
    text_605c550654115400ca1c9b4c: "Add to store page",
    text_605c550654115400ca1c9b36_max: "(max. {max} characters)",

    // editAppPage_HTML create success
    text_605c550654115400ca1c9ba2: "Submit Success",
    //creat html name

    // editAppPage_previewQRcode
    text_606572002024f9017d6ed831: "Scan QRcode to preview store page",
    text_606572002024f9017d6ed830: "*Preview only, cannot edit.",
    text_6065202eb4bc0000c573f2eb: "Preview",
    // editAppPage_chooseTemplate
    text_605c550654115400ca1c9ac5: "Template1{lineBreak}1 Banner Per Row",
    text_605c550654115400ca1c9ac6: "Template2{lineBreak}2 Banners Per Row",
    text_605c550654115400ca1c9aaa_a: "Image: ",
    text_605c550654115400ca1c9aaa_a1: "Image1: ",
    text_605c550654115400ca1c9aaa_a2: "Image2: ",
    text_605c550654115400ca1c9aaa_a3: "Image1-2: ",
    text_605c550654115400ca1c9aaa_a4: "Image2-3: ",
    text_605c550654115400ca1c9aaa_a5: "Image3: ",
    text_605c550654115400ca1c9aaa_a6: "Image1 dimension: ",
    text_605c550654115400ca1c9aaa_a7: "Image2 dimension: ",
    text_605c550654115400ca1c9aaa_a8: "Image3 dimension: ",
    text_605c550654115400ca1c9aaa_b: "W: ",
    text_605c550654115400ca1c9aaa_c: "H: ",
    text_605c550654115400ca1c9aaa_d: "px",
    text_605c550654115400ca1c9aaa_f: "no limit",
    text_605c550654115400ca1c9aaa_g: " x ",
    text_605c550654115400ca1c9abe: "Max. File Size: 5mb",
    text_605c550654115400ca1c9ac3: "Template3{lineBreak}3 Banners Per Row",
    text_605c550654115400ca1c9acb: "Template4{lineBreak}4 Banners Per Row",
    text_605c550654115400ca1c9ab4: "Next",
    //new chooseTemplate
    text_605c550654115400ca1c9ac7: "Template5{lineBreak}2 Banner Per Row",
    text_605c550654115400ca1c9ac8: "Template6{lineBreak}2 Banner Per Row",
    text_605c550654115400ca1c9ac9: "Template7{lineBreak}3 Banner Per Row",
    text_605c550654115400ca1c9ac10: "Template8{lineBreak}3 Banner Per Row",
    // editAppPage_addbanner_chooes template
    text_6048985b3857e1012082651c: "1 Banner Per Row",
    text_6048985b3857e1012082651d: "2 Banners Per Row",
    text_6048985b3857e10120826527: "3 Banners Per Row",
    text_6048985b3857e1012082651f: "4 Banners Per Row",
    text_6048985b3857e1012082651g: "Template5",
    text_6048985b3857e1012082651h: "Template6",
    text_6048985b3857e1012082651i: "Template7",
    text_6048985b3857e1012082651j: "Template8",

    //banner template edit page different image size
    text_6048985b3857e1012082651k: "{width}px",

    // banners_manage over50
    text_605c550654115400ca1c9a1d: "Caution",
    text_605c550654115400ca1c9a21: "You have reached the limit (max.50 files)",
    text_60b0be2fa2efe900ee04e8ad: "The file size exceeds the allowable limit.",
    text_60b0be2fa2efe900ee04e8c0: "Uploaded file is not a valid image. Only JPG and PNG files are allowed:",
    text_605c550654115400ca1c9a1b: "Cancel",
    text_605c550654115400ca1c9a1a: "Confirm",
    //banners_manage upload image error msg
    text_605c550654115400ca1c9a40: "Wrong dimension / size",
    //no store error message
    text_608a1f2b06af3400b429006a: "System will be ready soon, please stay tune.",
    text_608a1f2b06af3400b429006b: "The system is currently unavailable. Please contact us.",
    text_608a1f2b06af3400b429006c: "No store is found. Please contact us.",
    //remove module message
    text_605c550654115400ca1c9bb8: "Caution",
    text_605c550654115400ca1c9bc2: "Are you sure want to remove modules?",
    text_60658943393faa00be277dcf: "Cancel",
    text_60658943393faa00be277dd0: "Remove",
    //choose store page message
    text_608a1f2b06af3400b4290072: "Store Front Management",
    text_608a1f2b06af3400b429006e: "Choose an Account",
    //HTML message
};
export default en;
