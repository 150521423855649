import { Button, createStyles, FormControl, InputBase, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { ApiServiceContext, UnknowErrorDialogContext, RwdContext } from "../App";
import { MODEL_CategoryDetails, REQUEST_ArticleStatusUpdate } from "../interfaces";
import { articleListPopupStatus, ArticleStatus, RejectReasonCode } from "../interfaces/enums";
import { UtilFunctions } from "../utils/UtilFunctions";
import ContentContainerBox from "./boxContainers/ContentContainer";
import { ErrorDialog } from "./dialogs/ErrorDialog";
import LoadingIcon from "./loading/LoadingIcon";
import LabelTextField from "./textfield/LabelTextField";
import StatusPopup from "./dialogs/StatusPopup";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../assets/animations/btn_loading_animation.json";

interface UpdateArticlepProps {
    location?: any;
    articleId: string;
    categoryList?: MODEL_CategoryDetails[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageWrapper: {
            position: "relative",
            textAlign: "left",
            "@media (max-width : 1280px)": {
                width: "100%",
                height: "auto",
            },
        },
        contentPreviewContainer: {
            maxWidth: "100%",
            border: "solid 1px #d8d8d8",
            borderRadius: 8,
            overflowX: "scroll",
            marginTop: 13,
            "& .previewContentTitle": {
                fontSize: 15,
                fontWeight: 700,
                padding: "12px 12px 0px 12px",
            },
            "& .content": {
                width: "100%",
                padding: 10,
            },
        },
        buttonContainer: {
            position: "absolute",
            top: -59,
            right: 0,
            "@media (max-width : 1280px)": {
                top: -51,
            },
        },
        buttons: {
            marginLeft: "10px",
            "@media (max-width : 1280px)": {
                padding: 0,
                fontSize: "14px",
                width: "0",
                height: "0",
            },
        },
        failPassedButton: {
            fontSize: "18px",
            width: "80px",
            height: "37px",
            "@media (max-width : 1280px)": {
                width: 0,
                height: 0,
                padding: 0,
                fontSize: "14px",
            },
        },
        dropdownWrapper: {
            width: "100%",
        },
        dropdown: {
            width: "100%",
        },
        normalFont: {
            fontWeight: "normal",
            "@media (max-width : 1280px)": {
                width: "auto",
                fontSize: "11px",
            },
        },
    })
);

function UpdateArticle(props: UpdateArticlepProps) {
    const intl = useIntl();
    const classes = useStyles();

    const [articleData, setArticleData] = useState();
    const [categoryList, setCategoryList] = useState();
    const [matchedCategories, setMatchedCategories] = useState();
    const [rejectReasonList, setRejectReasonList] = useState();
    const [disapproveReasonDialog, setDisapproveReasonDialog] = useState(false);
    const [rejectReasonCode, setRejectReasonCode] = useState<string>("none");
    const [rejectReasonFreeTextInput, setRejectReasonFreeTextInput] = useState<string>("");
    const [unknowErrorDialog, setUnknowErrorDialog] = useContext(UnknowErrorDialogContext);
    const [copyTextPopup, setCopyTextPopup] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);
    const history = useHistory();
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");

    const apiService = useContext(ApiServiceContext);
    const device = useContext(RwdContext);

    const publicUrl = articleData?.isPublicContent
        ? `${process.env.REACT_APP_CDN_DOMAIN}/zh/media/content_partner_public/${articleData?.userId}/article/${props.articleId}`
        : null;

    const fetchCategoryList = () => {
        apiService
            .getCategoryList()
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setCategoryList([...res.data.data]);
                } else throw new Error("Error Unknown");
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };

    const fetchArticleData = (articleId: string) => {
        apiService
            .getSavedArticle(articleId)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setArticleData(res.data?.data?.articleList[0]);
                } else throw new Error("Error Unknown");
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };

    const fetchRejectReasonList = () => {
        apiService
            .getRejectList()
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setRejectReasonList(res.data?.data);
                } else throw new Error("Error Unknown");
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };

    const disapproveArticleHandler = () => {
        setIsRejecting(true);
        let data: REQUEST_ArticleStatusUpdate = {
            articleId: props.articleId,
            status: ArticleStatus.PUBLISH_DENIED,
            rejectCode: rejectReasonCode,
            rejectCustomMessage: rejectReasonFreeTextInput,
        };
        updateArticleStatus(data);
    };

    const approveArticleHandler = () => {
        let data: REQUEST_ArticleStatusUpdate = {
            articleId: props.articleId,
            status: ArticleStatus.PUBLISHED,
        };
        updateArticleStatus(data);
    };

    const updateArticleStatus = (data) => {
        apiService
            .postUpdateArticleStatus(data)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    if (UtilFunctions.validateApiResponse(res.data)) {
                        history.push("/articles", {
                            status: articleListPopupStatus.APPROVAL_FINISHED,
                        });
                    } else {
                        setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                    }
                }
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setIsUpdating(false);
                setIsRejecting(false);
            });
    };
    const getRejectReasonDropdownList = () => {
        return (
            <FormControl variant="outlined" className={classes.dropdownWrapper}>
                <Select
                    onChange={(event) => setRejectReasonCode(event.target.value as string)}
                    className={classes.dropdown}
                    value={rejectReasonCode}
                >
                    <MenuItem value={"none"} disabled>
                        <div className={classes.normalFont}>{intl.formatMessage({ id: "admin.approve.10" })}</div>
                    </MenuItem>
                    {rejectReasonList &&
                        rejectReasonList?.map?.((reason, index) => (
                            <MenuItem value={reason.rejectCode} key={index}>
                                <div className={classes.normalFont}>{reason.zh?.rejectReasonDisplayText}</div>
                            </MenuItem>
                        ))}
                </Select>
                {rejectReasonCode === RejectReasonCode.OTHERS && (
                    <InputBase
                        id="articleTitleInput"
                        style={{
                            borderRadius: 8,
                            border: "solid 1px #999999",
                            padding: "10px 10px",
                            marginTop: "10px",
                        }}
                        multiline
                        rows={3}
                        rowsMax={5}
                        onChange={(e) => {
                            setRejectReasonFreeTextInput(e.target.value);
                        }}
                    />
                )}
            </FormControl>
        );
    };

    useEffect(() => {
        if (articleData && articleData?.category && categoryList)
            setMatchedCategories(categoryList.filter((category) => articleData.category.includes(category._id)));
    }, [categoryList, articleData?.category]);

    useEffect(() => {
        if (!(categoryList && categoryList.length > 0)) fetchCategoryList();
        if (props.articleId) fetchArticleData(props.articleId);
        fetchRejectReasonList();
    }, []);

    return (
        <React.Fragment>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <div className={classes.pageWrapper}>
                {articleData && categoryList && rejectReasonList ? (
                    <React.Fragment>
                        {articleData?.statusCode !== ArticleStatus.PUBLISHED ? (
                            articleData.statusCode === ArticleStatus.WAITING_FOR_APPROVAL ? (
                                <div className={classes.buttonContainer}>
                                    <Button
                                        className={device == "PC" ? "" : classes.failPassedButton}
                                        variant="outlined"
                                        onClick={() => {
                                            setDisapproveReasonDialog(true);
                                            setIsUpdating(true);
                                        }}
                                        color="primary"
                                        disabled={isUpdating}
                                    >
                                        {intl.formatMessage({ id: "admin.approve.7" })}
                                    </Button>
                                    <Button
                                        className={classes.buttons}
                                        variant="contained"
                                        onClick={() => {
                                            approveArticleHandler();
                                            setIsUpdating(true);
                                        }}
                                        color="primary"
                                        disabled={isUpdating}
                                    >
                                        {!isUpdating ? (
                                            intl.formatMessage({ id: "admin.approve.8" })
                                        ) : (
                                            <Lottie
                                                options={{
                                                    loop: true,
                                                    autoplay: true,
                                                    animationData: BtnLoadingAnimation,
                                                    rendererSettings: {
                                                        preserveAspectRatio: "xMidYMid slice",
                                                    },
                                                }}
                                                width={40}
                                                height={29}
                                            />
                                        )}
                                    </Button>
                                </div>
                            ) : null
                        ) : (
                            articleData?.isPublicContent &&
                            publicUrl && (
                                <div className={classes.buttonContainer}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            UtilFunctions.copyToClipboard(publicUrl);
                                            setCopyTextPopup(!copyTextPopup);
                                        }}
                                        color="primary"
                                    >
                                        {intl.formatMessage({ id: "admin.approve.11" })}
                                    </Button>
                                    <Button
                                        className={classes.buttons}
                                        variant="contained"
                                        color="primary"
                                        href={publicUrl}
                                        target="_blank"
                                    >
                                        {intl.formatMessage({ id: "admin.approve.12" })}
                                    </Button>
                                </div>
                            )
                        )}
                        <ContentContainerBox>
                            <div>
                                {`${intl.formatMessage({
                                    id: "admin.approve.1",
                                })}`}
                                {matchedCategories &&
                                    matchedCategories.map((matchedCate, index) => {
                                        return `${
                                            intl.locale === "zh" ? matchedCate?.zh?.name : matchedCate?.en?.name
                                        }${index + 1 < matchedCategories.length ? " / " : ""}`;
                                    })}
                            </div>
                            <div>
                                {`${intl.formatMessage({
                                    id: "admin.approve.2",
                                })}${UtilFunctions.getDateTimeFromTimestamp(articleData.publishDate)}`}
                            </div>
                            {articleData.scheduledPublish && (
                                <div>
                                    {`${intl.formatMessage({
                                        id: "admin.approve.14",
                                    })}${UtilFunctions.getDateTimeFromTimestamp(articleData.scheduledPublish)}`}
                                </div>
                            )}
                        </ContentContainerBox>
                        <ContentContainerBox>
                            <LabelTextField
                                style={{ width: "calc( 100% - 24px )", paddingBottom: "13px" }}
                                label={intl.formatMessage({
                                    id: "admin.approve.3",
                                })}
                                className={"articleTitle"}
                                variant="filled"
                                value={articleData.zh?.title}
                                disabled={true}
                            />
                            <div className={classes.contentPreviewContainer}>
                                <div className="previewContentTitle">
                                    {intl.formatMessage({ id: "admin.approve.4" })}
                                </div>
                                <div
                                    className="content sun-editor-editable"
                                    dangerouslySetInnerHTML={{ __html: articleData.zh?.data }}
                                ></div>
                            </div>
                        </ContentContainerBox>
                        <ContentContainerBox>
                            <div className={"containerTitle"}>{intl.formatMessage({ id: "admin.approve.5" })}</div>
                            <img
                                style={
                                    device == "PC"
                                        ? {
                                              width: "377px",
                                              height: "101px",
                                              objectFit: "cover",
                                          }
                                        : { width: "90%", height: "101px", objectFit: "cover" }
                                }
                                src={articleData.zh?.coverImage}
                            ></img>
                        </ContentContainerBox>
                        <ErrorDialog
                            title={intl.formatMessage({ id: "admin.approve.9" })}
                            disabled={isRejecting}
                            content={getRejectReasonDropdownList()}
                            confirmBtnText={intl.formatMessage({ id: "general.1.001" })}
                            confirmHandler={(e) => disapproveArticleHandler()}
                            cancelBtnText={intl.formatMessage({ id: "general.1.004" })}
                            cancelHandler={() => {
                                setDisapproveReasonDialog(false);
                                setIsUpdating(false);
                            }}
                            openStatus={disapproveReasonDialog}
                            toggleDialog={() => {
                                setDisapproveReasonDialog(!disapproveReasonDialog);
                                setIsUpdating(false);
                            }}
                        />
                    </React.Fragment>
                ) : (
                    <LoadingIcon />
                )}
                <StatusPopup
                    openStatus={copyTextPopup}
                    content={intl.formatMessage({ id: "admin.approve.13" })}
                    toggleDialog={setCopyTextPopup}
                />
            </div>
        </React.Fragment>
    );
}

export default UpdateArticle;
