import React, { useContext, useEffect, useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useIntl } from "react-intl";
import { createStyles, makeStyles, Grid } from "@material-ui/core";
import { AxiosError } from "axios";
import { ReactComponent as AddSign } from "../assets/store/ic_add Modules.svg";
import { DialogButton, RoundButton } from "../components/buttons/StyledButtons";
import { DragOrderList } from "../components/DragOrderList";
import { BannerEditor } from "../components/BannerEditor";
import { HtmlEditor } from "../components/HtmlEditor";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import { SelectStoreButton } from "../components/buttons/SelectStoreButton";
import { ModuleDialog, ModuleErrorDialog } from "../components/dialogs/ModuleDialog";
import { SubmitSuccessStatusPopup } from "../components/dialogs/StatusPopup";
import { apiAddress } from "../interfaces/index.d";
import { SunEditorReactProps } from "suneditor-react";
import { HtmlType, StoreBannerComponentType, imageType } from "../interfaces/enums";
import { ApiServiceContext, ChooseStoreStatusContext, UnknowErrorDialogContext } from "../App";
import useQRCode from "../hooks/QRCodeHook";
import { UtilFunctions } from "../utils/UtilFunctions";
import { OAuth2Login } from "../utils/OAuth2Login";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../assets/animations/btn_loading_animation.json";

// Define types
export type TemplateNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export type BannerDetail = {
    image: string;
    url: string;
    bannerNameZh: string;
    bannerNameEn: string;
    imageZh: string;
    imageEn: string;
};
export type BannerModuleData = {
    moduleName: string;
    numberOfBanner: TemplateNumber;
    bannerData: BannerDetail[];
    focusedBanner: number;
};
export type HtmlModuleData = {
    name: string;
    content: string;
};
export type Module = {
    type: string;
    name?: string;
    enable?: boolean;
    data?: any;
};
export type ModuleList = Module[];

// Define Props
type AddModuleButtonProps = {
    onClick: () => void;
    disabled?: boolean;
    children?: React.ReactNode;
};

// Create style
const useStyles = makeStyles(() =>
    createStyles({
        font: { fontSize: 14, lineHeight: 1.14 },
        marginTop: { marginTop: 16 },
        marginRight: { marginRight: 16 },
        padding: { padding: 16 },
        flexContainer: { display: "flex" },
        card: {
            color: "rgba(34, 34, 34, 0.6)",
            backgroundColor: "#ffffff",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.12)",
        },
        bottomBar: { flexDirection: "row-reverse", backgroundColor: "#fafafa" },
    })
);

// Button for adding HTML and Banner module
const AddModuleButton = (props: AddModuleButtonProps) => {
    const classes = useStyles();
    const { children, disabled, onClick } = props;
    return (
        <RoundButton className={classes.marginRight} disabled={disabled} onClick={() => onClick && onClick()}>
            <AddSign />
            {children}
        </RoundButton>
    );
};

// Preview Component
const PreviewContent = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    return (
        <div className={classes.flexContainer} style={{ padding: 16 }}>
            <div style={{ width: 180, height: 180 }}>{props.children}</div>
            <div className={classes.padding} style={{ width: 440, paddingTop: 62, color: "#222222" }}>
                <div style={{ fontSize: 18, lineHeight: 1.33, fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "text_606572002024f9017d6ed831" })}
                </div>
                <div style={{ fontSize: 16, lineHeight: 1.5 }}>
                    {intl.formatMessage({ id: "text_606572002024f9017d6ed830" })}
                </div>
            </div>
        </div>
    );
};

// Helper functions
// Determine is Banner or not
export const isBanner = (type) => Object.values(StoreBannerComponentType).includes(type);
export const isHtml = (type) => Object.values(HtmlType).includes(type);
// Return number of banner by type
export const getNumberOfBanner = (type) =>
    (Object.values(StoreBannerComponentType).indexOf(type) + 1) as TemplateNumber;
// Return type of banner module
export const getBannerModuleType = (n: TemplateNumber) => Object.values(imageType)[n - 1];
const newForPutSisComponent = (n: TemplateNumber) => Object.values(StoreBannerComponentType)[n - 1];

// Edit App Page
function EditAppPage(props) {
    const classes = useStyles();
    const intl = useIntl();
    const [accessToken, setAccessToken] = useState("");
    const apiService = useContext(ApiServiceContext);
    const [chooseStore, setChooseStoreStatus] = useContext(ChooseStoreStatusContext);

    // Init banner editor data
    const initBannerModuleData: BannerModuleData = {
        moduleName: "",
        numberOfBanner: 0,
        bannerData: [],
        focusedBanner: 0,
    };

    // Initialize Hooks
    // List of module
    const [moduleList, setModuleList] = useState<ModuleList>([]);
    // Full list of module
    const [fullModuleList, setFullModuleList] = useState<ModuleList>([]);
    // Index of editing module
    const [editingModuleIndex, setEditingModuleIndex] = useState<number>(moduleList.length);
    // Data of Banner Module Editor
    const [editingBannerModuleData, setEditingBannerModuleData] = useState<BannerModuleData>(initBannerModuleData);
    // Preview QR code
    const [qrcode, setQRCodeUrl] = useQRCode({ width: 180, margin: 0 });
    // Set open 1/2/3/4 banner editor
    const [openBannerEditor, setOpenBannerEditor] = useState<boolean>(false);
    // Set open preview dialog
    const [openPreviewDialog, setopenPreviewDialog] = useState<boolean>(false);
    // Set open submit success popup
    const [openSubmitSuccess, setOpenSubmitSuccess] = useState<boolean>(false);
    const [otherVideoLink, setOtherVideoLink] = useState(false);
    const [htmlImageUploadErrorHandel, setHtmlImageUploadErrorHandel] = useState(false);
    const [openHtmlEditor, setOpenHtmlEditor] = useState<boolean>(false);
    const [htmlImageUploadErrorContent, setHtmlImageUploadErrorContent] = useState("");
    const [htmlName, setHtmlName] = useState("");
    const [htmlContent, setHtmlContent] = useState("");
    const htmlContentRef = useRef(htmlContent);
    htmlContentRef.current = htmlContent;
    const [unknowErrorDialog, setUnknowErrorDialog] = useContext(UnknowErrorDialogContext);

    const [openValidateError, setOpenValidateError] = useState(false);
    const [errMsg, setErrMsg] = useState("general.1.002");

    const [isSubmittingPreview, setIsSubmittingPreview] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    // Load module list
    useEffect(() => {
        apiService
            .getSisAvailableComponents()
            .then((res) => {
                if (UtilFunctions.validateApiResponseSuccess(res) && res.data.data) {
                    setFullModuleList(res.data.data);
                } else throw new Error("Error Unknown");
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
        chooseStore.shopCode &&
            apiService
                .getSisComponents(chooseStore.shopCode)
                .then((res) => {
                    if (UtilFunctions.validateApiResponseSuccess(res) && res.data.data) {
                        setModuleList(res.data.data.components || []);
                    } else throw new Error("Error Unknown");
                })
                .catch(() => {
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                });
    }, [chooseStore]);

    //get token
    useEffect(() => {
        if (!accessToken) {
            getToken();
        }
    }, []);

    // useEffect(() => {
    //     if (previewDialog) {
    //         previewHandler()?.then((res) => {
    //             if (res?.data?.zh) {
    //                 setQRCodeUrl(res.data.zh);
    //             }
    //         });
    //     }
    // }, [previewDialog]);

    // const previewHandler = () => {
    //     if (!UtilFunctions.validateContent(htmlContent)) {
    //         setInvalidContentDialog(true);
    //         return;
    //     }
    //     let submitData = { htmlContent, preview: true };
    //     return apiService.postNewArticle(submitData).then((res) => {
    //         if (UtilFunctions.validateApiResponse(res.data)) {
    //             return res.data;
    //         } else {
    //             setUnknowErrorDialog({ status: !unknowErrorDialog.status });
    //             setFormChanged(true);
    //         }
    //     });
    // };

    const getToken = async () => {
        const { token } = await OAuth2Login.getAccessToken();
        setAccessToken(token?.value ?? "");
    };

    // Helper function for checking if component is enabled
    const isEnabled = (type) => {
        let module = fullModuleList.find((o) => o.type === type);
        return module ? module.enable : true;
    };

    // Helper function for validating module name (check duplicate names)
    const hasDuplicateName = (str: string) => moduleList.some((o, i) => o.name === str && editingModuleIndex !== i);

    // Handle add banner module
    const handleAddBannerModule = (data: BannerModuleData) => {
        let newModuleList = [...moduleList];
        let type = newForPutSisComponent(data.numberOfBanner);
        newModuleList.splice(editingModuleIndex, editingModuleIndex === moduleList.length ? 0 : 1, {
            name: data.moduleName.trim(),
            type,
            data: data.bannerData.map((o, i) => ({
                image: o.imageZh,
                url: o.url.trim(),
                bannerNameZh: o.bannerNameZh.trim(),
                bannerNameEn: o.bannerNameEn.trim(),
                imageEn: o.imageEn ? o.imageEn : o.imageZh,
                imageZh: o.imageZh,
            })),
        });
        setModuleList(newModuleList);
        setOpenBannerEditor(false);
    };

    // Handle edit banner module
    const handleEditBannerModule = (i: number) => {
        setEditingModuleIndex(i);
        let numBanner = getNumberOfBanner(moduleList[i]?.type);
        const migrationBannerData = (data: any) => {
            if (data !== []) {
                return data.map((o, i) => ({
                    image: o.image,
                    url: o.url,
                    bannerNameZh: o.bannerNameZh,
                    bannerNameEn: o.bannerNameEn,
                    imageZh: o.imageZh ? o.imageZh : o.image,
                    imageEn: o.imageEn ? o.imageEn : "",
                }));
            }
            return data;
        };
        setEditingBannerModuleData({
            moduleName: moduleList[i]?.name || "",
            numberOfBanner: numBanner,
            bannerData: migrationBannerData(moduleList[i]?.data || []),
            focusedBanner: 0,
        });
        setOpenBannerEditor(true);
    };

    //handel add html module
    const HandeladdHtml = (name: string, content: string) => {
        if (!UtilFunctions.validateContent(content)) {
            setOtherVideoLink(true);
            return;
        }
        let newModuleList = [...moduleList];
        newModuleList.splice(editingModuleIndex, editingModuleIndex === moduleList.length ? 0 : 1, {
            name: name.trim(),
            type: "CUSTOM_HTML",
            data: content,
        });
        setModuleList(newModuleList);
        setOpenHtmlEditor(false);
    };
    //handle edit html module
    const HandleEditHtmlModuleData = (i: number) => {
        setEditingModuleIndex(i);
        setHtmlName(moduleList[i]?.name || "");
        setHtmlContent(moduleList[i]?.data || "");
        setOpenHtmlEditor(true);
    };

    // Handle edit module
    const handleEditModule = (i: number) => {
        if (isBanner(moduleList[i].type)) handleEditBannerModule(i);
        else if (isHtml(moduleList[i].type)) HandleEditHtmlModuleData(i);
        else return;
    };

    //handle delete module
    const handleDeleteModule = (i: number) => {
        let nowModuleList = [...moduleList];
        nowModuleList.splice(i, 1);
        setModuleList(nowModuleList);
    };
    // Handle click preview button
    const handlePreview = () =>
        chooseStore.shopCode &&
        apiService
            .postSisComponentsPreview(chooseStore.shopCode, moduleList)
            .then((res) => {
                if (UtilFunctions.validateApiResponseSuccess(res) && res.data.data) {
                    setQRCodeUrl(
                        process.env.REACT_APP_HYBRIS_DOMAIN + "/s/" + chooseStore.shopCode + "?payload=" + res.data.data
                    );
                    setopenPreviewDialog(true);
                } else throw new Error("Error Unknown");
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setIsSubmittingPreview(false);
            });

    // Handle click submit button
    const handleSubmit = () => {
        setIsSubmitting(true);
        chooseStore.shopCode &&
            apiService
                .putSisComponents(chooseStore.shopCode, moduleList)
                .then((res) => {
                    if (UtilFunctions.validateApiResponseSuccess(res) && res.data.data) {
                        setOpenSubmitSuccess(true);
                        setTimeout(() => setOpenSubmitSuccess(false), 3000);
                        chooseStore.shopCode &&
                            apiService
                                .getSisComponents(chooseStore.shopCode)
                                .then((res) => {
                                    if (UtilFunctions.validateApiResponseSuccess(res) && res.data.data) {
                                        setModuleList(res.data.data.components || []);
                                    } else throw new Error("Error Unknown");
                                })
                                .catch(() => {
                                    setErrMsg("general.1.002");
                                    setOpenValidateError(true);
                                });
                    } else throw new Error("Error Unknown");
                })
                .catch(() => {
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
    };
    const setInvalidImageFormatPopup = () => {
        setUnknowErrorDialog({
            status: !unknowErrorDialog.status,
            content: intl.formatMessage({ id: "image_fileWrong_html" }),
        });
    };

    const changeHtmlImageUploadError = () => {
        setHtmlImageUploadErrorHandel(!htmlImageUploadErrorHandel);
    };

    const imageUploadErrorHandler = (errMsg, result) => {
        if (errMsg === "NA") {
            setUnknowErrorDialog({ status: !unknowErrorDialog.status });
            return;
        }
        setUnknowErrorDialog({ status: !unknowErrorDialog.status, content: errMsg });
    };

    const imageUploadBeforeHandler: SunEditorReactProps["onImageUploadBefore"] = (files, info, uploadHandler) => {
        // If true is returned, the internal upload process runs normally.
        // If false is returned, no image upload is performed.
        // If new fileList are returned,  replaced the previous fileList
        // If undefined is returned, it waits until "uploadHandler" is executed.

        // let max_in_bytes = 10 * 1024 * 1024; // 10m
        // if (files[0]?.size >= max_in_bytes) return false;
        const formData = new FormData();
        formData.append("file-0", files[0]);
        if (!UtilFunctions.isValidImageExt(files[0].name)) {
            changeHtmlImageUploadError();
            setHtmlImageUploadErrorContent(intl.formatMessage({ id: "image_fileWrong_html" }));
            return false;
        }
        apiService
            .postRichTextEditorImages(formData)
            .then((res) => {
                if (res?.data?.status === "fail") {
                    // changeHtmlImageUploadError();
                    // setHtmlImageUploadErrorContent("zxczxczxczxczxczx");
                    uploadHandler("NA");
                    throw new Error();
                } else {
                    uploadHandler(res.data);
                }
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 413) {
                    changeHtmlImageUploadError();
                    setHtmlImageUploadErrorContent(intl.formatMessage({ id: "image_bigger10mb_html" }));
                    return;
                }
                if (err.response?.status === 500) {
                    changeHtmlImageUploadError();
                    setHtmlImageUploadErrorContent(err.response?.data?.message);
                    return;
                }
                // changeHtmlImageUploadError();
                // setHtmlImageUploadErrorContent("11111111");
                uploadHandler("NA");
            });
        return false;
    };

    return (
        <>
            <div className="editAppPageWrapper pageWrapper">
                {!chooseStore.shopCode ? <Redirect to="/store-list-page" /> : null}
                <div>
                    <Grid container direction="row" justify="space-between" className="title">
                        <span>{intl.formatMessage({ id: "text_6048985b3857e101208263ff" })}</span>
                        <Grid item direction="row" alignItems="center">
                            <span>Select Store: </span>
                            <SelectStoreButton />
                        </Grid>
                    </Grid>

                    <div className={`${classes.card} ${classes.marginTop} ${classes.padding}`}>
                        <div className={classes.font}>
                            {intl.formatMessage({ id: "text_6048985b3857e1012082640b" })}
                        </div>
                        <div className={classes.marginTop}>
                            <AddModuleButton
                                onClick={() => HandleEditHtmlModuleData(moduleList.length)}
                                disabled={moduleList.filter((o) => isHtml(o.type)).length === 1}
                            >
                                {intl.formatMessage({ id: "text_6048985b3857e101208263fa" })}
                            </AddModuleButton>
                            <AddModuleButton
                                onClick={() => handleEditBannerModule(moduleList.length)}
                                disabled={moduleList.filter((o) => isBanner(o.type)).length === 20}
                            >
                                {intl.formatMessage({ id: "text_6048985b3857e101208263fb" })}
                            </AddModuleButton>
                        </div>
                    </div>

                    <div className={`${classes.card} ${classes.marginTop}`}>
                        <DragOrderList
                            items={moduleList.map((o) => ({ ...o, enable: isEnabled(o.type) }))}
                            onChangeOrder={setModuleList}
                            onEditModule={handleEditModule}
                            onDeleteModule={handleDeleteModule}
                        />
                    </div>

                    <div className={`${classes.card} ${classes.padding} ${classes.flexContainer} ${classes.bottomBar}`}>
                        <DialogButton onClick={() => handleSubmit()} disabled={isSubmitting}>
                            {isSubmitting ? (
                                <>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: BtnLoadingAnimation,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice",
                                            },
                                        }}
                                        width={40}
                                        height={29}
                                        style={{ position: "absolute" }}
                                    />
                                </>
                            ) : (
                                <>{intl.formatMessage({ id: "text_605c550654115400ca1c9a5f" })}</>
                            )}
                        </DialogButton>
                        <DialogButton
                            onClick={() => {
                                setIsSubmittingPreview(true);
                                handlePreview();
                            }}
                            disabled={isSubmittingPreview}
                            className={classes.marginRight}
                        >
                            {isSubmittingPreview ? (
                                <>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: BtnLoadingAnimation,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice",
                                            },
                                        }}
                                        width={40}
                                        height={29}
                                        style={{ position: "absolute" }}
                                    />
                                </>
                            ) : (
                                <>{intl.formatMessage({ id: "text_6065202eb4bc0000c573f2ee" })}</>
                            )}
                        </DialogButton>
                    </div>
                </div>

                {/* Edit Banner popup */}
                <BannerEditor
                    data={editingBannerModuleData}
                    onDataChange={setEditingBannerModuleData}
                    hasDuplicateName={hasDuplicateName}
                    onSubmit={handleAddBannerModule}
                    open={openBannerEditor}
                    onClose={() => setOpenBannerEditor(false)}
                />

                {/* Edit Html  */}
                <HtmlEditor
                    title={htmlName}
                    content={htmlContentRef.current}
                    open={openHtmlEditor}
                    onClose={() => setOpenHtmlEditor(false)}
                    submitHtml={HandeladdHtml}
                    changeHtmlName={setHtmlName}
                    changeHtmlContent={setHtmlContent}
                    uploadImageUrl={`${
                        process.env.REACT_APP_API_DOMAIN_PREFIX
                    }${apiAddress.imageUploadVersion2_POST()}`}
                    imageUploadToken={`Bearer ${accessToken}`}
                    onImageUploadError={imageUploadErrorHandler}
                    onImageUploadBefore={imageUploadBeforeHandler}
                />

                {/* Preview popup */}
                <ModuleDialog
                    title={intl.formatMessage({ id: "text_6065202eb4bc0000c573f2eb" })}
                    open={openPreviewDialog}
                    onClose={() => setopenPreviewDialog(false)}
                >
                    <PreviewContent>
                        <img src={qrcode} alt="" />
                    </PreviewContent>
                </ModuleDialog>

                {/* Submit Success popup */}
                <SubmitSuccessStatusPopup
                    openStatus={openSubmitSuccess}
                    toggleDialog={() => setOpenSubmitSuccess(false)}
                    content={intl.formatMessage({ id: "text_605c550654115400ca1c9ba2" })}
                />
                <ModuleErrorDialog
                    title={intl.formatMessage({ id: "text_605c550654115400ca1c9a1d" })}
                    confirmText={intl.formatMessage({ id: "text_605c550654115400ca1c9a1a" })}
                    cancelText={""}
                    onConfirm={() => setOtherVideoLink(!otherVideoLink)}
                    onCancel={() => setOtherVideoLink(!otherVideoLink)}
                    open={otherVideoLink}
                    onClose={() => setOtherVideoLink(!otherVideoLink)}
                >
                    {intl.formatMessage({ id: "image_other_link_html" })}
                </ModuleErrorDialog>
                <ModuleErrorDialog
                    title={intl.formatMessage({ id: "text_605c550654115400ca1c9a1d" })}
                    confirmText={intl.formatMessage({ id: "text_605c550654115400ca1c9a1a" })}
                    cancelText={""}
                    onConfirm={changeHtmlImageUploadError}
                    onCancel={changeHtmlImageUploadError}
                    open={htmlImageUploadErrorHandel}
                    onClose={changeHtmlImageUploadError}
                >
                    {htmlImageUploadErrorContent}
                </ModuleErrorDialog>
                <ErrorDialog
                    openStatus={openValidateError}
                    toggleDialog={() => setOpenValidateError(false)}
                    confirmHandler={() => setOpenValidateError(false)}
                    title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                    content={
                        <div style={{ height: "82px" }}>
                            {intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}
                        </div>
                    }
                    confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
                />
            </div>
        </>
    );
}

export default EditAppPage;
