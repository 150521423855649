import React, { useContext, useEffect } from "react";
import { Grid, createStyles, makeStyles, Theme, ButtonGroup, Button, Box, Typography } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import rightArrow from "../assets/admin/ic/rightArrow.svg";
import { useIntl } from "react-intl";
import { StoreInfoContext, ChooseStoreStatusContext, UserInfoContext } from "../App";
import HKTVmallLogo from "../assets/HKTVmall.svg";
import { userInfo } from "os";
import { OAuth2Login } from "../utils/OAuth2Login";
import AccountIc from "../assets/admin/img_account.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageColor: {
            backgroundColor: "white",
        },

        imgSet: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            position: "relative",
            marginBottom: "20px",
        },
        logoSet: {
            marginBottom: "0",
            paddingBottom: "0",
        },
        shopSet: {
            color: "rgba(0,0,0,0.54)",
            fontSize: "1.6rem",
            lineHeight: 1.1,
            align: "left",
            float: "left",
            clear: "both",
            marginBottom: "5px",
            textTransform: "capitalize",
            wordBreak: "break-all",
        },
        codeSet: {
            fontSize: "1rem",
            align: "left",
            float: "left",
            clear: "both",
            textTransform: "capitalize",
            color: "rgba(0,0,0,0.24)",
        },
        chooseText: {
            color: "#3a993a",
            fontWeight: "bold",
            height: "24px",
            marginTop: "0",
            paddingTop: "0",
            position: "absolute",
            top: "68%",
        },
        btSet: {
            backgroundColor: "white",
            color: "gray",
            marginBottom: "20px",
            justifyContent: "space-between",
        },
        centerSet: {
            color: "rgba(34,34,34,0.6)",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
        },
        header: {
            background: "#ffffff",
            boxShadow: "0 4px 8px 0 rgba(34, 34, 34, 0.08)",
            width: "100%",
            position: "fixed",
            top: "0",
            right: "0",
            left: "auto",
            zIndex: theme.zIndex.drawer + 1,
        },
        logo: {
            height: 88,
            borderRight: "1px solid #eeeeee",
            cursor: "pointer",
            transition: "background-color 0.6s",
            "&:hover": {
                backgroundColor: "rgba(34, 171, 57, 0.08)",
            },
        },
        text: {
            lineHeight: "36px",
            fontSize: 24,
            textAlign: "right",
            "& .logout": {
                fontSize: 16,
            },
        },
        logom: {
            cursor: "pointer",
            textAlign: "left",
            justifyContent: "flex-start",
            transition: "background-color 0.6s",
            "&:hover": {
                backgroundColor: "rgba(34, 171, 57, 0.08)",
            },
            marginLeft: 0,
            marginTop: 0,
        },
        hktvImage: {
            height: 84,
            flexGrow: 4,
            marginLeft: 0,
            paddingLeft: 0,
            float: "left",
        },
        headerm: {
            background: "#ffffff",
            boxShadow: "0 4px 8px 0 rgba(34, 34, 34, 0.08)",
            justifyContent: "space-between",
            width: "100%",
            position: "fixed",
            height: "84px",
            top: "0",
            left: "0",
            zIndex: theme.zIndex.drawer + 1,
        },
        box_pc: {
            minHeight: "10vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "30px",
        },
        wholePage: {
            backgroundColor: "#FFFFFF",
        },
    })
);
interface NotFoundPageProps {
    type?: string;
    text?: string;
}

/*<Typography align="center">
{intl.formatMessage({ id: "text_608a1f2b06af3400b429006a" })}
</Typography>*/

export function UserRoleNotFoundPage(props: NotFoundPageProps) {
    const classes = useStyles();
    const intl = useIntl();
    const userInfo = useContext(UserInfoContext);
    const storeInfo = useContext(StoreInfoContext);
    let stores = storeInfo?.storesDetails;
    return (
        <React.Fragment>
            {!(userInfo.roles?.length === 0) && !(stores.length === 0) ? (
                <></>
            ) : (
                <div className={classes.wholePage}>
                    <Box className={classes.box_pc}>
                        <Box display="flex" justifyContent="flex-end" className={classes.text}>
                            <img src={AccountIc} style={{ marginRight: "9px" }} alt="Account icon" />
                            {intl.formatMessage({ id: "editor.myAccount.1.001" }, { name: userInfo.userName })}
                        </Box>
                        <Box className={classes.text}>
                            <span className="logout" style={{ cursor: "pointer" }} onClick={() => OAuth2Login.logout()}>
                                {intl.formatMessage({ id: "editor.myAccount.1.002" })}
                            </span>
                        </Box>
                    </Box>
                    <Grid
                        className={classes.pageColor}
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: "90vh" }}
                    >
                        <Grid item sm={12} xs={6}>
                            <Box className={classes.imgSet}>
                                <img className={classes.logoSet} src={HKTVmallLogo} alt="HKTVmall logo"></img>
                                <h2 className={classes.chooseText}>
                                    {intl.formatMessage({ id: "text_608a1f2b06af3400b4290072" })}
                                </h2>
                            </Box>
                            <Typography align="center">{props.text}</Typography>
                        </Grid>
                    </Grid>
                </div>
            )}
        </React.Fragment>
    );
}

export default UserRoleNotFoundPage;
