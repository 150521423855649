import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ApiServiceContext, UnknowErrorDialogContext, RwdContext } from "../App";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import { NewCategoryDialog } from "../components/dialogs/NewCategoryDialog";
import LabelTextField from "../components/textfield/LabelTextField";
import { ValidationError } from "../components/UserDetails";
import { MODEL_CategoryDetails, REQUEST_CategoryStatusUpdate } from "../interfaces";
import { UtilFunctions } from "../utils/UtilFunctions";
import { ReactComponent as RemoveIc } from "../assets/editor/ic/remove.svg";
import { ReactComponent as RemoveEyeIc } from "../assets/admin/ic/icRemoveRedEye.svg";
import { ReactComponent as RemoveEyeHideIc } from "../assets/admin/ic/icRemoveRedEye_hide.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mobileCategoryPage: {
            width: "100%",
            padding: "10px",
            overflow: "hidden",
            "& .title": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "22px",
                fontWeight: "bold",
            },
        },
        articleListWrapper: {
            marginTop: 10,
        },
        head: {
            fontWeight: "bold",
            fontSize: "20px",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
            },
        },
        tableCell: {
            fontSize: "20px",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
            },
        },
        tableRow: {
            backgroundColor: "Green",
        },
        errorMsg: {
            color: "#FF5555",
            fontSize: "14px",
            padding: "4px",
        },
    })
);

type CategoryDialogStatus = {
    _categoryId: string | undefined;
    openStatus: boolean;
};

const dialogInitState: CategoryDialogStatus = {
    _categoryId: undefined,
    openStatus: false,
};

enum ActionType {
    UPDATE_ZHNAME,
    UPDATE_ENNAME,
}

function CategoryPage(props) {
    const intl = useIntl();
    const classes = useStyles();
    const apiService = useContext(ApiServiceContext);
    const device = useContext(RwdContext);

    const [categoryList, setCategoryList] = useState<MODEL_CategoryDetails[]>();

    const [changeStatusCategoryDialog, setChangeStatusCategoryDialog] = useState<CategoryDialogStatus>(dialogInitState);

    const [addCategoryDialog, setAddCategoryDialog] = useState(false);

    const [newZhCategoryName, setNewZhCategoryName] = useState("");

    const [newEnCategoryName, setNewEnCategoryName] = useState("");
    const [newCategoryValidated, setNewCategoryValidated] = useState(false);

    const [duplicated, setDuplicated] = useState(false);
    const [duplicatedEng, setDuplicatedEng] = useState(false);
    const [duplicatedZh, setDuplicatedZh] = useState(false);
    const [catInUse, setCatInUse] = useState(false);
    const [disableDialog, setDisableDialog] = useState(false);
    const [enableDialog, setEnableDialog] = useState(false);
    const [unknowErrorDialog, setUnknowErrorDialog] = useContext(UnknowErrorDialogContext);

    const [openValidateError, setOpenValidateError] = useState(false);
    const [errMsg, setErrMsg] = useState("general.1.002");
    const [isSubmittingEnable, setIsSubmittingEnable] = useState(false);
    const [isSubmittingDisable, setIsSubmittingDisable] = useState(false);
    const [isSubmittingDelete, setIsSubmittingDelete] = useState(false);
    const [isSubmittingAdd, setIsSubmittingAdd] = useState(false);
    // fetch Categories from api
    useEffect(() => {
        apiService
            .getCategoryList()
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) setCategoryList(res.data.data);
                else throw new Error("Error Unknown");
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    }, []);

    const renderCategories = () => {
        return categoryList?.map((cat) => (
            <TableRow key={cat._id}>
                <TableCell align="center" className={classes.tableCell}>
                    {cat?.zh?.name}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                    {cat?.en?.name}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                    <div
                        style={{
                            paddingRight: "20px",
                            display: "inline-flex",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            cat.status === "disabled" ? enableCategoryHandler(cat._id) : disableCategoryHandler(cat._id)
                        }
                    >
                        <Box style={{ display: "flex", marginRight: "22px", whiteSpace: "nowrap" }}>
                            <div style={{ marginRight: "7px" }}>
                                {cat.status === "disabled" ? <RemoveEyeIc /> : <RemoveEyeHideIc />}
                            </div>
                            {cat.status === "disabled"
                                ? intl.formatMessage({ id: "admin.category.3.016" })
                                : intl.formatMessage({ id: "admin.category.3.018" })}
                        </Box>
                    </div>
                    <div
                        style={{
                            paddingRight: "20px",
                            display: "inline-flex",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => deleteCategoryHandler(cat._id)}
                    >
                        <Box style={{ display: "flex" }}>
                            <div style={{ marginRight: "7px" }}>
                                <RemoveIc />
                            </div>
                            {intl.formatMessage({ id: "admin.category.3.019" })}
                        </Box>
                    </div>
                </TableCell>
            </TableRow>
        ));
    };

    const updateCategories = () => {
        apiService
            .getCategoryList()
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) setCategoryList(res.data.data);
                else throw new Error("Error Unknown");
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };
    const resetDuplicated = () => {
        setDuplicated(false);
        setDuplicatedEng(false);
        setDuplicatedZh(false);
    };

    const addCategoryHandler = () => {
        let data = {
            zh: {
                name: newZhCategoryName,
            },
            en: {
                name: newEnCategoryName,
            },
        };
        setIsSubmittingAdd(true);
        apiService
            .postCategoryList(data)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setNewZhCategoryName("");
                    setNewEnCategoryName("");
                    setAddCategoryDialog(false);
                    updateCategories();
                } else {
                    if (res.data.code === "NAME_EXIST") {
                        setDuplicated(true);
                    } else if (res.data.code === "NAME_EXIST_ZH") {
                        setDuplicatedZh(true);
                    } else if (res.data.code === "NAME_EXIST_EN") {
                        setDuplicatedEng(true);
                    } else throw new Error("Error Unknown");
                }
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setIsSubmittingAdd(false);
            });
    };

    const enableCategory = () => {
        if (!!!changeStatusCategoryDialog._categoryId) return;
        let data: REQUEST_CategoryStatusUpdate = {
            categoryId: changeStatusCategoryDialog._categoryId,
            status: "ENABLED",
        };
        setIsSubmittingEnable(true);
        apiService
            .postCategoryStatusUpdate(data)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    updateCategories();
                    setEnableDialog(false);
                } else {
                    throw new Error("Error Unknown");
                }
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
                setEnableDialog(false);
            })
            .finally(() => {
                setIsSubmittingEnable(false);
            });
    };

    const deleteCategoryHandler = (id: string) => {
        setChangeStatusCategoryDialog({ _categoryId: id, openStatus: true });
    };

    const disableCategoryHandler = (id: string) => {
        setDisableDialog(true);
        setChangeStatusCategoryDialog({ _categoryId: id, openStatus: false });
    };

    const enableCategoryHandler = (id: string) => {
        setEnableDialog(true);
        setChangeStatusCategoryDialog({ _categoryId: id, openStatus: false });
    };

    const deleteCategory = () => {
        if (!!!changeStatusCategoryDialog._categoryId) return;
        let data = {
            categoryId: changeStatusCategoryDialog._categoryId,
        };
        setIsSubmittingDelete(true);
        apiService
            .deleteCategoryList(data)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    setChangeStatusCategoryDialog(dialogInitState);
                    updateCategories();
                } else {
                    if (res.data.code == "CATEGORY_IN_USE") {
                        setCatInUse(true);
                    } else throw new Error("Error Unknown");
                }
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
                setChangeStatusCategoryDialog({ _categoryId: "", openStatus: false });
            })
            .finally(() => setIsSubmittingDelete(false));
    };

    const disableCategory = () => {
        if (!!!changeStatusCategoryDialog._categoryId) return;
        let data = {
            categoryId: changeStatusCategoryDialog._categoryId,
            status: "DISABLED",
        };
        setIsSubmittingDisable(true);
        apiService
            .postCategoryStatusUpdate(data)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    updateCategories();
                    setDisableDialog(false);
                } else {
                    setDisableDialog(false);
                    setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                    throw new Error("Error Unknown");
                }
            })
            .catch(() => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
                setDisableDialog(false);
            })
            .finally(() => setIsSubmittingDisable(false));
    };

    const rules = (action: ActionType): ValidationError | null => {
        switch (action) {
            case ActionType.UPDATE_ZHNAME:
                if (newZhCategoryName.length > 6)
                    return {
                        error: true,
                        helperText: "只限6個字元",
                    };
                break;
            case ActionType.UPDATE_ENNAME:
                if (newEnCategoryName.length > 12)
                    return {
                        error: true,
                        helperText: "只限12個字元",
                    };
        }

        return null;
    };

    const newCatZhFieldForm = React.useMemo(
        () => (
            <div style={{ paddingBottom: 22 }}>
                <LabelTextField
                    label={intl.formatMessage({
                        id: "admin.category.3.008",
                    })}
                    variant="filled"
                    fullWidth
                    value={newZhCategoryName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewZhCategoryName(e.currentTarget.value)}
                    style={{ marginTop: 27 }}
                    error={duplicatedZh || duplicated}
                    {...rules(ActionType.UPDATE_ZHNAME)}
                />
                <div className={classes.errorMsg}>
                    {duplicatedZh || duplicated ? intl.formatMessage({ id: "admin.category.3.017" }) : ""}
                </div>
            </div>
        ),
        [newZhCategoryName, duplicatedZh, duplicated]
    );
    const newCatEnFieldForm = React.useMemo(
        () => (
            <>
                <LabelTextField
                    label={intl.formatMessage({
                        id: "admin.category.3.009",
                    })}
                    variant="filled"
                    fullWidth
                    value={newEnCategoryName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewEnCategoryName(e.currentTarget.value)}
                    error={duplicatedEng || duplicated}
                    {...rules(ActionType.UPDATE_ENNAME)}
                />
                <div className={classes.errorMsg}>
                    {duplicatedEng || duplicated ? intl.formatMessage({ id: "admin.category.3.017" }) : ""}
                </div>
            </>
        ),
        [newEnCategoryName, duplicatedEng, duplicated]
    );

    const newCategoryForm = () => (
        <div>
            {newCatZhFieldForm}
            {newCatEnFieldForm}
        </div>
    );

    useEffect(() => {
        if (
            newZhCategoryName.length > 0 &&
            newZhCategoryName.length <= 6 &&
            newEnCategoryName.length > 0 &&
            newEnCategoryName.length <= 12
        ) {
            setNewCategoryValidated(true);
            return;
        }
        setNewCategoryValidated(false);
    }, [newZhCategoryName, newEnCategoryName]);

    return (
        <React.Fragment>
            <div className={device == "PC" ? "categoryPageWrapper pageWrapper" : classes.mobileCategoryPage}>
                <Box display="flex" justifyContent="space-between">
                    <Box component="span" className="title">
                        {intl.formatMessage({ id: "admin.category.3.001" })}
                    </Box>

                    <Button
                        variant="contained"
                        onClick={() => setAddCategoryDialog(!addCategoryDialog)}
                        color="primary"
                    >
                        {intl.formatMessage({ id: "admin.category.3.002" })}
                    </Button>
                </Box>

                <div className="pageContent">
                    <React.Fragment>
                        <div className={`${classes.articleListWrapper}`}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" className={classes.head}>
                                                {intl.formatMessage({ id: "admin.category.3.003" })}
                                            </TableCell>
                                            <TableCell align="center" className={classes.head}>
                                                {intl.formatMessage({ id: "admin.category.3.004" })}
                                            </TableCell>
                                            <TableCell align="center" className={classes.head}>
                                                {intl.formatMessage({ id: "admin.category.3.005" })}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{renderCategories()}</TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </React.Fragment>
                </div>

                <ErrorDialog
                    title={intl.formatMessage({ id: "admin.category.3.012" })}
                    content={intl.formatMessage({ id: "admin.category.3.020" })}
                    confirmBtnText={intl.formatMessage({ id: "admin.category.3.011" })}
                    confirmHandler={(e) => {
                        setCatInUse(false);
                        setChangeStatusCategoryDialog(dialogInitState);
                    }}
                    openStatus={catInUse}
                    toggleDialog={() => {
                        setCatInUse(false);
                        setChangeStatusCategoryDialog(dialogInitState);
                    }}
                />

                <ErrorDialog
                    title={intl.formatMessage({ id: "admin.category.3.012" })}
                    content={intl.formatMessage({ id: "admin.category.3.021" })}
                    confirmBtnText={intl.formatMessage({ id: "admin.category.3.011" })}
                    confirmHandler={(e) => deleteCategory()}
                    cancelBtnText={intl.formatMessage({ id: "admin.category.3.014" })}
                    cancelHandler={() => setChangeStatusCategoryDialog(dialogInitState)}
                    openStatus={changeStatusCategoryDialog.openStatus}
                    toggleDialog={() => setChangeStatusCategoryDialog(dialogInitState)}
                    disabled={isSubmittingDelete}
                />

                <ErrorDialog
                    title={intl.formatMessage({ id: "admin.category.3.012" })}
                    content={intl.formatMessage({ id: "admin.category.3.023" })}
                    confirmBtnText={intl.formatMessage({ id: "admin.category.3.011" })}
                    confirmHandler={(e) => enableCategory()}
                    cancelBtnText={intl.formatMessage({ id: "admin.category.3.014" })}
                    cancelHandler={() => {
                        setEnableDialog(false);
                        setChangeStatusCategoryDialog(dialogInitState);
                    }}
                    openStatus={enableDialog}
                    toggleDialog={() => {
                        setEnableDialog(false);
                        setChangeStatusCategoryDialog(dialogInitState);
                    }}
                    notError={true}
                    disabled={isSubmittingEnable}
                />

                <ErrorDialog
                    title={intl.formatMessage({ id: "admin.category.3.012" })}
                    content={intl.formatMessage({ id: "admin.category.3.022" })}
                    confirmBtnText={intl.formatMessage({ id: "admin.category.3.011" })}
                    confirmHandler={(e) => disableCategory()}
                    cancelBtnText={intl.formatMessage({ id: "admin.category.3.014" })}
                    cancelHandler={() => {
                        setDisableDialog(false);
                        setChangeStatusCategoryDialog(dialogInitState);
                    }}
                    openStatus={disableDialog}
                    toggleDialog={() => {
                        setDisableDialog(false);
                        setChangeStatusCategoryDialog(dialogInitState);
                    }}
                    notError={false}
                    disabled={isSubmittingDisable}
                />
                <ErrorDialog
                    openStatus={openValidateError}
                    toggleDialog={() => setOpenValidateError(false)}
                    confirmHandler={() => {
                        setOpenValidateError(false);
                    }}
                    title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                    content={
                        <div style={{ height: "82px" }}>
                            {intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}
                        </div>
                    }
                    confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
                />

                <NewCategoryDialog
                    title={
                        duplicatedEng || duplicatedZh
                            ? intl.formatMessage({ id: "admin.category.3.012" })
                            : intl.formatMessage({ id: "admin.category.3.007" })
                    }
                    content={newCategoryForm()}
                    disabled={isSubmittingAdd}
                    confirmBtnText={intl.formatMessage({ id: "admin.category.3.011" })}
                    confirmHandler={(e) => {
                        resetDuplicated();
                        addCategoryHandler();
                    }}
                    cancelBtnText={intl.formatMessage({ id: "4.048" })}
                    cancelHandler={() => setAddCategoryDialog(false)}
                    openStatus={addCategoryDialog}
                    toggleDialog={() => setAddCategoryDialog(!addCategoryDialog)}
                    newCategoryValidated={newCategoryValidated}
                />
            </div>
        </React.Fragment>
    );
}

export default CategoryPage;
