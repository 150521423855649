import { createStyles, makeStyles, OutlinedInputProps, TextField, TextFieldProps, Theme } from "@material-ui/core";
import React from "react";

const useStylesLabelTF = makeStyles((theme: Theme) =>
    createStyles({
        inputRoot: {
            border: "1px solid #d8d8d8",
            overflow: "hidden",
            borderRadius: 4,
            backgroundColor: "#fff",
            transition: theme.transitions.create(["border-color", "box-shadow"]),
            "&:hover": {
                backgroundColor: "#fff",
            },
            "&$focused": {
                backgroundColor: "#fff",
                // boxShadow: `${fade(
                //     theme.palette.primary.main,
                //     0.25
                // )} 0 0 0 2px`,
                // borderColor: theme.palette.primary.main,
            },
            "&$error": {
                color: "#222",
                border: "1px solid #ff0000",
            },
            "&$disabled": {
                color: "#222",
                background: "#ffffff",
            },
        },
        inputLabelRoot: {
            color: "#222",
            "&$focused": {
                color: "#222",
            },
            "&$error": {
                color: "#222",
            },
            "&$disabled": {
                color: "#222",
                background: "#ffffff",
            },
            fontSize: "1.2rem",
        },
        formHelperTextRoot: {
            "&$error": {
                lineHeight: "27px",
                fontSize: "18px",
                color: "#ff0000",
                marginLeft: 0,
            },
        },
        focused: {},
        error: {},
        disabled: {},
    })
);

export default function LabelTextField(props: TextFieldProps) {
    const classes = useStylesLabelTF();

    return (
        <TextField
            InputProps={
                {
                    classes: {
                        root: classes.inputRoot,
                        focused: classes.focused,
                        error: classes.error,
                        disabled: classes.disabled,
                    },
                    disableUnderline: true,
                } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
                classes: {
                    root: classes.inputLabelRoot,
                    focused: classes.focused,
                    error: classes.error,
                    disabled: classes.disabled,
                },
            }}
            FormHelperTextProps={{
                classes: {
                    root: classes.formHelperTextRoot,
                    error: classes.error,
                },
            }}
            {...props}
        />
    );
}
