import React, { useEffect, useState, useContext } from "react";
import { RwdContext } from "../App";
import { Redirect } from "react-router-dom";
import { OAuth2Login } from "../utils/OAuth2Login";
import useRWD from "../hooks/MobileRWD";
import { ROLE_TYPE } from "../interfaces/enums";
import { UserInfoContext } from "../App";
import { userInfo } from "os";

enum TokenStatus {
    WAITING_FOR_TOKEN,
    TOKEN_OK,
    TOKEN_ERROR,
}

export const OAuth2LoginCallback = (props) => {
    const userInfo = React.useContext(UserInfoContext);
    const [tokenStatus, setTokenStatus] = useState(0);
    const device = useContext(RwdContext);
    useEffect(() => {
        const getToken = async () => {
            setTokenStatus(TokenStatus.WAITING_FOR_TOKEN);
            await OAuth2Login.authorizationCodeHandler()
                .then(() => {
                    console.log("Trying to obtain access token");
                    OAuth2Login.getAccessToken()
                        .then((token) => {
                            // Redirect to App
                            setTokenStatus(TokenStatus.TOKEN_OK);
                            props.setGTokenStatus?.(true);
                        })
                        .catch((err) => {
                            // Error occurred when getting token
                            console.log("Failed to obtain access token", err.constructor.name);
                        });
                })
                .catch((err: Object) => {
                    console.error("Error when handling auth code:", err.constructor.name);
                    OAuth2Login.removeOAuthStorage();
                    OAuth2Login.authorize();
                });
        };

        getToken();
    }, []);
    return (
        <>
            {tokenStatus === TokenStatus.WAITING_FOR_TOKEN ? <h1>Please wait...</h1> : null}
            {tokenStatus === TokenStatus.TOKEN_OK && device === "PC" ? (
                <Redirect to="/my-account" />
            ) : userInfo.roles?.includes(ROLE_TYPE.SHOP_IN_SHOP) ? (
                <Redirect to="/store-list-page" />
            ) : null}
            {
                // Will goes here when logout
                tokenStatus === TokenStatus.TOKEN_ERROR ? <Redirect to="/my-account" /> : null
            }
        </>
    );
};
