import Box from "@material-ui/core/Box";
import { styled } from "@material-ui/core/styles";

export const ContentContainerBox = styled(Box)({
    background: "#ffffff",
    border: 0,
    borderRadius: 6,
    color: "#222222",
    marginTop: 12,
    marginBottom: 12,
    paddingTop: 12,
    paddingBottom: 12,
    "& >*": {
        marginLeft: 12,
        marginRight: 12,
    },
    "& .containerTitle": {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#222222",
        paddingBottom: "13px",
    },
    "& .containerRemark": {
        fontSize: "16px",
        color: "#222222",
        marginBottom: "8px",
    },
});
export default ContentContainerBox;
