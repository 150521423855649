export enum ArticleStatus {
    WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
    WAITING_FOR_PUBLISH = "WAITING_FOR_PUBLISH",
    PUBLISH_DENIED = "PUBLISH_DENIED",
    PUBLISHED = "PUBLISHED",
    DRAFT = "DRAFT",
}

export enum RejectReasonCode {
    EXTERNAL_LINKS = "EXTERNAL_LINKS",
    ADS_OVERLOAD = "ADS_OVERLOAD",
    MISMATCH_CATEGORY = "MISMATCH_CATEGORY",
    CONTENT_ISSUE = "CONTENT_ISSUE",
    OTHERS = "OTHERS",
}

export enum articleListPopupStatus {
    SAVED_AS_DRAFT = "SAVED_AS_DRAFT",
    WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
    SAVED_UPDATES = "SAVED_UPDATES",
    APPROVAL_FINISHED = "APPROVAL_FINISHED",
}

export enum KolLiveBookingRejectReason {
    TIME_FRAME_MAX_BOOKING_REACHED = "TIME_FRAME_MAX_BOOKING_REACHED",
    OVERLAP_TIMEFRAME = "OVERLAP_TIMEFRAME",
    TIMESLOT_QUOTA_FULL = "TIMESLOT_QUOTA_FULL",
    BOOKING_QUOTA_FULL = "BOOKING_QUOTA_FULL",
    TIMESLOT_BOOKED = "TIMESLOT_BOOKED",
    APPROVED_BOOKINGS_QUOTA_FOR_TIMESLOT_REACHED = "APPROVED_BOOKINGS_QUOTA_FOR_TIMESLOT_REACHED",
}

export enum ROLE_TYPE {
    ADMIN = "ADMIN",
    KOL = "KOL",
    MEDIA = "MEDIA",
    PUBLIC = "PUBLIC_CONTENT",
    HOST = "LIVE_SHOW_HOST",
    SHOP_IN_SHOP = "SHOP_IN_SHOP",
    SUPERFOLLOW_ADMIN = "SUPERFOLLOW_ADMIN",
    LIVE_ADMIN = "LIVE_ADMIN",
}

export enum RoutePath {
    MY_ACCOUNT = "/my-account",
    ARTICLES = "/articles",
    NEW_ARTICLE = "/new-article",
    EDIT_ARTICLE = "/edit-article",
    APPROVE_ARTICLE = "/approve-article",
    DASHBOARD = "/dashboard",
    CATEGORY = "/category",
    LIVE_BOOKING_EDITOR_MENU = "/live-booking",
    LIVE_BOOKING = "/live-booking",
    LIVE_BOOKING_MENU = "/edit-time-slots",
    LIVE_BOOKING_HISTORY = "/live-booking-history",
    TIME_SLOTS_FOR_BOOKING = "/time-slots-for-booking",
    EDIT_TIME_SLOTS = "/edit-time-slots",
    ALL_TIME_SLOTS = "/all-time-slots",
    STORE_LIST_PAGE = "/store-list-page",
    EDIT_APP_PAGE = "/edit-app-page",
    MANAGE_BANNER = "/manage-banner",
    NO_STORE = "/no-store-found",
}

export enum RoutePathRule {
    MY_ACCOUNT_PROFILE_REQUIRED,
    STORE_SELECT_REQUIRED,
}

export enum StoreBannerComponentType {
    IMAGE_GALLERY_LAYOUT_1 = "IMAGE_GALLERY_LAYOUT_1",
    IMAGE_GALLERY_LAYOUT_2 = "IMAGE_GALLERY_LAYOUT_2",
    IMAGE_GALLERY_LAYOUT_3 = "IMAGE_GALLERY_LAYOUT_3",
    IMAGE_GALLERY_LAYOUT_4 = "IMAGE_GALLERY_LAYOUT_4",
    IMAGE_GALLERY_LAYOUT_5 = "IMAGE_GALLERY_LAYOUT_5",
    IMAGE_GALLERY_LAYOUT_6 = "IMAGE_GALLERY_LAYOUT_6",
    IMAGE_GALLERY_LAYOUT_7 = "IMAGE_GALLERY_LAYOUT_7",
    IMAGE_GALLERY_LAYOUT_8 = "IMAGE_GALLERY_LAYOUT_8",
}

export enum imageType {
    TYPE_1 = "TYPE_1",
    TYPE_2 = "TYPE_2",
    TYPE_3 = "TYPE_3",
    TYPE_4 = "TYPE_4",
    TYPE_5 = "TYPE_5",
    TYPE_6 = "TYPE_6",
    TYPE_7 = "TYPE_7",
    TYPE_8 = "TYPE_8",
}

export enum HtmlType {
    CUSTOM_HTML = "CUSTOM_HTML",
}
