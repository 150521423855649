import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, createStyles, Grid, makeStyles, Popover, Select, styled, Theme, Typography } from "@material-ui/core";

import BookingRecordPage from "./BookingRecordPage";
import SelectInputWithLabel from "../components/textfield/SelectInputWithLabel";
import { UtilFunctions } from "../utils/UtilFunctions";
import { ApiServiceContext, RwdContext } from "../App";
import classes from "*.module.css";
import { stringify } from "querystring";
import { isClassExpression } from "typescript";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import { ReactComponent as CloseIcon } from "../assets/admin/ic/closeSmall.svg";

const dayNameMapping = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContent: {
            marginTop: 18,
            "& .flex": {
                display: "flex",
                justifyContent: "space-between",
            },
            "& .wrapper": {
                marginTop: 32,
            },
        },
        mobilePage: {
            width: "100%",
            marginTop: "10px",
            "& .title": {
                fontSize: "22px",
                fontWeight: "bold",
                textAlign: "left",
                marginLeft: "10px",
            },
        },
        dropdownlistStyle: {
            width: "100%",
            margin: "5px 20px 5px 20px",
        },
        FiltersItems: {
            marginTop: 10,
            "@media (max-width : 1280px)": {
                padding: "0 20px",
                fontWeight: "bold",
            },
        },
        FiltersContainer: {
            width: "100%",
            height: "auto",
            backgroundColor: "#fff",
            borderRadius: "6px 6px 0px 0px",
            marginTop: 11,
            padding: 0,
        },
        CalendarHeader: {
            padding: 20,
            position: "relative",
            width: "100%",
            "@media (max-width: 1280px)": {
                position: "relative",
                padding: 8,
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
            },
        },
        MonthTitle: {
            position: "relative",
            left: 0,
            fontWeight: "bold",
            fontSize: 18,
            "@media (max-width: 1280px)": {
                width: "auto",
                position: "relative",
                fontSize: 12,
            },
        },
        StatusTitle: {
            position: "absolute",
            right: 0,
        },
        StatusCircle: {
            height: 16,
            width: 16,
            right: 0,
            borderRadius: "50%",
            display: "inline-block",
            marginRight: 5,
            "@media (max-width: 1280px)": {
                height: 8,
                width: 8,
                right: 0,
                borderRadius: "50%",
                display: "inline-block",
                marginRight: 5,
            },
        },
        StatusSubTitle: {
            position: "relative",
            top: -3,
            marginRight: 10,
            "@media (max-width: 1280px)": {
                position: "relative",
                top: -3,
                marginRight: 5,
                fontSize: "14px",
            },
        },
        WeekBarContainer: {
            width: "100%",
            height: "auto",
            backgroundColor: "#fff",
            borderRadius: "6px 6px 0px 0px",
            marginTop: 11,
        },
        WeekBarItems: {
            display: "inline",
            width: "12.5%",
            textAlign: "center",
            position: "relative",
            userSelect: "none",
        },
        DayItems: {
            marginBottom: 8,
            fontSize: 18,
        },
        NumberDayItems: {
            fontSize: 18,
        },
        Disable: {
            filter: "opacity(0.4)",
            backgroundColor: "rgba(191,191,191, 0.4)",
        },
        DayBar: {
            width: "100%",
            textAlign: "center",
            marginBottom: 7,
        },
        DayName: {
            width: "14.28%",
            display: "inline-block",
            textAlign: "center",
            fontSize: 18,
            "@media (max-width : 1280px)": {
                fontSize: 14,
            },
        },
        DayWrapper: {
            width: "100%",
            textAlign: "center",
        },
        DayItem: {
            width: "14.3%",
            display: "inline-table",
            textAlign: "center",
            borderLeft: "2.5px solid #c4c4c4",
            borderRight: "1.5px solid #c4c4c4",
            borderBottom: "2.5px solid #c4c4c4",
            minHeight: "205px",
            background: "#fff",
        },
        DayItemDate: {
            fontWeight: 500,
            "@media (max-width : 1280px)": {
                fontSize: 10,
            },
        },
        CalendarWeekContainer: {
            width: "100%",
            height: "40%",
            display: "flex",
        },
        Reject: {
            border: "2px solid #d0021b",
            color: "#d0021b",
        },
        Waiting: {
            border: "2px solid #0076ff",
            color: "#0076ff",
        },
        Approve: {
            border: "2px solid #22ab39",
            color: "#22ab39",
        },
        Cancel: {
            border: "2px solid #ff8d00",
            color: "#ff8d00",
        },
        More: {
            border: "2px solid #222222",
            color: "#222222",
        },
        TimeSlotBtn: {
            cursor: "pointer",
            display: "inline",
            borderRadius: 24,
            maxWidth: 113,
            width: "100%",
            height: 36,
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word",
            "-webkit-line-clamp": 2 /* number of lines to show */,
            "-webkit-box-orient": "vertical",
            background: "none",
            marginTop: 4,
        },
        TimeSlotBtnMore: {
            cursor: "pointer",
            display: "inline",
            borderRadius: 24,
            maxWidth: 113,
            width: "100%",
            minHeight: 36,
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word",
            "-webkit-line-clamp": 2 /* number of lines to show */,
            "-webkit-box-orient": "vertical",
            background: "none",
            marginTop: 4,
        },
        Popover: {
            marginLeft: 128,
            "@media (max-width : 1280px)": {
                marginLeft: 10,
            },
        },
        Popover_Wrapper: {
            width: "200px",
            height: "320px",
            position: "relative",
            padding: 10,
        },
        Popover_CloseBtn: {
            textAlign: "end",
            position: "absolute",
            right: 0,
        },
        MoreTimeSlotBtn_Wrapper: {
            textAlign: "center",
            marginBottom: 10,
        },
        CloseBtn_Wrapper: {
            position: "relative",
            textAlign: "center",
        },
        Day_Wrapper: {
            fontSize: 16,
            fontWeight: "bold",
            textAlign: "center",
            marginTop: 3,
        },
        Time_Wrapper: {
            fontSize: 17,
            fontWeight: "bold",
            textAlign: "center",
        },
        timeSlotNotFound: {
            color: "#ff5555",
            fontWeight: "bold",
            fontSize: 20,
            marginTop: 25,
        },
    })
);
const ConfirmButton = styled(Button)({
    marginLeft: 15,
    marginBottom: 4,
    width: "145px",
    height: "50px",
    padding: "12px",
    borderRadius: "6px",
    backgroundColor: "#22ab39",
    fontFamily: "NotoSansTC",
    fontSize: 22,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
});
const StyledSelect = styled(Select)({
    width: "210px",
    height: "50px",
    padding: "10px",
    borderRadius: "6px",
    border: "solid 1px #999999",
    backgroundColor: "#ffffff",
});

function LiveBookingHistoryPage(props) {
    const classes = useStyles();
    const intl = useIntl();
    const apiService = useContext(ApiServiceContext);
    const device = useContext(RwdContext);
    const [selected_month, setSelected_month] = useState<string>("2021-04");
    const [dropdown_yearMonth, setDropdown_yearMonth] = useState<Array<any>>([]);
    const [timeSlot_isFetched, setTimeSlot_isFetched] = useState<boolean>(false);
    const [bookings_isFetched, setBookings_isFetched] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);

    const [timeSlot, setTimeSlot] = useState<any>([]);
    const [bookings, setBookings] = useState<any>([]);
    const [monthSaver, setMonthSaver] = useState<any>([]);
    const [bookingDetailsPage_Data, setBookingDetailsPage_Data] = useState<any>();
    const [isBookingDetailsPage, setIsBookingDetailsPage] = useState<boolean>(false);
    const [isMorePopover, setIsMorePopover] = useState<boolean>(false);
    const [morePopoverData, setMorePopoverData] = useState<any>();
    const [errMsg, setErrMsg] = useState("general.1.002");
    const [openValidateError, setOpenValidateError] = useState(false);

    const init_MonthlyData = (_yearMonth: string) => {
        //console.log("init timeSlot");
        //console.log(timeSlot);
        //console.log("init bookings");
        //console.log(bookings);
        const calendar = UtilFunctions.getCalendar(_yearMonth);
        let obj: Array<any> = [];
        calendar.forEach((_cal, _calIndex) => {
            obj[_calIndex] = [];
            _cal.date.forEach((_day, _dayIndex) => {
                obj[_calIndex].push({
                    date: _day,
                    timeSlot: [],
                    bookings: [],
                });
                bookings.forEach((_booking, _bookingIndex) => {
                    if (_day == _booking.localDate) {
                        if (obj[_calIndex][_dayIndex].bookings) {
                            obj[_calIndex][_dayIndex].bookings.push(_booking);
                        }
                        // obj[_calIndex][_dayIndex].timeSlot = timeSlot.filter((t) => t._id == _booking.timeSlotRefId)[0];
                        obj[_calIndex][_dayIndex].timeSlot.push(
                            timeSlot.filter((t) => t._id == _booking.timeSlotRefId)
                        );
                        // testingObj.push(timeSlot.filter((t) => t._id == _booking.timeSlotRefId))
                    }
                });
            });
        });

        // console.log("calendar: ");
        // console.log(calendar);
        // console.log("formatted data: ");
        // console.log(obj);
        setMonthSaver(obj);
    };

    const getAllTimeSlot_handler = (_month: string, _toMonth?: string) => {
        let obj, startDayOfMonth, endDayOfMonth;

        if (_month && _toMonth) {
            const startDay = UtilFunctions.getStartEndDay(_month);
            const endDay = UtilFunctions.getStartEndDay(_toMonth);

            startDayOfMonth = startDay[0];
            endDayOfMonth = endDay[1];

            obj = {
                startLocalDate: `${_month}-${startDayOfMonth}`,
                endLocalDate: `${_toMonth}-${endDayOfMonth}`,
                showDisabled: true,
            };
        } else {
            const startEndDay = UtilFunctions.getStartEndDay(_month);
            startDayOfMonth = startEndDay[0];
            endDayOfMonth = startEndDay[1];

            obj = {
                startLocalDate: `${_month}-${startDayOfMonth}`,
                endLocalDate: `${_month}-${endDayOfMonth}`,
                showDisabled: true,
            };
        }

        apiService
            .getAllTimeSlots(obj)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    if (_month && _toMonth) {
                        // console.log("getAllTimeSlots");
                        // console.log(res.data?.data);

                        let year = res.data?.data[0]?.year;
                        let month = res.data?.data[0]?.month;
                        //console.log(yearMonth);
                        if (year && month) {
                            //console.log("initial yearMonth: " + yearMonth);
                            getAllBookings_handler(_month, _toMonth);
                            setTimeSlot(res.data?.data);
                        }
                    }
                } else {
                    console.log("Fail to fetch getAllTimeSlots");
                    throw new Error("Error Unknown");
                }
            })
            .catch((err) => {
                if (err.code === "ECONNABORTED") {
                    if (_month && _toMonth) {
                        getAllBookings_handler(_month, _toMonth);
                    }
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                } else {
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                }
            })
            .finally(() => {
                setTimeSlot_isFetched(true);
            });
    };

    const sortingForBookings = (_bookings: any) => {
        let WAITING_FOR_APPROVAL: any = [];
        let REJECTED: any = [];
        let APPROVED: any = [];
        let CANCELLED: any = [];

        _bookings.forEach((booking, b_index) => {
            if (booking.status == "WAITING_FOR_APPROVAL") {
                WAITING_FOR_APPROVAL.push(booking);
            } else if (booking.status == "REJECTED") {
                REJECTED.push(booking);
            } else if (booking.status == "APPROVED") {
                APPROVED.push(booking);
            } else if (booking.status == "CANCELLED") {
                CANCELLED.push(booking);
            }
        });

        WAITING_FOR_APPROVAL.sort((a, b) => b.createTime - a.createTime);
        REJECTED.sort((a, b) => b.createTime - a.createTime);
        APPROVED.sort((a, b) => b.createTime - a.createTime);
        CANCELLED.sort((a, b) => b.createTime - a.createTime);

        const _obj: any = [...WAITING_FOR_APPROVAL, ...APPROVED, ...REJECTED, ...CANCELLED];

        setBookings(_obj);
    };

    const getAllBookings_handler = (_month: string, _toMonth?: string) => {
        let obj, startDayOfMonth, endDayOfMonth;

        if (_month && _toMonth) {
            const startDay = UtilFunctions.getStartEndDay(_month);
            const endDay = UtilFunctions.getStartEndDay(_toMonth);

            startDayOfMonth = startDay[0];
            endDayOfMonth = endDay[1];

            obj = {
                startLocalDate: `${_month}-${startDayOfMonth}`,
                endLocalDate: `${_toMonth}-${endDayOfMonth}`,
            };
        } else {
            const startEndDay = UtilFunctions.getStartEndDay(_month);
            startDayOfMonth = startEndDay[0];
            endDayOfMonth = startEndDay[1];

            obj = {
                startLocalDate: `${_month}-${startDayOfMonth}`,
                endLocalDate: `${_month}-${endDayOfMonth}`,
            };
        }

        apiService
            .getAllBookings(obj)
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    sortingForBookings(res.data?.data);
                } else {
                    console.log("Fail to fetch getAllBookings");
                    throw new Error("Error Unknown");
                }
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            })
            .finally(() => {
                setBookings_isFetched(true);
            });
    };

    const changeMonth_handler = (val: any) => {
        setSelected_month(val);

        getAllBookings_handler(val);
        getAllTimeSlot_handler(val);

        init_MonthlyData(selected_month);
        // console.log("change month " + val);
    };

    const init_yearMonth = () => {
        // console.log("init year month");
        // console.log(_obj);
        let list: Array<any> = [];
        let temp_date = "";
        timeSlot.map((item) => {
            if (!item.disabled) {
                let dateForDisplay = item.year + "-" + `${item.month <= 9 ? "0" + item.month : item.month}`;
                let formattedDate = UtilFunctions.getShortFormDate(JSON.stringify(dateForDisplay));

                if (dateForDisplay !== temp_date) {
                    list.push({
                        name: formattedDate,
                        value: dateForDisplay,
                    });
                }
                temp_date = dateForDisplay;
            }
        });
        setSelected_month(list[0]?.value ? list[0]?.value : "");
        setDropdown_yearMonth(list);
    };

    const init_yearMonthList = () => {
        let dateRange = UtilFunctions.getInitialDateRange();
        getAllTimeSlot_handler(dateRange[0], dateRange[1]);
    };

    const onClick_timeSlot_handler = (bookings: any, timeSlot: any) => {
        const obj = {
            booking: bookings,
            timeSlot: timeSlot,
        };

        setBookingDetailsPage_Data(obj);
        setIsBookingDetailsPage(true);
    };

    const onClick_moreTimeSlot_handler = (obj?: any) => {
        if (obj) {
            setMorePopoverData(obj);
        }

        setIsMorePopover(!isMorePopover);
    };

    const onClose_moreTimeSlot_handler = () => {
        setIsMorePopover(false);
    };

    const refreshApiData = () => {
        getAllBookings_handler(selected_month);
        getAllTimeSlot_handler(selected_month);

        // selected_publisher_handler("ALL_PUBLISHER");
        // selected_timeSlot_handler("ALL_TIMESLOT");
        // selected_status_handler("ALL_STATUS");

        if (isMorePopover) {
            setIsMorePopover(!isMorePopover);
        }
    };

    const onCloseHandler = () => {
        refreshApiData();
        setIsBookingDetailsPage(false);
    };

    useEffect(() => {
        init_yearMonthList();
        const forceDropdownStyle: any = document.getElementsByClassName("makeStyles-typeThreeUI_select-261")[0];
        if (typeof forceDropdownStyle !== "undefined") {
            try {
                forceDropdownStyle.style.background = "#fff";
            } catch {}
        }
    }, []);

    useEffect(() => {
        if (timeSlot_isFetched && bookings_isFetched && selected_month) {
            init_MonthlyData(selected_month);

            setTimeSlot_isFetched(false);
            setBookings_isFetched(false);
            setIsFetched(true);
        } else if (timeSlot_isFetched && bookings_isFetched) {
            setIsFetched(true);
        }
    }, [timeSlot_isFetched, bookings_isFetched]);

    useEffect(() => {
        if (timeSlot.length !== 0) init_yearMonth();
    }, [timeSlot]);

    if (isFetched) {
        if (!isBookingDetailsPage && monthSaver.length) {
            return (
                <>
                    <ErrorDialog
                        openStatus={openValidateError}
                        toggleDialog={() => setOpenValidateError(false)}
                        confirmHandler={() => setOpenValidateError(false)}
                        title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                        content={
                            <div style={{ height: "82px" }}>
                                {intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}
                            </div>
                        }
                        confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
                    />
                    <div className={device == "PC" ? "LiveBookingHistoryPageWrapper pageWrapper" : classes.mobilePage}>
                        <div className={device == "PC" ? `pageContent ${classes.pageContent}` : ""}>
                            <div className="LiveBookingHistoryTopBar flex">
                                <div className="title">{intl.formatMessage({ id: "editor.liveBooking.1.003" })}</div>
                            </div>

                            <div className="wrapper">
                                <DropDownList
                                    text={"admin.editTimeSlots.1.007"}
                                    options={dropdown_yearMonth}
                                    value={selected_month}
                                    // placeholder={intl.formatMessage({ id: "admin.editTimeSlots.1.049.a" })}
                                    func={(val) => changeMonth_handler(val)}
                                />
                                <Grid container justify="flex-start" className={classes.FiltersContainer}>
                                    <div className={classes.CalendarHeader}>
                                        <span className={classes.MonthTitle}>
                                            {UtilFunctions.getShortFormDate(selected_month)}
                                        </span>
                                        <span className={classes.StatusTitle}>
                                            <span
                                                className={classes.StatusCircle}
                                                style={{ backgroundColor: "#0076ff" }}
                                            />
                                            <span className={classes.StatusSubTitle}>
                                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.012" })}
                                            </span>
                                            <span
                                                className={classes.StatusCircle}
                                                style={{ backgroundColor: "#22ab39" }}
                                            />
                                            <span className={classes.StatusSubTitle}>
                                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.013" })}
                                            </span>
                                            <span
                                                className={classes.StatusCircle}
                                                style={{ backgroundColor: "#d0021b" }}
                                            />
                                            <span className={classes.StatusSubTitle}>
                                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.015" })}
                                            </span>
                                            <span
                                                className={classes.StatusCircle}
                                                style={{ backgroundColor: "#ff8d00" }}
                                            />
                                            <span className={classes.StatusSubTitle}>
                                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.014" })}
                                            </span>
                                        </span>
                                    </div>

                                    <div className={classes.DayBar}>
                                        <span className={classes.DayName}>{"Sun"}</span>
                                        <span className={classes.DayName}>{"Mon"}</span>
                                        <span className={classes.DayName}>{"Tue"}</span>
                                        <span className={classes.DayName}>{"Wed"}</span>
                                        <span className={classes.DayName}>{"Thur"}</span>
                                        <span className={classes.DayName}>{"Fri"}</span>
                                        <span className={classes.DayName}>{"Sat"}</span>
                                    </div>
                                </Grid>
                                <div className={classes.DayWrapper}>
                                    {monthSaver.map((week, w_index) => {
                                        return (
                                            <Grid item className={classes.CalendarWeekContainer}>
                                                <CalendarWeek
                                                    selected_month={selected_month}
                                                    week={week}
                                                    w_index={w_index}
                                                    monthSaverLength={monthSaver.length}
                                                    onClick_timeSlot_handler={onClick_timeSlot_handler}
                                                    onClick_moreTimeSlot_handler={onClick_moreTimeSlot_handler}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Popover
                        className={classes.Popover}
                        open={isMorePopover}
                        onClose={onClose_moreTimeSlot_handler}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                    >
                        <div className={classes.Popover_Wrapper}>
                            <div className={classes.CloseBtn_Wrapper}>
                                <span className={classes.Time_Wrapper}>
                                    {morePopoverData?.bookings &&
                                        UtilFunctions.getShortFormDate(morePopoverData?.bookings[0]?.localDate)}
                                </span>
                                <span
                                    className={classes.Popover_CloseBtn}
                                    onClick={() => onClick_moreTimeSlot_handler()}
                                >
                                    <CloseIcon style={{ cursor: "pointer" }} />
                                </span>
                            </div>

                            <div className={classes.Day_Wrapper}>
                                {morePopoverData?.bookings &&
                                    UtilFunctions.getDateNameFromDate(morePopoverData?.bookings[0]?.localDate)}
                            </div>
                            <Grid container justify="space-evenly" className={classes.FiltersContainer}>
                                {morePopoverData?.bookings?.map((booking, b_index) => {
                                    return (
                                        <Popover_TimeSlot_Button
                                            booking={booking}
                                            timeSlot={morePopoverData.timeSlot.filter(
                                                (t) => t[0]._id == booking.timeSlotRefId
                                            )}
                                            onClick_timeSlot_handler={onClick_timeSlot_handler}
                                        />
                                    );
                                })}
                            </Grid>
                        </div>
                    </Popover>
                </>
            );
        } else if (isBookingDetailsPage) {
            return (
                <>
                    <ErrorDialog
                        openStatus={openValidateError}
                        toggleDialog={() => setOpenValidateError(false)}
                        confirmHandler={() => setOpenValidateError(false)}
                        title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                        content={
                            <div style={{ height: "82px" }}>
                                {intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}
                            </div>
                        }
                        confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
                    />
                    <BookingRecordPage
                        onCloseHandler={() => onCloseHandler()}
                        bookingDetailsPage_Data={bookingDetailsPage_Data}
                        selected_month={selected_month}
                        refreshApiData={() => refreshApiData()}
                    />
                </>
            );
        } else {
            return (
                <>
                    <ErrorDialog
                        openStatus={openValidateError}
                        toggleDialog={() => setOpenValidateError(false)}
                        confirmHandler={() => setOpenValidateError(false)}
                        title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                        content={
                            <div style={{ height: "82px" }}>
                                {intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}
                            </div>
                        }
                        confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
                    />
                    <div className="LiveBookingHistoryPageWrapper pageWrapper">
                        <div className={`pageContent ${classes.pageContent}`}>
                            <div className="LiveBookingHistoryTopBar flex">
                                <div className="title">{intl.formatMessage({ id: "editor.liveBooking.1.003" })}</div>
                            </div>
                            <div className={classes.timeSlotNotFound}>
                                {intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.036b" })}
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    } else {
        return <></>;
    }
}

function Popover_TimeSlot_Button(props) {
    const classes = useStyles();
    const intl = useIntl();

    let borderColor = classes.Waiting;
    const status = props.booking.status;
    const startTime = UtilFunctions.getFormattedTime(props.timeSlot[0][0].startTime);
    const endTime = UtilFunctions.getFormattedTime(props.timeSlot[0][0].endTime);

    if (status == "REJECTED") {
        borderColor = classes.Reject;
    } else if (status == "WAITING_FOR_APPROVAL") {
        borderColor = classes.Waiting;
    } else if (status == "APPROVED") {
        borderColor = classes.Approve;
    } else if (status == "CANCELLED") {
        borderColor = classes.Cancel;
    }

    return (
        <Grid item xs={6} className={classes.MoreTimeSlotBtn_Wrapper}>
            <button
                className={`${classes.TimeSlotBtn} ${borderColor}`}
                onClick={() => props.onClick_timeSlot_handler(props.booking, props.timeSlot)}
            >
                <span>{startTime + " - " + endTime}</span>
            </button>
        </Grid>
    );
}

function CalendarWeek(props) {
    return props.week.map((day, d_index) => {
        return (
            <CalendarDay
                selected_month={props.selected_month}
                day={day}
                w_index={props.w_index}
                d_index={d_index}
                monthSaverLength={props.monthSaverLength}
                onClick_timeSlot_handler={props.onClick_timeSlot_handler}
                onClick_moreTimeSlot_handler={props.onClick_moreTimeSlot_handler}
            />
        );
    });
}

function CalendarDay(props) {
    const classes = useStyles();
    let isTheDateWithinThisMonth = UtilFunctions.isTheDateWithinThisMonth(props.selected_month, props.day.date);

    return (
        <>
            <Grid
                item
                className={classes.DayItem}
                style={{
                    background: props.d_index == 0 || props.d_index == 6 ? "#e8e8e8" : "#fff",
                    borderTop: props.w_index == 0 ? "3px solid #c4c4c4" : "",
                    borderLeft: props.d_index == 0 ? "3px solid #c4c4c4" : "",
                    borderRight: props.d_index == 6 ? "3px solid #c4c4c4" : "",
                    borderBottom: props.w_index == props.monthSaverLength - 1 ? "3px solid #c4c4c4" : "",
                }}
            >
                <Grid
                    item
                    className={classes.DayItemDate}
                    style={{ filter: isTheDateWithinThisMonth ? "unset" : "opacity(0)" }}
                >
                    {UtilFunctions.getShortFormDay(props.day.date)}
                </Grid>

                {isTheDateWithinThisMonth && (
                    <CalendarTimeSlotButtons
                        day={props.day}
                        d_index={props.d_index}
                        onClick_timeSlot_handler={props.onClick_timeSlot_handler}
                        onClick_moreTimeSlot_handler={props.onClick_moreTimeSlot_handler}
                    />
                )}
            </Grid>
        </>
    );
}

function CalendarTimeSlotButtons(props) {
    const bookings = props.day?.bookings;
    const classes = useStyles();
    const intl = useIntl();
    let hasMoreButton = false;

    return bookings.map((booking, b_index) => {
        let borderColor = classes.Waiting;
        const status = booking.status;

        let startTimeRaw = props.day?.timeSlot?.filter((t) => t[0]._id == booking.timeSlotRefId);
        let endTimeRaw = props.day?.timeSlot?.filter((t) => t[0]._id == booking.timeSlotRefId);

        const startTime = UtilFunctions.getFormattedTime(startTimeRaw[0][0].startTime);
        const endTime = UtilFunctions.getFormattedTime(endTimeRaw[0][0].endTime);

        if (status == "REJECTED") {
            borderColor = classes.Reject;
        } else if (status == "WAITING_FOR_APPROVAL") {
            borderColor = classes.Waiting;
        } else if (status == "APPROVED") {
            borderColor = classes.Approve;
        } else if (status == "CANCELLED") {
            borderColor = classes.Cancel;
        }

        if (b_index < 4) {
            return (
                <button
                    className={`${classes.TimeSlotBtn} ${borderColor}`}
                    onClick={() => props.onClick_timeSlot_handler(booking, props.day.timeSlot)}
                >
                    <span>{startTime + " - " + endTime}</span>
                </button>
            );
        } else {
            borderColor = classes.More;
            const moreText1 = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.017a" });
            const moreText2 = intl.formatMessage({ id: "admin.allTimeSlotsCalendar.1.017b" });
            const _length = props.day?.bookings?.length;

            const _bookings = props.day?.bookings?.slice(4, _length);
            const _timeSlot = props.day?.timeSlot;
            const obj = {
                bookings: _bookings,
                timeSlot: _timeSlot,
            };

            //console.log(obj);

            if (hasMoreButton) {
                return "";
            } else {
                hasMoreButton = true;
                return (
                    <button
                        className={`${classes.TimeSlotBtnMore} ${borderColor}`}
                        onClick={() => props.onClick_moreTimeSlot_handler(obj)}
                    >
                        <span>{moreText1 + " " + `${_length - 4}` + " " + moreText2}</span>
                    </button>
                );
            }
        }
    });
}

function DropDownList(props) {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Grid item className={classes.FiltersItems}>
            <div>{intl.formatMessage({ id: props.text })}</div>
            <span style={{ background: "#fff" }}>
                <SelectInputWithLabel
                    options={props.options}
                    value={props.value}
                    typeThreeUI={true}
                    disabled={!Boolean(props.options.length)}
                    onChange={(val) => {
                        props.func(val);
                    }}
                />
            </span>
        </Grid>
    );
}

export default LiveBookingHistoryPage;
