import { Button, Dialog, IconButton, Typography } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, styled, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { RwdContext } from "../../App";
import { ReactComponent as CloseIcon } from "../../assets/editor/ic/Close_white.svg";

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose?: () => void;
    rwdStatus?: string;
}

const styles = (theme: Theme) =>
    createStyles({
        title: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.error.contrastText,
            fontSize: "20px",
            fontWeight: 600,
            padding: "24px",
            "@media (max-width : 1280px)": {
                fontSize: "14px",
                padding: "16px 24px",
            },
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(2),
            top: theme.spacing(2),
        },
        mobileCloseButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(0.8),
            width: "25px",
        },
        confirmButton: {
            backgroundColor: theme.palette.success.main,
            color: "white",
            "@media (max-width : 1280px)": {
                padding: "8px 22px",
                fontSize: "14px",
                minHeight: 0,
                fontWeight: "bold",
            },
        },
        cancelButton: {
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
            "@media (max-width : 1280px)": {
                padding: "8px 22px",
                fontSize: "14px",
                minHeight: 0,
                fontWeight: "bold",
            },
        },
    });

export const ConfirmSaveDataDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, rwdStatus, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.title} {...other}>
            <Typography variant="inherit">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={rwdStatus == "PC" ? classes.closeButton : classes.mobileCloseButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const ConfirmSaveDataDialogContent = styled(MuiDialogContent)({
    fontSize: "20px",
    color: "#222222",
    "& .title": {
        fontSize: "28px",
        fontWeight: 900,
    },
    "@media (max-width : 1280px)": {
        fontSize: 14,
    },
});

export const ConfirmSaveDataDialogActions = styled(MuiDialogActions)({
    fontSize: "20px",
});

export type ErrorDialog = {
    openStatus: boolean;
    toggleDialog: () => void;
    confirmHandler: (e?) => void;
    cancelHandler?: (e?) => void;
    title: string;
    content: string | JSX.Element;
    confirmBtnText: any;
    cancelBtnText?: string;
    onClose?: (e) => void;
    rwdStatus?: string;
};

export const ConfirmSaveDataDialog = withStyles(styles)((props: ErrorDialog & WithStyles<typeof styles>) => {
    const { classes } = props;
    const rwdStatus = useContext(RwdContext);
    return (
        <Dialog
            maxWidth={"sm"}
            scroll={"paper"}
            fullWidth={true}
            aria-labelledby="customized-dialog-title"
            open={props.openStatus}
            onClose={props.onClose}
            transitionDuration={0}
        >
            <ConfirmSaveDataDialogTitle
                id="error-dialog-title"
                onClose={() => props.toggleDialog()}
                rwdStatus={rwdStatus}
            >
                {props.title}
            </ConfirmSaveDataDialogTitle>
            <ConfirmSaveDataDialogContent>
                <div className="content">{props.content}</div>
            </ConfirmSaveDataDialogContent>
            <ConfirmSaveDataDialogActions>
                {props.cancelBtnText && props.cancelHandler && (
                    <Button variant="outlined" onClick={() => props.cancelHandler?.()} className={classes.cancelButton}>
                        {props.cancelBtnText}
                    </Button>
                )}
                <Button variant="contained" onClick={(e) => props.confirmHandler(e)} className={classes.confirmButton}>
                    {props.confirmBtnText}
                </Button>
            </ConfirmSaveDataDialogActions>
        </Dialog>
    );
});
