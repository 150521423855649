// eslint-disable-next-line
import { Box, ThemeProvider } from "@material-ui/core";
import React, { createContext, useCallback, useContext, useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AppTheme } from "./AppTheme";
import { AlertDialogProps } from "./components/dialogs/AlertDialog";
import { ErrorDialog } from "./components/dialogs/ErrorDialog";
import Header from "./components/Header";
import LoadingIcon from "./components/loading/LoadingIcon";
import { OAuth2LoginCallback } from "./components/OAuthLoginCallback";
import PrivateRoute from "./components/PrivateRoute";
import Sidebar from "./components/Sidebar";
import { MODEL_Store, MODEL_UserDetails_Context } from "./interfaces";
import { ROLE_TYPE, RoutePath } from "./interfaces/enums";
import ApprovePage from "./pages/ApproveArticlePage";
import ArticleList from "./pages/ArticleListPage";
import ArticlePage from "./pages/ArticlePage";
import CategoryPage from "./pages/CategoryPage";
import DashboardPage from "./pages/DashboardPage";
import EditArticlePage from "./pages/EditArticlePage";
import MyAccountPage from "./pages/MyAccountPage";
import LiveBookingPage from "./pages/LiveBookingPage";
import LiveBookingHistoryPage from "./pages/LiveBookingHistoryPage";
import EditTimeSlotsPage from "./pages/EditTimeSlotsPage";
import AllTimeSlotsCalendarPage from "./pages/AllTimeSlotsCalendarPage";
import StoreListPage from "./pages/StoreListPage";
import EdiAppPage from "./pages/EditAppPage";
import "./scss/App.scss";
import ApiService from "./utils/APIService";
import { oauth2Config, OAuth2Login } from "./utils/OAuth2Login";
import { UtilFunctions } from "./utils/UtilFunctions";
import useRWD from "./hooks/MobileRWD";
import { type } from "os";
import ManageBannerPage from "./pages/ManageBannerPage";
import UserRoleNotFoundPage from "./pages/UserRoleNotFoundPage";
import StoreNotFoundPage from "./pages/NoStoreFoundPage";

let _accessToken = "undefined";
const oauthLogin = OAuth2Login.init(oauth2Config);

export interface AppProps {
    language: "zh" | "en";
    setLanguage: (lang: "zh" | "en") => void;
}

export const ApiServiceContext = createContext<ApiService>(
    new ApiService(
        "",
        (props) => {},
        () => {}
    )
);

const initState: MODEL_UserDetails_Context = {
    userId: "",
    userName: "",
    timestamp: "",
    roles: [],
};

const storeInit: MODEL_Store = {
    storesDetails: [],
};

type ErrorDialogState = { status: boolean; content?: any };

//choose store status
type ChooseStoreState = { status: boolean; shopCode?: string; shopName?: string };

export const UserInfoContext = createContext<MODEL_UserDetails_Context>(initState);
export const StoreInfoContext = createContext<MODEL_Store>(storeInit);
export const IsEmptyUserDetail = createContext<[boolean, Function]>([false, () => {}]);

export const UnknowErrorDialogContext = createContext<[ErrorDialogState, (value: ErrorDialogState) => void]>([
    { status: false },
    (value) => {},
]);
//choose store context, use to pass to other component
export const ChooseStoreStatusContext = createContext<[ChooseStoreState, (value: ChooseStoreState) => void]>([
    { status: false },
    (value) => {},
]);

export const SidebarHiddenContext = createContext<[boolean, Function]>([false, () => {}]);
export const RwdContext = createContext("");

function App(props: AppProps) {
    const { language, setLanguage } = props;
    const { formatMessage } = useIntl();
    const intl = useIntl();
    const [gTokenStatus, setGTokenStatus] = useState(false);
    const [sidebarHidden, setSidebarHidden] = useState<boolean>(false);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");

    const { device } = useRWD();

    const [globalAlertBox, setGlobalAlertBox] = useState<AlertDialogProps>({
        open: false,
        onClose: () => {},
    });

    const _setGlobalAlertBox = (props: AlertDialogProps) => {
        setGlobalAlertBox((prevState) => ({ ...prevState, ...props }));
    };

    const onApiTokenFailed = useCallback(() => {
        // TO-DO: Api failed function
    }, [formatMessage]);

    const _apiService = React.useMemo(
        () => new ApiService(_accessToken, _setGlobalAlertBox, onApiTokenFailed),
        [onApiTokenFailed]
    );

    const [userInfo, setUserInfo] = React.useState(initState);
    const [storeInfo, setStoreInfo] = useState(storeInit);
    const [isUserDetailEmpty, setIsUserDetailEmpty] = useState(false);
    const [isSidebarHidden, setIsSidebarHidden] = useState(false);
    const [isUserRoleEmpty, setIsUserRoleEmpty] = useState(false);
    useEffect(() => {
        console.log(process.env.REACT_APP_NOT_AUTHOIRZED_REDIRECT_WSO2 === "true" ? "TO WSO2" : "DEV_MODE");
        if (
            !OAuth2Login.isAuthorized() &&
            process.env.REACT_APP_NOT_AUTHOIRZED_REDIRECT_WSO2 === "true" &&
            !!!window.location.href.match(/\/oauth2\/setAuthCode/)
        ) {
            OAuth2Login.authorize();
        }
        if (OAuth2Login.isAuthorized()) setGTokenStatus(true);
    }, []);

    useEffect(() => {
        if (!gTokenStatus) return;
        _apiService.account
            .getUserDetails()
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    let { data } = res.data;
                    let parsed = { ...initState };
                    Object.keys(parsed).forEach((v) => {
                        parsed[v] = data[v];
                    });
                    if (
                        !(
                            data?.roles?.includes(ROLE_TYPE.ADMIN) ||
                            data?.roles?.includes(ROLE_TYPE.SUPERFOLLOW_ADMIN) ||
                            data?.roles?.includes(ROLE_TYPE.LIVE_ADMIN)
                        ) &&
                        !(data?.zh && typeof data?.zh?.name !== "undefined")
                    ) {
                        setIsUserDetailEmpty(true);
                    }
                    if (data?.roles.includes(ROLE_TYPE.SHOP_IN_SHOP)) {
                        _apiService.getStoreDetails().then((res) => {
                            if (UtilFunctions.validateApiResponse(res?.data)) {
                                let { data } = res.data;
                                let parsed = { ...storeInit };
                                if (data.storesDetails) {
                                    Object.keys(parsed).forEach((v) => {
                                        parsed[v] = data[v];
                                    });
                                }

                                setStoreInfo(parsed);
                            }
                        });
                    }
                    if (data?.roles.length > 0 && data?.roles[0] !== "") {
                        setIsUserRoleEmpty(false);
                    } else setIsUserRoleEmpty(true);

                    setUserInfo(parsed);
                } else {
                    throw new Error("Error Unknown");
                }
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    }, [gTokenStatus]);

    const [unknowErrorDialog, setUnknowErrorDialog] = useState<ErrorDialogState>({
        status: false,
    });

    const [chooseStoreStatus, setChooseStoreStatus] = useState<ChooseStoreState>({
        status: false,
    });
    return (
        <ApiServiceContext.Provider value={_apiService}>
            <UnknowErrorDialogContext.Provider value={[unknowErrorDialog, setUnknowErrorDialog]}>
                <ThemeProvider theme={AppTheme}>
                    <UserInfoContext.Provider value={userInfo}>
                        {isUserRoleEmpty ? (
                            <UserRoleNotFoundPage text={intl.formatMessage({ id: "text_608a1f2b06af3400b429006b" })} />
                        ) : (
                            <SidebarHiddenContext.Provider value={[isSidebarHidden, setIsSidebarHidden]}>
                                <RwdContext.Provider value={device ? device : ""}>
                                    {/* <CssBaseline /> */}
                                    <Route path="/oauth2/setAuthCode">
                                        <OAuth2LoginCallback setGTokenStatus={setGTokenStatus} />
                                    </Route>
                                    <ChooseStoreStatusContext.Provider
                                        value={[chooseStoreStatus, setChooseStoreStatus]}
                                    >
                                        <StoreInfoContext.Provider value={storeInfo}>
                                            <Switch>
                                                <PrivateRoute
                                                    path={RoutePath.STORE_LIST_PAGE}
                                                    exact
                                                    component={StoreListPage}
                                                />
                                                <div id="superfollowCmsContainer">
                                                    <Header />
                                                    <IsEmptyUserDetail.Provider
                                                        value={[isUserDetailEmpty, setIsUserDetailEmpty]}
                                                    >
                                                        <WithLogin>
                                                            <div className="mainWrapper">
                                                                <Sidebar />

                                                                <Switch>
                                                                    <PrivateRoute
                                                                        path={RoutePath.DASHBOARD}
                                                                        exact
                                                                        component={DashboardPage}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.EDIT_APP_PAGE}
                                                                        exact
                                                                        component={EdiAppPage}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.ARTICLES}
                                                                        exact
                                                                        component={ArticleList}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.MY_ACCOUNT}
                                                                        exact
                                                                        component={MyAccountPage}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.NEW_ARTICLE}
                                                                        exact
                                                                        component={ArticlePage}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.EDIT_ARTICLE}
                                                                        exact
                                                                        component={EditArticlePage}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.APPROVE_ARTICLE}
                                                                        exact
                                                                        component={ApprovePage}
                                                                    />

                                                                    <PrivateRoute
                                                                        path={RoutePath.CATEGORY}
                                                                        exact
                                                                        component={CategoryPage}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.EDIT_TIME_SLOTS}
                                                                        exact
                                                                        component={EditTimeSlotsPage}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.ALL_TIME_SLOTS}
                                                                        exact
                                                                        component={AllTimeSlotsCalendarPage}
                                                                    />
                                                                    {/* <Route path={[`/category`, `/testroute`]} component={AuthRequired} /> */}
                                                                    <PrivateRoute
                                                                        path={RoutePath.LIVE_BOOKING}
                                                                        exact
                                                                        component={LiveBookingPage}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.LIVE_BOOKING_HISTORY}
                                                                        exact
                                                                        component={LiveBookingHistoryPage}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.MANAGE_BANNER}
                                                                        exact
                                                                        component={ManageBannerPage}
                                                                    />
                                                                    <PrivateRoute
                                                                        path={RoutePath.NO_STORE}
                                                                        exact
                                                                        component={StoreNotFoundPage}
                                                                    />
                                                                </Switch>
                                                            </div>
                                                        </WithLogin>
                                                    </IsEmptyUserDetail.Provider>
                                                    <ErrorDialog
                                                        openStatus={openValidateError}
                                                        toggleDialog={() => setOpenValidateError(false)}
                                                        confirmHandler={() => setOpenValidateError(false)}
                                                        title={intl.formatMessage({
                                                            id: "editor.liveBooking.1.023",
                                                        })}
                                                        content={
                                                            <div style={{ height: "82px" }}>
                                                                {intl.formatMessage(
                                                                    { id: errMsg },
                                                                    { lineBreak: <br /> }
                                                                )}
                                                            </div>
                                                        }
                                                        confirmBtnText={intl.formatMessage({
                                                            id: "editor.liveBooking.1.039",
                                                        })}
                                                    />
                                                    <ErrorDialog
                                                        title={intl.formatMessage({ id: "error.1.01" })}
                                                        content={
                                                            unknowErrorDialog.content ??
                                                            intl.formatMessage({ id: "general.1.002" })
                                                        }
                                                        confirmBtnText={intl.formatMessage({ id: "general.1.001" })}
                                                        confirmHandler={(e) =>
                                                            setUnknowErrorDialog({
                                                                status: !unknowErrorDialog.status,
                                                            })
                                                        }
                                                        openStatus={unknowErrorDialog.status}
                                                        toggleDialog={() =>
                                                            setUnknowErrorDialog({
                                                                status: !unknowErrorDialog.status,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </Switch>
                                        </StoreInfoContext.Provider>
                                    </ChooseStoreStatusContext.Provider>
                                </RwdContext.Provider>
                            </SidebarHiddenContext.Provider>
                        )}
                    </UserInfoContext.Provider>
                </ThemeProvider>
            </UnknowErrorDialogContext.Provider>
        </ApiServiceContext.Provider>
    );
}

const WithLogin = ({ children }) => {
    const userInfo = useContext(UserInfoContext);
    const [isEmptyUserDetail] = useContext(IsEmptyUserDetail);
    const storeInfo = useContext(StoreInfoContext);
    const location = useLocation();
    if (
        (userInfo?.roles?.includes(ROLE_TYPE.MEDIA) ||
            userInfo?.roles?.includes(ROLE_TYPE.HOST) ||
            userInfo?.roles?.includes(ROLE_TYPE.KOL) ||
            userInfo?.roles?.includes(ROLE_TYPE.PUBLIC)) &&
        !userInfo?.roles?.includes(ROLE_TYPE.SHOP_IN_SHOP) &&
        isEmptyUserDetail &&
        location.pathname !== "/my-account"
    ) {
        return <Redirect to="/my-account" />;
    }

    // else if (
    //     (location.pathname === "/manage-banner" ||
    //         location.pathname === "/edit-app-page" ||
    //         location.pathname === "/store-list-page") &&
    //     (!Array.isArray(storeInfo?.storesDetails) || storeInfo?.storesDetails?.length === 0) &&
    //     userInfo?.roles?.includes(ROLE_TYPE.SHOP_IN_SHOP) &&
    //     userInfo.roles?.length > 1
    // ) {
    //     return <Redirect to="/no-store-found" />;
    // }

    return userInfo.roles?.length ? (
        children
    ) : (
        <Box position="fixed" width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
            <LoadingIcon />
        </Box>
    );
};

export default App;
