import { Button, styled } from "@material-ui/core";

export const RoundButton = styled(Button)({
    height: 48,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 12,
    borderRadius: 42,
    border: "solid 1px #3a993a",
    backgroundColor: "#ffffff",

    // font style
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: 1.14,
    color: "#3a993a",
    textTransform: "none",

    "&:disabled": {
        color: "#3a993a",
        opacity: 0.2,
    },
});

export const DialogButton = styled(Button)({
    height: 48,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 22px",
    borderRadius: 4,
    backgroundColor: "#3a993a",

    // font style
    fontSize: 16,
    lineHeight: 1.5,
    color: "#ffffff",
    textTransform: "none",

    "&:disabled": {
        opacity: 0.2,
        color: "#ffffff",
    },

    "&:hover": {
        color: "#ffffff",
        backgroundColor: "#3a993a",
    },
});

export const ErrorDialogButton = styled(Button)({
    height: 40,
    padding: 10,
    backgroundColor: "#ff5555",
    // font style
    fontSize: 16,
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
        color: "#ffffff",
        backgroundColor: "#ff5555",
    },
});

export const InvertedErrorDialogButton = styled(Button)({
    height: 40,
    padding: 10,
    border: "solid 1px #ff5555",
    backgroundColor: "#ffffff",
    // font style
    fontSize: 16,
    color: "#ff5555",
    textTransform: "none",
    "&:hover": {
        color: "#ff5555",
        backgroundColor: "#ffffff",
    },
});
