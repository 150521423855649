import { IconButton, Typography } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, styled, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/editor/ic/Close_grey.svg";
import { ReactComponent as MobileCloseIcon } from "../../assets/mobile/close.svg";

export const CenteredDialogTitle = styled(MuiDialogTitle)({
    fontSize: "22px",
    textAlign: "center",
    fontWeight: 600,
    margin: "28px 0",
    padding: 0,
});
export default CenteredDialogTitle;

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    rwdStatus?: string;
    children: React.ReactNode;
    onClose?: () => void;
    disabled?: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        mobileCloseButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(4),
        },
        mobileTitleContent: {
            fontSize: "16px",
            color: "#222222",
        },
    });

export const GenericDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, rwdStatus, disabled, ...other } = props;
    return (
        <CenteredDialogTitle disableTypography {...other}>
            <Typography variant="inherit" className={rwdStatus == "PC" ? "" : classes.mobileTitleContent}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={rwdStatus == "PC" ? classes.closeButton : classes.mobileCloseButton}
                    onClick={onClose}
                    disabled={disabled}
                >
                    {rwdStatus == "PC" ? <CloseIcon /> : <MobileCloseIcon />}
                </IconButton>
            ) : null}
        </CenteredDialogTitle>
    );
});

export const GenericDialogContent = styled(MuiDialogContent)({
    fontSize: "20px",
    color: "#222222",
    "& .title": {
        fontSize: "28px",
        fontWeight: 900,
    },
});

export const GenericDialogActions = styled(MuiDialogActions)({
    fontSize: "20px",
});
