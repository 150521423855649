import { Button, createStyles, Dialog, Typography, WithStyles, withStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { RwdContext } from "../../App";
import { ScheduleProps } from "../UpdateArticle";
import { GenericDialogActions, GenericDialogContent, GenericDialogTitle } from "./GenericDialog";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../../assets/animations/btn_loading_animation.json";
import useQRCode from "../../hooks/QRCodeHook";

const styles = () =>
    createStyles({
        previewDialog: {
            minHeight: "90%",
            minWidth: "90%",
            "& .contentTitle": {
                fontWeight: 900,
                color: "#222222",
            },
            "& .timeSelector": {
                minHeight: 126,
                // margin: "19px 0px 7px",
                padding: "10px 20px 0px",
                backgroundColor: "#bde5c3",
                borderTop: "1px solid #0000001f",
            },
            "& .scheduledPublishContentHeader": {
                margin: "0px 20px",
            },
        },
        previewDialogMobile: {
            minHeight: "auto",
            minWidth: "auto",
            "& .contentTitle": {
                fontWeight: 900,
                color: "#222222",
            },
            "& .timeSelector": {
                minHeight: "18%",
                // margin: "19px 0px 7px",
                padding: "10px 20px 0px",
                backgroundColor: "#bde5c3",
                borderTop: "1px solid #0000001f",
            },
            "& .scheduledPublishContentHeader": {
                margin: "0px 20px",
            },
        },
        dialogAction: {
            alignItems: "flex-end",
            justifyContent: "space-between",
            "& .previewQRCode": {
                display: "inline-flex",
                "& img": {
                    width: 95,
                    height: 95,
                },
                "& h6": {
                    margin: "auto 0px 0px 8px",
                },
            },
            "& .mobilePreviewQRCode": {
                display: "inline-block",
                "& img": {
                    width: 65,
                    height: 65,
                },
                "& h6": {
                    fontSize: "14px",
                    margin: "auto 0px 0px 8px",
                },
            },
        },
    });

export interface PreviewDialogProps extends WithStyles<typeof styles> {
    openStatus: boolean;
    rwdStatus?: string;
    toggleDialog: () => void;
    confirmHandler: (e?) => void;
    cancelHandler: (e?) => void;
    title: string;
    content: string;
    confirmBtnText: any;
    cancelBtnText: string;
    children?: JSX.Element;
    schedulePost?: ScheduleProps;
    qrCode?: string;
    disabled?: boolean;
}

export const PreviewDialog = withStyles(styles)((props: PreviewDialogProps) => {
    const intl = useIntl();
    const device = useContext(RwdContext);
    const { classes } = props;

    return (
        <Dialog
            className={device === "PC" ? classes.previewDialog : classes.previewDialogMobile}
            maxWidth={device == "PC" ? "xl" : "sm"}
            scroll={"paper"}
            fullWidth={true}
            aria-labelledby="customized-dialog-title"
            open={props.openStatus}
        >
            <GenericDialogTitle
                id="customized-dialog-title"
                onClose={() => props.toggleDialog()}
                rwdStatus={props.rwdStatus && props.rwdStatus}
                disabled={props.disabled}
            >
                {props.schedulePost
                    ? intl.formatMessage({ id: "newArticle.1.017" })
                    : intl.formatMessage({ id: "newArticle.1.014" })}
            </GenericDialogTitle>
            {props.schedulePost ? (
                <div>
                    <div className="timeSelector" style={device == "PC" ? {} : { paddingBottom: "9px" }}>
                        {props.children}
                    </div>

                    <div className="scheduledPublishContentHeader">
                        <Typography
                            variant="h6"
                            gutterBottom
                            style={device == "PC" ? {} : { fontSize: "16px", textAlign: "left" }}
                        >
                            {intl.formatMessage({ id: "newArticle.1.021" })}
                        </Typography>
                    </div>
                </div>
            ) : null}

            <GenericDialogContent dividers>
                <div className="contentTitle">{props.title}</div>
                <div className="content sun-editor-editable" dangerouslySetInnerHTML={{ __html: props.content }}></div>
            </GenericDialogContent>
            <GenericDialogActions
                classes={{ root: classes.dialogAction }}
                style={device == "PC" ? {} : { justifyContent: "center" }}
            >
                {props.qrCode ? (
                    <div className={device == "PC" ? "previewQRCode" : "mobilePreviewQRCode"}>
                        <img src={props.qrCode as string} alt="" />
                        <Typography variant="subtitle2">
                            使用手機掃描QRcode, 至HKTVmall App預覽文章
                            <br />
                            (請更新HKTVmall App至最新版本)
                        </Typography>
                    </div>
                ) : null}
                {device == "PC" ? (
                    <Button
                        variant="outlined"
                        onClick={() => props.cancelHandler()}
                        color="primary"
                        style={{
                            marginLeft: "auto",
                        }}
                        disabled={props.disabled}
                    >
                        {props.cancelBtnText}
                    </Button>
                ) : null}
                <Button
                    variant="contained"
                    onClick={props.confirmHandler}
                    color="primary"
                    disabled={props.schedulePost?.isSubmitting}
                    style={device == "PC" ? {} : { fontSize: "14px" }}
                >
                    {props.schedulePost?.isSubmitting ? (
                        <>
                            <span style={{ visibility: "hidden" }}>{props.confirmBtnText}</span>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: BtnLoadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width={40}
                                height={29}
                                style={{ position: "absolute" }}
                            />
                        </>
                    ) : (
                        props.confirmBtnText
                    )}
                </Button>
            </GenericDialogActions>
        </Dialog>
    );
});

export default PreviewDialog;
