import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState, useContext } from "react";
import { useIntl } from "react-intl";
import { IsEmptyUserDetail, RwdContext } from "../App";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
import UserDetails from "../components/UserDetails";
import { UserInfoContext } from "../App";
import { ROLE_TYPE, RoutePathRule } from "../interfaces/enums";
import { useHistory, useLocation } from "react-router-dom";
import { RouteItems } from "../components/PrivateRoute";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContent: {
            marginTop: 18,
        },
        mobilePageContent: {
            backgroundColor: "#eeeeee",
            width: "100%",
        },
    })
);

function MyAccountPage(props) {
    const classes = useStyles();
    const intl = useIntl();
    const [isEmptyUserDetail] = useContext(IsEmptyUserDetail);
    const device = useContext(RwdContext);
    return (
        <React.Fragment>
            <div
                className={device == "PC" ? "myAccountPageWrapper pageWrapper" : ""}
                style={device == "PC" ? {} : { width: "100%", height: "100vh", backgroundColor: "#eeeeee" }}
            >
                <div
                    className="title"
                    style={
                        device == "PC"
                            ? { fontSize: "36px" }
                            : {
                                  fontSize: 22,
                                  fontWeight: "bold",
                                  marginLeft: "10px",
                                  marginTop: "8px",
                                  marginBottom: "5px",
                              }
                    }
                >
                    {intl.formatMessage({ id: "editor.myAccount.1.003" })}
                </div>
                <div className={`pageContent ${device == "PC" ? classes.pageContent : classes.mobilePageContent}`}>
                    <UserDetails></UserDetails>
                </div>
            </div>

            <BlockingAlert isBlocking={isEmptyUserDetail} />
        </React.Fragment>
    );
}
function useCallbackPrompt(when): [boolean, () => void, () => void, Location | null] {
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const location = useLocation();
    const [isEmptyUserDetail] = useContext(IsEmptyUserDetail);

    const cancelNavigation = useCallback(() => {
        setShowPrompt(false);
    }, []);

    const handleBlockedNavigation = useCallback(
        (nextLocation) => {
            const nextRouteItem = RouteItems.find((route) => nextLocation.pathname === route.to);
            // No Blocking when next path not requred to fill my account profile
            if (
                isEmptyUserDetail &&
                location.pathname === "/my-account" &&
                !nextRouteItem?.rules?.includes(RoutePathRule.MY_ACCOUNT_PROFILE_REQUIRED)
            )
                return true;
            if (!confirmedNavigation) {
                setShowPrompt(true);
                setLastLocation(nextLocation);
                return false;
            }
            return true;
        },
        [confirmedNavigation]
    );

    const confirmNavigation = useCallback(() => {
        setShowPrompt(false);
        setConfirmedNavigation(true);
    }, []);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            history.push(lastLocation.pathname, lastLocation);
        }
    }, [confirmedNavigation, lastLocation]);

    usePrompt(handleBlockedNavigation, when);

    return [showPrompt, confirmNavigation, cancelNavigation, lastLocation];
}

function usePrompt(message, when) {
    const history = useHistory();
    const unblock = useRef<Function | null>(null);

    useEffect(() => {
        if (when) {
            unblock.current = history.block(message);
        } else {
            unblock.current = null;
        }
        return () => {
            if (unblock.current) {
                unblock.current();
            }
        };
    }, [when, history, message]);
}

const BlockingAlert = ({ isBlocking }) => {
    const [showPrompt, confirmNavigation, cancelNavigation, lastLocation] = useCallbackPrompt(isBlocking);
    const { formatMessage } = useIntl();
    const { roles } = useContext(UserInfoContext);

    const nextRouteItem = RouteItems.find((route) => lastLocation?.pathname === route.to);

    return (
        <ErrorDialog
            title={formatMessage({ id: "editor.allPosts.4.038" })}
            content={
                nextRouteItem?.activateRoles?.includes(ROLE_TYPE.HOST)
                    ? formatMessage({ id: "editor.myAccount.NA.002" })
                    : formatMessage({ id: "editor.myAccount.NA.001" })
            }
            confirmBtnText={formatMessage({ id: "editor.allPosts.4.011" })}
            confirmHandler={cancelNavigation as () => void}
            openStatus={showPrompt as boolean}
            toggleDialog={cancelNavigation as () => void}
        />
    );
};

export default MyAccountPage;
