import React, { useState, useEffect } from "react";
function useRWD() {
    const [device, setDevice] = useState<string>();
    const handleMobileRwd = () => {
        if (window.innerWidth > 1280) {
            if (device !== "PC") {
                setDevice("PC");
            }
        } else {
            if (device !== "mobile") {
                setDevice("mobile");
            }
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleMobileRwd);
        handleMobileRwd();
        return () => {
            window.removeEventListener("resize", handleMobileRwd);
        };
    }, []);
    return { device };
}
export default useRWD;
