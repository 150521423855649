import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
    Box,
    DialogActions,
    Dialog,
    InputBase,
    makeStyles,
    Theme,
    createStyles,
    Grid,
    DialogTitle,
    styled,
} from "@material-ui/core";
import SunEditor, { SunEditorReactProps } from "suneditor-react";
import CodeMirror from "codemirror";
import { ApiServiceContext } from "../App";
import { DialogButton } from "./buttons/StyledButtons";
import { ReactComponent as CloseIcon } from "../assets/store/ic_close.svg";
import { UtilFunctions } from "../utils/UtilFunctions";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";
const editorLangObj = {
    toolbar: {
        default: "Default",

        save: "Save",

        font: "Font",

        formats: "Formats",

        fontSize: "Size",

        bold: "Bold",

        underline: "Underline",

        italic: "Italic",

        strike: "Strike",

        subscript: "Subscript",

        superscript: "Superscript",

        removeFormat: "Remove Format",

        fontColor: "Font Color",

        hiliteColor: "Hilite Color",

        indent: "Indent",

        outdent: "Outdent",

        align: "Align",

        alignLeft: "Align left",

        alignRight: "Align right",

        alignCenter: "Align center",

        alignJustify: "Align justify",

        list: "list",

        orderList: "Ordered list",

        unorderList: "Unordered list",

        horizontalRule: "horizontal line",

        hr_solid: "solid",

        hr_dotted: "dotted",

        hr_dashed: "dashed",

        table: "Table",

        link: "Link",

        image: "Image",

        video: "Video",

        fullScreen: "Full screen",

        showBlocks: "Show blocks",

        codeView: "Code view",

        undo: "Undo",

        redo: "Redo",

        preview: "Preview",

        print: "print",

        tag_p: "Paragraph",

        tag_div: "Normal (DIV)",

        tag_h: "Header",

        tag_blockquote: "Quote",

        tag_pre: "Code",

        template: "Template",
    },

    dialogBox: {
        linkBox: {
            title: "Insert Link",

            url: "URL to link",

            text: "Text to display",

            newWindowCheck: "Open in new window",
        },

        imageBox: {
            title: "Insert image",

            file: "Select from files",

            url: "Image URL",

            altText: "Alternative text",
        },

        videoBox: {
            title: "Insert Video",

            url: "YouTube Url",
        },

        caption: "Insert description",

        close: "Close",

        submitButton: "Submit",

        revertButton: "Revert",

        proportion: "constrain proportions",

        width: "Width",

        height: "Height",

        basic: "Basic",

        left: "Left",

        right: "Right",

        center: "Center",

        ratio: "Ratio",
    },

    controller: {
        edit: "Edit",

        remove: "Remove",

        insertRowAbove: "Insert row above",

        insertRowBelow: "Insert row below",

        deleteRow: "Delete row",

        insertColumnBefore: "Insert column before",

        insertColumnAfter: "Insert column after",

        deleteColumn: "Delete column",

        resize100: "Resize 100%",

        resize75: "Resize 75%",

        resize50: "Resize 50%",

        resize25: "Resize 25%",

        mirrorHorizontal: "Mirror, Horizontal",

        mirrorVertical: "Mirror, Vertical",

        rotateLeft: "Rotate left",

        rotateRight: "Rotate right",

        maxSize: "Max size",

        minSize: "Min size",

        tableHeader: "Table header",

        mergeCells: "Merge cells",

        splitCells: "Split Cells",

        HorizontalSplit: "Horizontal split",

        VerticalSplit: "Vertical split",
    },
};

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        titleBox: {
            display: "flex",
            justifyContent: "space-between",
            position: "sticky",
            top: "0px",
            zIndex: 3,
            alignItems: "center",
            height: "68px",
            backgroundColor: "#ffffff",
            borderBottom: "2px solid #f1f1f1",
            marginBottom: "16px",
        },
        htmlText: {
            marginLeft: "16px",
            fontSize: "16px",
            color: "rgba(34,34,34,0.6)",
        },
        closeStyle: {
            marginRight: "16px",
            cursor: "pointer",
        },
        createhtmlnameText: {
            fontSize: "16px",
            lineHeight: 1.5,
        },
        inputBase: {
            width: "40%",
            height: "45px",
            border: "2px solid #f1f1f1",
            padding: 16,
            flexGrow: 1,
        },
        inputBox: {
            height: "120px",
            margin: "16px",
            backgroundColor: "#ffffff",
        },
        innerInputBox: {
            margin: "16px",
            paddingTop: 16,
        },
        editor: {
            margin: "8px",
            zIndex: 1,
        },
    })
);
type HtmlEditorProps = {
    title: string;
    content: string;
    open: boolean;
    onClose: () => void;
    submitHtml: (o: string, a: string) => void;
    changeHtmlName: (val: string) => void;
    changeHtmlContent: (val: string) => void;
    uploadImageUrl: string;
    imageUploadToken: string;
    onImageUploadError: (errorMessage: any, result: any) => void;
    onImageUploadBefore: SunEditorReactProps["onImageUploadBefore"];
};
export const HtmlEditor = (props: HtmlEditorProps) => {
    const {
        title,
        content,
        open,
        onClose,
        submitHtml,
        changeHtmlName,
        changeHtmlContent,
        imageUploadToken,
        uploadImageUrl,
        onImageUploadError,
        onImageUploadBefore,
    } = props;
    const [submitClick, setSubmitClick] = useState(false);
    const [imageIds, setImageIds] = useState<string[]>([]);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");
    const classes = useStyles();
    const intl = useIntl();
    const apiService = useContext(ApiServiceContext);
    const setModuleName = (name: string) => {
        if (name.length <= 50) changeHtmlName(name);
    };
    const setModuleContent = (content: string) => {
        changeHtmlContent(content);
    };
    const handleRichTextEditorImageUpload = () => {
        let doc = new DOMParser().parseFromString(content, "text/html");
        let imgTags = doc.querySelectorAll("img");
        let tmpData = content;
        let newImageIds: string[] = [];
        if (imgTags && imgTags.length > 0) {
            let formData = new FormData();
            imgTags.forEach((img, index) => {
                if (img.src.startsWith("data:image")) {
                    // base64 images
                    formData.append("imageFiles", UtilFunctions.dataURLtoFile(img.src, index));
                } else {
                    let newImageId = img.src.split("/").pop()?.split(".")[0];
                    if (newImageId && !imageIds.includes(newImageId)) {
                        newImageIds.push(newImageId);
                    }
                }
            });

            // Only call api when have base64 image source in content, which means has imageFiles in formData
            if (formData.has("imageFiles"))
                apiService
                    .postRichTextEditorImages(formData)
                    .then((res) => {
                        if (
                            UtilFunctions.validateApiResponse(res.data) &&
                            UtilFunctions.validateApiResponseSuccess(res) &&
                            res.data
                        ) {
                            res.data.result.forEach((image, index) => {
                                tmpData.replace(imgTags[index].src, `https:${image.url}`);
                                newImageIds.push(image.name.split(".")[0]);
                            });
                        } else throw new Error("Error Unknown");
                    })
                    .catch((err) => {
                        setErrMsg("general.1.002");
                        setOpenValidateError(true);
                    });
            if (newImageIds.length > 0) setImageIds([...imageIds].concat(newImageIds));
        }
    };
    useEffect(() => {
        handleRichTextEditorImageUpload();
    }, [content]);
    const toggleToWYSIWYG = () => {
        let codeViewBtn: HTMLElement = document.getElementsByClassName(
            "se-btn se-code-view-enabled se-resizing-enabled _se_command_codeView se-tooltip"
        )[0] as HTMLElement;
        if (codeViewBtn) {
            codeViewBtn.click();
        }
    };
    useEffect(() => {
        let imageStatusData = {
            imageIds: imageIds,
            status: "commit",
        };
        if (submitClick === true) {
            if (imageIds.length) {
                apiService
                    .postUpdateImageStatus(imageStatusData)
                    .then((res) => {
                        if (UtilFunctions.validateApiResponse(res.data)) submitHtml(title, content);
                        else throw new Error("Error Unknown");
                    })
                    .catch((err) => {
                        setErrMsg("general.1.002");
                        setOpenValidateError(true);
                    });
            } else {
                submitHtml(title, content);
            }
            onClose && onClose();
            setSubmitClick(!submitClick);
        }
    }, [content, submitClick]);

    return (
        <>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <Dialog
                key={props.imageUploadToken}
                open={open}
                onClose={() => onClose && onClose()}
                fullWidth={true}
                maxWidth="lg"
                transitionDuration={0}
            >
                <Grid style={{ backgroundColor: "#f1f1f1", position: "relative" }}>
                    <Grid className={classes.titleBox}>
                        <Box className={classes.htmlText}>
                            {intl.formatMessage({ id: "text_6048985b3857e101208263fa" })}
                        </Box>
                        <CloseIcon className={classes.closeStyle} onClick={() => onClose && onClose()} />
                    </Grid>
                    <Grid className={classes.inputBox}>
                        <Grid className={classes.innerInputBox}>
                            <Box className={classes.createhtmlnameText}>
                                {intl.formatMessage({ id: "create_html_name" })}
                            </Box>
                            <InputBase
                                className={classes.inputBase}
                                value={title}
                                onChange={(e) => setModuleName(e.target.value)}
                                error={title.length > 50}
                            />
                        </Grid>
                    </Grid>
                    <Box className={classes.editor}>
                        <SunEditor
                            lang="en"
                            setContents={content}
                            onChange={(content) => {
                                setModuleContent(content);
                            }}
                            height={"430px"}
                            onImageUploadError={onImageUploadError}
                            onImageUploadBefore={onImageUploadBefore}
                            setOptions={{
                                codeMirror: CodeMirror,
                                buttonList: [
                                    ["undo", "redo"],
                                    ["font", "fontSize", "formatBlock"],
                                    ["blockquote"],
                                    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                    ["fontColor", "hiliteColor" /*, "textStyle" */],
                                    ["removeFormat"],
                                    "/", // Line break
                                    // ["outdent", "indent"],
                                    ["align", "horizontalRule", "list", "lineHeight"],
                                    ["table", "link", "image", "video" /* 'audio' ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin. // You must add the "imageGalleryUrl".
                                    /** ['imageGallery'] */ ["showBlocks", "codeView"],
                                ],
                                lang: editorLangObj,
                                stickyToolbar: -1,
                                imageUrlInput: false,
                                imageUploadHeader: { authorization: imageUploadToken },
                                imageUploadUrl: uploadImageUrl ? uploadImageUrl : undefined,
                                videoSizeOnlyPercentage: true,
                                videoRotation: false,
                                youtubeQuery: "fs=0",
                            }}
                            disable={content.length >= 3 * 1024 * 1024}
                        />
                    </Box>
                </Grid>
                <DialogActions style={{ position: "sticky", bottom: "0px", zIndex: 3, backgroundColor: "#fafafa" }}>
                    <DialogButton
                        onClick={() => {
                            toggleToWYSIWYG();
                            setSubmitClick(!submitClick);
                        }}
                        disabled={!(title !== "" && content !== "" && content !== "<p><br></p>")}
                    >
                        {intl.formatMessage({ id: "text_605c550654115400ca1c9a5f" })}
                    </DialogButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
