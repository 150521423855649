import React, { useContext } from "react";
import { createStyles, makeStyles, Theme, OutlinedInput } from "@material-ui/core";
import Hint from "../buttons/Hint";
import { RwdContext } from "../../App";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        field: {
            margin: "32px 16px",
            width: "calc(100% - 32px)",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
        },
        mobileField: {
            width: "100%",
            margin: 0,
        },
        fieldTitle: {
            marginRight: 30,
            width: "92px",
            lineHeight: "40px",
            fontSize: "20px",
            fontWeight: "bold",
            "@media (max-width : 1280px)": {
                width: "100%",
                fontSize: 14,
                lineHeight: "10px",
            },
        },
        input: {
            width: "calc(100% - 72px)",
            "@media (max-width : 1280px)": {
                marginTop: "8px",
                width: "100%",
            },
        },
    })
);

export default function TextBoxWithLabel(props: {
    label: string;
    placeholder?: string;
    hint?: string;
    value: string;
    readOnly?: boolean;
    onChange?: (val: string) => void;
}) {
    const classes = useStyles();
    const device = useContext(RwdContext);
    return (
        <div className={device == "PC" ? classes.field : classes.mobileField}>
            <span className={classes.fieldTitle}>{props.label}</span>
            {typeof props.hint !== "undefined" && props.hint && (
                <Hint style={{ left: "66px", top: "8px" }} text={props.hint} />
            )}
            <OutlinedInput
                multiline
                rows={3}
                value={props.value}
                className={classes.input}
                placeholder={props.placeholder ? props.placeholder : ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    props.onChange ? props.onChange(e.target.value) : () => {}
                }
                readOnly={props.readOnly}
            />
        </div>
    );
}
