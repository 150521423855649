import { Box, Button, createStyles, makeStyles, Theme, useTheme, withWidth } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useImmer } from "use-immer";
import { ApiServiceContext, IsEmptyUserDetail, UnknowErrorDialogContext, RwdContext } from "../App";
import { ReactComponent as NewCoverImageBtn } from "../assets/editor/img_coverPhoto.svg";
import { ReactComponent as NewProfileImageBtn } from "../assets/editor/img_Profile.svg";
import { Language } from "../const";
import { MODEL_UserDetails } from "../interfaces";
import { UtilFunctions } from "../utils/UtilFunctions";
import GeneralContainer from "./boxContainers/GeneralContainer";
import { ImageUploadContainer } from "./boxContainers/ImageUploadContainer";
import StatusPopup from "./dialogs/StatusPopup";
import LabelTextField from "./textfield/LabelTextField";
import Lottie from "react-lottie";
import BtnLoadingAnimation from "../assets/animations/btn_loading_animation.json";
import { ROLE_TYPE } from "../interfaces/enums";
import { ErrorDialog } from "../components/dialogs/ErrorDialog";

enum ActionType {
    UPDATE_NAME = "updateName",
    UPDATE_INTRO = "updateIntro",
    UPDATE_PROFILE_IMAGE = "updateProfileImage",
    UPDATE_BANNER = "updateBanner",
}

interface props {
    children: React.ReactNode;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContent: {
            marginTop: 18,
        },
        mt: {
            marginTop: 12,
        },
    })
);

const initState: MODEL_UserDetails = {
    profilePicture: "",
    banner: "",
    zh: {
        intro: "",
        name: "",
    },
    en: {
        intro: "",
        name: "",
    },
    userId: "",
    userName: "",
    roles: [],
};

export default function UserDetails(props: Partial<props>) {
    const intl = useIntl();
    const theme = useTheme();
    const classes = useStyles();
    const device = useContext(RwdContext);
    const apiService = useContext(ApiServiceContext);
    const [isEmpty, setIsEmpty] = useContext(IsEmptyUserDetail);

    const [userDetail, updateUserDetail] = useImmer<MODEL_UserDetails>(initState);
    const [okayToSave, setOkayToSave] = useState(true);
    const [apiResponse, setApiResponse] = useState<any>();
    const [openStatus, setOpenStatus] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [imageIds, setImageIds] = useState<string[]>([]);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");

    const needCoverPhoto: Boolean = userDetail.roles.some((o) =>
        [ROLE_TYPE.KOL, ROLE_TYPE.MEDIA, ROLE_TYPE.PUBLIC].includes(o)
    );

    const fetchUserDetails = () => {
        apiService.account
            .getUserDetails()
            .then((res) => {
                if (UtilFunctions.validateApiResponse(res.data)) {
                    const { data } = res.data;
                    // Use initState instead of fetched data
                    if (data?.zh?.name === null) return;
                    updateUserDetail(() => data);
                } else {
                    throw new Error("Error Unknown");
                }
            })
            .catch((err) => {
                setErrMsg("general.1.002");
                setOpenValidateError(true);
            });
    };
    const fieldUpdateHandler = (updateLang: Language, action: ActionType, newValue) => {
        let lang = updateLang === Language.Chinese ? "zh" : "en";

        updateUserDetail((draft) => {
            switch (action) {
                case ActionType.UPDATE_NAME:
                    draft[lang].name = newValue;
                    break;
                case ActionType.UPDATE_INTRO:
                    draft[lang].intro = newValue;
                    break;
                case ActionType.UPDATE_PROFILE_IMAGE:
                    draft.profilePicture = newValue;
                    break;
                case ActionType.UPDATE_BANNER:
                    draft.banner = newValue;
                    break;
            }
        });
    };

    const uploadProfilePicHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        // TO-DO: update upload image method with api
        if (e.currentTarget?.files && e.currentTarget.files[0]) {
            UtilFunctions.compressImage(e.currentTarget.files[0], {
                maxWidth: 450,
                maxHeight: 450,
                quality: 0.8,
            }).then((compressedImg) => {
                var file = new File([compressedImg], "compressed-image.jpg");
                let imageData = new FormData();
                imageData.append("imageFiles", file);
                apiService
                    .postImages(imageData)
                    .then((res) => {
                        if (UtilFunctions.validateApiResponse(res.data)) {
                            setImageIds([...imageIds].concat([res.data.data[0]?.id]));
                            let resizedUrl = UtilFunctions.getResizedImageUrl(180, 180, res.data.data[0]?.url, true);
                            if (resizedUrl)
                                fieldUpdateHandler(Language.Chinese, ActionType.UPDATE_PROFILE_IMAGE, resizedUrl);
                        } else throw new Error("Error Unknown");
                    })
                    .catch((err) => {
                        console.warn(err);
                        setErrMsg("general.1.002");
                        setOpenValidateError(true);
                    });
            });
        }
    };

    const uploadBannerHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        // TO-DO: update upload image method with api
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            UtilFunctions.compressImage(e.currentTarget.files[0], {
                maxWidth: 1208,
                maxHeight: 320,
                quality: 0.8,
            }).then((compressedImg) => {
                var file = new File([compressedImg], "compressed-image.jpg");
                let imageData = new FormData();
                imageData.append("imageFiles", file);
                apiService
                    .postImages(imageData)
                    .then((res) => {
                        if (UtilFunctions.validateApiResponse(res.data)) {
                            setImageIds([...imageIds].concat([res.data.data[0]?.id]));
                            let resizedUrl = UtilFunctions.getResizedImageUrl(1208, 320, res.data.data[0]?.url, true);
                            if (resizedUrl) fieldUpdateHandler(Language.Chinese, ActionType.UPDATE_BANNER, resizedUrl);
                        } else throw new Error("Error Unknown");
                    })
                    .catch((err) => {
                        console.warn(err);
                        setErrMsg("general.1.002");
                        setOpenValidateError(true);
                    });
            });
        }
    };

    const [unknowErrorDialog, setUnknowErrorDialog] = useContext(UnknowErrorDialogContext);

    const setInvalidImageFormatPopup = () => {
        setUnknowErrorDialog({
            status: !unknowErrorDialog.status,
            content: intl.formatMessage({ id: "newArticle.1.024" }),
        });
    };

    const updateHandler = () => {
        setSubmitting(true);
        let imageStatusData = {
            imageIds: imageIds,
            status: "commit",
        };
        if (imageIds.length) {
            apiService
                .postUpdateImageStatus(imageStatusData)
                .then((res) => {
                    if (UtilFunctions.validateApiResponse(res.data)) {
                        apiService.account
                            .postUserDetails(userDetail)
                            .then((res) => {
                                if (UtilFunctions.validateApiResponse(res.data)) {
                                    setApiResponse(intl.formatMessage({ id: "editor.myAccount.1.015" }));
                                    setOpenStatus(true);
                                    setImageIds([]);
                                    // Remove Navigation Blocking
                                    if (isEmpty && res?.data?.status === "success") {
                                        setIsEmpty(false);
                                    }
                                    // if (containerRef.current) window.scrollTo(0, containerRef.current.offsetTop);
                                } else {
                                    setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                                    throw new Error("Error Unknown");
                                }
                            })
                            .catch((err) => {
                                console.warn(err);
                                setErrMsg("general.1.002");
                                setOpenValidateError(true);
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                        throw new Error("Error Unknown");
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                });
        } else {
            apiService.account
                .postUserDetails(userDetail)
                .then((res) => {
                    if (UtilFunctions.validateApiResponse(res.data)) {
                        setApiResponse(intl.formatMessage({ id: "editor.myAccount.1.015" }));
                        setOpenStatus(true);
                        // Remove Navigation Blocking
                        if (isEmpty && res?.data?.status === "success") {
                            setIsEmpty(false);
                        }
                        // if (containerRef.current) window.scrollTo(0, containerRef.current.offsetTop);
                    } else {
                        setUnknowErrorDialog({ status: !unknowErrorDialog.status });
                        throw new Error("Error Unknown");
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    setErrMsg("general.1.002");
                    setOpenValidateError(true);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const fieldOkay = (input: MODEL_UserDetails): boolean => {
        if (
            input.zh.name?.trim().length === 0 ||
            input.zh.intro?.trim().length === 0 ||
            input.en.name?.trim().length === 0 ||
            input.en.intro?.trim().length === 0 ||
            input.profilePicture?.trim().length === 0 ||
            (needCoverPhoto ? input.banner?.trim().length === 0 : false)
        )
            return false;

        if (
            input.zh.name?.length > 10 ||
            input.zh.intro?.length > 100 ||
            input.en.name?.length > 20 ||
            input.en.intro?.length > 200
        )
            return false;

        return true;
    };

    // Block Save Btn
    useEffect(() => {
        if (!fieldOkay(userDetail)) {
            setOkayToSave(false);
            return;
        }
        setOkayToSave(true);
    }, [userDetail]);
    return (
        <React.Fragment>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => setOpenValidateError(false)}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <div
                style={
                    device == "PC"
                        ? {}
                        : {
                              width: "auto",
                              height: "auto",
                              margin: "5px 10px 8px",
                              backgroundColor: "#ffffff",
                              borderRadius: "6px",
                          }
                }
            >
                <UserInfo userDetail={userDetail} fieldUpdateHandler={fieldUpdateHandler}>
                    <ImageUploadContainer
                        containerTitle={intl.formatMessage({ id: "editor.myAccount.1.007" })}
                        imageUrl={userDetail.profilePicture}
                        imageOnChange={uploadProfilePicHandler}
                        id={"upload-user-detail-profilePic"}
                        imageOnDelete={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            fieldUpdateHandler(Language.Chinese, ActionType.UPDATE_PROFILE_IMAGE, "");
                            setImageIds(
                                imageIds.filter((i) => i != UtilFunctions.getImageIdFromUrl(userDetail.profilePicture))
                            );
                            // Clears the real input value to enable re-upload for the same image
                            let inputDOM = document.getElementById("upload-user-detail-profilePic") as HTMLInputElement;
                            inputDOM.value = "";
                        }}
                        style={
                            device == "PC"
                                ? { padding: 0, marginTop: 12, marginBottom: 14 }
                                : { padding: 0, marginTop: 10, marginBottom: 14, width: "auto", height: "auto" }
                        }
                        imageInvalid={setInvalidImageFormatPopup}
                    >
                        <NewProfileImageBtn style={device == "PC" ? {} : { width: "auto", height: "auto" }} />
                    </ImageUploadContainer>
                </UserInfo>
            </div>
            {/* Cover Photo */}
            {needCoverPhoto && (
                <ImageUploadContainer
                    containerTitle={intl.formatMessage({ id: "editor.myAccount.1.012" })}
                    containerRemark={intl.formatMessage({ id: "editor.myAccount.1.013" })}
                    imageUrl={userDetail.banner}
                    imageOnChange={uploadBannerHandler}
                    id={"upload-user-detail-banner"}
                    imageOnDelete={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        fieldUpdateHandler(Language.Chinese, ActionType.UPDATE_BANNER, "");
                        setImageIds(imageIds.filter((i) => i != UtilFunctions.getImageIdFromUrl(userDetail.banner)));
                        // Clears the real input value to enable re-upload for the same image
                        let inputDOM = document.getElementById("upload-user-detail-banner") as HTMLInputElement;
                        inputDOM.value = "";
                    }}
                    style={
                        device == "PC"
                            ? { marginTop: 12 }
                            : {
                                  width: "auto",
                                  height: "auto",
                                  margin: "5px 10px 8px",
                                  backgroundColor: "#ffffff",
                                  borderRadius: "6px",
                              }
                    }
                    imageInvalid={setInvalidImageFormatPopup}
                >
                    <NewCoverImageBtn style={device == "PC" ? {} : { width: "auto", height: "auto" }} />
                </ImageUploadContainer>
            )}
            {/* Footer - Button Groups */}
            <Box marginTop={device == "PC" ? 2 : ""} style={{ width: "auto", margin: "5px 10px 8px" }}>
                <Button
                    disabled={!okayToSave || isSubmitting}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={updateHandler}
                    style={device == "PC" ? {} : { width: "100%", height: "14%", border: "solid 1px #d8d8d8" }}
                >
                    {!isSubmitting ? (
                        intl.formatMessage({ id: "editor.myAccount.1.014" })
                    ) : (
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: BtnLoadingAnimation,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                },
                            }}
                            width={40}
                            height={29}
                        />
                    )}
                </Button>
            </Box>
            <StatusPopup openStatus={openStatus} content={apiResponse} toggleDialog={setOpenStatus} />
        </React.Fragment>
    );
}

interface UserInfoProps {
    userDetail: MODEL_UserDetails;
    fieldUpdateHandler: (updateLang: Language, action: ActionType, newValue) => void;
    children?: React.ReactNode;
}

export type ValidationError = {
    error: boolean;
    helperText: string;
};

const UserInfo = (props: UserInfoProps) => {
    const intl = useIntl();
    const classes = useStyles();
    const device = useContext(RwdContext);
    const mobileStyle = {
        width: "auto",
        height: "8%",
        margin: "8px 0 8px 1px",
        borderRadius: "6px",
        border: "solid 1px #d8d8d8",
        backgroundColor: "#ffffff",
        "@media (max-width : 1280px)": {
            margin: "3px 0 3px 1px",
        },
    };

    const rules = (lang: Language, action: ActionType): ValidationError | null => {
        const { userDetail } = props;
        let exceededFlag = false;
        switch (lang) {
            case Language.Chinese:
                switch (action) {
                    case ActionType.UPDATE_NAME:
                        if (userDetail.zh.name?.length > 10) exceededFlag = true;
                        break;
                    case ActionType.UPDATE_INTRO:
                        if (userDetail.zh.intro?.length > 100) exceededFlag = true;
                }
                break;
            case Language.English:
                switch (action) {
                    case ActionType.UPDATE_NAME:
                        if (userDetail.en.name?.length > 20) exceededFlag = true;

                        break;

                    case ActionType.UPDATE_INTRO:
                        if (userDetail.en.intro?.length > 200) exceededFlag = true;
                }
        }

        return exceededFlag
            ? {
                  error: true,
                  helperText: "字數超出限額",
              }
            : null;
    };

    const CHINESE_UPDATE_NAME = React.useMemo(
        () => (
            <LabelTextField
                style={device == "PC" ? {} : mobileStyle}
                label={intl.formatMessage({
                    id: "editor.myAccount.1.008",
                })}
                variant="filled"
                value={props.userDetail.zh?.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    props.fieldUpdateHandler(Language.Chinese, ActionType.UPDATE_NAME, e.target.value)
                }
                onError={(e) => {}}
                {...rules(Language.Chinese, ActionType.UPDATE_NAME)}
            />
        ),
        [props.userDetail.zh.name]
    );
    const ENGLISH_UPDATE_NAME = React.useMemo(
        () => (
            <LabelTextField
                style={device == "PC" ? {} : mobileStyle}
                label={intl.formatMessage({
                    id: "editor.myAccount.1.009",
                })}
                className={classes.mt}
                variant="filled"
                value={props.userDetail.en.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    props.fieldUpdateHandler(Language.English, ActionType.UPDATE_NAME, e.target.value)
                }
                {...rules(Language.English, ActionType.UPDATE_NAME)}
            />
        ),
        [props.userDetail.en.name]
    );
    const CHINESE_UPDATE_INTRO = React.useMemo(
        () => (
            <LabelTextField
                style={device == "PC" ? {} : mobileStyle}
                label={intl.formatMessage({
                    id: "editor.myAccount.1.01",
                })}
                className={classes.mt}
                variant="filled"
                multiline
                rows="5"
                value={props.userDetail.zh.intro}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    props.fieldUpdateHandler(Language.Chinese, ActionType.UPDATE_INTRO, e.target.value)
                }
                {...rules(Language.Chinese, ActionType.UPDATE_INTRO)}
            />
        ),
        [props.userDetail.zh.intro]
    );
    const ENGLISH_UPDATE_INTRO = React.useMemo(
        () => (
            <LabelTextField
                style={device == "PC" ? {} : mobileStyle}
                label={intl.formatMessage({
                    id: "editor.myAccount.1.011",
                })}
                className={classes.mt}
                variant="filled"
                multiline
                rows="5"
                value={props.userDetail.en.intro}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    props.fieldUpdateHandler(Language.English, ActionType.UPDATE_INTRO, e.target.value)
                }
                {...rules(Language.English, ActionType.UPDATE_INTRO)}
            />
        ),
        [props.userDetail.en.intro]
    );

    return (
        <GeneralContainer display="flex" flexDirection="column">
            {/* Profile Image Upload Container */}
            {props.children}
            {/* Name */}
            {CHINESE_UPDATE_NAME}
            {ENGLISH_UPDATE_NAME}

            {/* Description */}
            {CHINESE_UPDATE_INTRO}
            {ENGLISH_UPDATE_INTRO}
        </GeneralContainer>
    );
};
