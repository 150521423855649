import React, { useContext } from "react";
import { createStyles, makeStyles, Theme, OutlinedInput } from "@material-ui/core";
import Hint from "../buttons/Hint";
import { RwdContext } from "../../App";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        field: {
            margin: "32px 16px",
            width: "calc(100% - 32px)",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
        },
        mobilefield: {
            margin: "5px 0 0",
        },
        typeTwoUI_field: {
            marginBottom: "32px",
            position: "relative",
        },
        fieldTitle: {
            marginRight: 30,
            width: "92px",
            lineHeight: "40px",
            fontSize: "20px",
            fontWeight: "bold",
        },
        mobilefieldTitle: {
            display: "flex",
            flexFlow: "row",
            flexWrap: "wrap",
            fontSize: "14px",
            fontWeight: "bold",
            textAlign: "left",
            color: "#222222",
        },
        typeTwoUI_fieldTitle: {
            marginRight: 12,
            width: "93px",
            lineHeight: "40px",
            fontWeight: "bold",
            fontSize: 18,
        },
        input: {
            margin: "auto",
            height: "40px",
            width: "calc(100% - 72px)",
        },
        mobileInput: {
            width: "100%",
            height: "7%",
            "@media (max-width : 1280px)": {
                marginTop: "5px",
                marginBottom: "8px",
            },
        },
        typeTwoUI_input: {
            height: "40px",
            width: "80px",
            marginRight: 12,
        },
    })
);

export default function TextInputWithLabel(props: {
    label?: string;
    placeholder?: string;
    hint?: string | React.ReactNode;
    value: string;
    readOnly?: boolean;
    typeTwoUI?: boolean;
    onChange?: (val: string) => void;
}) {
    const classes = useStyles();
    const device = useContext(RwdContext);
    return (
        <div
            className={
                props.typeTwoUI ? classes.typeTwoUI_field : device === "mobile" ? classes.mobilefield : classes.field
            }
        >
            <span
                className={
                    props.typeTwoUI
                        ? classes.typeTwoUI_fieldTitle
                        : device === "mobile"
                        ? classes.mobilefieldTitle
                        : classes.fieldTitle
                }
            >
                {props.label ? props.label : ""}
                {typeof props.hint !== "undefined" && props.hint && device === "mobile" && <Hint text={props.hint} />}
            </span>
            {typeof props.hint !== "undefined" && props.hint && device === "PC" && <Hint text={props.hint} />}
            <OutlinedInput
                value={props.value}
                className={
                    props.typeTwoUI
                        ? classes.typeTwoUI_input
                        : device === "mobile"
                        ? classes.mobileInput
                        : classes.input
                }
                placeholder={props.placeholder ? props.placeholder : ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    props.onChange ? props.onChange(e.target.value) : () => {}
                }
                readOnly={props.readOnly}
            />
        </div>
    );
}
