import React, { useState } from "react";
import { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { RwdContext } from "../../App";
import { ReactComponent as TooltipBG } from "../../assets/editor/img_tooltip.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hint: {
            position: "absolute",
            left: "86px",
            top: "calc(50% - 12px)",
            height: "100%",
        },
        hintmobile: {
            marginLeft: "8px",
            height: "100%",
        },
        circle: {
            position: "relative",
            margin: "auto 0",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            background: "#c2c2c2",
            "@media (max-width : 1280px)": {
                width: "20px",
                height: "20px",
            },
        },
        questionMark: {
            position: "absolute",
            top: "0.5px",
            left: "8.5px",
            color: "#FFFFFF",
            "@media (max-width : 1280px)": {
                top: "-0.5px",
                left: "7.0px",
            },
        },
        contentBG: {
            position: "absolute",
            zIndex: 2,
            top: "36px",
            left: "-104px",
        },
        content: {
            position: "absolute",
            zIndex: 3,
            top: "36px",
            left: "-104px",
            width: "420px",
            height: "100px",
            padding: "19px 12px 7px 9px",
            "@media (max-width : 1280px)": {
                width: "225px",
            },
        },
    })
);

export default function Hint(props: { text: string | React.ReactNode; style?: {} }) {
    const classes = useStyles();
    const device = useContext(RwdContext);
    const [open, setOpen] = useState(false);
    return (
        <div
            className={device === "PC" ? classes.hint : classes.hintmobile}
            style={props.style}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        >
            <div className={classes.circle}>
                <span className={classes.questionMark}>?</span>
                {open && (
                    <>
                        <div className={classes.content}>{props.text}</div>
                        <TooltipBG className={classes.contentBG} />
                    </>
                )}
            </div>
        </div>
    );
}
