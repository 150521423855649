import React, { useState, useEffect, useContext } from "react";

import { useIntl } from "react-intl";
import Dashboard from "../components/Dashboard";
import LoadingIcon from "../components/loading/LoadingIcon";
import { userInfo } from "os";
import { ROLE_TYPE } from "../interfaces/enums";
import { UserInfoContext, RwdContext } from "../App";

function DashboardPage(props) {
    const intl = useIntl();
    const userInfo = useContext(UserInfoContext);
    const device = useContext(RwdContext);
    return (
        <React.Fragment>
            <div
                className={device == "PC" ? "dashboardPageWrapper pageWrapper" : ""}
                style={device == "PC" ? {} : { width: "100%", height: "100vh" }}
            >
                <div
                    className={device == "PC" ? "title" : ""}
                    style={
                        device == "PC"
                            ? {
                                  display: "flex",
                                  justifyContent: "space-between",
                              }
                            : { fontSize: "18px", textAlign: "left", color: "#222222", marginLeft: "8px" }
                    }
                >
                    <span
                        style={
                            device == "PC"
                                ? {}
                                : { display: "block", fontWeight: "bold", fontSize: "22px", marginTop: "8px" }
                        }
                    >
                        {intl.formatMessage({ id: "editor.dashboard.2.001" })}
                    </span>
                    <span style={device == "PC" ? {} : { display: "block" }}>
                        {intl.formatMessage({ id: "editor.dashboard.NA.001" })}
                    </span>
                </div>
                <div
                    className={device == "PC" ? "pageContent" : ""}
                    style={device == "PC" ? {} : { width: "auto", height: "auto" }}
                >
                    <Dashboard
                        isAdmin={
                            userInfo.roles?.includes(ROLE_TYPE.ADMIN) ||
                            userInfo.roles?.includes(ROLE_TYPE.SUPERFOLLOW_ADMIN)
                        }
                        userId={userInfo.userId}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default DashboardPage;
