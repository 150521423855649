import {
    createStyles,
    IconButton,
    makeStyles,
    Theme,
    Grid,
    Button,
    Box,
    Input,
    InputAdornment,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { UserInfoContext, RwdContext } from "../App";
import ArticleList from "../components/ArticleList";
import StatusPopup from "../components/dialogs/StatusPopup";
import { articleListPopupStatus, ROLE_TYPE } from "../interfaces/enums";
import { ReactComponent as ListViewGreen } from "../assets/editor/bn_viewList_green.svg";
import { ReactComponent as ListViewGrey } from "../assets/editor/bn_viewList_grey.svg";
import { ReactComponent as GridViewGreen } from "../assets/editor/bn_viewModule_green.svg";
import { ReactComponent as GridViewGrey } from "../assets/editor/bn_viewModule_grey.svg";
import { UserSettings } from "../interfaces";
import SelectInputWithLabel from "../components/textfield/SelectInputWithLabel";
import { UtilFunctions } from "../utils/UtilFunctions";
import { ReactComponent as SearchIcon } from "../assets/admin/ic/Search.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mobileContainer: {
            width: "100%",
            height: "100%",
            backgroundColor: "#eeeeee",
        },
        heading: {
            display: "flex",
            justifyContent: "space-between",
            "& .ic": {
                padding: 0,
                "@media (max-width: 1208px)": {
                    order: 1,
                },
                "&:not(:last-child)": {
                    marginRight: "4px",
                },
            },
            "& .icGroup": {
                "@media (max-width: 1208px)": {
                    marginLeft: "auto",
                },
            },
            "& .searchBar": {
                height: "50px",
                padding: "8px 8px 8px 10px",
                borderRadius: "6px",
                backgroundColor: "white",
                marginLeft: "auto",
                "@media (max-width: 1208px)": {
                    order: 2,
                    width: "100%",
                    margin: "5px 10px 7px",
                },
            },
        },
        contentPreviewContainer: {
            "& .previewContentTitle": {
                fontSize: 15,
                fontWeight: 700,
                padding: "12px 12px 0px 12px",
            },
            "& .content": {
                width: "100%",
                padding: 10,
            },
        },
        iconRoot: {
            padding: 0,
        },
        FiltersItems: {
            marginLeft: 12,
            marginTop: 10,
            "@media (max-width: 1208px)": {
                fontSize: "14px",
                width: "95%",
                marginLeft: 12,
                marginBottom: 9,
            },
        },
        FiltersContainer: {
            width: "auto",
            height: "auto",
            backgroundColor: "#fff",
            borderRadius: 6,
            marginTop: 11,
            "@media (max-width: 1208px)": {
                flexDirection: "column",
                margin: "5px 10px 7px",
                borderRadius: "6px",
                backgroundColor: "rgb(255 255 255)",
            },
        },

        SearchIcon: {
            color: "#717171",
        },
    })
);

enum ArticlesViewMode {
    LIST_VIEW,
    GRID_VIEW,
}

function DropDownList(props) {
    const intl = useIntl();
    const classes = useStyles();

    if (props.placeholder) {
        return (
            <Grid item className={classes.FiltersItems}>
                <div>{intl.formatMessage({ id: props.text })}</div>
                <SelectInputWithLabel
                    placeholder={props.placeholder}
                    options={props.options}
                    value={props.value}
                    typeThreeUI={true}
                    disabled={!Boolean(props.options.length)}
                    onChange={(val) => {
                        props.func(val);
                    }}
                />
            </Grid>
        );
    } else {
        return (
            <Grid item className={classes.FiltersItems}>
                <div>{intl.formatMessage({ id: props.text })}</div>
                <SelectInputWithLabel
                    options={props.options}
                    value={props.value}
                    typeThreeUI={true}
                    disabled={!Boolean(props.options.length)}
                    onChange={(val) => {
                        props.func(val);
                    }}
                />
            </Grid>
        );
    }
}

function ArticlePage(props) {
    const { formatMessage } = useIntl();
    const location = useLocation<{ status: articleListPopupStatus }>();
    const userInfo = useContext(UserInfoContext);
    const classes = useStyles();
    const intl = useIntl();
    const device = useContext(RwdContext);
    const [isGridView, setIsGridView] = useState(true);
    const [openStatus, setOpenStatus] = useState(false);
    const [statusDisplayText, setStatusDisplayText] = useState("");
    const view_ref = React.useRef(isGridView);
    const [categoryCode, setCategoryCode] = useState<string>("ALL_CATEGORY");
    const [updateMonth, setUpdateMonth] = useState<string>("ALL_MONTH");
    const [uploadMonth, setUploadMonth] = useState<string>("ALL_MONTH");
    const [statusCode, setStatusCode] = useState<string>("ALL_STATUS");
    const [dropdown_Category, setDropdown_Category] = useState<Array<any>>([]);
    const [dropdown_UploadMonth, setDropdown_UploadMonth] = useState<Array<any>>([]);
    const [dropdown_UpdateMonth, setDropdown_UpdateMonth] = useState<Array<any>>([]);
    const [dropdown_Status, setDropdown_Status] = useState<Array<any>>([]);

    const [status, setStatus] = useState<Array<any>>([]);
    const [category, setCategory] = useState<Array<any>>([]);
    const [allData, setAllData] = useState<Array<Item>>([]);
    const init_dataForDropdown = (_obj: Array<any>) => {
        //console.log(_obj);
        setAllData(_obj);
    };
    const init_dataForStatus = (_obj: Array<any>) => {
        //console.log(_obj);
        setStatus(_obj);
    };
    const init_dataForCategory = (_obj: Array<any>) => {
        //console.log(_obj);
        setCategory(_obj);
    };

    interface Item {
        _id: string;
        zh?: any;
        en?: any;
        userName: string;
        updateDate: any;
        statusCode: string;
        isPublicContent: boolean;
        publishDate: any;
        category: Array<string>;
    }

    const init_dropdown_Category = () => {
        let list: Array<any> = [
            {
                name: "所有分類",
                value: "ALL_CATEGORY",
            },
        ];
        const categoryCodeList = new Set();

        category?.forEach((item) => {
            categoryCodeList.add(item?.zh?.name);
        });

        categoryCodeList.forEach((item) => {
            list.push({
                name: item,
                value: item,
            });
        });

        setDropdown_Category(list);
    };
    const init_dropdown_UploadMonth = () => {
        let list: Array<any> = [
            {
                name: "所有月份",
                value: "ALL_MONTH",
            },
        ];
        const uploadMonthList = new Set<string>();

        allData.forEach((item) => {
            const publishDate = item?.publishDate;
            const date = UtilFunctions.getDateFromTimestamp(publishDate).substring(3).replace("/", "-");
            var temp = date.substring(3, 7) + "-" + date.substring(0, 2);
            uploadMonthList.add(temp);
        });

        uploadMonthList.forEach((item) => {
            list.push({
                name: item,
                value: item,
            });
        });
        const allmonth = list.splice(0, 1);
        list.sort((a, b) => (b.name > a.name ? 1 : a.name > b.name ? -1 : 0));
        list.splice(0, 0, allmonth[0]);
        setDropdown_UploadMonth(list);
    };

    const init_dropdown_UpdateMonth = () => {
        let list: Array<any> = [
            {
                name: "所有月份",
                value: "ALL_MONTH",
            },
        ];
        const updateMonthList = new Set<string>();

        allData.forEach((item) => {
            const updateDate = item?.updateDate;
            const date = UtilFunctions.getDateFromTimestamp(updateDate).substring(3).replace("/", "-");
            var temp = date.substring(3, 7) + "-" + date.substring(0, 2);
            updateMonthList.add(temp);
        });

        updateMonthList.forEach((item) => {
            list.push({
                name: item,
                value: item,
            });
        });
        const allmonth = list.splice(0, 1);
        list.sort((a, b) => (b.name > a.name ? 1 : a.name > b.name ? -1 : 0));
        list.splice(0, 0, allmonth[0]);
        setDropdown_UpdateMonth(list);
    };

    const init_dropdown_Status = () => {
        let list: Array<any> = [
            {
                name: "所有狀態",
                value: "ALL_STATUS",
            },
        ];
        const statusCodeList = new Set();

        status?.forEach((item) => {
            statusCodeList.add(item?.zh?.statusCodeText);
        });

        statusCodeList.forEach((item) => {
            list.push({
                name: item,
                value: item,
            });
        });

        setDropdown_Status(list.sort());
    };

    useEffect(() => {
        init_dropdown_UploadMonth();
        init_dropdown_UpdateMonth();
    }, [allData]);

    useEffect(() => {
        init_dropdown_Category();
    }, [allData, category]);

    useEffect(() => {
        init_dropdown_Status();
    }, [allData, status]);
    useEffect(() => {
        let d = localStorage.getItem("user-settings");
        if (d) {
            try {
                let j: UserSettings = JSON.parse(d);
                if ("grid_view" in j) setIsGridView(j.grid_view);
            } catch {}
        }

        if (location.state?.status) {
            setOpenStatus(true);
            let statusDispalyText = "F";
            switch (location.state.status) {
                case articleListPopupStatus.SAVED_AS_DRAFT:
                    statusDispalyText = formatMessage({ id: "editor.allPosts.4.028" }); //已成功儲存草稿
                    break;

                case articleListPopupStatus.WAITING_FOR_APPROVAL:
                    statusDispalyText = formatMessage({ id: "newArticle.1.023" }); //上傳成功！正等待審批
                    break;

                case articleListPopupStatus.SAVED_UPDATES:
                    statusDispalyText = formatMessage({ id: "4.027" }); //更改成功！正等待審批
                    break;

                case articleListPopupStatus.APPROVAL_FINISHED:
                    statusDispalyText = formatMessage({ id: "admin.allPosts.2.033" }); //完成審批！
                    break;
            }
            setStatusDisplayText(statusDispalyText);
        }

        return () => {
            let p = localStorage.getItem("user-settings") ?? { grid_view: view_ref.current };
            if (typeof p === "string") {
                try {
                    let j: UserSettings = JSON.parse(p);
                    localStorage.setItem("user-settings", JSON.stringify({ ...j, grid_view: view_ref.current }));
                } catch {}
            } else localStorage.setItem("user-settings", JSON.stringify(p));
        };
    }, []);

    const changeUpdateMonth_handler = (val: any) => {
        setUpdateMonth(val);
    };
    const changeUploadMonth_handler = (val: any) => {
        setUploadMonth(val);
    };
    const changeCategory_handler = (val: any) => {
        setCategoryCode(val);
    };
    const changeStatus_handler = (val: any) => {
        setStatusCode(val);
    };
    useEffect(() => {
        view_ref.current = isGridView;
    }, [isGridView]);
    const [searchQuery, setSearchQuery] = useState("");
    const searchHandler = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (!openStatus) {
            window.history.replaceState({}, document.title);
        }
    }, [openStatus]);
    const switchViewMode = (view: ArticlesViewMode) =>
        view === ArticlesViewMode.GRID_VIEW ? setIsGridView(true) : setIsGridView(false);
    return (
        <React.Fragment>
            <div className={device == "PC" ? "articlePageWrapper pageWrapper" : `${classes.mobileContainer}`}>
                <div className={classes.heading}>
                    <Box display="flex" alignItems="center" flexWrap="wrap" width="100%" justifyContent="space-between">
                        <span
                            className="title"
                            style={
                                device == "PC"
                                    ? {}
                                    : {
                                          fontSize: "22px",
                                          fontWeight: "bold",
                                          margin: "8px 0 14px 10px",
                                          textAlign: "left",
                                          color: "#222222",
                                      }
                            }
                        >
                            {formatMessage({ id: "4.001" })}
                        </span>
                        <Input
                            className="searchBar"
                            value={searchQuery}
                            onChange={searchHandler}
                            disableUnderline={true}
                            type="text"
                            id="header-search"
                            placeholder={formatMessage({ id: "4.050" })}
                            name="s"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon className={classes.SearchIcon} />
                                </InputAdornment>
                            }
                        />
                        <div className="icGroup" style={device == "PC" ? {} : { margin: "5px 10px 7px" }}>
                            <IconButton
                                className="ic"
                                aria-label="list view"
                                onClick={() => switchViewMode(ArticlesViewMode.LIST_VIEW)}
                            >
                                {isGridView ? <ListViewGrey /> : <ListViewGreen />}
                            </IconButton>

                            <IconButton
                                className="ic"
                                aria-label="grid view"
                                onClick={() => switchViewMode(ArticlesViewMode.GRID_VIEW)}
                            >
                                {isGridView ? <GridViewGreen /> : <GridViewGrey />}
                            </IconButton>
                        </div>
                    </Box>
                </div>

                <Grid container justify="flex-start" className={classes.FiltersContainer}>
                    <DropDownList
                        text={"4.051"}
                        options={dropdown_Category}
                        value={categoryCode}
                        //placeholder={intl.formatMessage({ id: "4.055" })}
                        func={(val) => changeCategory_handler(val)}
                    />
                    <DropDownList
                        text={"4.052"}
                        options={dropdown_UploadMonth}
                        value={uploadMonth}
                        // placeholder={intl.formatMessage({ id: "4.056" })}
                        func={(val) => changeUploadMonth_handler(val)}
                    />
                    <DropDownList
                        text={"4.053"}
                        options={dropdown_UpdateMonth}
                        value={updateMonth}
                        //placeholder={intl.formatMessage({ id: "4.056" })}
                        func={(val) => changeUpdateMonth_handler(val)}
                    />
                    <DropDownList
                        text={"4.054"}
                        options={dropdown_Status}
                        value={statusCode}
                        //placeholder={intl.formatMessage({ id: "4.057" })}
                        func={(val) => changeStatus_handler(val)}
                    />
                </Grid>
                <div
                    className="pageContent"
                    style={
                        device == "PC" ? { marginTop: 18 } : { margin: "5px 10px 7px", width: "auto", height: "auto" }
                    }
                >
                    <ArticleList
                        admin={
                            userInfo.roles?.includes(ROLE_TYPE.ADMIN) ||
                            userInfo.roles?.includes(ROLE_TYPE.SUPERFOLLOW_ADMIN)
                        }
                        isGridView={isGridView}
                        filterCriteria={searchQuery}
                        getDropdownArticleData={init_dataForDropdown}
                        getDropdownCategoryData={init_dataForCategory}
                        getDropdownStatusData={init_dataForStatus}
                        filterCategory={categoryCode}
                        filterUploadMonth={uploadMonth}
                        filterUpdateMonth={updateMonth}
                        filterStatus={statusCode}
                    ></ArticleList>
                </div>
            </div>

            <StatusPopup
                openStatus={openStatus}
                content={statusDisplayText}
                toggleDialog={setOpenStatus}
                rwdStatus={device}
            />
        </React.Fragment>
    );
}

export default ArticlePage;
