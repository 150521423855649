import React, { useEffect, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { UtilFunctions } from "../../utils/UtilFunctions";
import { DragAndDropBannerUpload } from "../DragAndDropBannerUpload";
import { useIntl } from "react-intl";
import { StoreBannerComponentType, imageType } from "../../interfaces/enums";
import { ApiServiceContext, UnknowErrorDialogContext } from "../../App";
import { ReactComponent as WarningIcon } from "../../assets/store/ic_Warning.svg";
import { setSeconds } from "date-fns";
import { TIMEOUT } from "dns";
import { Bar } from "react-chartjs-2";
import { ReactComponent as ClockIc } from "../../assets/editor/ic/clock.svg";
import { ErrorDialog } from "../../components/dialogs/ErrorDialog";

interface UploadContainerProps {
    storeId: string;
    library: string[];
    maxSize: number;
    imageOnDelete?: (e) => void;
    Bannerid: string;
    getUploadStatus: boolean;
    newDialogHandler: (e) => void;
    imgSize: any[];
    handleChange: () => void;
    over5mb: (list) => void;
    over50images: () => void;
    dimensionError: (list) => void;
    formatError: (list) => void;
    disable?: boolean;
    isUploading: (status) => void;
    exitUploading: boolean;
}

const styles = {
    largeIcon: {
        fontWeight: "bold",
    },

    textstyle: {
        fontSize: "12.3px",
        color: "rgba(34, 34, 34, 0.6)",
    },
    errorBox: {
        padding: "0px 30px",
        backgroundColor: "#f5f5f5",
    },
};

export const UploadContainer = (props: UploadContainerProps) => {
    const refTemp = React.useRef(false);

    const apiService = useContext(ApiServiceContext);
    const intl = useIntl();
    const { Bannerid, imgSize, handleChange, maxSize, library, storeId, disable, exitUploading } = props;

    const [dimensionList, setdimensionList] = React.useState<Array<string>>([]);
    const [formatList, setformatList] = React.useState<Array<string>>([]);
    const [maxsizeList, setmaxsizeList] = React.useState<Array<string>>([]);
    const [end, setEnd] = React.useState(false);
    const [totalImg, setTotalImg] = React.useState<number>(0);
    const [uploadedImg, setUploadedImg] = React.useState<number>(0);
    const [startUpload, setStartUpload] = React.useState(false);

    const [FileFormatErrorMsg, setFileFormatErrorMsg] = React.useState("");
    const [ImageSizeDimensionErrorMsg, setImageSizeDimensionErrorMsg] = React.useState("");
    const [ImageSizeDimensionError, setImageSizeDimensionError] = React.useState(false);
    const [ImageFormatError, setImageFormatError] = React.useState(false);
    const [openValidateError, setOpenValidateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("general.1.002");

    const handlenoerror = () => {
        props.newDialogHandler(true);
    };

    const switchUploadDisplay = (bannerid: string) => {
        switch (bannerid) {
            case "1":
                return <>{intl.formatMessage({ id: "5.008" })}</>;
            case "2":
                return <>{intl.formatMessage({ id: "5.016" })}</>;
            case "3":
                return <>{intl.formatMessage({ id: "5.017" })}</>;
            case "4":
                return <>{intl.formatMessage({ id: "5.018" })}</>;
            case "5-6":
                return <>{intl.formatMessage({ id: "5.040" })}</>;
            case "7-8":
                return <>{intl.formatMessage({ id: "5.042" })}</>;
        }
    };

    var tempCount = 0;

    const handleFinishUpload = () => {
        setStartUpload(true);
        tempCount++;
        setUploadedImg(tempCount);
        if (tempCount === totalImg) {
            props.isUploading(false);
        }
    };

    const checkImageSizeDimension = (imageSize: any, img: any) => {
        let returnCount = 0;
        imageSize.forEach((element) => {
            if (element.width !== img.width || (Bannerid !== "1" && element.height !== img.height)) {
                returnCount += 1;
            }
        });
        return returnCount;
    };

    const loadImage = (f: File) =>
        new Promise<any>((resolve) => {
            let img = new Image();
            img.onload = () => resolve(img);
            img.src = window.URL.createObjectURL(f);
        });

    let invalidDimension = [""];
    let invalidFileFormat = [""];
    let invalidFileSize = [""];

    useEffect(() => {
        refTemp.current = exitUploading;
    }, [exitUploading]);

    let counter = 0;
    const uploadImage = async (f) => {
        // Validate image
        let file = f[counter];
        if (counter === 0) {
            setImageFormatError(false);
            setFileFormatErrorMsg("");
            setImageSizeDimensionErrorMsg("");
            setImageSizeDimensionError(false);
        }

        if (file && file.name && UtilFunctions.isValidImageExt(file.name)) {
            await loadImage(file).then(async (img) => {
                if (file.size > maxSize * 1048576) {
                    //added

                    if (invalidFileSize.length == 1)
                        if (invalidFileSize[0] === "") {
                            invalidFileSize = [file.name];
                        } else {
                            invalidFileSize = [...invalidFileSize, file.name];
                        }
                    else {
                        invalidFileSize = [...invalidFileSize, file.name];
                    }
                    setmaxsizeList(invalidFileSize);
                    counter++;

                    if (counter < f.length) {
                        uploadImage(f);
                    }
                } else if (checkImageSizeDimension(imgSize, img) === imgSize.length) {
                    setImageSizeDimensionError(true);
                    if (invalidDimension.length == 1)
                        if (invalidDimension[0] === "") {
                            invalidDimension = [file.name];
                        } else {
                            invalidDimension = [...invalidDimension, file.name];
                        }
                    else {
                        invalidDimension = [...invalidDimension, file.name];
                    }

                    setdimensionList(invalidDimension);
                    setImageSizeDimensionErrorMsg(intl.formatMessage({ id: "text_605c550654115400ca1c9a40" }));
                    counter++;
                    if (counter < f.length) {
                        uploadImage(f);
                    }
                } else {
                    if (storeId) {
                        let component_type_test: imageType = imageType.TYPE_1;
                        if (Bannerid === "1") component_type_test = imageType.TYPE_1;
                        else if (Bannerid === "2") component_type_test = imageType.TYPE_2;
                        else if (Bannerid === "3") component_type_test = imageType.TYPE_3;
                        else if (Bannerid === "4") component_type_test = imageType.TYPE_4;
                        else if (Bannerid === "5-6" && img.width === 355) component_type_test = imageType.TYPE_5;
                        else if (Bannerid === "5-6" && img.width === 833) component_type_test = imageType.TYPE_6;
                        else if (Bannerid === "7-8" && img.width === 485) component_type_test = imageType.TYPE_7;
                        else if (Bannerid === "7-8" && img.width === 703) component_type_test = imageType.TYPE_8;

                        let data = new FormData();
                        data.append("imageFiles", f[counter]);
                        data.append("type", component_type_test);
                        data.append("store", storeId);
                        setTotalImg(f.length);
                        if (!refTemp.current) {
                            await apiService
                                .postBannerList(data)
                                .then((res) => {
                                    handleFinishUpload();

                                    if (res.data.status === "success") {
                                        props.handleChange();
                                        if (
                                            invalidDimension[0] === "" &&
                                            invalidFileFormat.length === 1 &&
                                            counter === f.length - 1
                                        ) {
                                            handlenoerror();
                                        }
                                    } else {
                                        let failMsg = res.data;
                                        if (failMsg.code === "IMAGES_UPLOAD_QUOTA_FULL") props.over50images();
                                        else if (failMsg.code === "MAX_IMAGE_FILE_SIZE") props.over5mb(maxsizeList);
                                        else if (failMsg.code === "IMAGE_DIMENSION_UNMATCH") {
                                            setImageSizeDimensionError(true);
                                            if (invalidDimension.length == 1)
                                                if (invalidDimension[0] === "") {
                                                    invalidDimension = [file.name];
                                                } else {
                                                    invalidDimension = [...invalidDimension, file.name];
                                                }
                                            else {
                                                invalidDimension = [...invalidDimension, file.name];
                                            }
                                            setdimensionList(invalidDimension);
                                            setImageSizeDimensionErrorMsg(intl.formatMessage({ id: "5.014" }));
                                        } else if (failMsg.code === "IMAGE_EXTENSION_UNSUPPORTED") {
                                            if (invalidFileFormat.length == 1)
                                                if (invalidFileFormat[0] === "") {
                                                    invalidFileFormat = [file.name];
                                                } else {
                                                    invalidFileFormat = [...invalidFileFormat, file.name];
                                                }
                                            else {
                                                invalidFileFormat = [...invalidFileFormat, file.name];
                                            }
                                            setImageFormatError(true);
                                            setFileFormatErrorMsg(intl.formatMessage({ id: "5.028" }));
                                        } else {
                                            throw new Error("Error Unknown");
                                        }
                                    }
                                })
                                .catch((err) => {
                                    setErrMsg("general.1.002");
                                    setOpenValidateError(true);
                                })
                                .finally(() => {
                                    counter++;
                                    if (counter < f.length) {
                                        uploadImage(f);
                                    }
                                });
                        }
                    } else {
                        console.log("ERROR: Cannot get storeid");
                    }
                }

                if (counter >= f.length) {
                    setEnd(true);
                }
            });
        } else {
            var fileExtension = file.name.split(".").pop();
            if (fileExtension !== "png" && fileExtension !== "jpg") {
                if (invalidFileFormat.length == 1)
                    if (invalidFileFormat[0] === "") {
                        invalidFileFormat = [file.name];
                    } else {
                        invalidFileFormat = [...invalidFileFormat, file.name];
                    }
                else {
                    invalidFileFormat = [...invalidFileFormat, file.name];
                }
                setformatList(invalidFileFormat);
                setImageFormatError(true);
                setFileFormatErrorMsg(intl.formatMessage({ id: "5.028" }));
                counter++;
                if (counter < f.length) {
                    uploadImage(f);
                }
                if (counter >= f.length) {
                    setEnd(true);
                }
            }
        }
    };

    const onImageDrag = async (f: FileList) => {
        let tempLength = library.length > 1 ? library[1].length : 0;
        if (f.length + library[0].length + tempLength > 100) {
            props.over50images();
            return;
        } else {
            props.isUploading(true);
            uploadImage(f);
        }
    };

    useEffect(() => {
        if (end) checkError();
    }, [end, formatList, dimensionList, dimensionList]);

    useEffect(() => {
        props.isUploading(false);
    }, []);

    const classes = styles;

    const checkError = () => {
        if (end) {
            if (formatList.length >= 1 && formatList[0] !== "") props.formatError(formatList);
            if (maxsizeList.length >= 1 && maxsizeList[0] !== "") props.over5mb(maxsizeList);
            if (dimensionList.length >= 1 && dimensionList[0] !== "") props.dimensionError(dimensionList);
        }
    };

    return (
        <>
            <ErrorDialog
                openStatus={openValidateError}
                toggleDialog={() => setOpenValidateError(false)}
                confirmHandler={() => {
                    setOpenValidateError(false);
                }}
                title={intl.formatMessage({ id: "editor.liveBooking.1.023" })}
                content={
                    <div style={{ height: "82px" }}>{intl.formatMessage({ id: errMsg }, { lineBreak: <br /> })}</div>
                }
                confirmBtnText={intl.formatMessage({ id: "editor.liveBooking.1.039" })}
            />
            <Box id="error" bgcolor="white" padding="inherit">
                <Box padding="10px 0px" color="rgba(34, 34, 34, 0.6)" className="classes.textstyle">
                    <div>{intl.formatMessage({ id: "5.007" })}</div>
                    <div> {switchUploadDisplay(Bannerid)}</div>

                    {startUpload && (
                        <div style={{ color: "#3A993A", display: "flex", verticalAlign: "middle" }}>
                            <div style={{ marginRight: "5px" }}>
                                <ClockIc />
                            </div>
                            {intl.formatMessage(
                                { id: "5.030" },
                                {
                                    totalImg: totalImg,
                                    uploadedImg: uploadedImg,
                                }
                            )}
                        </div>
                    )}
                </Box>
                <div style={{ height: "500px" }}>
                    <DragAndDropBannerUpload disabled={disable} onImageDrag={onImageDrag} maxFileSize={5} />
                </div>
            </Box>
        </>
    );
};
